import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles,
  CardHeader
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import GetAppIcon from '@material-ui/icons/GetApp';
import CardMedia from "@material-ui/core/CardMedia";
import * as FA from 'react-fontawesome';
import {connect} from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1),
    transform: 'scale(1.25)'
  }
}));

const ProductCard = ({ className, storeInfo, product, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardMedia
        component="img"
        alt={product.title}
        height="240"
        image={product.images[0].url}
        title={product.title}
      />
      <CardContent>
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h4"
        >
          {product.title}
        </Typography>
        {/*<Typography*/}
        {/*  align="center"*/}
        {/*  color="textSecondary"*/}
        {/*  variant="body2"*/}
        {/*>*/}
        {/*  {product.description}*/}
        {/*</Typography>*/}
      </CardContent>
      <Box flexGrow={1} />
      <Divider />
      <Box p={2}>
        <Grid
          container
          justify="space-between"
          spacing={2}
        >
          <Grid
            className={classes.statsItem}
            item
          >
            {/*<AccessTimeIcon*/}
            {/*  className={classes.statsIcon}*/}
            {/*  color="action"*/}
            {/*/>*/}

            <Typography
              color="textSecondary"
              display="inline"
              variant="body2"
            >
              <FA className={classes.statsIcon}
                name="money"></FA>
               {` ${storeInfo.storeCurrencySymbol} `+ product.price}
            </Typography>
          </Grid>
          <Grid
            className={classes.statsItem}
            item
          >
            <FA className={classes.statsIcon}
              name="bullhorn"></FA>
            <Typography
              color="textSecondary"
              display="inline"
              variant="body2"
            >
              {product.status}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

ProductCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  storeInfo: state.currentStore.storeInfo,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
