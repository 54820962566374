const Apps = [
    {
        name: "Messenger",
        logo: "messenger.png",
        controls: [
            {
                type: "text",
                name: "pageId",
                label: "Page Id"
            }
        ]
    }
]

export default Apps;
