const INITIAL_STATE = {
  storeInfo: {
    ownerFirstName: '',
    ownerLastName: '',
    storeEmail: '',
    storeName: '',
    storeAddress: '',
    colors: {
      primary: "#000000",
    }
  },
  categories: [],
  products: [],
  orders: [],
  notifications: [],
  members:[]
}

// eslint-disable-next-line import/prefer-default-export
export const currentStoreReducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case 'SET_STORE_INFO':
      return {
        ...state,
        storeInfo: {
          ...action.payload
        }
      };
    case 'FETCH_STORE_CATEGORIES_SUCCESS':
      return {
        ...state,
        categories: [...action.payload]
      };
    case 'FETCH_STORE_CATEGORIES_ERROR':
      return {
        ...state,
        categories: []
      };
    case 'FETCH_STORE_PRODUCTS_SUCCESS':
      return {
        ...state,
        products: [...action.payload]
      };
    case 'FETCH_STORE_PRODUCTS_ERROR':
      return {
        ...state,
        products: []
      };
    case 'FETCH_STORE_ORDERS_SUCCESS':
      return {
        ...state,
        orders: [...action.payload]
      };
    case 'FETCH_STORE_ORDERS_ERROR':
      return {
        ...state,
        orders: []
      };
    case 'FETCH_STORE_NOTIFICATIONS_SUCCESS':
      return {
        ...state,
        notifications: [...action.payload]
      };
    case 'FETCH_STORE_NOTIFICATIONS_ERROR':
      return {
        ...state,
        notifications: []
      };
      case 'FETCH_STORE_MEMBERS_SUCCESS':
      return {
        ...state,
        members: [...action.payload]
      };
    case 'FETCH_STORE_MEMBERS_ERROR':
      return {
        ...state,
        members: []
      };
    default:
      return state;
  }
}
