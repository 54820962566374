import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import CustomerListView from 'src/views/customer/CustomerListView';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import RegisterView from 'src/views/auth/RegisterView';
import SettingsView from 'src/views/settings/SettingsView/settings-view';
import PaymentMethodSettings from 'src/views/settings/SettingsView/payment-method';
import RefundSettings from 'src/views/settings/SettingsView/refund-settings';
import StatsView from 'src/views/stats/stat-view';
import ProductDesignerView from './views/product/product-designer-view/product-designer-view';
import ProductDetailsView from './views/product/product-details-view/product-details-view';
import AddCategoryForm from './views/product/product-designer-view/add-category-form';
import OrderListView from './views/order/OrderListView';
import OrderDetailView from './views/order/order-detail-view/order-detail-view';
import RefundRequestListView from './views/refund-request/refund-request-list-view';
import RefundDetailView from './views/refund-request/refund-request-detail-view';
import VerifyView from './views/account/AccountView/account-verification';
import AddDiscountForm from './views/discount/add-discount-form/add-discount-form';
import DiscountList from './views/discount/discount-list-view/discount-list-view';
import PosDiscountList from './views/pos/discount-list/discount-list';
import PosProductList from './views/pos/product/ProductListView';
import PosProductDetailsView from './views/pos/product/product-details-view/product-details-view';
import InvoiceList from './views/invoices/invoice-list-view/invoice-list-view';
import StoreLayout from './layouts/StoreLayout';
import Checkout from './views/pos/checkout/checkout.page';
import BillingView from './views/billing/billing';
import ForgotPassword from './views/auth/forgot-password';
import SocialsLinks from './views/settings/SettingsView/socials';
import Integrations from './views/settings/SettingsView/integretions/integrations';
import ThemeDesigner from './views/account/AccountView/theme-designer';
import InvoiceDesigner from './views/invoices/invoice-designer-view/invoice-designer-view';



const routes = [

  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      {
        path: 'account',
        children: [
          { path: '/', element: <AccountView /> },
          { path: '/themeDesigner', element: <ThemeDesigner /> },
          { path: '/verify', element: <VerifyView /> }
        ]
      },
      { path: 'customers', element: <CustomerListView /> },
      { path: 'billing', element: <BillingView /> },
      {
        path: 'orders',
        children: [
          {
            path: '/',
            element: <OrderListView />
          },
          {
            path: '/:orderId',
            element: <OrderDetailView />
          }
        ]
      },
      {
        path: 'refundRequests',
        children: [
          {
            path: '/',
            element: <RefundRequestListView />
          },
          {
            path: '/:refundRequestId',
            element: <RefundDetailView />
          }
        ]
      },
      { path: 'dashboard', element: <DashboardView /> },
      {
        path: 'discounts',
        children: [
          { path: '/', element: <DiscountList /> },
          { path: '/designer', element: <AddDiscountForm /> }
        ]
      },
      {
        path: 'invoices',
        children: [
          { path: '/', element: <InvoiceList /> },
          { path: '/designer', element: <InvoiceDesigner /> }
        ]
      },
      {
        path: 'products',
        children: [
          {
            path: '/',
            element: <ProductListView />
          },
          {
            path: '/categoryDesigner',
            element: <AddCategoryForm />
          },
          {
            path: '/:category',
            element: <ProductListView />
          },
          {
            path: '/designer',
            element: <ProductDesignerView />
          },
          {
            path: '/:category/:productId',
            element: <ProductDetailsView />
          },
          {
            path: 'editProduct/:category/:productId',
            element: <ProductDetailsView />
          }
        ]
      },
      {
        path: 'settings',
        children: [
          {
            path: '/',
            element: <SettingsView />
          },
          {
            path: '/paymentMethods',
            element: <PaymentMethodSettings />
          },
          {
            path: 'refundSettings',
            element: <RefundSettings />
          },
          {
            path: 'socials',
            element: <SocialsLinks/>
          },
          {
            path: 'integrations',
            element: <Integrations/>
          }
        ]
      },
      { path: 'stats', element: <StatsView /> },
      // {path: '*', element: <Navigate to="/404"/>}
    ]
  },
  {
    path: "pos",
    element: <StoreLayout />,
    children: [
      {
        path: "/products",
        children: [
          {
            path: "/",
            element: <PosProductList />,
          },
          {
            path: "/:category",
            element: <PosProductList />,
          },
          {
            path: "/:category/:productId",
            element: <PosProductDetailsView />,
          },
        ],
      },
      {
        path: "/discountOffers",
        children: [
          {
            path: "/",
            element: <PosDiscountList />,
          },
          {
            path: "/:category/:productId",
            element: <ProductDetailsView />,
          },
        ],
      },
      {
        path: "/checkout",
        element: <Checkout />,
      },
    ],
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: 'forgotPassword', element: <ForgotPassword /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/login" /> }
    ]
  }
];

export default routes;
