import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    makeStyles,
    TextField,
    Card,
    MenuItem,
    CardHeader,
    Divider,
    Typography,
    Chip,
    Button
} from '@material-ui/core';
import Page from 'src/components/Page';
import Notifications from '../Notifications';
import Password from '../Password';
import { connect } from 'react-redux';
import axios from 'axios';
import { SetStoreInfo } from '../../../../redux/current-store/current-store-actions';
import { SetErrors, SetSnackNotice } from '../../../../redux/app/app-actions';
import * as _ from 'lodash';
import { validateData } from 'src/utils/validation';
import { Facebook, Instagram } from '@material-ui/icons';
import YouTubeIcon from '@material-ui/icons/YouTube';
import IntegrationSelection from './integration-selection';
import TemplateFormGenerator from 'src/components/template-form-generator/template-form-generator';
import Apps from './integration-apps';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    currencyContainer: {
        padding: theme.spacing(2)
    },
    currency: {
        marginLeft: theme.spacing(1)
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText
    },
    paymentMethodBox: {
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    paymentMethodLogo: {
        height: '100px',
        width: 'auto',
    },
    errorText: {
        color: theme.palette.error.main,
    },
    socialLinks: {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(1, 0),
        '& svg': {
            marginRight: theme.spacing(2)
        }
    }
}));

const Integrations = ({
    storeInfo,
    setStoreInfo,
    setErrors,
    setSnackNotice
}) => {
    const classes = useStyles();
    const [selectedIntegration, setSelectedIntegration] = useState('');
    const [existingIntegrations, setExistingIntegrations] = useState([]);
    const [integrationForm, setIntegrationForm] = useState('');


    useEffect(() => {
        getExistingIntegrations();
    }, []);


    useEffect(() => {
        if (selectedIntegration && selectedIntegration.length) {
            console.log(existingIntegrations);
            setIntegrationForm(<TemplateFormGenerator controls={Apps.find(app => app.name === selectedIntegration).controls} submit={addIntegration} submitLabel={`Integrate ${selectedIntegration}`} existingObject={existingIntegrations.find(integration => integration.name === selectedIntegration) || {}} formHeader={`Integrate ${selectedIntegration}`} />);
        }
        else {
            setIntegrationForm(null);
        }
    }, [selectedIntegration])


    const getExistingIntegrations = async () => {
        try {
            const integrations = await axios({
                url: 'https://kripson-store-server-8qq76.ondigitalocean.app/stores/getIntegrations',
                method: 'POST',
                data: {
                    sessionId: storeInfo.sessionId,
                    storeId: storeInfo._id,
                }
            });

            if (integrations.data.status === 1) {
                setSnackNotice({
                    message: integrations.data.message,
                    severity: 'success'
                });
                setExistingIntegrations(integrations.data.result);
            } else if (integrations.data.status === 3) {
                setStoreInfo({
                    ownerFirstName: '',
                    ownerLastName: '',
                    storeEmail: '',
                    storeName: '',
                    storeAddress: '',
                    colors: {primary: '#000000'}
                });
                setSnackNotice({
                    severity: 'warning',
                    message: integrations.data.message
                });
            } else {
                setErrors(integrations.data.errors);
            }
        }
        catch (e) {
            setErrors(["Something went wrong, please try again."]);
        }
    }


    const addIntegration = async (newIntegration) => {
        try {
            const integrations = await axios({
                url: 'https://kripson-store-server-8qq76.ondigitalocean.app/stores/addIntegration',
                method: 'POST',
                data: {
                    sessionId: storeInfo.sessionId,
                    storeId: storeInfo._id,
                    newIntegration: {
                        name: selectedIntegration,
                        ...newIntegration
                    }
                }
            });

            if (integrations.data.status === 1) {
                setSnackNotice({
                    message: integrations.data.message,
                    severity: 'success'
                });
                getExistingIntegrations();
                setSelectedIntegration(null);
            } else if (integrations.data.status === 3) {
                setStoreInfo({
                    ownerFirstName: '',
                    ownerLastName: '',
                    storeEmail: '',
                    storeName: '',
                    storeAddress: '',
                    colors: {primary: '#000000'}
                });
                setSnackNotice({
                    severity: 'warning',
                    message: integrations.data.message
                });
            } else {
                setErrors(integrations.data.errors);
            }
        }
        catch (e) {
            setErrors(["Something went wrong, please try again."]);
        }
    }


    return (
        <Page className={classes.root} title="Settings">
            <Container maxWidth="lg">
                <Box mb={3}>
                    <Typography color="textPrimary" variant="h2">
                        Integrations
                    </Typography>
                    <Typography color="textSecondary" gutterBottom variant="body2">
                        Connect your store to third party services
                    </Typography>
                </Box>

                {
                    selectedIntegration && selectedIntegration.length && integrationForm ?
                        <>
                            <Box mb={1} display="flex" justifyContent="space-between" flexWrap="wrap">
                                <Button  size="large" onClick={() => setSelectedIntegration('')} >
                                    Back
                                </Button>
                                <Button  size="large" href={`/app/tutorials/messengerSetup`}>
                                    Click to see the tutorial
                                </Button>
                            </Box>

                            {integrationForm}
                        </>
                        :
                        <IntegrationSelection setSelectedIntegration={setSelectedIntegration} />
                }



            </Container>
        </Page>
    );
};

const mapStateToProps = state => ({
    storeInfo: state.currentStore.storeInfo
});

const mapDispatchToProps = dispatch => ({
    setStoreInfo: storeInfo => dispatch(SetStoreInfo(storeInfo)),
    setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
    setErrors: errors => dispatch(SetErrors(errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(Integrations);
