import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Hidden, makeStyles } from '@material-ui/core';

import Page from 'src/components/Page';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import AwesomeSliderStyles from 'react-awesome-slider/src/styled/fold-out-animation/fold-out-animation.scss';
import '../../../customStyles.scss';
import './product-details-view.scss';
import { useNavigate, useParams } from 'react-router-dom';
import {
  FetchStoreProductsStart,
  SetStoreInfo
} from '../../../redux/current-store/current-store-actions';
import { SetErrors, SetSnackNotice } from '../../../redux/app/app-actions';
import { connect } from 'react-redux';
import axios from 'axios';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import * as Swal from 'sweetalert2';
import EditProductForm from '../product-designer-view/edit-product-form';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    maxWidth: '100vw',
    overflow: 'hidden',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productDetailsSection: {
    padding: '20px !important',
    display: 'grid',
    alignContent: 'start',
    gridRowGap: '20px',
    marginBottom: '25px'
  },
  productColor: {
    height: '25px',
    width: '25px',
    borderRadius: '25px'
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    marginLeft: theme.spacing(1)
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    marginLeft: theme.spacing(1)
  },
  errorSmall: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: 'white',
    marginLeft: theme.spacing(1)
  },
  slider: {
    display: 'none'
  },
  sizeOptionContainer: {
    display: 'flex'
  },
  sizeChoice: {
    backgroundColor: theme.palette.background.paper,
    display: 'grid',
    justifyContent: 'center',
    alignContent: 'center',
    boxShadow: '0 0 5px 0 rgba(0,0,0,0.15)',
    borderRadius: '5px',
    minHeight: '25px',
    padding: theme.spacing(1),
    marginRight: theme.spacing(1)
  },

  detailsSectionRows: {}
}));

const ProductDetailsView = ({
  storeId,
  storeInfo,
  setSnackNotice,
  setStoreInfo,
  setErrors,
}) => {
  const classes = useStyles();

  const { category, productId } = useParams();
  const navigate = useNavigate();

  const [product, setProduct] = useState({});
  const [mode, setMode] = useState('detailMode');

  const retrieveProduct = (productId, storeId) => {
    axios({
      url:
        'https://kripson-store-server-8qq76.ondigitalocean.app/products/getProductById',
      method: 'POST',
      data: {
        sessionId: storeInfo.sessionId,
        storeId: storeId,
        productId: productId
      }
    }).then(response => {
      if (response.data.status === 1) {
        setProduct(response.data.result[0]);
      } else if (response.data.status === 3) {
        setStoreInfo({
          ownerFirstName: '',
          ownerLastName: '',
          storeEmail: '',
          storeName: '',
          storeAddress: '',
          colors: {primary: '#000000'}
        });
        setSnackNotice({
          severity: 'warning',
          message: response.data.message
        });
      } else {
        setErrors(response.data.errors);
      }
    });
  };

  useEffect(() => {
    if (productId && storeId) {
      retrieveProduct(productId, storeId);
    }
  }, [productId, storeId]);

  useEffect(() => {
    if (mode === 'detailMode' && productId && storeId) {
      retrieveProduct(productId, storeId);
    }
  }, [mode]);

  const deleteProduct = async productId => {
    setSnackNotice({
      message: `Deleting product`,
      severity: 'info'
    });

    const response = await axios({
      url:
        'https://kripson-store-server-8qq76.ondigitalocean.app/products/removeProduct',
      method: 'POST',
      onUploadProgress: progressEvent => console.log(progressEvent),
      data: {
        sessionId: storeInfo.sessionId,
        productId,
        storeId
      }
    });

    if (response.data.status === 1) {
      navigate(`/app/products/${category}`);

      setSnackNotice({
        message: `Product deleted`,
        severity: 'success'
      });
    } else if (response.data.status === 3) {
      setStoreInfo({
        ownerFirstName: '',
        ownerLastName: '',
        storeEmail: '',
        storeName: '',
        storeAddress: '',
        colors: {primary: '#000000'}
      });
      setSnackNotice({
        severity: 'warning',
        message: response.data.message
      });
    } else {
      setErrors(response.data.errors);
    }
  };

  const fireDeleteConfirmation = productId => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.isConfirmed) {
        deleteProduct(productId);

        // await Swal.fire(
        //   'Deleted!',
        //   'Your file has been deleted.',
        //   'success'
        // )
      }
    });
  };

  return mode === 'editMode' ? (
    product ? (
      <EditProductForm product={product} setMode={setMode} />
    ) : (
      ''
    )
  ) : (
    <Page className={classes.root} title="Product Details">
      <Grid container spacing={1}>
        <Grid item xs={12} md={7} className={classes.productDetailsSection}>
          <AwesomeSlider
            animation={'foldOutAnimation'}
            className={'slider'}
            media={
              product.images
                ? product.images.map((image, index) => {
                    return {
                      source: image.url
                    };
                  })
                : []
            }
          />
        </Grid>
        <Grid item xs={12} md={5} className={classes.productDetailsSection}>
          <Typography
            align="left"
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            {product.title}
          </Typography>

          <Typography
            align="left"
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            {product.description}
          </Typography>

          <Typography
            align="left"
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
              {storeInfo.storeCurrencySymbol} {product.price ? product.price.toLocaleString('en-IN') : ''}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            Primary color:
          </Typography>
          <span
            className={classes.productColor}
            style={{ backgroundColor: product.color ? product.color : '' }}
          ></span>

          <Typography variant="h5" color="textPrimary">Available Sizes:</Typography>
          <div className={classes.sizeOptionContainer}>
            {product.sizes
              ? product.sizes.map((size, index) => (
                  <Typography
                    align="left"
                    color="textPrimary"
                    gutterBottom
                    variant="body2"
                    className={classes.sizeChoice}
                  >
                    {size}
                  </Typography>
                ))
              : ''}
          </div>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Button  size="large"
                fullWidth
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                onClick={() => setMode('editMode')}
              >
                Edit Product
              </Button>
            </Grid>
            <Hidden smDown>
              <Grid item xs={12} sm={6}>
                <Button  size="large"
                  fullWidth
                  variant="contained"
                  className={classes.error}
                  startIcon={<DeleteIcon />}
                  onClick={() => fireDeleteConfirmation(productId)}
                >
                  Delete Product
                </Button>
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Grid item xs={12} sm={6}>
                <Button  size="large"
                  fullWidth
                  variant="contained"
                  className={classes.errorSmall}
                  startIcon={<DeleteIcon />}
                  onClick={() => fireDeleteConfirmation(productId)}
                >
                  Delete Product
                </Button>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
      <style jsx>
        {`
                .slider{
                  --loader-bar-color: ${storeInfo.colors.primary} !important;
                },
              `}
      </style>
    </Page>
  );
};

const mapStateToProps = state => ({
  storeId: state.currentStore.storeInfo._id,
  storeInfo: state.currentStore.storeInfo
});

const mapDispatchToProps = dispatch => ({
  FetchStoreProductsStart: requiredInfo =>
    dispatch(FetchStoreProductsStart(requiredInfo)),
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setErrors: errors => dispatch(SetErrors(errors)),
  setStoreInfo: store => dispatch(SetStoreInfo(store))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailsView);
