import {combineReducers} from  'redux';
// import {persistReducer} from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import {currentStoreReducer} from './current-store/current-store-reducer';
import {appReducer} from "./app/app-reducer";
import { cartReducer } from './cart/cart-reducer';

//
// const persistConfig = {
//
// 	key:'root',
// 	storage,
// 	whitelist:['cart']
//
// }


const rootReducer = combineReducers({
	currentStore: currentStoreReducer,
  cart:cartReducer,
  app: appReducer,
});


export default rootReducer;
