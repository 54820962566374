import React, { useEffect, useState } from "react";
import "./checkout-item.styles.scss";

import { connect } from "react-redux";
import { RemoveItem } from "../../redux/cart/cart.actions.js";
import { DecreaseQuantity } from "../../redux/cart/cart.actions.js";
import { AddItem } from "../../redux/cart/cart.actions.js";
import DeleteIcon from "@material-ui/icons/Delete";
import { Typography } from "@material-ui/core";
import Decimal from "decimal.js-light";

function CheckoutItem({ cartItem, RemoveItem, storeInfo, DecreaseQuantity, AddItem, storeId }) {
  const { title, images, price, selectedSize } = cartItem.item;
  const [totalDiscountedAmount, setTotalDiscountedAmount] = useState('0');
  const { quantity, discountData } = cartItem;

  useEffect(() => {

    let total = new Decimal('0');
    if(cartItem.hasOwnProperty('discountData'))
    {
      for(const key in discountData){
        total = total.plus(new Decimal(discountData[key].discountAmountApplied));
      }
    }
    setTotalDiscountedAmount(total.toString());
  },[cartItem, discountData])

  
  return (
    <div className="checkout-item">
      <div className="image-container">
        <img src={images[0].url} alt="item" />
      </div>
      <Typography className="title" color="textPrimary" variant="h5">
        {title}
      </Typography>
      <span className="quantity">
        <div className="arrow" onClick={() => DecreaseQuantity(cartItem.item, storeId)}>
          &#10094;
        </div>
        <Typography className="value" color="textPrimary" variant="h5">
          {quantity}
        </Typography>
        {
          quantity !== cartItem.item.stockQuantity[cartItem.item.selectedSize] ? <div className="arrow" onClick={() => AddItem(cartItem.item, storeId)}>
          &#10095;
        </div> : ''
        }
      </span>
      <Typography className="price" color="textPrimary" variant="h5">
        {storeInfo.storeCurrencySymbol}{totalDiscountedAmount !== '0'? 
         <><del>{new Decimal(price).times(quantity).toString()}</del> <ins style={{color:'red'}}>{new Decimal(price).times(quantity).sub(totalDiscountedAmount).toString()}</ins></>
    
        :new Decimal(price).times(quantity).toString()}
       </Typography>
      <Typography className="size" color="textPrimary" variant="h5">
        {selectedSize}
      </Typography>
      <DeleteIcon className="remove-button" onClick={() => RemoveItem(cartItem.item, storeId)} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  storeId: state.currentStore.storeInfo._id,
  storeInfo: state.currentStore.storeInfo,
  stores: state.cart.stores,
  storesCart: state.cart.stores
});

const mapDispatchToProps = (dispatch) => ({
  RemoveItem: (item, storeId) => dispatch(RemoveItem(item, storeId)),
  AddItem: (item, storeId) => dispatch(AddItem(item, storeId)),
  DecreaseQuantity: (item, storeId) => dispatch(DecreaseQuantity(item, storeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutItem);
