import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    makeStyles,
    TextField,
    Card,
    MenuItem,
    CardHeader,
    Divider,
    Typography,
    Chip,
    Button
} from '@material-ui/core';
import Page from 'src/components/Page';
import Notifications from './Notifications';
import Password from './Password';
import { connect } from 'react-redux';
import axios from 'axios';
import { SetStoreInfo } from '../../../redux/current-store/current-store-actions';
import { SetErrors, SetSnackNotice } from '../../../redux/app/app-actions';
import * as _ from 'lodash';
import FlagIcon from '../../../components/flag-component/flag-component';
import { flagCurrencyMap } from '../../../flagCurrencyMap';
import PaymentMethodSetupForm from 'src/components/payment-method-setup-form/payment-method-setup-form';
import countryPaymentMethodMap from './country-payment-method-map';
import { validateData } from 'src/utils/validation';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    currencyContainer: {
        padding: theme.spacing(2)
    },
    currency: {
        marginLeft: theme.spacing(1)
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText
    },
    paymentMethodBox: {
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    paymentMethodLogo: {
        height: '100px',
        width: 'auto',
    },
    errorText: {
        color: theme.palette.error.main,
    },
    policyCard: {
        padding: theme.spacing(1)
    }
}));





const RefundSettings = ({ storeInfo,
    setStoreInfo,
    setErrors,
    setSnackNotice }) => {
    const classes = useStyles();
    const [newRefundDuration, setNewRefundDuration] = useState(null);
    const [newRefundPolicies, setNewRefundPolicies] = useState('');





    useEffect(() => {
        setNewRefundDuration(storeInfo.refundDuration || 0);
        setNewRefundPolicies(storeInfo.refundPolicies || '');
    }, [storeInfo]);


    useEffect(() => {
        console.log(newRefundPolicies);
    }, [newRefundPolicies])

    const updateStoreInfo = async () => {
        if (storeInfo) {

            const validationResponse = await validateData('updateStore', {
                ...storeInfo,
                refundDuration: newRefundDuration || storeInfo.refundDuration,
                refundPolicies: newRefundPolicies || storeInfo.refundPolicies,
            });

            console.log(validationResponse);

            if (!validationResponse.validity) {
                setErrors(validationResponse.errors);
                return;
            }

            setSnackNotice({
                message: 'Updating store information',
                severity: 'warning'
            });
            console.log(newRefundDuration);
            axios({
                url:
                    'https://kripson-store-server-8qq76.ondigitalocean.app/stores/updateStoreInfo',
                method: 'POST',
                data: {
                    ...storeInfo,
                    refundDuration: (newRefundDuration === '0' ? 0 : Number(newRefundDuration)),
                    refundPolicies: newRefundPolicies,
                }
            })
                .then(response => {
                    if (response.data.status === 1) {
                        setSnackNotice({
                            message: 'Store information updated successfully',
                            severity: 'success'
                        });
                        setStoreInfo({...storeInfo, ...response.data.result});
                    } else if (response.data.status === 3) {
                        setStoreInfo({
                            ownerFirstName: '',
                            ownerLastName: '',
                            storeEmail: '',
                            storeName: '',
                            storeAddress: '',
                            colors: {primary: '#000000'}
                          });
                        setSnackNotice({
                            severity: 'warning',
                            message: response.data.message
                        });
                    } else {
                        setErrors(response.data.errors);
                    }
                })
                .catch(error => {
                    setSnackNotice({
                        message: 'Store information could not be updated',
                        severity: 'error'
                    });
                });
        }
    };


    return <Page title="Payment Methods" className={classes.root}>
        <Container maxWidth="lg">
            <Box mb={3}>
                <Typography color="textPrimary" variant="h2">
                    Refund Policies
                </Typography>
                <Typography color="textSecondary" gutterBottom variant="body2">
                    Update your refund policies
                </Typography>
            </Box>
            <Box my={2}>
                <Card >
                    <CardHeader
                        subheader="Set order refund duration"
                        title="Refund Settings"
                    >
                    </CardHeader>
                    <Divider />
                    <Box p={2} display="grid">
                        <TextField onChange={(e) => setNewRefundDuration(e.target.value)}
                            value={newRefundDuration}
                            label="Refund duration" variant="outlined" required helperText="Number of days before an order becomes unrefundable." />
                        {newRefundDuration === '0' ? <span className={classes.errorText}>Refund duration is set to zero, this makes all orders non-refundable.</span> : ''}
                    </Box>
                </Card>
            </Box>

            <Box my={1} >
                <Card>
                    <CardHeader title="Fill in your policies down below">

                    </CardHeader>
                    <Divider />
                    <Box className={classes.policyCard}>
                        <ReactQuill value={newRefundPolicies} onChange={(value) => setNewRefundPolicies(value)} modules={{
                            toolbar: [
                                [{ header: [1, 2, false] }],
                                ['bold', 'italic', 'underline'],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                ['image', 'code-block']
                            ]
                        }}
                        />
                    </Box>
                </Card>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="flex-end" my={1}>
                <Button  size="large" variant="contained" onClick={updateStoreInfo} color="primary">Update policy</Button>
            </Box>


        </Container>
    </Page>
}


const mapStateToProps = state => ({
    storeInfo: state.currentStore.storeInfo
});

const mapDispatchToProps = dispatch => ({
    setStoreInfo: storeInfo => dispatch(SetStoreInfo(storeInfo)),
    setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
    setErrors: errors => dispatch(SetErrors(errors))
});


export default connect(mapStateToProps, mapDispatchToProps)(RefundSettings);



