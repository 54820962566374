import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles,
  Chip,
  useMediaQuery
} from '@material-ui/core';
import { validatePassword } from '../../../utils/password-validation';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import PasswordRuleBox from 'src/components/password-rule-box/password-rule-box';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Password = ({ className, setNewPassword, updateStoreInfo, ...rest }) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [confirm, setConfirm] = useState('');
  const [values, setValues] = useState({
    password: ''
  });
  const [error, setError] = useState(null);

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const [passwordErrors, setPasswordErrors] = useState({
    min: true,
    uppercase: true,
    lowercase: true,
    digits: true,
    symbols: true,
    spaces: true
  });


  const validateNewPassword = password => {
    const validationErros = validatePassword(password);

    if (validationErros.length) {
      const newPasswordErros = {};
      Object.keys(passwordErrors).forEach(key => {
        if (validationErros.includes(key)) {
          newPasswordErros[key] = true;
        } else {
          newPasswordErros[key] = false;
        }
      });

      setPasswordErrors(newPasswordErros);
    } else {
      setPasswordErrors({
        min: false,
        uppercase: false,
        lowercase: false,
        digits: false,
        symbols: false,
        spaces: false
      });
    }
  };

  useEffect(() => {
    if (confirm === values.password) {
      if (Object.keys(passwordErrors).every((key) => !passwordErrors[key])) {
        setNewPassword(values.password);
      }
      setError(null);
    } else {
      setError('Password and confirmation do not match');
    }
  }, [confirm, values]);

  return (
    <form className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader subheader="Update password" title="Password" />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Password"
            margin="normal"
            name="password"
            onChange={(e) => { handleChange(e); validateNewPassword(e.target.value); }}
            type="password"
            value={values.password}
            variant="outlined"
            error={values.password && !Object.keys(passwordErrors).every((key) => !passwordErrors[key])}
            helperText={values.password && Object.keys(passwordErrors).every((key) => passwordErrors[key]) ? 'Password must conform to the mentioned rules' : ''}
          />
          <PasswordRuleBox passwordErrors={passwordErrors} />
          <TextField
            fullWidth
            label="Confirm password"
            margin="normal"
            name="confirm"
            onChange={e => setConfirm(e.target.value)}
            type="password"
            value={confirm}
            variant="outlined"
            error={values.password && error}
            helperText={values.password && error ? error : ''}
          />
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button size="large"
            color="primary"
            variant="contained"
            style={{ flexGrow: isSmallScreen ? 1 : 'unset' }}
            disabled={(values.password || confirm) && (!Object.keys(passwordErrors).every((key) => !passwordErrors[key]) || error)}
            onClick={() => {
              if (confirm === values.password) {
                updateStoreInfo();
              }
            }}
          >
            Update
          </Button>
        </Box>
      </Card>
    </form>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
