import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  CardHeader,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  TextField,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import * as FA from 'react-fontawesome';
import { SetErrors, SetSnackNotice } from 'src/redux/app/app-actions';
import {
  FetchStoreNotificationsStart,
  FetchStoreordersStart
} from 'src/redux/current-store/current-store-actions';
import CachedIcon from '@material-ui/icons/Cached';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import momentTimeZone from 'moment-timezone';
// import './order-product-card.scss';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SendIcon from '@material-ui/icons/Send';
import Decimal from 'decimal.js-light';
import { FaMoneyBill } from 'react-icons/fa';
import { FaQrcode } from 'react-icons/fa';

function PaymentMethodSelectionSection({
  total,
  setPaymentMethod,
  paymentMethod,
  ...rest
}) {
  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      marginBottom: theme.spacing(2)
    },
    warning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText
    },
    error: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText
    },
    success: {
      backgroundColor: theme.palette.success.main,
      color: 'white'
    },
    paymentMethodSelectionSection: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    paymentMethodOption: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(5),
      marginRight: theme.spacing(1),
      flex:1,
      transition: 'all 0.25s',
      cursor: 'pointer',
      minWidth: '200px',
      marginBottom: theme.spacing(1),
      '& svg': {
        height: '32px',
        width: '32px',
        marginRight: theme.spacing(1)
      }
    },

    active: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    }
  }));

  const classes = useStyles();

  const getOrderStatusIcon = status => {
    switch (status) {
      case 'processing':
        return <CachedIcon />;
      case 'cancelled':
      case 'refunded':
        return <ClearIcon />;
      case 'delivered':
        return <CheckIcon />;
      default:
        return;
    }
  };

  const getOrderStatusClass = status => {
    switch (status) {
      case 'processing':
        return classes.warning;
      case 'cancelled':
      case 'refunded':
        return classes.error;
      case 'delivered':
        return classes.success;
      default:
        return;
    }
  };

  return (
    <Box mt={2}>
      <Typography variant="h4">Select Payment Method</Typography>
      <Box
        className={`${classes.root} ${classes.paymentMethodSelectionSection}`}
        mt={2}
      >
        <Card
          className={`${classes.paymentMethodOption} ${
            paymentMethod === 'cash' ? classes.active : ''
          }`}
          onClick={e => setPaymentMethod('cash')}
        >
          <FaMoneyBill /> Cash
        </Card>
        <Card
          className={`${classes.paymentMethodOption} ${
            paymentMethod === 'phonepay' ? classes.active : ''
          }`}
          onClick={e => setPaymentMethod('phonepay')}
        >
          <FaQrcode />
          Phone Pay
        </Card>
      </Box>
    </Box>
  );
}

const mapStateToProps = state => ({
  storeId: state.currentStore.storeInfo._id,
  storeInfo: state.currentStore.storeInfo,
  notifications: state.currentStore.notifications
});

const mapDispatchToProps = dispatch => ({
  FetchStoreNotificationsStart: (storeId, sessionId) =>
    dispatch(FetchStoreNotificationsStart(storeId, sessionId)),
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setErrors: errors => dispatch(SetErrors(errors))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentMethodSelectionSection);
