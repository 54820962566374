import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Card,
  Chip,
  Container,
  Divider,
  Grid,
  TextField,
  MenuItem,
  makeStyles,
  Hidden
} from '@material-ui/core';

import Page from 'src/components/Page';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import AwesomeSliderStyles from 'react-awesome-slider/src/styled/fold-out-animation/fold-out-animation.scss';
import '../../../customStyles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import {
  FetchStoreOrdersStart,
  SetStoreInfo
} from '../../../redux/current-store/current-store-actions';
import { SetErrors, SetSnackNotice } from '../../../redux/app/app-actions';
import { connect } from 'react-redux';
import axios from 'axios';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import * as Swal from 'sweetalert2';
// import RefundForm from "../order-designer-view/edit-order-form";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import CachedIcon from '@material-ui/icons/Cached';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import momentTimeZone from 'moment-timezone';
import { validateData } from 'src/utils/validation';
import * as _ from 'lodash';
import HistoryIcon from '@material-ui/icons/History';
import Decimal from 'decimal.js-light';
import RefundItemCard from 'src/components/refund-item-card/refund-item-card';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    padding: theme.spacing(3),
    width: '100%'
  },
  orderDetailsSection: {
    padding: '20px !important',
    display: 'grid',
    alignContent: 'start',
    gridRowGap: '20px'
  },
  orderColor: {
    height: '25px',
    width: '25px',
    borderRadius: '25px'
  },
  sizeOptionContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 50px)',
    gridColumnGap: '10px'
  },
  sizeChoice: {
    backgroundColor: 'white',
    display: 'grid',
    justifyContent: 'center'
  },
  statusSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  detailsSectionRows: {},
  container: {
    maxHeight: 440
  },
  customerDetailsSection: {
    marginBottom: '16px'
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    marginLeft: theme.spacing(1)
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    marginLeft: theme.spacing(1)
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: 'white',
    marginLeft: theme.spacing(1)
  },
  buttonContainer: {
    marginTop: theme.spacing(2)
  }
}));

const RefundForm = ({
  storeId,
  storeInfo,
  order,
  setMode,
  productImages,
  setStoreInfo,
  setSnackNotice,
  setErrors
}) => {
  const classes = useStyles();

  const navigate = useNavigate();
  const [refund, setRefund] = useState({
    amount: '0',
    reason: ''
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [refundProductMap, setRefundProductMap] = React.useState({});
  const [validationErrors, setValidationErrors] = React.useState({});

  const handleChange = event => {
    const numberToRefund = Number(event.target.value || 0);
    const product = order.products.find(
      product => product._id + product.selectedSize === event.target.name
    );

    if (numberToRefund + (product.refundedQuantity || 0) <= product.quantity) {
      setRefundProductMap({
        ...refundProductMap,
        [event.target.name]: numberToRefund
      });
    } else {
      setRefundProductMap({
        ...refundProductMap,
        [event.target.name]: 0
      });
      event.target.value = '0';
      setSnackNotice({
        severity: 'error',
        message: `Max refundable quantity for this item is ${product.quantity -
          (product.refundedQuantity || 0)}`
      });
    }
  };

  useEffect(() => {
    calculateRefundAmount();
  }, [refundProductMap]);

  const getUpdatedOrderProducts = () => {
    const arrayToReturn = [];
    for (const product of order.products) {
      const productToAdd = _.cloneDeep(product);

      if (
        refundProductMap.hasOwnProperty(
          productToAdd._id + productToAdd.selectedSize
        )
      ) {
        productToAdd.refundedQuantity =
          productToAdd.refundedQuantity +
          Number(
            refundProductMap[productToAdd._id + productToAdd.selectedSize]
          );
      }

      arrayToReturn.push(productToAdd);
    }
    return arrayToReturn;
  };

  const calculateRefundAmount = () => {
    let totalRefundAmount = new Decimal('0');
    for (const [key, value] of Object.entries(refundProductMap)) {
      const product = order.products.find(
        product => product._id + product.selectedSize === key
      );

      const discountData = product.discountData;

      let possibleDiscountApplied = new Decimal(0);

      if (discountData) {
        for (let x = 0; x < Number(value); x++) {
          if (discountData[x] && discountData[x]['discountAmountApplied']) {
            possibleDiscountApplied = possibleDiscountApplied.add(
              new Decimal(discountData[x]['discountAmountApplied'])
            );
          }
        }
      }

      totalRefundAmount = totalRefundAmount.add(
        new Decimal(product.price).times(value).sub(possibleDiscountApplied)
      );
    }

    setRefund({
      ...refund,
      amount: totalRefundAmount
    });
  };

  const editOrder = async () => {
    const refundedAmount = order.refundedAmount
      ? new Decimal(order.refundedAmount)
      : new Decimal('0');
    if (
      refund.amount &&
      refund.reason &&
      !new Decimal(refund.amount).lte(new Decimal('0'))
    ) {
      if (
        new Decimal(refund.amount)
          .add(new Decimal(refundedAmount))
          .lte(new Decimal(order.total))
      ) {
        setSnackNotice({
          message: 'Order editing in process',
          severity: 'info'
        });

        try {
          const response = await axios({
            url: 'https://kripson-store-server-8qq76.ondigitalocean.app/orders/updateOrder',
            method: 'POST',
            onUploadProgress: progressEvent => console.log(progressEvent),
            data: {
              sessionId: storeInfo.sessionId,
              updatedOrder: {
                ...order,
                products: getUpdatedOrderProducts(),
                tags: [
                  new Decimal(refundedAmount)
                    .add(new Decimal(refund.amount))
                    .gte(new Decimal(order.total))
                    ? 'fully refunded'
                    : 'partially refunded'
                ],
                refundedAmount: new Decimal(refundedAmount).add(
                  new Decimal(refund.amount)
                ),
                refunds: order.refunds ? order.refunds.concat(refund) : [refund]
              },
              storeId: storeId,
              orderId: order._id
            }
          });

          if (response.data.status === 1) {
            setSnackNotice({
              message: 'Order Updated',
              severity: 'success'
            });

            setMode('detailMode');
          } else if (response.data.status === 3) {
            setStoreInfo({
              ownerFirstName: '',
              ownerLastName: '',
              storeEmail: '',
              storeName: '',
              storeAddress: '',
              colors: { primary: '#000000' }
            });
            setSnackNotice({
              severity: 'warning',
              message: response.data.message
            });
          } else {
            setErrors(response.data.errors);
            setSnackNotice({
              message: 'Product update failed',
              severity: 'error'
            });
          }
        } catch (e) {
          setSnackNotice({
            message: 'Product update failed',
            severity: 'error'
          });
        }
      } else {
        setErrors(['Refund amount cannot exceed order totals']);
      }
    } else {
      const errors = [];
      if (!refund.amount) {
        errors.push('Refund amount must be greater than 0');
      }

      if (!refund.reason) {
        errors.push('Please provide a reason for refund');
      }
      setErrors(errors);
    }
  };

  // const deleteOrder = async (orderId) => {
  //     setSnackNotice({
  //         message: `Deleting order`,
  //         severity: 'info'
  //     });

  //     const response = await axios({
  //         url: 'https://kripson-store-server-8qq76.ondigitalocean.app/orders/removeOrder',
  //         method: 'POST',
  //         onUploadProgress: progressEvent => console.log(progressEvent),
  //         data: {
  //             orderId,
  //             storeId
  //         },
  //     });

  //     if (response.data.status === 1) {
  //         navigate(`/app/orders`);

  //         setSnackNotice({
  //             message: `Order deleted`,
  //             severity: 'success'
  //         });
  //     }

  //     if (response.data.status === 2) {
  //         setErrors(response.data.errors);
  //     }
  // }

  // const fireDeleteConfirmation = (orderId) => {
  //     Swal.fire({
  //         title: 'Are you sure?',
  //         text: "You won't be able to revert this!",
  //         icon: 'warning',
  //         showCancelButton: true,
  //         confirmButtonColor: '#3085d6',
  //         cancelButtonColor: '#d33',
  //         confirmButtonText: 'Yes, delete it!'
  //     }).then((result) => {
  //         if (result.isConfirmed) {

  //             deleteOrder(orderId);

  //             // await Swal.fire(
  //             //   'Deleted!',
  //             //   'Your file has been deleted.',
  //             //   'success'
  //             // )
  //         }
  //     })
  // }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const columns = [
    { id: 'productImage', label: 'Product', minWidth: 100 },
    { id: 'title', label: 'Title', minWidth: 70 },
    {
      id: 'quantity',
      label: 'Quantity',
      minWidth: 50,
      align: 'center',
      //TODO: put appropriate locale depending on store country
      format: value => value.toLocaleString('en-IN')
    },
    {
      id: 'selectedSize',
      label: 'Size',
      minWidth: 35,
      align: 'center',
      format: value => value.toFixed(2)
    },
    {
      id: 'refundedQuantity',
      label: 'Items refunded already',
      minWidth: 35,
      align: 'center',
      format: value => value.toFixed(2)
    },
    {
      id: 'refundQuantity',
      label: 'No of items to refund',
      minWidth: 150,
      align: 'left',
      format: value => value.toLocaleString('en-IN')
    }
    // {
    //   id: 'refundAmount',
    //   label: 'Total Refund Amount',
    //   minWidth: 150,
    //   align: 'left',
    //   format: value => value.toLocaleString('en-IN')
    // }
  ];
  {
    /* <RefundForm order={order} setMode={setMode}/> */
  }

  return (
    <Page className={classes.root} title="Order Details">
      <Box mb={2} className={classes.statusSection}>
        <Box display="flex" alignItems="center">
          <Typography variant="h3">Refund Form</Typography>
        </Box>
      </Box>

      {new Date().getTime() - new Date(order.createdDate).getTime() <=
      storeInfo.refundDuration * 86400000 ? (
        ''
      ) : (
        <Typography variant="h6" color="error">
          The refund duration for this order has already been exhausted.
        </Typography>
      )}

      <Paper className={classes.customerDetailsSection}>
        <Box p={2}>
          <Typography fullWidth variant="h5" mb={2}>
            Refund Amount
          </Typography>
        </Box>

        <Divider />
        <Box p={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <TextField
                error={Boolean(validationErrors['amount'])}
                fullWidth
                label={storeInfo.storeCurrencySymbol}
                helperText="Refund Amount"
                margin="normal"
                name="refundAmount"
                onChange={e => {
                  try {
                    if (_.isFinite(Number(e.target.value))) {
                      setRefund({
                        ...refund,
                        amount: e.target.value
                      });

                      setValidationErrors({
                        ...validationErrors,
                        amount: ''
                      });
                    } else {
                      setValidationErrors({
                        ...validationErrors,
                        amount: 'Amount must be a number'
                      });
                    }
                  } catch (e) {
                    setValidationErrors({
                      ...validationErrors,
                      amount: 'Amount must be a number'
                    });
                  }
                }}
                type="text"
                value={refund.amount}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                error={Boolean(validationErrors['reason'])}
                fullWidth
                label="Reason for refund"
                margin="normal"
                name="reason"
                onChange={e => {
                  setRefund({
                    ...refund,
                    reason: e.target.value
                  });
                }}
                type="text"
                value={refund.reason}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box display={{ xs: 'none', lg: 'block' }}>
        <Paper>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {order && order.products
                  ? order.products
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((product, idx) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={product._id + product.selectedSize}
                          >
                            {columns.map(column => {
                              const value =
                                column.id === 'productImage' ? (
                                  Object.keys(productImages).length ? (
                                    <Avatar src={productImages[product._id]} />
                                  ) : (
                                    ''
                                  )
                                ) : (
                                  product[column.id]
                                );
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.id === 'refundQuantity' ? (
                                    (product.hasOwnProperty(
                                      'refundedQuantity'
                                    ) ? (
                                      product.refundedQuantity !==
                                      product.quantity
                                    ) : (
                                      true
                                    )) ? (
                                      <TextField
                                        error={Boolean(
                                          validationErrors['customer.email']
                                        )}
                                        label="Quantity to refund"
                                        margin="normal"
                                        fullWidth={true}
                                        style={{ overflow: 'ellipsis' }}
                                        name={
                                          product._id + product.selectedSize
                                        }
                                        onChange={handleChange}
                                        // onKeyDown={e => {
                                        //   e.preventDefault();
                                        //   setSnackNotice({
                                        //     severity: 'error',
                                        //     message:
                                        //       'Typing not allowed. Please, use the increase/decrease buttons instead'
                                        //   });
                                        // }}
                                        type="number"
                                        variant="outlined"
                                        InputProps={{
                                          inputProps: {
                                            min: 0,
                                            max:
                                              product.quantity -
                                              (product.refundedQuantity || 0)
                                          }
                                        }}
                                      />
                                    ) : (
                                      <Chip
                                        className={classes.error}
                                        label={'Fully Refunded'}
                                      />
                                    )
                                  ) : column.id === 'refundAmount' ? (
                                    `${storeInfo.storeCurrencySymbol} ` +
                                    column.format(
                                      order &&
                                        order.products &&
                                        order.products[idx].refundedQuantity
                                        ? order.products[idx].refundedQuantity *
                                            product.price
                                        : ''
                                    )
                                  ) : column.format &&
                                    typeof value === 'number' ? (
                                    column.format(value)
                                  ) : (
                                    value
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })
                  : ''}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={order && order.products ? order.products.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <Box display={{ xs: 'block', lg: 'none' }}>
        {order && order.products
          ? order.products.map((product, idx) => (
              <RefundItemCard
                product={product}
                productImage={
                  product && productImages ? productImages[product._id] : ''
                }
                handleChange={handleChange}
                refundProductMap={refundProductMap}
              />
            ))
          : ''}
      </Box>

      <Box mt={5}>
        <Box mb={2}>
          <Typography
            variant="h3"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            Refund History <HistoryIcon />
          </Typography>
        </Box>

        <Paper>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Refund Amount</TableCell>

                  <TableCell>Reason for refund</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order && order.refunds
                  ? order.refunds.map((refund, idx) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                          <TableCell key={idx + refund.amount}>
                            {`${storeInfo.storeCurrencySymbol} ` +
                              refund.amount}
                          </TableCell>

                          <TableCell
                            key={idx + refund.reason}
                            style={{ overflow: 'scroll' }}
                          >
                            {refund.reason}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : ''}

                <TableRow>
                  <TableCell>Total Amount Refunded:</TableCell>

                  <TableCell>
                    {storeInfo.storeCurrencySymbol}{' '}
                    {order && order.refunds
                      ? order.refunds.reduce((acc, refund) => {
                          const oldValue = new Decimal(acc.amount);
                          return {
                            amount: new Decimal(refund.amount)
                              .add(oldValue)
                              .toString()
                          };
                        }).amount
                      : 0}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={order && order.products ? order.products.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <Hidden xsDown>
        <Box my={2} display="flex" justifyContent="flex-end">
          <Button
            size="large"
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            onClick={editOrder}
            disabled={
              order && order.tags && order.tags.includes('fully refunded')
            }
          >
            Update
          </Button>
          <Button
            size="large"
            variant="contained"
            className={classes.error}
            onClick={() => setMode('detailMode')}
          >
            Cancel
          </Button>
        </Box>
      </Hidden>

      <Hidden smUp>
        <Box my={2} display="block">
          <Button
            size="large"
            fullWidth
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            onClick={editOrder}
            style={{ marginBottom: '8px' }}
            disabled={
              order && order.tags && order.tags.includes('fully refunded')
            }
          >
            Update
          </Button>
          <Button
            size="large"
            fullWidth
            variant="contained"
            className={classes.error}
            onClick={() => setMode('detailMode')}
            style={{ marginLeft: '0' }}
          >
            Cancel
          </Button>
        </Box>
      </Hidden>
    </Page>
  );
};

const mapStateToProps = state => ({
  storeId: state.currentStore.storeInfo._id,
  storeInfo: state.currentStore.storeInfo
});

const mapDispatchToProps = dispatch => ({
  FetchStoreOrdersStart: requiredInfo =>
    dispatch(FetchStoreOrdersStart(requiredInfo)),
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setErrors: validationErrors => dispatch(SetErrors(validationErrors)),
  setStoreInfo: store => dispatch(SetStoreInfo(store))
});

export default connect(mapStateToProps, mapDispatchToProps)(RefundForm);
