import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {
    Avatar,
    Box,
    Button,
    Input,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles,
    Container,
    CardHeader,
    Fab,
    Chip,
    useMediaQuery,
    TextField,
    MenuItem,
} from '@material-ui/core';
import { connect } from 'react-redux';
import imageCompression from "browser-image-compression";
import axios from 'axios';
import { DropzoneArea } from "material-ui-dropzone";
import Page from 'src/components/Page';
import { ColorPicker } from 'material-ui-color';
import { SetStoreInfo, UpdateStoreInfo } from 'src/redux/current-store/current-store-actions';
import { SetErrors, SetSnackNotice } from 'src/redux/app/app-actions';
import VisibilityIcon from '@material-ui/icons/Visibility';
import palette from 'image-palette';
import pixels from 'image-pixels';
import { Skeleton } from '@material-ui/lab';
import rgbHex from 'rgb-hex';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import contrast from 'contrast';
import { fonts } from 'src/fontSrc';
import * as _ from 'lodash';


const InvoiceDesigner = ({ className, updateStoreInfo, storeInfo, setStoreInfo, setSnackNotice, setErrors, ...rest }) => {

    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

    const [invoiceTemplateSettings, setInvoiceTemplateSettings] = useState({});
    const [copiedIndex, setCopiedIndex] = useState(null);
    const [colorPaletteImage, setColorPaletteImage] = useState('');
    const [colorExtracts, setColorExtracts] = useState([]);
    const [isExtractingColors, setIsExtractingColors] = useState(false);


    const [customFields, setCustomFields] = useState([]);
    const [customFieldValidations, setCustomFieldValidations] = useState([]);


    useEffect(() => {
        if (invoiceTemplateSettings.value && invoiceTemplateSettings.value.customFields && invoiceTemplateSettings.value.customFields.length) {
            setCustomFields(invoiceTemplateSettings.value.customFields);
        }
    }, [invoiceTemplateSettings]);


    const handleCustomFieldsChange = (idx, attribute, value) => {

        const temp = _.cloneDeep(customFields);

        temp[idx][attribute] = value;

        setCustomFields(temp);
    }


    useEffect(() => {
        validateCustomFields();
    }, [customFields]);


    const addCustomField = () => {
        setCustomFields([...customFields].concat([{ key: '', value: '' }]));
    }


    const validateCustomFields = () => {
        const customValidations = [];
        customFields.forEach((field, idx) => {
            if (!field.key) {
                customValidations.push(`${idx}key`);
            }

            if (!field.value) {
                customValidations.push(`${idx}value`);
            }
        });

        setCustomFieldValidations(customValidations);
    }

    const fetchInvoiceCustomizations = async () => {
        const serverResponse = await axios({
            url: 'https://kripson-store-server-8qq76.ondigitalocean.app/customizations/getCustomization',
            method: 'POST',
            data: {
                storeId: storeInfo._id,
                filter: { key: 'invoice-template' },
                sessionId: storeInfo.sessionId
            }
        });

        if (serverResponse.data.status === 1) {
            if (serverResponse.data.result.length) {
                setInvoiceTemplateSettings(serverResponse.data.result[0]);
            }
        } else if (serverResponse.data.status === 3) {
            setStoreInfo({
                ownerFirstName: '',
                ownerLastName: '',
                storeEmail: '',
                storeName: '',
                storeAddress: '',
                colors: { primary: '#000000' }
            });
            setSnackNotice({
                severity: 'warning',
                message: serverResponse.data.message
            });
        } else {
            setErrors(serverResponse.data.errors);
        }

    }


    const updateInvoiceSettings = async () => {
        setSnackNotice({
            severity: 'info',
            message: 'Updating invoice template settings.'
        });
        const serverResponse = await axios({
            url: 'https://kripson-store-server-8qq76.ondigitalocean.app/customizations/updateCustomization',
            method: 'POST',
            data: {
                storeId: storeInfo._id,
                sessionId: storeInfo.sessionId,
                _id: invoiceTemplateSettings._id,
                key: 'invoice-template',
                value: { ...invoiceTemplateSettings.value, customFields: customFields }
            }
        });

        if (serverResponse.data.status === 1) {
            if (serverResponse.data.result) {
                setInvoiceTemplateSettings(serverResponse.data.result);
                setSnackNotice({
                    severity: 'success',
                    message: serverResponse.data.message
                });
            }
        } else if (serverResponse.data.status === 3) {
            setStoreInfo({
                ownerFirstName: '',
                ownerLastName: '',
                storeEmail: '',
                storeName: '',
                storeAddress: '',
                colors: { primary: '#000000' }
            });
            setSnackNotice({
                severity: 'warning',
                message: serverResponse.data.message
            });
        } else {
            setErrors(serverResponse.data.errors);
        }
    }


    useEffect(() => {
        if (storeInfo._id) {
            fetchInvoiceCustomizations();
        }
    }, [storeInfo]);


    const useStyles = makeStyles((theme) => ({
        root: {
            paddingBottom: theme.spacing(3),
            paddingTop: theme.spacing(3)
        },
        avatar: {
            height: 100,
            width: 100
        },
        colourTitles: {
            marginRight: theme.spacing(1),
            width: isSmallScreen ? '100%' : 'unset'
        },
        colourBox: {
            display: 'grid',
            gridRowGap: theme.spacing(1),
            '& .ColorPicker-MuiTextField-root': {
                maxWidth: isSmallScreen ? '145px' : 'unset'
            }
        },
        previewButton: {
            marginLeft: theme.spacing(1),
        },
        colorChip: {
            display: isSmallScreen ? 'none' : 'inherit'
        },
        visibilityIcon: {
            marginRight: theme.spacing(1),
        },
        colorCodeHex: {
            position: 'absolute',
            left: theme.spacing(1),
            top: theme.spacing(1),
        },
        colorExtractBox: {
            height: isSmallScreen ? '50px' : '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative'
        },
    }));

    const classes = useStyles();

    useEffect(() => {
        if (copiedIndex !== null) {
            const timer = setTimeout(() => setCopiedIndex(null), 2000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [copiedIndex])

    useEffect(() => {
        (async () => {
            if (colorPaletteImage) {
                setIsExtractingColors(true);
                let { ids, colors } = palette(await pixels(colorPaletteImage.base64data));
                setColorExtracts(colors);
                setIsExtractingColors(false);
            }
        })()

    }, [colorPaletteImage])

    const handleColorChange = (data, name) => {
        if (typeof data === 'string' && data.startsWith('#')) {
            setInvoiceTemplateSettings({
                ...invoiceTemplateSettings,
                value: {
                    ...invoiceTemplateSettings.value,
                    [name]: data
                }

            })
        }
        else {
            setInvoiceTemplateSettings({
                ...invoiceTemplateSettings,
                value: {
                    ...invoiceTemplateSettings.value,
                    [name]: `#${data.hex}`
                }
            })
        }

    }

    const imageConversion = async (files) => {
        const compressedFileArray = [];
        for (let file of files) {

            const options = {
                maxSizeMB: 0.5,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            };
            try {
                const compressedFile = await imageCompression(file, options);
                compressedFileArray.push(compressedFile);

            } catch (error) {
                console.log(error);
            }
        }

        const base64dataarray = [];
        let counter = base64dataarray.length;

        compressedFileArray.forEach((file, idx) => {
            let reader = new FileReader();
            reader.onloadend = function () {
                base64dataarray[counter].base64data = reader.result;
                if (idx === files.length - 1) {
                    setColorPaletteImage(base64dataarray[0])
                }

                counter++;


            };
            reader.readAsDataURL(file);
            base64dataarray.push({ type: file.type });
        })
    };

    const handleChange = (e) => {
        setInvoiceTemplateSettings({
            ...invoiceTemplateSettings,
            value: {
                ...invoiceTemplateSettings.value,
                [e.target.name]: e.target.value
            }
        })
    }

    return (
        <Page className={classes.root} title="Theme Designer">
            <Container maxWidth="lg">
                <Box mb={3}>
                    <Typography color="textPrimary" variant="h2">
                        Invoice Designer
                    </Typography>
                    <Typography color="textSecondary" gutterBottom variant="body2">
                        Design your store's invoice template
                    </Typography>
                </Box>
                <Card>
                    <CardHeader title="Color Palette" subheader="Configure your invoice template's color palette">

                    </CardHeader>
                    <Divider />
                    <CardContent className={classes.colourBox}>
                        <Box display="flex" alignItems="center" flexWrap="wrap">
                            <Typography color="textPrimary" variant='h6' className={classes.colourTitles}>
                                Background color:
                            </Typography>
                            <Chip className={classes.colorChip} label={`${(invoiceTemplateSettings.value || {}).backgroundColor}`} avatar={<Avatar alt="B" src="/static/images/avatar/1.jpg" style={{ backgroundColor: (invoiceTemplateSettings.value || {}).primary }} />}>
                            </Chip>

                            <ColorPicker value={`${(invoiceTemplateSettings.value || {}).backgroundColor}` || 'transparent'} onChange={(data) => handleColorChange(data, 'backgroundColor')} />
                            {/* <Button size="large" variant="contained" className={classes.previewButton}> {isSmallScreen ? <VisibilityIcon /> : <><VisibilityIcon className={classes.visibilityIcon} /> Preview</>} </Button> */}
                        </Box>
                        <Box display="flex" alignItems="center" flexWrap="wrap">
                            <Typography color="textPrimary" variant='h6' className={classes.colourTitles}>
                                Text color:
                            </Typography>
                            <Chip className={classes.colorChip} label={`${(invoiceTemplateSettings.value || {}).textColor || 'none'}`} avatar={<Avatar alt="B" src="/static/images/avatar/1.jpg" style={{ backgroundColor: (invoiceTemplateSettings.value || {}).textColor || 'none' }} />}>
                            </Chip>

                            <ColorPicker value={`${(invoiceTemplateSettings.value || {}).textColor}` || 'transparent'} onChange={(data) => handleColorChange(data, 'textColor')} />
                            {/* <Button size="large" variant="contained" className={classes.previewButton}> {isSmallScreen ? <VisibilityIcon /> : <><VisibilityIcon className={classes.visibilityIcon} /> Preview</>} </Button> */}
                        </Box>
                        <Box>
                            <Box mb={1}>
                                <Typography color="textPrimary" variant='h6'>
                                    Header font style:
                                </Typography>
                            </Box>

                            <TextField
                                select
                                label="Select"
                                value={(invoiceTemplateSettings.value || {}).headerFont || 'Roboto'}
                                onChange={handleChange}
                                helperText="Please select the desired font for header"
                                variant="outlined"
                                name="headerFont"
                                fullWidth
                            >
                                {Object.keys(fonts).map((font, idx) => {
                                    return (<MenuItem key={idx} value={font}>
                                        {font === 'Roboto' ? `${font} (Recommended)` : font}
                                    </MenuItem>)
                                })}
                            </TextField>
                        </Box>
                        <Box>
                            <Box mb={1}>
                                <Typography color="textPrimary" variant='h6'>
                                    Body font style:
                                </Typography>
                            </Box>

                            <TextField
                                select
                                label="Select"
                                value={(invoiceTemplateSettings.value || {}).bodyFont || 'Roboto'}
                                onChange={handleChange}
                                helperText="Please select the desired font for body"
                                variant="outlined"
                                name="bodyFont"
                                fullWidth
                            >
                                {Object.keys(fonts).map((font, idx) => {
                                    return (<MenuItem key={idx} value={font}>
                                        {font === 'Roboto' ? `${font} (Recommended)` : font}
                                    </MenuItem>)
                                })}
                            </TextField>
                        </Box>
                    </CardContent>
                    <Divider />

                </Card>
                {/* <Box mt={2}>
                    <Card>
                        <CardHeader title="Extract colors" subheader="Upload a photo to extract colour palette from">

                        </CardHeader>
                        <CardActions>

                            <DropzoneArea
                                dropzoneText="Upload an image"
                                maxFileSize={20000000}
                                filesLimit={1}
                                onChange={(files) => imageConversion(files)}
                                name="colorExtractSource"
                            />
                        </CardActions>
                        {isExtractingColors ? <Skeleton variant="rect" height={100} /> : <Box display="flex" alignItems="center" flexWrap="wrap" justifyContent="center">
                            {colorExtracts.map((color, idx) => {
                                return <Box className={classes.colorExtractBox} style={{ backgroundColor: `rgba(${color[0]},${color[1]} ,${color[2]} ,${color[3]})`, width: `${100 / colorExtracts.length}%` }}>
                                    {isSmallScreen ? '' : <span style={{ color: contrast(rgbHex(`rgb(${color[0]},${color[1]} ,${color[2]})`)) === 'light' ? '#000' : '#fff' }} className={classes.colorCodeHex}>{rgbHex(`rgb(${color[0]},${color[1]} ,${color[2]})`).toString().toUpperCase()}</span>
                                    }

                                    {copiedIndex === idx ? <span style={{ color: contrast(rgbHex(`rgb(${color[0]},${color[1]} ,${color[2]})`)) === 'light' ? '#000' : '#fff' }}> Copied!</span> :
                                        <Button size="large" style={{ color: contrast(rgbHex(`rgb(${color[0]},${color[1]} ,${color[2]})`)) === 'light' ? '#000' : '#fff' }} endIcon={<FileCopyIcon />} onClick={() => {
                                            setCopiedIndex(idx);
                                            navigator.clipboard.writeText(rgbHex(`rgb(${color[0]},${color[1]} ,${color[2]})`).toString().toUpperCase());
                                        }}
                                            className={classes.copyButton}>
                                            {isSmallScreen ? '' : <span>Copy</span>}
                                        </Button>}
                                </Box>
                            })}
                        </Box>}

                    </Card>
                </Box> */}

                {/* Custom fields in invoice */}
                <Box mt={2}>
                    <Card>
                        <CardHeader title="Add Custom Fields" subheader="Add custom fields to include in your invoice.">

                        </CardHeader>
                        <Divider />
                        <Box p={2} display="flex" alignItems="center" flexWrap="wrap" justifyContent={isSmallScreen ? 'stretch' : 'flex-end'}>
                            <Button variant="outlined" onClick={addCustomField} color='primary'>+ Add Custom Field</Button>
                        </Box>

                        <Box p={2}>

                            {customFields.map((field, idx) => {
                                return <Box mb={1}>
                                    <Box mb={1}>
                                        <TextField
                                            label="Custom field label"
                                            value={field['key']}
                                            onChange={(e) => handleCustomFieldsChange(idx, 'key', e.target.value)}
                                            helperText="Please insert the custom label"
                                            variant="outlined"
                                            name="key"
                                            error={customFieldValidations.includes(`${idx}key`)}
                                            fullWidth
                                        >
                                        </TextField>
                                    </Box>
                                    <Box>
                                        <TextField
                                            label="Custom field value"
                                            value={field['value']}
                                            onChange={(e) => handleCustomFieldsChange(idx, 'value', e.target.value)}
                                            helperText="Please insert the custom value"
                                            variant="outlined"
                                            error={customFieldValidations.includes(`${idx}value`)}
                                            name="value"
                                            fullWidth
                                        >
                                        </TextField>
                                    </Box>
                                </Box>
                            })}
                        </Box>
                    </Card>
                </Box>


                <Box
                    display="flex"
                    justifyContent="flex-end"
                    p={isSmallScreen ? 0 : 2}
                    my={2}
                >
                    <Button size="large"
                        color="primary"
                        variant="contained"
                        onClick={updateInvoiceSettings}
                        disabled={customFieldValidations.length}
                        style={{ flexGrow: isSmallScreen ? 1 : 'unset' }}
                    >
                        Update Invoice Settings
                    </Button>
                </Box>
            </Container>
        </Page>
    );
};

InvoiceDesigner.propTypes = {
    className: PropTypes.string
};

const mapStateToProps = state =>
({
    storeInfo: state.currentStore.storeInfo
});

const mapDispatchToProps = dispatch => (
    {
        setStoreInfo: (storeInfo) => dispatch(SetStoreInfo(storeInfo)),
        setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
        setErrors: (error) => dispatch(SetErrors(error)),
        updateStoreInfo: (storeInfo) => dispatch(UpdateStoreInfo(storeInfo))
    });

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDesigner);
