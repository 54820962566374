 import {createStore, applyMiddleware} from 'redux';
import logger from 'redux-logger';
import rootReducer from './root-reducer';
import {persistStore, persistReducer} from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage'
// import {fetchCollectionsStart} from './collection/collection.saga';
// import thunk from 'redux-thunk';
//
import rootSaga from './root-saga';





const SagaMiddleware = createSagaMiddleware();
const middlewares = [ SagaMiddleware, logger];


export const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['currentStore']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, applyMiddleware(...middlewares));


SagaMiddleware.run(rootSaga);


export const persistor = persistStore(store);


