import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Hidden
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import FilterListIcon from '@material-ui/icons/FilterList';
import DialogComponent from '../../../../components/dialog-component/dialog-component';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({setKeyword, className,  handleSearchFilterChange, ...rest }) => {
  const classes = useStyles();
  const [filterDialogOpen, setFilterDialogOpen] = React.useState(false);
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
     <Hidden xsDown>
        <Box
          display="flex"
          justifyContent="space-between"
          className={classes.topButtonBox}
        >
          <Button  size="large"
            color="primary"
            variant="contained"
            startIcon={<FilterListIcon />}
            onClick={() => setFilterDialogOpen(true)}
          >
            Apply filters
          </Button>
        </Box>
      </Hidden>

      <Hidden smUp>
        <Box
          display="flex"
          justifyContent="stretch"
          className={classes.topButtonBox}
        >
          <Button  size="large"
            color="primary"
            variant="contained"
            startIcon={<FilterListIcon />}
            onClick={() => setFilterDialogOpen(true)}
            style={{marginBottom:'10px'}}
            fullWidth
          >
            Apply filters
          </Button>
        </Box>
      </Hidden>
      <DialogComponent
        open={filterDialogOpen}
        onClose={() => setFilterDialogOpen(false)}
        handleSearchFilterChange={handleSearchFilterChange}
      />
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                onChange={(e) => setKeyword(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search product"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
