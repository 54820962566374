import React,{useEffect, useState} from 'react';
import {connect} from "react-redux";




const FontLoader = ({ className, font, ...rest }) => {

  const [link, setLink] = useState('');



  useEffect(() => {

    if(font && font.url) {
        setLink(<link rel="stylesheet" href={font.url}></link>);
    }
  },[font]);

  return (<>
  {link}

  </>); 
};

const mapStateToProps = state =>
  ({
    storeInfo: state.currentStore.storeInfo
  });

const mapDispatchToProps = dispatch => (
  {
  });

export default connect(mapStateToProps, mapDispatchToProps)(FontLoader);
