import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import MailIcon from '@material-ui/icons/Mail';
import DeleteIcon from '@material-ui/icons/Delete';
import Label from '@material-ui/icons/Label';
import CategoryIcon from '@material-ui/icons/Category';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import InfoIcon from '@material-ui/icons/Info';
import ForumIcon from '@material-ui/icons/Forum';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import {useLocation} from "react-router-dom";
import {connect} from "react-redux";
import {ShoppingBag as ShoppingBagIcon} from "react-feather";
import {Link} from 'react-router-dom';
import { FaShoppingBag } from 'react-icons/fa';

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
  },
  content: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium
  },
  group: {
    marginLeft: 0
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.75, 0.25),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(0.25),
    stroke: 'currentColor',
    height: '20px',
    width: '20px'
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
    textTransform: 'none',
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const {labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other} = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon}/>
          <Typography variant="button" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: 400,
  },
});

function StyledTreeView({ categories}) {
  const classes = useStyles();
  const location = useLocation();

  return (
    <TreeView
      className={classes.root}
      expanded={location.pathname.startsWith('/app/products') ? ['1'] : []}
      selected={location.pathname.includes('/app/products') ? ['1'] : []}
    >

      <Link to={`/app/products/`}>
        <StyledTreeItem nodeId="1" labelText="Products" labelIcon={FaShoppingBag}>
          <Link to={'/app/products/categoryDesigner'}>
            <StyledTreeItem
              nodeId={'2'}
              labelText={'Add Category'}
              labelIcon={AddCircleOutlineIcon}
              color="#1a73e8"
              bgColor="#e8f0fe"
            />
          </Link>
          {categories.map((category, idx) =>
            <Link key={idx} to={'/app/products/' + category._id}>
              <StyledTreeItem
                nodeId={String(idx + 3)}
                labelText={category.categoryName}
                labelIcon={CategoryIcon}
                labelInfo=""
                color="#1a73e8"
                bgColor="#e8f0fe"
              />
            </Link>)}
        </StyledTreeItem>
      </Link>
    </TreeView>
  );
}

const mapStateToProps = state =>
  ({

  });


export default connect(mapStateToProps, null)(StyledTreeView);
