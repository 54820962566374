import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Card,
  Container,
  Hidden,
  Link,
  makeStyles,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { SetStoreInfo } from '../../../redux/current-store/current-store-actions';
import { SetErrors, SetSnackNotice } from '../../../redux/app/app-actions';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import { SketchPicker } from 'react-color';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { DropzoneArea } from 'material-ui-dropzone';
import * as FA from 'react-fontawesome';
import imageCompression from 'browser-image-compression';
import ProgressBar from '../../../components/progress-bar/progress-bar';
import * as urlencode from 'urlencode';
import ChipInput from 'material-ui-chip-input';
import validator from 'validator';

const useStyles = makeStyles(theme => ({
  sizeGrid: {
    margin: '1em 0'
  },

  formSectionHeader: {
    margin: '16px 0'
  },
  extraMargin: {
    margin: '40px 0 0 0'
  },
  card: {
    backgroundColor: '#FFFBFE',
    padding: '20px'
  },
  individualSize: {
    display: 'grid',
    gridColumnGap: '10px',
    alignItems: 'center',
    gridTemplateColumns: 'auto 1fr auto'
  },
  formContainer: {
    position: 'relative',
    padding: '20px'
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: 'white'
  }
}));

const UpdateProductForm = ({
  storeId,
  product,
  storeInfo,
  categories,
  setSnackNotice,
  setStoreInfo,
  setMode,
  notice,
  setErrors
}) => {
  const classes = useStyles();

  const editProduct = async values => {

    if (
      !(customErrors.images || Object.keys(customErrors.stockQuantity).length)
    ) {
      setSnackNotice({
        message: 'Product editing in process',
        severity: 'info'
      });

      const productToSubmit = {
        ...productToUpdate,
        ...values,
        newImages: productToUpdate.images.filter(
          img => !product.images.map(innerImg => innerImg.key).includes(img.key)
        ),
        deletedImagesKeys: [...deletedImagesKeys]
      };

      try {
        const response = await axios({
          url:
            'https://kripson-store-server-8qq76.ondigitalocean.app/products/updateProduct',
          method: 'POST',
          onUploadProgress: progressEvent => console.log(progressEvent),
          data: {
            sessionId: storeInfo.sessionId,
            updatedProduct: { ...productToSubmit, unitValue: String(productToSubmit.unitValue || '') },
            storeId: storeId,
            productId: product._id
          }
        });
        if (response.data.status === 1) {
          setProductToUpdate({
            color: '#000000',
            sizes: [],
            stockQuantity: {},
            images: []
          });
          setSnackNotice({
            message: 'Product Updated',
            severity: 'success'
          });

          setMode('detailMode');
        } else if (response.data.status === 3) {
          setStoreInfo({
            ownerFirstName: '',
            ownerLastName: '',
            storeEmail: '',
            storeName: '',
            storeAddress: '',
            colors: { primary: '#000000'}
          });
          setSnackNotice({
            severity: 'warning',
            message: response.data.message
          });
        } else {
          setErrors(response.data.errors);
          setSnackNotice({
            message: 'Product update failed',
            severity: 'error'
          });
        }
      } catch (e) {
        setSnackNotice({
          message: 'Product update failed',
          severity: 'error'
        });
      }
    } else {
      console.log(customErrors);
    }
  };

  const imageConversion = async files => {
    const compressedFileArray = [];
    for (let file of files) {
      const options = {
        maxSizeMB: 0.4,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      };
      try {
        const compressedFile = await imageCompression(file, options);
        compressedFileArray.push(compressedFile);
      } catch (error) {
        console.log(error);
      }
    }

    const base64dataarray = [];
    let counter = base64dataarray.length;

    compressedFileArray.forEach((file, idx) => {
      let reader = new FileReader();
      reader.onloadend = function () {
        base64dataarray[counter].base64data = reader.result;
        if (idx === files.length - 1) {
          setProductToUpdate({
            ...productToUpdate,
            images: base64dataarray
          });
        }

        counter++;
      };
      reader.readAsDataURL(file);
      base64dataarray.push({
        type: file.type,
        key: urlencode.decode(file.name)
      });
    });
  };

  const [customErrors, setCustomErrors] = useState({
    images: '',
    stockQuantity: {}
  });

  const [productToUpdate, setProductToUpdate] = useState({
    color: '#000000',
    sizes: [],
    stockQuantity: {},
    images: [],
    tags: []
  });

  const [deletedImagesKeys, setDeletedImagesKeys] = useState([]);

  const validateNewProduct = () => {
    const errors = { ...customErrors };
    const { sizes, stockQuantity, images } = productToUpdate;

    for (const size of sizes) {
      if (!(stockQuantity.hasOwnProperty(size) && stockQuantity[size])) {
        if (!errors.hasOwnProperty('stockQuantity')) {
          errors['stockQuantity'] = {};
        }
        errors.stockQuantity[
          size
        ] = `Please provide a valid stock quantity for ${size} size of this product`;
      } else {
        delete errors.stockQuantity[size];
      }
    }

    if (images.length === 0) {
      errors.images = 'Please upload at least one image of the product';
    } else {
      errors.images = '';
    }

    setCustomErrors({ ...errors });
  };

  useEffect(() => {
    validateNewProduct();
  }, [productToUpdate]);

  useEffect(() => {
    if (product) {
      setProductToUpdate({
        color: product.color,
        sizes: product.sizes,
        stockQuantity: product.stockQuantity,
        images: product.images,
        tags: product.tags,
      });
    }
  }, [product]);

  const [productForm, setProductForm] = useState('');
  const [newSize, setNewSize] = useState('');

  useEffect(() => {
    if (categories.length > 0 && product) {
      setProductForm(
        <Formik
          initialValues={{
            parentCategory: product.parentCategory,
            price: product.price,
            status: product.status,
            costPrice: product.costPrice,
            title: product.title,
            brand: product.brand ? product.brand : '',
            unitValue: product.unitValue || 0,
            unitLabel: product.unitLabel || '',
            description: product.description,
            isRefundable: product.isRefundable || false,
            isDeliverable: product.isDeliverable || false,
            isSoldByCount: product.isSoldByCount || false
          }}
          validationSchema={Yup.object().shape({
            price: Yup.number().min(1).required('Price is required'),
            status: Yup.string().min(1).required('Status is required'),
            costPrice: Yup.number().min(1).required('Cost price is required'),
            title: Yup.string().min(1).required('Title is required'),
            unitValue: Yup.number().when("isSoldByCount", {
              is: false,
              then: Yup.number().min(1).required("Unit Value is required"),
            }),
            unitLabel: Yup.string().when("isSoldByCount", {
              is: false,
              then: Yup.string().min(1).required("Unit Label is required"),
            }),
            description: Yup.string().min(1).required('Description is required'),
            isRefundable: Yup.boolean().required(
              'Please mark whether this product is refundable or not'
            ),
            isDeliverable: Yup.boolean().required(
              'Please mark whether this product is deliverable or not'
            ),
            isSoldByCount: Yup.boolean().required(
              'Please mark whether this product is sold by cound or not'
            ),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(false);
            console.log('fired');
            await editProduct(values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <Box mb={3}>
                <Typography color="textPrimary" variant="h2">
                  Edit Product
                </Typography>
              </Box>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card>
                    <Grid container spacing={1} className={classes.card}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Title"
                          value={values.title}
                          onChange={handleChange}
                          helperText="Product title"
                          variant="outlined"
                          onBlur={handleBlur}
                          name="title"
                          fullWidth
                          error={Boolean(touched.title && errors.title)}
                          margin="normal"
                        />

                        <TextField
                          helperText="Product Brand"
                          label="Brand"
                          name="brand"
                          value={values.brand}
                          error={Boolean(touched.brand && errors.brand)}
                          onChange={handleChange}
                          variant="outlined"
                          fullWidth
                          margin="normal"
                        />

                        <TextField
                          error={Boolean(touched.price && errors.price)}
                          fullWidth
                          helperText={'Selling price of the product'}
                          label={`Price ${storeInfo && storeInfo.storeCurrencySymbol
                              ? storeInfo.storeCurrencySymbol
                              : ''
                            }`}
                          margin="normal"
                          name="price"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          value={values.price}
                          variant="outlined"
                        />
                        <TextField
                          select
                          label="Select"
                          value={values.status}
                          onChange={handleChange}
                          helperText={'Please select the status of the product'}
                          variant="outlined"
                          onBlur={handleBlur}
                          name="status"
                          fullWidth
                          error={Boolean(touched.status && errors.status)}
                          margin="normal"
                        >
                          <MenuItem value={'coming soon'}>coming soon</MenuItem>
                          <MenuItem value={'available now'}>
                            available now
                          </MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          select
                          label="Select"
                          value={values.parentCategory}
                          onChange={handleChange}
                          helperText="Please select the product category"
                          variant="outlined"
                          onBlur={handleBlur}
                          name="parentCategory"
                          fullWidth
                          error={Boolean(
                            touched.parentCategory && errors.parentCategory
                          )}
                          margin="normal"
                        >
                          {categories.map(category => {
                            return (
                              <MenuItem key={category._id} value={category._id}>
                                {category.categoryName}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                        <TextField
                          error={Boolean(touched.costPrice && errors.costPrice)}
                          fullWidth
                          helperText={'Cost price of the product'}
                          label={`Cost price ${storeInfo && storeInfo.storeCurrencySymbol
                              ? storeInfo.storeCurrencySymbol
                              : ''
                            }`}
                          margin="normal"
                          name="costPrice"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="number"
                          value={values.costPrice}
                          variant="outlined"
                        />

                        <TextField
                          helperText="Please provide a description of the product"
                          label="Description"
                          multiline
                          rowsMax={5}
                          name="description"
                          value={values.description}
                          error={Boolean(
                            touched.description && errors.description
                          )}
                          onChange={handleChange}
                          variant="outlined"
                          fullWidth
                          margin="normal"
                        />
                        <Box mt={2} mb={1}>
                          <ChipInput
                            name="tags"
                            defaultValue={productToUpdate.tags}
                            variant="outlined"
                            helperText="Attach tags to the product"
                            fullWidth
                            fullWidthInput
                            onChange={chips => {
                              console.log(chips)
                              setProductToUpdate({
                                ...productToUpdate,
                                tags: chips
                              });
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                  <Box mt={2}>
                    <Card className={classes.card}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.isRefundable}
                            onChange={handleChange}
                            name="isRefundable"
                            color="primary"
                          />
                        }
                        label="is Refundable?"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.isDeliverable}
                            onChange={handleChange}
                            name="isDeliverable"
                            color="primary"
                          />
                        }
                        label="is Deliverable?"
                      />

                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.isSoldByCount}
                            onChange={handleChange}
                            name="isSoldByCount"
                            color="primary"
                          />
                        }
                        label="is sold by count?"
                      />
                      {values.isSoldByCount ? '' : <>
                        <TextField
                          helperText="Only insert a number here. How much is 1 unit? eg 1kg/ 1ltr. This is the minimum amount that can be purchased."
                          label="1 unit = ?."
                          name="unitValue"
                          value={values.unitValue}
                          error={Boolean(touched.unitValue && errors.unitValue)}
                          onChange={handleChange}
                          variant="outlined"
                          type="number"
                          inputProps={{
                            type: 'number',
                            min: 1,
                          }}
                          fullWidth
                          margin="normal"
                        />

                        <TextField
                          helperText="Unit label. eg: kg/ltr"
                          label="Unit Label"
                          name="unitLabel"
                          value={values.unitLabel}
                          error={Boolean(touched.unitLabel && errors.unitLabel)}
                          onChange={handleChange}
                          variant="outlined"
                          fullWidth
                          margin="normal"
                        /></>}
                    </Card>
                  </Box>
                  <Box mt={2}>
                    <Card>
                      <Box className={`${classes.card}`}>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h5"
                        >
                          Add a new size:
                        </Typography>
                        <TextField
                          fullWidth
                          label="New size"
                          margin="normal"
                          name="size"
                          onChange={e => {
                            setNewSize(e.target.value.toUpperCase());
                          }}
                          type="text"
                          variant="outlined"
                        />
                        <Box my={2}>
                          <Button  size="large"
                            className={classes.success}
                            disabled={isSubmitting}
                            fullWidth
                            size="large"
                            variant="contained"
                            onClick={() => {
                              const productToUpdateSizeArray = [
                                ...productToUpdate.sizes
                              ];
                              productToUpdateSizeArray.push(newSize);
                              setProductToUpdate({
                                ...productToUpdate,
                                sizes: productToUpdateSizeArray
                              });
                            }}
                          >
                            Add Size
                          </Button>
                        </Box>
                      </Box>
                    </Card>
                  </Box>

                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                    className={`${classes.formSectionHeader} ${classes.extraMargin}`}
                  >
                    Available Sizes
                  </Typography>
                  {productToUpdate.sizes.length > 0 ? (
                    ''
                  ) : (
                    <span style={{ color: 'red' }}>
                      At least one item size must be provided{' '}
                      <FA name="exclamation-circle"></FA>
                    </span>
                  )}
                  <Card>
                    <Grid
                      container
                      className={`${classes.card}`}
                      style={{ margin: '10px 0 0 -4px' }}
                    >
                      <Grid item xs={12}>
                        <Grid
                          container
                          spacing={1}
                          className={classes.sizeGrid}
                        >
                          {productToUpdate.sizes.map(size => {
                            return (
                              <Grid
                                item
                                xs={12}
                                md={6}
                                key={size.id}
                                className={classes.individualSize}
                              >
                                <span>{size}</span>

                                <TextField
                                  error={
                                    customErrors.stockQuantity
                                      ? customErrors.stockQuantity.hasOwnProperty(
                                        size
                                      ) && customErrors.stockQuantity[size]
                                      : false
                                  }
                                  helperText={`Stock quantity for ${size} size`}
                                  label="Stock Quantity"
                                  margin="normal"
                                  name="sizes"
                                  type="number"
                                  inputProps={{
                                    type: 'number',
                                    min: 1,
                                  }}
                                  onChange={e =>
                                    setProductToUpdate({
                                      ...productToUpdate,
                                      stockQuantity: {
                                        ...productToUpdate.stockQuantity,
                                        [size]: Math.round(Number(e.target.value))
                                      }
                                    })
                                  }
                                  value={
                                    productToUpdate.stockQuantity[size]
                                      ? productToUpdate.stockQuantity[size]
                                      : ''
                                  }
                                  variant="outlined"
                                />
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => {
                                    setProductToUpdate({
                                      ...productToUpdate,
                                      sizes: productToUpdate.sizes.filter(
                                        innerSize => innerSize !== size
                                      )
                                    });
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>

                  <Box mt={2}>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="h5"
                      className={classes.formSectionHeader}
                    >
                      Product Color:
                    </Typography>
                    <SketchPicker
                      label="Color of the product"
                      name="color"
                      id="product-color"
                      color={productToUpdate.color}
                      disableAlpha={true}
                      onChange={color =>
                        setProductToUpdate({
                          ...productToUpdate,
                          color: color.hex
                        })
                      }
                      width={300}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Typography
                color="textPrimary"
                gutterBottom
                variant="h4"
                className={`${classes.formSectionHeader} ${classes.extraMargin}`}
              >
                Upload Product Images
              </Typography>

              {customErrors.images ? (
                <span style={{ color: 'red' }}>
                  {customErrors.images}
                  <FA
                    style={{ marginLeft: '5px' }}
                    name={'exclamation-circle'}
                  ></FA>
                </span>
              ) : (
                ''
              )}
              <Box my={2}>
                <DropzoneArea
                  filesLimit={4}
                  onChange={files => imageConversion(files)}
                  initialFiles={
                    product && product.images
                      ? product.images.map(img => img.url)
                      : []
                  }
                  onDelete={deletedFile => {
                    console.log(deletedFile);
                    console.log(deletedFile.name.includes(storeId));
                    if (deletedFile.name.includes(storeId)) {
                      const newDeletedFilesArray = [...deletedImagesKeys];

                      newDeletedFilesArray.push(
                        urlencode.decode(deletedFile.name)
                      );
                      setDeletedImagesKeys([...newDeletedFilesArray]);
                    }
                  }}
                />
              </Box>
              <Hidden xsDown>
                <Box my={5} display="flex" justifyContent="space-between">
                  <Button  size="large"
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    Update Product
                  </Button>
                  <Button  size="large"
                    color="secondary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={() => setMode('detailMode')}
                    style={{
                      backgroundColor: '#f44336',
                      color: 'white',
                      marginLeft: '15px'
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Hidden>

              <Hidden smUp>
                <Box my={5} display="block">
                  <Button  size="large"
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                    style={{ marginBottom: '8px' }}
                  >
                    Update Product
                  </Button>
                  <Button  size="large"
                    color="secondary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={() => setMode('detailMode')}
                    style={{ backgroundColor: '#f44336', color: 'white' }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Hidden>
            </form>
          )}
        </Formik>
      );
    }
  }, [productToUpdate, newSize, customErrors, categories, product]);

  return (
    <div className={classes.formContainer}>
      {notice.message === 'Product editing in process' ? (
        <ProgressBar completed={false} action={'Updating your product'} />
      ) : (
        ''
      )}
      {productForm}
    </div>
  );
};

const mapStateToProps = state => ({
  notice: state.app.notice,
  storeId: state.currentStore.storeInfo._id,
  storeInfo: state.currentStore.storeInfo,
  categories: state.currentStore.categories
});

const mapDispatchToProps = dispatch => ({
  setStoreInfo: storeInfo => dispatch(SetStoreInfo(storeInfo)),
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setErrors: errors => dispatch(SetErrors(errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProductForm);
