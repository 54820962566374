import React, { useEffect } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  ListItem,
  makeStyles
} from '@material-ui/core';
import StyledTreeView from "../../../components/styled-tree-view/StyledTreeView";
import { connect } from "react-redux";
import StyleTreeViewGeneric from 'src/components/style-tree-view-generic/style-tree-view-generic';
import { Settings } from 'react-feather';
import PaymentIcon from '@material-ui/icons/Payment';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import LinkIcon from '@material-ui/icons/Link';
import { GrConnect } from 'react-icons/gr'
import { IoIosBrush } from "react-icons/io";
import { BsFillGearFill } from 'react-icons/bs';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  treeViewButton: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '4px 0',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const NavItem = ({
  className,
  href,
  icon: Icon,
  title,
  FetchStoreCategoriesStart,
  categories,
  ...rest
}) => {
  const classes = useStyles();


  const getNavItem = (title) => {

    switch (title) {
      case 'Products':
        return (<StyledTreeView categories={categories} />);
      case 'Settings':
        return (<StyleTreeViewGeneric categories={settingChildren} baseUrl={'/app/settings'} baseLabel={'Settings'} baseIcon={BsFillGearFill} />);
      case 'Account':
        return (<StyleTreeViewGeneric categories={accountChildren} baseUrl={'/app/account'} baseLabel={'Account'} baseIcon={Icon} />);
      default:
        return <React.Fragment>
          {Icon && (
            <Icon
              className={classes.icon}
              size="20"
            />
          )}
          <span className={classes.title}>
            {title}
          </span>
        </React.Fragment>
    }

  }

  const settingChildren = [
    {
      categoryName: 'Payment Methods',
      link: 'paymentMethods',
      icon: PaymentIcon
    },
    {
      categoryName: 'Refund Settings',
      link: 'refundSettings',
      icon: MoneyOffIcon
    },
    {
      categoryName: 'Social Links',
      link: 'socials',
      icon: LinkIcon
    },
    {
      categoryName: 'Integrations',
      link: 'integrations',
      icon: GrConnect
    },
  ]

  const accountChildren = [
    {
      categoryName: 'Theme Designer',
      link: 'themeDesigner',
      icon: IoIosBrush
    }
  ]

  return (
    <ListItem
      className={clsx(classes.item, className)}
      disableGutters
      {...rest}
    >
      <Button  size="large"
        activeClassName={classes.active}
        className={['Products', 'Settings', 'Account'].includes(title) ? classes.treeViewButton : classes.button}
        component={RouterLink}
        to={href}
      >

        {getNavItem(title)}
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

const mapDispatchToProps = dispatch => (
  {
  });

const mapStateToProps = state =>
({
  categories: state.currentStore.categories
});


export default connect(mapStateToProps, mapDispatchToProps)(NavItem);
