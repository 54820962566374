import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon
} from 'react-feather';
import NavItem from './NavItem';
import StyledTreeView from "../../../components/styled-tree-view/StyledTreeView";
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import {connect} from 'react-redux';
import { SetStoreInfo } from 'src/redux/current-store/current-store-actions';
import { SetErrors } from 'src/redux/app/app-actions';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DescriptionIcon from '@material-ui/icons/Description';
import { FaCashRegister, FaMoneyBillWave, FaUsers, FaBoxes, FaFileInvoiceDollar, FaShoppingBag, FaUserCircle } from "react-icons/fa";
import {BsFillBarChartFill, BsFillGearFill} from 'react-icons/bs';
import {HiReceiptRefund, HiShoppingBag} from 'react-icons/hi';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import EqualizerIcon from '@material-ui/icons/Equalizer';

import axios from 'axios';


const items = [
  {
    href: '/app/dashboard',
    icon: BsFillBarChartFill,
    title: 'Dashboard'
  },
  {
    href: '/app/customers',
    icon: FaUsers,
    title: 'Customers'
  },
  {
    href: '/app/orders',
    icon: FaBoxes,
    title: 'Orders'
  },
  {
    href: '/app/invoices',
    icon: FaFileInvoiceDollar,
    title: 'Invoices'
  },
  {
    href: '/app/refundRequests',
    icon: HiReceiptRefund,
    title: 'Refund Requests'
  },
  {
    href: '/app/products',
    icon: HiShoppingBag,
    title: 'Products',
  },
  {
    href: '/app/discounts',
    icon: LoyaltyIcon,
    title: 'Discounts'
  },
  {
    href: '/app/account',
    icon: FaUserCircle,
    title: 'Account'
  },
  {
    href: '/app/settings',
    icon: BsFillGearFill,
    title: 'Settings'
  },
  {
    href: '/app/stats',
    icon: EqualizerIcon,
    title: 'Stats'
  },
  {
    href: '/app/billing',
    icon: FaMoneyBillWave,
    title: 'Billing'
  },
  {
    href: '/pos',
    icon: FaCashRegister,
    title: 'Point of Sale'
  },
  // {
  //   href: '/login',
  //   icon: LockIcon,
  //   title: 'Login'
  // },
  // {
  //   href: '/register',
  //   icon: UserPlusIcon,
  //   title: 'Register'
  // },
  // {
  //   href: '/404',
  //   icon: AlertCircleIcon,
  //   title: 'Error'
  // }
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile, storeInfo, setStoreInfo, setErrors }) => {
  const classes = useStyles();
  const location = useLocation();

  // useEffect(() => {
  //   if (openMobile && onMobileClose) {
  //     onMobileClose();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location.pathname]);

  const logout = async ()=>{
    const response = await axios({
      url: 'https://kripson-store-server-8qq76.ondigitalocean.app/customers/logout',
      method: 'POST',
      data:{
        sessionId: storeInfo.sessionId,
      }
    });

    if(response.data.status === 1)
    {
      setStoreInfo({
        ownerFirstName: '',
        ownerLastName: '',
        storeEmail: '',
        storeName: '',
        storeAddress: '',
        colors: {primary: '#000000'}
      })
    }
    else
    {
      setErrors(response.data.errors)
    }
  }
  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={storeInfo && storeInfo.storeBanner? storeInfo.storeBanner.url:''}
          to="/app/account"
        />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {storeInfo?storeInfo.storeName:''}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}

        <Hidden lgUp>
        <NavItem
              href={'/logout'}
              key={'logout'}
              title={'Logout'}
              icon={ExitToAppIcon}
              onClick={logout}/>
        </Hidden>
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

const mapDispatchToProps = dispatch => (
  {
    setStoreInfo: store => dispatch(SetStoreInfo(store)),
    setErrors: errors => dispatch(SetErrors(errors)),
  });

const mapStateToProps = state =>
  ({
    storeInfo: state.currentStore.storeInfo,
  });


export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
