import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Container,
  Link,
  makeStyles,
  TextField,
  Typography,
  Card,
  Hidden,
  Switch,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Avatar,
  Fab,
  FormHelperText
} from '@material-ui/core';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  FetchStoreCategoriesStart,
  SetStoreInfo
} from '../../../redux/current-store/current-store-actions';
import { SetErrors, SetSnackNotice } from '../../../redux/app/app-actions';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import { SketchPicker } from 'react-color';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { DropzoneArea } from 'material-ui-dropzone';
import * as FA from 'react-fontawesome';
import imageCompression from 'browser-image-compression';
import ProgressBar from '../../../components/progress-bar/progress-bar';
import Page from '../../../components/Page';
import * as _ from 'lodash';
import { NavLink } from 'react-router-dom';
import ChipInput from 'material-ui-chip-input';
import generateUniqueToken from '../../../utils/generate-unique-token';
// import SelectProductsDialog from '../select-products-dialog';
import CheckoutItemComponent from 'src/components/checkout-item/checkout-item.component';
import { DataGrid } from '@material-ui/data-grid';
import CustomNoRowsOverlay from 'src/components/custom-no-rows/custom-no-rows';
import { validateData } from 'src/utils/validation';
import {
  DatePicker,
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
  TimePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  sizeGrid: {
    margin: '1em 0'
  },

  formSectionHeader: {
    margin: '16px 0'
  },
  extraMargin: {
    margin: '40px 0 0 0'
  },
  card: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  smallCard:{
    padding:theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  individualSize: {
    display: 'grid',
    gridColumnGap: '10px',
    alignItems: 'center',
    gridTemplateColumns: 'auto 1fr auto'
  },
  formContainer: {
    position: 'relative'
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: 'white'
  }
}));

const EditInvoiceForm = ({
  storeId,
  categories,
  setSnackNotice,
  setStoreInfo,
  storeInfo,
  notice,
  invoice,
  setInvoiceToEditPick,
  setErrors
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    
  const editInvoice = async values => {
    if (Object.keys(customErrors).every(errorKey => !customErrors)) {
      setSnackNotice({
        message: 'Editing invoice offer',
        severity: 'info'
      });
      try {
        const response = await axios({
          url:
            'https://kripson-store-server-8qq76.ondigitalocean.app/invoices/updateInvoice',
          method: 'POST',
          onUploadProgress: progressEvent => console.log(progressEvent),
          data: {
            sessionId: storeInfo.sessionId,
            updatedInvoice: {
              ...InvoiceToEdit,
              startDate: InvoiceToEdit.startDate.getTime(),
              endDate: InvoiceToEdit.endDate.getTime(),
              eligibleProducts: InvoiceToEdit.eligibleProducts.map(
                prd => prd._id
              ),
              eligibleCategories: InvoiceToEdit.eligibleCategories.map(
                cat => cat._id
              ),
              storeId: storeId
            }
          }
        });
        if (response.data.status === 1) {
          setSnackNotice({
            message: 'invoice offer created',
            severity: 'success'
          });
        } else if (response.data.status === 3) {
          setStoreInfo({
            ownerFirstName: '',
            ownerLastName: '',
            storeEmail: '',
            storeName: '',
            storeAddress: '',
            colors: { primary: '#000000'}
          });
          setSnackNotice({
            severity: 'warning',
            message: response.data.message
          });
        } else {
          setErrors(response.data.errors);
          setSnackNotice({
            message: 'invoice creation failed',
            severity: 'error'
          });
        }
      } catch (e) {
        setSnackNotice({
          message: 'invoice creation failed',
          severity: 'error'
        });
      }
    } else {
      console.log(InvoiceToEdit);
    }
  };

  const [selectProductsDialogOpen, setSelectProductsDialogOpen] = useState(
    false
  );
  const [selectCategoriesDialogOpen, setSelectCategoriesDialogOpen] = useState(
    false
  );

  const [customErrors, setCustomErrors] = useState({
    images: '',
    stockQuantity: {}
  });

  const [isMaxNumberOfUse, setIsMaxNumberOfUse] = useState(false);
  const [isMaxNumberOfUsePerOrder, setIsMaxNumberOfUsePerOrder] = useState(
    false
  );
  const [
    isMaxNumberOfUsePerCustomer,
    setIsMaxNumberOfUsePerCustomer
  ] = useState(false);
  const [isProductLimitPerUse, setIsProductLimitPerUse] = useState(false);

  const [InvoiceToEdit, setInvoiceToEdit] = useState({
    code: '',
    type: '',
    value: '',
    appliesTo: '',
    eligibleCategories: [],
    eligibleProducts: [],
    maxNumberOfUse: 1,
    maxNumberOfUsePerOrder: 1,
    maxNumberOfUsePerCustomer: 1,
    productLimitPerUse: 1,
    orderRequirements: [],
    customerRequirements: [],
    startDate: new Date(),
    endDate: new Date(),
    expired: false
  });

  const validateNewInvoice = async () => {
    const errors = await validateData('InvoiceToEdit', InvoiceToEdit);

    const errorMap = {};
    if (errors.errorMap) {
      errors.errorMap.forEach(error => {
        errorMap[error.path] = error.message;
      });
    }
    setCustomErrors({ ...errorMap });
    console.log(errorMap);
  };

  const columns = [
    {
      field: 'image',
      headerName: 'img',
      width: 90,
      renderCell: params => {
        return <Avatar src={params.value.props.src} />;
      }
    },
    { field: 'title', headerName: 'title', width: 200 },
    {
      field: '',
      headerName: '',
      width: 50,
      renderCell: params => {
        return (
          <DeleteIcon
            onClick={() =>
              setInvoiceToEdit({
                ...InvoiceToEdit,
                eligibleProducts: InvoiceToEdit.eligibleProducts.filter(
                  (prd, idx) => idx !== params.id - 1
                )
              })
            }
          />
        );
      }
    }
  ];

  useEffect(() => {
    validateNewInvoice();
  }, [InvoiceToEdit]);

  useEffect(() => {
    setInvoiceToEdit({...invoice, startDate: new Date(invoice.startDate), endDate: new Date(invoice.endDate)});
  },[invoice])



  const handleChange = e => {
    if (e.target.name === 'appliesTo') {
      if (e.target.value === 'products') {
        setInvoiceToEdit({
          ...InvoiceToEdit,
          [e.target.name]: e.target.value,
          eligibleCategories: []
        });
      } else if (e.target.value === 'categories') {
        setInvoiceToEdit({
          ...InvoiceToEdit,
          [e.target.name]: e.target.value,
          eligibleProducts: []
        });
      } else {
        setInvoiceToEdit({
          ...InvoiceToEdit,
          [e.target.name]: e.target.value,
          eligibleCategories: [],
          eligibleProducts: []
        });
      }
    } else {
      setInvoiceToEdit({
        ...InvoiceToEdit,
        [e.target.name]: e.target.value
      });
    }
  };

  const generateInvoiceCode = async () => {
    const newCode = await generateUniqueToken(
      storeInfo.sessionId,
      'invoices',
      'code',
      storeId,
      8,
      'distinguishable'
    );
    setInvoiceToEdit({
      ...InvoiceToEdit,
      code: newCode
    });
  };

  const [InvoiceForm, setInvoiceForm] = useState('');

  useEffect(() => {
    if (categories.length > 0) {
      setInvoiceForm(
        <form onSubmit={e => e.preventDefault()} className={classes.root}>
          {/* <Box mb={3}>
            <Typography color="textPrimary" variant="h2">
              Edit invoice
            </Typography>
            <Typography color="textSecondary" gutterBottom variant="body2">
              Edit your invoice offer
            </Typography>
          </Box>
          <SelectProductsDialog
            open={selectProductsDialogOpen}
            onClose={() => setSelectProductsDialogOpen(false)}
            addEligibleProducts={products => {
              setInvoiceToEdit({
                ...InvoiceToEdit,
                eligibleProducts: InvoiceToEdit.eligibleProducts.concat(products)
              });
            }}
            alreadySelectedProductIds={InvoiceToEdit.eligibleProducts.map(
              prd => prd._id
            )}
          />

          <Card className={isSmallScreen? classes.smallCard:classes.card}>
            <Typography color="textPrimary" gutterBottom variant="h5">
              invoice code
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  placeholder="eg : FESTIVE30"
                  value={InvoiceToEdit.code}
                  onChange={handleChange}
                  helperText="Code to use at checkout"
                  variant="outlined"
                  name="code"
                  fullWidth
                  error={Boolean(customErrors.code)}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <Button  size="large"
                  variant="contained"
                  margin="normal"
                  color="primary"
                  onClick={generateInvoiceCode}
                >
                  Generate Code
                </Button>
              </Grid>
            </Grid>
          </Card>

          <Card className={isSmallScreen? classes.smallCard:classes.card}>
            <Typography color="textPrimary" gutterBottom variant="h5">
              Start and End Dates
            </Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="start" spacing={1}>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="invoice start date"
                    format="MM/dd/yyyy"
                    inputVariant="outlined"
                    fullWidth
                    value={InvoiceToEdit.startDate}
                    onChange={date =>
                      setInvoiceToEdit({
                        ...InvoiceToEdit,
                        startDate: date
                      })
                    }
                    name="startDate"
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    style={{ marginRight: '8px' }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TimePicker
                    margin="normal"
                    id="time-picker"
                    label="invoice start time"
                    fullWidth
                    value={InvoiceToEdit.startDate}
                    inputVariant="outlined"
                    onChange={date =>
                      setInvoiceToEdit({
                        ...InvoiceToEdit,
                        startDate: date
                      })
                    }
                    name="startDate"
                    KeyboardButtonProps={{
                      'aria-label': 'change time'
                    }}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="start" spacing={1}>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="invoice end date"
                    format="MM/dd/yyyy"
                    value={InvoiceToEdit.endDate}
                    fullWidth
                    inputVariant="outlined"
                    onChange={date =>
                      setInvoiceToEdit({
                        ...InvoiceToEdit,
                        endDate: date
                      })
                    }
                    name="endDate"
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    style={{ marginRight: '8px' }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TimePicker
                    margin="normal"
                    id="time-picker"
                    label="invoice start time"
                    value={InvoiceToEdit.endDate}
                    fullWidth
                    inputVariant="outlined"
                    onChange={date =>
                      setInvoiceToEdit({
                        ...InvoiceToEdit,
                        endDate: date
                      })
                    }
                    name="endDate"
                    KeyboardButtonProps={{
                      'aria-label': 'change time'
                    }}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </Card>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card className={isSmallScreen? classes.smallCard:classes.card}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <FormControl component="fieldset" error={customErrors.type}>
                      <FormLabel component="legend">
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h5"
                        >
                          Types
                        </Typography>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="body2"
                        >
                          Please, select the type of the invoice
                        </Typography>
                      </FormLabel>
                      <RadioGroup
                        aria-label="type"
                        name="type"
                        value={InvoiceToEdit.type}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="percentage"
                          control={<Radio />}
                          label="%"
                        />
                        <FormControlLabel
                          value="amount"
                          control={<Radio />}
                          label="Amount"
                        />
                      </RadioGroup>
                      <FormHelperText>{customErrors.type}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              {' '}
              <Card className={isSmallScreen? classes.smallCard:classes.card}>
                <Typography color="textPrimary" gutterBottom variant="h5">
                  Value
                </Typography>
                <Typography color="textSecondary" gutterBottom variant="body2">
                  Please, select the value of invoice
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(customErrors.value)}
                      fullWidth
                      helperText={'invoice value'}
                      label={
                        InvoiceToEdit.type === 'percentage'
                          ? '%'
                          : InvoiceToEdit.type === 'amount'
                          ? storeInfo.storeCurrencySymbol
                          : ''
                      }
                      margin="normal"
                      name="value"
                      onChange={handleChange}
                      type="text"
                      value={InvoiceToEdit.value}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>

          <Card className={isSmallScreen? classes.smallCard:classes.card}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControl
                  component="fieldset"
                  error={customErrors.appliesTo}
                >
                  <FormLabel component="legend">
                    <Typography color="textPrimary" gutterBottom variant="h5">
                      Applies to
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    aria-label="type"
                    name="appliesTo"
                    value={InvoiceToEdit.appliesTo}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="order"
                      control={<Radio color="primary" />}
                      label="Entire order"
                    />
                    <FormControlLabel
                      value="categories"
                      control={<Radio color="primary" />}
                      label="Specific categories"
                    />
                    <FormControlLabel
                      value="products"
                      control={<Radio color="primary" />}
                      label="Specific products"
                    />
                  </RadioGroup>
                  <FormHelperText>{customErrors.appliesTo}</FormHelperText>
                </FormControl>
              </Grid>
              {['products', 'categories'].includes(InvoiceToEdit.appliesTo) ? (
                <Grid item xs={12}>
                  <Button  size="large"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (InvoiceToEdit.appliesTo === 'products') {
                        setSelectProductsDialogOpen(true);
                      } else {
                        setSelectCategoriesDialogOpen(true);
                      }
                    }}
                  >
                    {InvoiceToEdit.eligibleCategories.length ||
                    InvoiceToEdit.eligibleProducts.length
                      ? 'Add more'
                      : 'Select'}{' '}
                    eligible {InvoiceToEdit.appliesTo} for invoice
                  </Button>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          </Card>
          {InvoiceToEdit.eligibleCategories.length ||
          InvoiceToEdit.eligibleProducts.length ? (
            <Card className={isSmallScreen? classes.smallCard:classes.card}>
              <Typography color="textPrimary" gutterBottom variant="h5">
                Selected {InvoiceToEdit.appliesTo}
              </Typography>
              <DataGrid
                autoHeight
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay
                }}
                rows={InvoiceToEdit.eligibleProducts.map((invoice, idx) => {
                  return {
                    title: invoice.title,
                    image: <Avatar src={invoice.images[0].url} />,
                    id: idx + 1,
                    color: invoice.color
                  };
                })}
                columns={columns}
                pageSize={10}
              />
            </Card>
          ) : (
            ''
          )}

          <Box mt={2}>
            <Card className={isSmallScreen? classes.smallCard:classes.card}>
              <Typography variant="h5" color="textPrimary" gutterBottom>
                Apply Limits
              </Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Enforce limits on this invoice offer
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <TextField
                    error={Boolean(customErrors.maxNumberOfUse)}
                    fullWidth
                    helperText={
                      'Set max number of use throughout the store for this invoice'
                    }
                    label={'Max number of use'}
                    margin="normal"
                    name="maxNumberOfUse"
                    onChange={e => {
                      if (
                        e.target.value === '' ||
                        Number(e.target.value) !== 0
                      ) {
                        console.log(e.target.value);
                        handleChange(e);
                      } else {
                        console.log(e.target.value);
                        setInvoiceToEdit({
                          ...InvoiceToEdit,
                          maxNumberOfUse: 1
                        });
                      }
                    }}
                    type="number"
                    value={InvoiceToEdit.maxNumberOfUse}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    error={Boolean(customErrors.maxNumberOfUsePerOrder)}
                    fullWidth
                    helperText={'Set max number of use per order'}
                    label={'Max number of use'}
                    margin="normal"
                    name="maxNumberOfUsePerOrder"
                    onChange={e => {
                      if (
                        e.target.value === '' ||
                        Number(e.target.value) !== 0
                      ) {
                        handleChange(e);
                      } else {
                        setInvoiceToEdit({
                          ...InvoiceToEdit,
                          maxNumberOfUsePerOrder: 1
                        });
                      }
                    }}
                    type="number"
                    value={InvoiceToEdit.maxNumberOfUsePerOrder}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    error={Boolean(customErrors.maxNumberOfUsePerCustomer)}
                    fullWidth
                    helperText={'Set max number of use per customer'}
                    label={'Max number of use'}
                    margin="normal"
                    name="maxNumberOfUsePerCustomer"
                    onChange={e => {
                      if (
                        e.target.value === '' ||
                        Number(e.target.value) !== 0
                      ) {
                        handleChange(e);
                      } else {
                        setInvoiceToEdit({
                          ...InvoiceToEdit,
                          maxNumberOfUsePerCustomer: 1
                        });
                      }
                    }}
                    type="number"
                    value={InvoiceToEdit.maxNumberOfUsePerCustomer}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    error={Boolean(customErrors.productLimitPerUse)}
                    fullWidth
                    helperText={
                      'Set max number of products to apply this invoice on (per use)'
                    }
                    label={'invoice limit per use'}
                    margin="normal"
                    name="productLimitPerUse"
                    onChange={e => {
                      if (
                        e.target.value === '' ||
                        Number(e.target.value) !== 0
                      ) {
                        handleChange(e);
                      } else {
                        setInvoiceToEdit({
                          ...InvoiceToEdit,
                          productLimitPerUse: 1
                        });
                      }
                    }}
                    type="number"
                    value={InvoiceToEdit.productLimitPerUse}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Card>
          </Box>

          <Hidden xsDown>
            <Box my={5} display="flex" justifyContent="space-between">
              <Button  size="large"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={editInvoice}
              >
                Edit invoice
              </Button>
              <Button  size="large"
                className={classes.error}
                style={{ marginLeft: '15px' }}
                variant="contained"
                fullWidth
                size="large"
                onClick={(e)=>setInvoiceToEditPick(undefined)}
              >
                Cancel
              </Button>
            </Box>
          </Hidden>

          <Hidden smUp>
            <Box my={5} display="block">
              <Button  size="large"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={editInvoice}
                style={{ marginBottom: '8px' }}
              >
                Edit invoice
              </Button>
              <Button  size="large"
                className={classes.error}
                variant="contained"
                fullWidth
                size="large"
                onClick={(e)=>setInvoiceToEditPick(undefined)}
              >
                Cancel
              </Button>
            </Box>
          </Hidden> */}
        </form>
      );
    }
  }, [
    InvoiceToEdit,
    customErrors,
    categories,
    selectProductsDialogOpen,
    selectCategoriesDialogOpen
  ]);

  return (
    <div className={classes.formContainer}>
      {notice.message === 'Creating new invoice' ? (
        <ProgressBar completed={false} action={'Creating your invoice'} />
      ) : (
        ''
      )}
      {InvoiceForm}
    </div>
  );
};

const mapStateToProps = state => ({
  notice: state.app.notice,
  storeId: state.currentStore.storeInfo._id,
  storeInfo: state.currentStore.storeInfo,
  categories: state.currentStore.categories
});

const mapDispatchToProps = dispatch => ({
  FetchStoreCategoriesStart: (storeId, sessionId) =>
    dispatch(FetchStoreCategoriesStart(storeId, sessionId)),
  setStoreInfo: storeInfo => dispatch(SetStoreInfo(storeInfo)),
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setErrors: errors => dispatch(SetErrors(errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditInvoiceForm);
