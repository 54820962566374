import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles,
  TextField,
  Card,
  MenuItem,
  CardHeader,
  Divider,
  Typography,
  Chip,
  Button
} from '@material-ui/core';
import Page from 'src/components/Page';
import Notifications from './Notifications';
import Password from './Password';
import { connect } from 'react-redux';
import axios from 'axios';
import { SetStoreInfo } from '../../../redux/current-store/current-store-actions';
import { SetErrors, SetSnackNotice } from '../../../redux/app/app-actions';
import * as _ from 'lodash';
import FlagIcon from '../../../components/flag-component/flag-component';
import { flagCurrencyMap } from '../../../flagCurrencyMap';
import PaymentMethodSetupForm from 'src/components/payment-method-setup-form/payment-method-setup-form';
import countryPaymentMethodMap from './country-payment-method-map';
import { validateData } from 'src/utils/validation';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  currencyContainer: {
    padding: theme.spacing(2)
  },
  currency: {
    marginLeft: theme.spacing(1)
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  paymentMethodBox: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paymentMethodLogo: {
    height: '100px',
    width: 'auto',
  },
  errorText: {
    color: theme.palette.error.main,
  }
}));

const SettingsView = ({
  storeInfo,
  setStoreInfo,
  setErrors,
  setSnackNotice
}) => {
  const classes = useStyles();
  const [newPassword, setNewPassword] = useState(null);
  const [newNotificationMedium, setNewNotificationMedium] = useState(null);
  const [newStoreCurrency, setNewStoreCurrency] = useState(null);
 




  useEffect(() => {
    setNewNotificationMedium(storeInfo.notificationMedium || []);
    setNewStoreCurrency(storeInfo.storeCurrency);

  }, [storeInfo]);






  const updateStoreInfo = async () => {
    if (storeInfo) {

      const validationResponse = await validateData('updatedStore', {
        ...storeInfo,
        password: newPassword ? newPassword : null,
        storeCurrency: newStoreCurrency || storeInfo.storeCurrency,
        notificationMedium:
          newNotificationMedium || storeInfo.notificationMedium
      });

      if (!validationResponse.validity) {
        setErrors(validationResponse.errors);
        return;
      }

      setSnackNotice({
        message: 'Updating store information',
        severity: 'warning'
      });

      axios({
        url:
          'https://kripson-store-server-8qq76.ondigitalocean.app/stores/updateStoreInfo',
        method: 'POST',
        data: {
          ...storeInfo,
          password: newPassword ? newPassword : null,
          storeCurrency: newStoreCurrency || storeInfo.storeCurrency,
          notificationMedium:
            newNotificationMedium || storeInfo.notificationMedium
        }
      })
        .then(response => {
          if (response.data.status === 1) {
            setSnackNotice({
              message: 'Store information updated successfully',
              severity: 'success'
            });
            setStoreInfo({...storeInfo, ...response.data.result});
          } else if (response.data.status === 3) {
            setStoreInfo({
              ownerFirstName: '',
              ownerLastName: '',
              storeEmail: '',
              storeName: '',
              storeAddress: '',
              colors: {primary: '#000000'}
            });
            setSnackNotice({
              severity: 'warning',
              message: response.data.message
            });
          } else {
            setErrors(response.data.errors);
          }
        })
        .catch(error => {
          setSnackNotice({
            message: 'Store information could not be updated',
            severity: 'error'
          });
        });
    }
  };

  return (
    <Page className={classes.root} title="Settings">
      <Container maxWidth="lg">
        <Box mb={3}>
          <Typography color="textPrimary" variant="h2">
            Settings
          </Typography>
          <Typography color="textSecondary" gutterBottom variant="body2">
            General site settings
          </Typography>
        </Box>
        {/* <Box mb={3}>
          <Card>
            <CardHeader
              subheader="Edit your store currency"
              title="Store Currency"
            >
              Store Currency
            </CardHeader>
            <Divider />
            <Box className={classes.currencyContainer}>
              <TextField
                select
                value={newStoreCurrency}
                onChange={e => setNewStoreCurrency(e.target.value)}
                helperText={'Please select the currency for store transactions'}
                variant="outlined"
                name="storeCurrency"
                fullWidth
                error={!newStoreCurrency}
                margin="normal"
              >
                {Object.keys(flagCurrencyMap).map(currency => (
                  <MenuItem value={currency}>
                    {' '}
                    <FlagIcon code={flagCurrencyMap[currency]} size={'lg'} />
                    <span className={classes.currency}>
                      {currency}
                    </span>
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Card>
        </Box> */}






        <Notifications
          notificationMedium={newNotificationMedium}
          setNewNotificationMedium={setNewNotificationMedium}
          updateStoreInfo={updateStoreInfo}
        />
        <Box mt={3}>
          <Password
            setNewPassword={setNewPassword}
            updateStoreInfo={updateStoreInfo}
          />
        </Box>
      </Container>
    </Page>
  );
};

const mapStateToProps = state => ({
  storeInfo: state.currentStore.storeInfo
});

const mapDispatchToProps = dispatch => ({
  setStoreInfo: storeInfo => dispatch(SetStoreInfo(storeInfo)),
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setErrors: errors => dispatch(SetErrors(errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsView);
