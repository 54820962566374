import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Container,
  Link,
  makeStyles,
  TextField,
  Typography,
  Card,
  Hidden,
  Switch
} from '@material-ui/core';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  FetchStoreCategoriesStart,
  SetStoreInfo
} from '../../../redux/current-store/current-store-actions';
import { SetErrors, SetSnackNotice } from '../../../redux/app/app-actions';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import { SketchPicker } from 'react-color';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { DropzoneArea } from 'material-ui-dropzone';
import * as FA from 'react-fontawesome';
import imageCompression from 'browser-image-compression';
import ProgressBar from '../../../components/progress-bar/progress-bar';
import Page from '../../../components/Page';
import * as _ from 'lodash';
import { NavLink } from 'react-router-dom';
import ChipInput from 'material-ui-chip-input';
import validator from 'validator';

const useStyles = makeStyles(theme => ({
  sizeGrid: {
    margin: '1em 0'
  },

  formSectionHeader: {
    margin: '16px 0'
  },
  extraMargin: {
    margin: '40px 0 0 0'
  },
  card: {
    padding: '20px'
  },
  individualSize: {
    display: 'grid',
    gridColumnGap: '10px',
    alignItems: 'center',
    gridTemplateColumns: 'auto 1fr auto'
  },
  formContainer: {
    position: 'relative'
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: 'white'
  }
}));

const AddProductForm = ({
  storeId,
  categories,
  setSnackNotice,
  setStoreInfo,
  storeInfo,
  notice,
  setErrors
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const createProduct = async values => {
    if (
      !(customErrors.images || Object.keys(customErrors.stockQuantity).length)
    ) {
      setSnackNotice({
        message: 'Creating new product',
        severity: 'info'
      });
      const productToSubmit = {
        ...newProduct,
        ...values
      };
      try {
        const response = await axios({
          url: 'https://kripson-store-server-8qq76.ondigitalocean.app/products/createProduct',
          method: 'POST',
          onUploadProgress: progressEvent => console.log(progressEvent),
          data: {
            sessionId: storeInfo.sessionId,
            newProduct: {
              ...productToSubmit,
              unitValue: String(productToSubmit.unitValue || '')
            },
            storeId: storeId
          }
        });

        if (response.data.status === 1) {
          setNewProduct({
            color: '#000000',
            sizes: [],
            stockQuantity: {},
            images: []
          });
          setSnackNotice({
            message: 'Product created',
            severity: 'success'
          });
        } else if (response.data.status === 3) {
          setStoreInfo({
            ownerFirstName: '',
            ownerLastName: '',
            storeEmail: '',
            storeName: '',
            storeAddress: '',
            colors: { primary: '#000000' }
          });
          setSnackNotice({
            severity: 'warning',
            message: response.data.message
          });
        } else {
          setErrors(response.data.errors);
          setSnackNotice({
            message: 'Product creation failed',
            severity: 'error'
          });
        }
      } catch (e) {
        setSnackNotice({
          message: 'Product creation failed',
          severity: 'error'
        });
      }
    } else {
    }
  };

  const imageConversion = async files => {
    const compressedFileArray = [];
    for (let file of files) {
      const options = {
        maxSizeMB: 0.4,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      };
      try {
        const compressedFile = await imageCompression(file, options);
        compressedFileArray.push(compressedFile);
      } catch (error) {
        console.log(error);
      }
    }

    const base64dataarray = [];
    let counter = base64dataarray.length;

    compressedFileArray.forEach((file, idx) => {
      let reader = new FileReader();
      reader.onloadend = function() {
        base64dataarray[counter].base64data = reader.result;
        if (idx === files.length - 1) {
          setNewProduct({
            ...newProduct,
            images: base64dataarray
          });
        }

        counter++;
      };
      reader.readAsDataURL(file);
      base64dataarray.push({ type: file.type });
    });
  };

  const [customErrors, setCustomErrors] = useState({
    images: '',
    stockQuantity: {}
  });

  const [newProduct, setNewProduct] = useState({
    color: '#000000',
    tags: [],
    sizes: [],
    stockQuantity: {},
    images: []
  });

  const validateNewProduct = () => {
    const errors = { ...customErrors };
    const { sizes, stockQuantity, images } = newProduct;

    for (const size of sizes) {
      if (!(stockQuantity.hasOwnProperty(size) && stockQuantity[size])) {
        if (!errors.hasOwnProperty('stockQuantity')) {
          errors['stockQuantity'] = {};
        }
        errors.stockQuantity[
          size
        ] = `Please provide stock quantity for ${size} size of the product`;
      } else {
        delete errors.stockQuantity[size];
      }
    }

    if (images.length === 0) {
      errors.images = 'Please upload at least one image of the product';
    } else {
      errors.images = '';
    }

    setCustomErrors({ ...errors });
  };

  useEffect(() => {
    validateNewProduct();
  }, [newProduct]);

  const [productForm, setProductForm] = useState('');
  const [newSize, setNewSize] = useState('');

  useEffect(() => {
    setProductForm(
      <Formik
        initialValues={{
          price: '',
          status: '',
          costPrice: '',
          title: '',
          brand: '',
          description: '',
          unitValue: 0,
          unitLabel: '',
          isRefundable: false,
          isDeliverable: false,
          isSoldByCount: true,
          enableStockAlert: false,
          stockAlertQuantity: 0
        }}
        validationSchema={Yup.object().shape({
          price: Yup.number()
            .min(1)
            .required('Price is required'),
          status: Yup.string()
            .min(1)
            .required('Status is required'),
          costPrice: Yup.number()
            .min(1)
            .required('Cost price is required'),
          title: Yup.string()
            .min(1)
            .required('Title is required'),
          unitValue: Yup.number().when('isSoldByCount', {
            is: false,
            then: Yup.number()
              .min(1)
              .required('Unit Value is required')
          }),
          unitLabel: Yup.string().when('isSoldByCount', {
            is: false,
            then: Yup.string()
              .min(1)
              .required('Unit Label is required')
          }),
          description: Yup.string()
            .min(1)
            .required('Description is required'),
          isRefundable: Yup.boolean().required(
            'Please mark whether this product is refundable or not.'
          ),
          isDeliverable: Yup.boolean().required(
            'Please mark whether this product is deliverable or not.'
          ),
          isSoldByCount: Yup.boolean().required(
            'Please mark whether this product is sold by cound or not.'
          ),
          enableStockAlert: Yup.boolean().required(
            'Please mark whether to enable low product stock alert or not.'
          ),
          stockAlertQuantity: Yup.number().when('enableStockAlert', {
            is: true,
            then: Yup.number()
              .min(1)
              .required('Please provide a quantity for low stock alert.')
          })
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(false);
          await createProduct(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Box mb={3}>
              <Typography color="textPrimary" variant="h2">
                Add Product
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body2">
                Create a new product
              </Typography>
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <Grid container spacing={1} className={classes.card}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Title"
                        value={values.title}
                        onChange={handleChange}
                        helperText="Product title"
                        variant="outlined"
                        onBlur={handleBlur}
                        name="title"
                        fullWidth
                        error={Boolean(touched.title && errors.title)}
                        margin="normal"
                      />
                      <TextField
                        helperText="Product Brand"
                        label="Brand"
                        name="brand"
                        value={values.brand}
                        error={Boolean(touched.brand && errors.brand)}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                      />

                      <TextField
                        error={Boolean(touched.price && errors.price)}
                        fullWidth
                        helperText={'Selling price of the product'}
                        label={`Price ${
                          storeInfo && storeInfo.storeCurrencySymbol
                            ? storeInfo.storeCurrencySymbol
                            : ''
                        }`}
                        margin="normal"
                        name="price"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.price}
                        variant="outlined"
                      />

                      <TextField
                        select
                        label="Select"
                        value={values.status}
                        onChange={handleChange}
                        helperText={'Please select the status of the product'}
                        variant="outlined"
                        onBlur={handleBlur}
                        name="status"
                        fullWidth
                        error={Boolean(touched.status && errors.status)}
                        margin="normal"
                      >
                        <MenuItem value={'coming soon'}>coming soon</MenuItem>
                        <MenuItem value={'available now'}>
                          available now
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        select
                        label="Select"
                        value={values.parentCategory}
                        onChange={handleChange}
                        helperText="Please select the product category"
                        variant="outlined"
                        onBlur={handleBlur}
                        name="parentCategory"
                        fullWidth
                        error={Boolean(
                          touched.parentCategory && errors.parentCategory
                        )}
                        margin="normal"
                      >
                        {categories.map(category => {
                          return (
                            <MenuItem key={category._id} value={category._id}>
                              {category.categoryName}
                            </MenuItem>
                          );
                        })}
                      </TextField>

                      <TextField
                        error={Boolean(touched.costPrice && errors.costPrice)}
                        fullWidth
                        helperText={'Cost price of the product'}
                        label={`Cost price ${
                          storeInfo && storeInfo.storeCurrencySymbol
                            ? storeInfo.storeCurrencySymbol
                            : ''
                        }`}
                        margin="normal"
                        name="costPrice"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.costPrice}
                        variant="outlined"
                      />

                      <TextField
                        helperText="Please provide a description of the product"
                        label="Description"
                        multiline
                        rowsMax={5}
                        name="description"
                        value={values.description}
                        error={Boolean(
                          touched.description && errors.description
                        )}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                      />

                      <Box mt={2} mb={1}>
                        <ChipInput
                          name="tags"
                          variant="outlined"
                          helperText="Attach tags to the product"
                          fullWidth
                          fullWidthInput
                          onChange={chips => {
                            setNewProduct({
                              ...newProduct,
                              tags: chips
                            });
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
                <Box mt={2}>
                  <Card className={classes.card}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isRefundable}
                          onChange={handleChange}
                          name="isRefundable"
                          color="primary"
                        />
                      }
                      label="is Refundable?"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isDeliverable}
                          onChange={handleChange}
                          name="isDeliverable"
                          color="primary"
                        />
                      }
                      label="is Deliverable?"
                    />

                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isSoldByCount}
                          onChange={handleChange}
                          name="isSoldByCount"
                          color="primary"
                        />
                      }
                      label="is sold by count?"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.enableStockAlert}
                          onChange={handleChange}
                          name="enableStockAlert"
                          color="primary"
                        />
                      }
                      label="enable low stock alert?"
                    />
                    {values.isSoldByCount ? (
                      ''
                    ) : (
                      <>
                        <TextField
                          helperText="Only insert a number here. How much is 1 unit? eg 1kg/ 1ltr. This is the minimum amount that can be purchased."
                          label="1 unit = ?."
                          name="unitValue"
                          value={values.unitValue}
                          error={Boolean(touched.unitValue && errors.unitValue)}
                          onChange={handleChange}
                          variant="outlined"
                          fullWidth
                          inputProps={{
                            type: 'number',
                            min: 1
                          }}
                          type="number"
                          margin="normal"
                        />

                        <TextField
                          helperText="Unit label. eg: kg/ltr"
                          label="Unit Label"
                          name="unitLabel"
                          value={values.unitLabel}
                          error={Boolean(touched.unitLabel && errors.unitLabel)}
                          onChange={handleChange}
                          variant="outlined"
                          fullWidth
                          margin="normal"
                        />
                      </>
                    )}
                    {values.enableStockAlert ? (
                      <TextField
                        helperText="Only insert a number here. You will be alerted when the product stock is equals to this number."
                        label="Low stock threshold."
                        name="stockAlertQuantity"
                        value={values.stockAlertQuantity}
                        error={Boolean(
                          touched.stockAlertQuantity &&
                            errors.stockAlertQuantity
                        )}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        inputProps={{
                          type: 'number',
                          min: 0
                        }}
                        type="number"
                        margin="normal"
                      />
                    ) : (
                      ''
                    )}
                  </Card>
                </Box>
                <Box mt={2}>
                  <Card>
                    <Box className={`${classes.card}`}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h5"
                      >
                        Add a new size:
                      </Typography>
                      <TextField
                        fullWidth
                        label="New size"
                        margin="normal"
                        name="size"
                        onChange={e => {
                          setNewSize(e.target.value.toUpperCase());
                        }}
                        type="text"
                        variant="outlined"
                      />
                      <Box my={2}>
                        <Button
                          size="large"
                          className={classes.success}
                          disabled={isSubmitting}
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            if (!newSize) {
                              setSnackNotice({
                                message: 'Please entre a size to add',
                                severity: 'error'
                              });
                              return;
                            }
                            if (!newProduct.sizes.includes(newSize)) {
                              const newProductSizeArray = [...newProduct.sizes];
                              newProductSizeArray.push(newSize);
                              setNewProduct({
                                ...newProduct,
                                sizes: newProductSizeArray
                              });
                            } else {
                              setSnackNotice({
                                message: 'Duplicate size provided',
                                severity: 'error'
                              });
                            }
                          }}
                        >
                          Add Size
                        </Button>
                      </Box>
                    </Box>
                  </Card>
                </Box>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h4"
                  className={`${classes.formSectionHeader} ${classes.extraMargin}`}
                >
                  Available Sizes
                </Typography>
                {newProduct.sizes.length > 0 ? (
                  ''
                ) : (
                  <span style={{ color: 'red' }}>
                    At least one item size must be provided{' '}
                    <FA name="exclamation-circle"></FA>
                  </span>
                )}
                <Card>
                  <Grid
                    container
                    className={`${classes.card}`}
                    style={{ margin: '10px 0 0 -4px' }}
                  >
                    <Grid item xs={12}>
                      <Grid container spacing={1} className={classes.sizeGrid}>
                        {newProduct.sizes.map((size, idx) => {
                          return (
                            <Grid
                              item
                              xs={12}
                              md={6}
                              key={idx}
                              className={classes.individualSize}
                            >
                              <span>{size}</span>

                              <TextField
                                error={
                                  customErrors.stockQuantity
                                    ? customErrors.stockQuantity.hasOwnProperty(
                                        size
                                      ) ||
                                      !validator.isNumeric(
                                        String(newProduct.stockQuantity[size])
                                      )
                                    : false
                                }
                                key={idx}
                                helperText={`Stock quantity for ${size} size`}
                                label="Stock Quantity"
                                margin="normal"
                                name="sizes"
                                type="number"
                                inputProps={{
                                  type: 'number',
                                  min: 1
                                }}
                                onChange={e => {
                                  console.log('test');
                                  setNewProduct({
                                    ...newProduct,
                                    stockQuantity: {
                                      ...newProduct.stockQuantity,
                                      [size]: Math.round(Number(e.target.value))
                                    }
                                  });
                                }}
                                value={
                                  newProduct.stockQuantity[size]
                                    ? newProduct.stockQuantity[size]
                                    : ''
                                }
                                variant="outlined"
                              />
                              <IconButton
                                aria-label="delete"
                                onClick={() => {
                                  setNewProduct({
                                    ...newProduct,
                                    sizes: newProduct.sizes.filter(
                                      innerSize => innerSize !== size
                                    )
                                  });
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
                <Box mt={2}>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                    className={classes.formSectionHeader}
                  >
                    Pick product color
                  </Typography>
                  <SketchPicker
                    label="Color of the product"
                    name="color"
                    id="product-color"
                    color={newProduct.color}
                    disableAlpha={true}
                    onChange={color =>
                      setNewProduct({
                        ...newProduct,
                        color: color.hex
                      })
                    }
                    width={300}
                  />
                </Box>
              </Grid>
            </Grid>

            {/*<Typography*/}
            {/*  color="textPrimary"*/}
            {/*  gutterBottom*/}
            {/*  variant="h4"*/}
            {/*  className={`${classes.formSectionHeader} ${classes.extraMargin}`}*/}
            {/*>*/}
            {/*  Available Sizes:*/}
            {/*</Typography>*/}

            <Typography
              color="textPrimary"
              gutterBottom
              variant="h4"
              className={`${classes.formSectionHeader} ${classes.extraMargin}`}
            >
              Add Product Images
            </Typography>

            {customErrors.images ? (
              <span style={{ color: 'red' }}>
                {customErrors.images}
                <FA
                  style={{ marginLeft: '5px' }}
                  name={'exclamation-circle'}
                ></FA>
              </span>
            ) : (
              ''
            )}
            <Box mt={2}>
              <DropzoneArea
                filesLimit={4}
                onChange={files => imageConversion(files)}
              />
            </Box>
            <Hidden xsDown>
              <Box my={5} display="flex" justifyContent="space-between">
                <Button
                  size="large"
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Add Product
                </Button>
                <Button
                  size="large"
                  className={classes.error}
                  style={{ marginLeft: '15px' }}
                  variant="contained"
                  fullWidth
                  component={RouterLink}
                  to="/app/products"
                >
                  Cancel
                </Button>
              </Box>
            </Hidden>

            <Hidden smUp>
              <Box my={5} display="block">
                <Button
                  size="large"
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  style={{ marginBottom: '8px' }}
                >
                  Add Product
                </Button>
                <Button
                  size="large"
                  className={classes.error}
                  variant="contained"
                  fullWidth
                  component={RouterLink}
                  to="/app/products"
                >
                  Cancel
                </Button>
              </Box>
            </Hidden>
          </form>
        )}
      </Formik>
    );
  }, [newProduct, newSize, customErrors, categories]);

  return (
    <div className={classes.formContainer}>
      {notice.message === 'Creating new product' ? (
        <ProgressBar completed={false} action={'Creating your product'} />
      ) : (
        ''
      )}
      {productForm}
    </div>
  );
};

const mapStateToProps = state => ({
  notice: state.app.notice,
  storeId: state.currentStore.storeInfo._id,
  storeInfo: state.currentStore.storeInfo,
  categories: state.currentStore.categories
});

const mapDispatchToProps = dispatch => ({
  FetchStoreCategoriesStart: (storeId, sessionId) =>
    dispatch(FetchStoreCategoriesStart(storeId, sessionId)),
  setStoreInfo: storeInfo => dispatch(SetStoreInfo(storeInfo)),
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setErrors: errors => dispatch(SetErrors(errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProductForm);
