import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    Box,
    Button,
    Container,
    Link,
    makeStyles,
    TextField,
    Typography,
    IconButton
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    FetchStoreCategoriesStart,
    SetStoreInfo
} from '../../../redux/current-store/current-store-actions';
import { SetSnackNotice, SetErrors } from '../../../redux/app/app-actions';
import { connect } from 'react-redux';
import AllCategoryList from './all-category-list';
import * as _ from 'lodash';
import { DropzoneArea } from 'material-ui-dropzone';
import imageCompression from 'browser-image-compression';

const useStyles = makeStyles(theme => ({
}));

const EditCategoryForm = ({
    storeId,
    setStoreInfo,
    storeInfo,
    setSnackNotice,
    FetchStoreCategoriesStart,
    setErrors,
    category,
    setMode
}) => {
    const classes = useStyles();
    const [newImage, setNewImage] = useState({});


    const imageConversion = async (files) => {
        const compressedFileArray = [];
        for (let file of files) {

            const options = {
                maxSizeMB: 0.4,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            };
            try {
                const compressedFile = await imageCompression(file, options);
                compressedFileArray.push(compressedFile);

            } catch (error) {
                console.log(error);
            }
        }


        const base64dataarray = [];
        let counter = base64dataarray.length;

        compressedFileArray.forEach((file, idx) => {
            let reader = new FileReader();
            reader.onloadend = function () {
                base64dataarray[counter].base64data = reader.result;
                if (idx === files.length - 1) {
                    setNewImage(base64dataarray[0])
                }

                counter++;


            };
            reader.readAsDataURL(file);
            base64dataarray.push({ type: file.type });
        })
    };


    const editCategory = async values => {
        if (values.hasOwnProperty('categoryName')) {
            values.categoryName = _.capitalize(values['categoryName']);
        }
        setSnackNotice({
            message: 'Editing category',
            severity: 'info'
        });
        const response = await axios({
            url: 'https://kripson-store-server-8qq76.ondigitalocean.app/stores/editCategory',
            method: 'POST',
            data: {
                ...category,
                ...values,
                sessionId: storeInfo.sessionId,
                newCategoryImage: newImage && newImage.base64data ? newImage : null,
                _id: category._id,
                storeId: storeId
            }
        });

        if (response.data.status === 1) {
            FetchStoreCategoriesStart(storeId, storeInfo.sessionId);
            setSnackNotice({
                message: `${values.categoryName} category updated`,
                severity: 'success'
            });
        }
        else if (response.data.status === 3) {
            setStoreInfo({
                ownerFirstName: '',
                ownerLastName: '',
                storeEmail: '',
                storeName: '',
                storeAddress: '',
                colors: { primary: '#000000' }
            });
            setSnackNotice({
                severity: 'warning',
                message: response.data.message
            });
        }
        else {
            setErrors(response.data.errors);
            setSnackNotice({
                message: `${values.categoryName} category could not be updated`,
                severity: 'error'
            });
        }
    };

    return (
        <div className={classes.root}>
            <Formik
                initialValues={{
                    categoryName: category.categoryName,
                }}
                validationSchema={Yup.object().shape({
                    // categoryName: Yup.string().max(10).required('Category name is required')
                })}
                onSubmit={(values, { setSubmitting }) => {
                    editCategory(values);
                    setSubmitting(false);
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box>
                            <Box mb={3}>
                                <Typography color="textPrimary" variant="h2">
                                    Edit category
                                </Typography>
                                <Typography color="textSecondary" gutterBottom variant="body2">
                                    {`Rename category '${category.categoryName}'`}

                                </Typography>
                            </Box>

                            <TextField
                                error={Boolean(touched.categoryName && errors.categoryName)}
                                fullWidth
                                helperText={touched.categoryName && errors.categoryName}
                                label="Category Name"
                                margin="normal"
                                name="categoryName"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="text"
                                value={values.categoryName}
                                variant="outlined"
                            />

                            <DropzoneArea
                                dropzoneText="Edit the category image"
                                filesLimit={1}
                                onChange={(files) => imageConversion(files)}
                                name="newCategoryImage"
                            />

                            <Box my={2}>
                                <Button size="large"
                                    color="primary"
                                    disabled={isSubmitting || !values.categoryName}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    Edit Category
                                </Button>
                            </Box>


                            <Button size="large"
                                color="secondary"
                                fullWidth
                                size="large"
                                onClick={() => setMode('list')}
                                variant="contained"
                                style={{ backgroundColor: '#f44336', color: 'white' }}
                            >
                                Cancel
                            </Button>

                        </Box>
                    </form>
                )}
            </Formik>
        </div>
    );
};

const mapStateToProps = state => ({
    storeId: state.currentStore.storeInfo._id,
    storeInfo: state.currentStore.storeInfo,
});

const mapDispatchToProps = dispatch => ({
    FetchStoreCategoriesStart: (storeId, sessionId) =>
        dispatch(FetchStoreCategoriesStart(storeId, sessionId)),
    setStoreInfo: storeInfo => dispatch(SetStoreInfo(storeInfo)),
    setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
    setErrors: errors => dispatch(SetErrors(errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCategoryForm);
