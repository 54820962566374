import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Avatar,
  Chip,
  useMediaQuery,
  Card,
  Button
} from '@material-ui/core';
import { Pagination, Skeleton } from '@material-ui/lab';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import {
  FetchStoreInvoicesStart,
  SetStoreInfo
} from '../../../redux/current-store/current-store-actions';
import { SetErrors, SetSnackNotice } from '../../../redux/app/app-actions';
import { connect } from 'react-redux';
import { Link, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';

import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@material-ui/data-grid';
import EditInvoiceForm from '../edit-invoice-form/edit-invoice-form';
import CustomNoRowsOverlay from 'src/components/custom-no-rows/custom-no-rows';
import SendIcon from '@material-ui/icons/Send';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { saveAs } from 'file-saver';
import InvoiceCard from 'src/components/invoice-card/invoice-card';
import { getFormattedDate } from 'src/utils/date-time';
import CachedIcon from '@material-ui/icons/Cached';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  },
  container: {
    backgroundColor: theme.palette.background.default
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: 'white'
  },
  info: {
    backgroundColor: theme.palette.info.main,
    color: 'white'
  }
}));

const InvoiceList = ({
  setSnackNotice,
  setStoreInfo,
  setErrors,
  storeInfo,
  storeId,
  categories
}) => {
  const classes = useStyles();
  const location = useLocation();
  const [invoices, setInvoices] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [searchFilters, setSearchFilters] = useState({});
  const [mode, setMode] = useState('table');
  const [fetchingInvoices, setFetchingInvoices] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [InvoiceToEdit, setInvoiceToEdit] = useState(undefined);
  const [totalInvoices, setTotalInvoices] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (totalInvoices && page >= Math.ceil(totalInvoices / rowsPerPage)) {
      setPage(Math.ceil(totalInvoices / rowsPerPage) - 1);
    }
  }, [totalInvoices]);

  useEffect(() => {
    if (setInvoiceToEdit) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  }, [setInvoiceToEdit]);

  const getInvoiceStatusIcon = status => {
    switch (status) {
      case 'unpaid':
        return <CachedIcon />;
      case 'overdue':
      case 'refunded':
        return <HighlightOffIcon />;
      case 'paid':
        return <CheckCircleOutlineIcon />;
      default:
        return;
    }
  };

  const getInvoiceStatusClass = status => {
    switch (status) {
      case 'unpaid':
        return classes.warning;
      case 'overdue':
      case 'refunded':
        return classes.error;
      case 'paid':
        return classes.success;
      default:
        return;
    }
  };

  useEffect(() => {
    if (storeInfo && storeInfo._id) {
      setColumns([
        {
          field: 'invoiceNumber',
          headerName: 'Invoice #',
          width: 150
        },
        {
          field: 'dueDate',
          headerName: 'Due Date',
          width: 150,
          renderCell: params => {
            return (
              <span>{params.value ? getFormattedDate(params.value) : ''}</span>
            );
          }
        },
        {
          field: 'orderNumber',
          headerName: 'Order #',
          width: 150
        },
        {
          field: 'paymentStatus',
          headerName: 'status',
          width: 150,
          renderCell: params => {
            let status = 'paid';

            if (params.value === 'unpaid') {
              status = 'unpaid';

              if (params.getValue('dueDate') < Date.now()) {
                status = 'overdue';
              }
            }
            return (
              <Chip
                className={getInvoiceStatusClass(status)}
                icon={getInvoiceStatusIcon(status)}
                label={status}
              ></Chip>
            );
          }
        },

        {
          field: '1',
          headerName: 'Resend',
          width: 150,
          renderCell: params => {
            return (
              <Button  size="large"
                color="primary"
                variant="contained"
                startIcon={<SendIcon />}
                onClick={e =>
                  sendInvoice(
                    invoices.find(invoice => invoice._id === params.id)._id
                  )
                }
              >
                Resend
              </Button>
            );
          }
        },
        {
          field: '2',
          headerName: 'View',
          width: 125,
          renderCell: params => {
            return (
              <Button  size="large"
                className={classes.info}
                variant="contained"
                startIcon={<VisibilityIcon />}
                onClick={e =>
                  viewInvoice(
                    invoices.find(invoice => invoice._id === params.id)._id
                  )
                }
              >
                View
              </Button>
            );
          }
        },
        {
          field: '3',
          headerName: 'Download',
          width: 175,
          renderCell: params => {
            return (
              <Button  size="large"
                className={classes.success}
                variant="contained"
                startIcon={<CloudDownloadIcon />}
                onClick={e =>
                  downloadInvoice(
                    invoices.find(invoice => invoice._id === params.id)._id
                  )
                }
              >
                Download
              </Button>
            );
          }
        }
      ]);
    }
  }, [storeInfo, invoices]);

  let { category } = useParams();
  const navigate = useNavigate();

  const handleSearchFilterChange = filter => {
    setSearchFilters({
      ...filter
    });
  };

  const handleChangePage = (data) => {
    setPage(data.page);
  };

  const handleChangeRowsPerPage = data => {
    setRowsPerPage(data.pageSize);
    setPage(0);
  };

  useEffect(() => {
    fetchInvoices();
  }, [searchFilters, keyword, page, rowsPerPage]);

  const fetchInvoices = () => {
    const filter = {};

    if (searchFilters.startDate && searchFilters.endDate) {
      filter.dueDate = {
        $gte: new Date(searchFilters.startDate).getTime(),
        $lte: new Date(searchFilters.endDate).getTime()
      };
    } else if (searchFilters.startDate) {
      filter.dueDate = { $gte: new Date(searchFilters.startDate).getTime() };
    } else if (searchFilters.endDate) {
      filter.dueDate = { $lte: new Date(searchFilters.endDate).getTime() };
    }

    if (searchFilters.paymentStatus) {
      if (searchFilters.paymentStatus === 'overdue') {
        filter.dueDate = { $lt: Date.now() };
      } else {
        filter.paymentStatus = searchFilters.paymentStatus;
      }
    }

    if (keyword) {
      filter.$or = [
        { invoiceNumber: { $regex: keyword } },
        { orderNumber: { $regex: keyword } }
      ];
    }
    setFetchingInvoices(true);
    axios({
      url: 'https://kripson-store-server-8qq76.ondigitalocean.app/invoices/getInvoices',
      method: 'POST',
      onUploadProgress: progressEvent => console.log(progressEvent),
      data: {
        sessionId: storeInfo.sessionId,
        storeId: storeId,
        filter: filter,
        page: page,
        limit: rowsPerPage,
        skip: page * rowsPerPage,
      }
    }).then(response => {
      if (response.data.status === 1) {
        setTotalInvoices(response.data.totals);
        if (response.data.result.length === 0) {
          setPage(0);
        }
        setInvoices([...response.data.result]);
      } else if (response.data.status === 3) {
        setStoreInfo({
          ownerFirstName: '',
          ownerLastName: '',
          storeEmail: '',
          storeName: '',
          storeAddress: '',
          colors: {primary: '#000000'}
        });
        setSnackNotice({
          severity: 'warning',
          message: response.data.message
        });
      } else {
        setErrors(response.data.errors);
      }
      setFetchingInvoices(false);
    });
  };

  const sendInvoice = invoiceId => {
    setSnackNotice({
      severity: 'info',
      message: 'Sending invoice to the customer'
    });
    axios({
      url: 'https://kripson-store-server-8qq76.ondigitalocean.app/invoices/sendInvoice',
      method: 'POST',
      onUploadProgress: progressEvent => console.log(progressEvent),
      data: {
        sessionId: storeInfo.sessionId,
        storeId: storeId,
        invoiceId: invoiceId
      }
    }).then(response => {
      if (response.data.status === 1) {
        setSnackNotice({
          severity: 'success',
          message: response.data.message
        });
      } else if (response.data.status === 3) {
        setStoreInfo({
          ownerFirstName: '',
          ownerLastName: '',
          storeEmail: '',
          storeName: '',
          storeAddress: '',
          colors: {primary: '#000000'}
        });
        setSnackNotice({
          severity: 'warning',
          message: response.data.message
        });
      } else {
        setErrors(response.data.errors);
      }
    });
  };

  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const downloadInvoice = invoiceId => {
    setSnackNotice({
      severity: 'info',
      message: 'Downloading invoice'
    });
    axios({
      url: 'https://kripson-store-server-8qq76.ondigitalocean.app/invoices/downloadInvoice',
      method: 'POST',
      onUploadProgress: progressEvent => console.log(progressEvent),
      data: {
        sessionId: storeInfo.sessionId,
        storeId: storeId,
        invoiceId: invoiceId
      }
    }).then(response => {
      if (response.data.status === 3) {
        setStoreInfo({
          ownerFirstName: '',
          ownerLastName: '',
          storeEmail: '',
          storeName: '',
          storeAddress: '',
          colors: {primary: '#000000'}
        });
        setSnackNotice({
          severity: 'warning',
          message: response.data.message
        });
      } else if (response.data.status === 0) {
        setErrors(response.data.errors);
      } else {
        const blob = b64toBlob(response.data, 'application/pdf');

        saveAs(blob, 'invoice.pdf');

        setSnackNotice({
          severity: 'success',
          message: 'Invoice downloaded'
        });
      }
    });
  };

  const viewInvoice = invoiceId => {
    setSnackNotice({
      severity: 'info',
      message: 'Opening invoice'
    });
    var newWindow = window.open();
    axios({
      url: 'https://kripson-store-server-8qq76.ondigitalocean.app/invoices/downloadInvoice',
      method: 'POST',
      onUploadProgress: progressEvent => console.log(progressEvent),
      data: {
        sessionId: storeInfo.sessionId,
        storeId: storeId,
        invoiceId: invoiceId
      }
    }).then(response => {
      if (response.data.status === 3) {
        setStoreInfo({
          ownerFirstName: '',
          ownerLastName: '',
          storeEmail: '',
          storeName: '',
          storeAddress: '',
          colors: {primary: '#000000'}
        });
        setSnackNotice({
          severity: 'warning',
          message: response.data.message
        });
      } else if (response.data.status === 0) {
        setErrors(response.data.errors);
      } else {
        const blob = b64toBlob(response.data, 'application/pdf');
       
        const blobUrl = URL.createObjectURL(blob);
        
        newWindow.location = blobUrl ;

        newWindow.onload = () => {
          newWindow.document.title = 'invoice';
        };

        setSnackNotice({
          severity: 'success',
          message: 'Invoice opened'
        });
      }
    });
  };

  return editMode && InvoiceToEdit ? (
    ''
  ) : (
    // <EditInvoiceForm
    //   Invoice={InvoiceToEdit}
    //   setInvoiceToEditPick={setInvoiceToEdit}
    // />
    <Page className={classes.root} title="invoice">
      <Container maxWidth={false}>
        <Toolbar
          setKeyword={setKeyword}
          setMode={setMode}
          handleSearchFilterChange={handleSearchFilterChange}
          searchFilters={searchFilters}
        />
        {mode === 'card' || isSmallScreen ? (
          <Box mt={3}>
            <Grid container spacing={3}>
              {!fetchingInvoices &&
                invoices.map(invoice => (
                  <Grid item key={invoice.id} lg={4} md={6} xs={12}>
                    <InvoiceCard
                      viewInvoice={viewInvoice}
                      resendInvoice={sendInvoice}
                      downloadInvoice={downloadInvoice}
                      invoice={invoice}
                    />
                  </Grid>
                ))}
              {fetchingInvoices &&
                [1, 2, 3, 4].map(num => (
                  <Grid item key={num} lg={4} md={6} xs={12}>
                    <Skeleton
                      component="div"
                      height={350}
                      style={{ transform: 'unset' }}
                    />
                  </Grid>
                ))}
            </Grid>

            <Box mt={3} display="flex" justifyContent="center">
              <Pagination
                color="primary"
                count={Math.ceil(totalInvoices / rowsPerPage) || 1}
                size="small"
                onChange={(e, epage) => {
                  setPage(epage - 1);
                }}
                variant="outlined"
                shape="rounded"
              />
            </Box>
          </Box>
        ) : (
          <Box mt={2}>
            <DataGrid
              components={{
                NoRowsOverlay: CustomNoRowsOverlay
              }}
              className={classes.container}
              autoHeight
              rows={invoices.map(invoice => ({
                ...invoice,
                id: invoice._id
              }))}
              paginationMode="server"
              rowCount={totalInvoices}
              columns={columns}
              page={page}
              pageSize={rowsPerPage}
              rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 35, 40 , 45 , 50, 100]}
              onPageChange={handleChangePage}
              onPageSizeChange={handleChangeRowsPerPage}
            ></DataGrid>
          </Box>
        )}
      </Container>
    </Page>
  );
};




const mapStateToProps = state => ({
  storeInfo: state.currentStore.storeInfo,
  storeId: state.currentStore.storeInfo._id,
  categories: state.currentStore.categories
});

const mapDispatchToProps = dispatch => ({
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setStoreInfo: store => dispatch(SetStoreInfo(store)),
  setErrors: errors => dispatch(SetErrors(errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList);
