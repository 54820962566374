export const SetStoreInfo = (storeInfo) => ({
  type: 'SET_STORE_INFO',
  payload: storeInfo
})

export const UpdateStoreInfo = (storeInfo) => ({
  type: 'UPDATE_STORE_INFO',
  payload: storeInfo
})

export const FetchStoreCategoriesStart = (storeId, sessionId)=>({
  type: 'FETCH_STORE_CATEGORIES_START',
  payload: {storeId: storeId, sessionId: sessionId}
})

export const FetchStoreCategoriesSuccess = (categories)=>({
  type: 'FETCH_STORE_CATEGORIES_SUCCESS',
  payload: categories
})

export const FetchStoreCategoriesError = (e)=>({
  type: 'FETCH_STORE_CATEGORIES_ERROR',
  payload: e
})


export const FetchStoreProductsStart = (requiredInfo, sessionId)=>({
  type: 'FETCH_STORE_PRODUCTS_START',
  payload: {requiredInfo: requiredInfo, sessionId:sessionId}
})

export const FetchStoreProductsSuccess = (products)=>({
  type: 'FETCH_STORE_PRODUCTS_SUCCESS',
  payload: products
})

export const FetchStoreProductsError = (e)=>({
  type: 'FETCH_STORE_PRODUCTS_ERROR',
  payload: e
})


export const FetchStoreOrdersStart = (body, sessionId)=>({
  type: 'FETCH_STORE_ORDERS_START',
  payload: {body: body, sessionId: sessionId}
})

export const FetchStoreOrdersSuccess = (orders)=>({
  type: 'FETCH_STORE_ORDERS_SUCCESS',
  payload: orders
})

export const FetchStoreOrdersError = (e)=>({
  type: 'FETCH_STORE_ORDERS_ERROR',
  payload: e
})


export const FetchStoreNotificationsStart = (requiredInfo, sessionId)=>({
  type: 'FETCH_STORE_NOTIFICATIONS_START',
  payload: {requiredInfo: requiredInfo, sessionId: sessionId}
})

export const FetchStoreNotificationsSuccess = (products)=>({
  type: 'FETCH_STORE_NOTIFICATIONS_SUCCESS',
  payload: products
})

export const FetchStoreNotificationsError = (e)=>({
  type: 'FETCH_STORE_NOTIFICATIONS_ERROR',
  payload: e
})


export const FetchStoreMembersStart = (requiredInfo, sessionId)=>({
  type: 'FETCH_STORE_MEMBERS_START',
  payload: {requiredInfo: requiredInfo, sessionId: sessionId}
})

export const FetchStoreMembersSuccess = (products)=>({
  type: 'FETCH_STORE_MEMBERS_SUCCESS',
  payload: products
})

export const FetchStoreMembersError = (e)=>({
  type: 'FETCH_STORE_MEMBERS_ERROR',
  payload: e
})


