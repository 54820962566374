import {takeLatest,call,put, select } from 'redux-saga/effects';
import axios from 'axios';
import {
    ApplyDiscountCodes,
    SetStoreCart,
} from "./cart.actions";
import { ClearCart } from '../cart/cart.actions';
import { SetSnackNotice } from '../app/app-actions';
import {applyApplicableDiscounts} from '../../utils/discount-application';
import {
    SetStoreInfo
  } from "../current-store/current-store-actions";
import {selectCart} from './cart.selectors';




export function* applyDiscountCodesStart()
{
    yield takeLatest('APPLY_DISCOUNT_CODES', applyDiscountCodesStartAsync);
    yield takeLatest('ADD_ITEM', applyDiscountCodesStartAsyncWithSelector);
    yield takeLatest('REMOVE_ITEM', applyDiscountCodesStartAsyncWithSelector);
    yield takeLatest('DECREASE_QUANTITY', applyDiscountCodesStartAsyncWithSelector);
}

export function* applyDiscountCodesStartAsyncWithSelector(action)
{
    try
    {
        const entireCart = yield select(selectCart);
        const storeCart = entireCart.stores[action.payload.storeId];
        const {cartItems, discountCodesApplied} = storeCart;
        const newStoreCartItems = yield applyApplicableDiscounts(discountCodesApplied ? discountCodesApplied : [], action.payload.storeId, cartItems, {});

        yield put(SetStoreCart(newStoreCartItems, action.payload.storeId));
    }
    catch(e)
    {
        if(e.code === 'CUSTOM_3')
        {
          yield put(SetStoreInfo({}));
          yield put(SetSnackNotice({
            severity: 'warning',
            message: e.message
          }));
        }

        console.log(e);


        yield put(SetStoreCart([], action.payload.storeId ));
    }
}

export function* applyDiscountCodesStartAsync(action)
{

    try
    {
        const newStoreCartItems = yield applyApplicableDiscounts(action.payload.codes, action.payload.storeId, action.payload.cartItems, action.payload.customer);

        yield put(SetStoreCart(newStoreCartItems, action.payload.storeId));
    }
    catch(e)
    {
        if(e.code === 'CUSTOM_3')
        {
          yield put(SetStoreInfo({}));
          yield put(SetSnackNotice({
            severity: 'warning',
            message: e.message
          }));
        }

        yield put(SetStoreCart(action.payload.cartItems, action.payload.storeId));
    }
}








