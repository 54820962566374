import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import { FetchStoreMembersStart, SetStoreInfo } from 'src/redux/current-store/current-store-actions';
import { connect } from 'react-redux';
import { SetErrors, SetSnackNotice } from 'src/redux/app/app-actions';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.green[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.green[900]
  },
  differenceValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  }
}));

const TotalCustomers = ({ className, storeInfo, members, setStoreInfo, setSnackNotice, setErrors, fetchStoreMembers, ...rest }) => {
  const classes = useStyles();

  useEffect(() => {
    if(storeInfo._id){
      fetchStoreMembers(storeInfo._id, storeInfo.sessionId);
    }
  }, [storeInfo]);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              TOTAL MEMBERS
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {members.length}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <PeopleIcon />
            </Avatar>
          </Grid>
        </Grid>
        {/* <Box
          mt={2}
          display="flex"
          alignItems="center"
        >
          <ArrowUpwardIcon className={classes.differenceIcon} />
          <Typography
            className={classes.differenceValue}
            variant="body2"
          >
            16%
          </Typography>
          <Typography
            color="textSecondary"
            variant="caption"
          >
            Since last month
          </Typography>
        </Box> */}
      </CardContent>
    </Card>
  );
};

TotalCustomers.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => ({
  storeInfo: state.currentStore.storeInfo,
  members: state.currentStore.members,
});

const mapDispatchToProps = dispatch => ({
  setStoreInfo: store => dispatch(SetStoreInfo(store)),
  setErrors: error => dispatch(SetErrors(error)),
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  fetchStoreMembers: (storeId, sessionId) => dispatch(FetchStoreMembersStart(storeId, sessionId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TotalCustomers);