import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { FetchStoreCategoriesStart, SetStoreInfo } from 'src/redux/current-store/current-store-actions';
import { SetErrors, SetSnackNotice } from 'src/redux/app/app-actions';
import {connect} from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = ({storeInfo}) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(()=>{
    if(!storeInfo.sessionId) {
      navigate('/login');
    }
  },[storeInfo])

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state =>
  ({
    storeInfo: state.currentStore.storeInfo
  });

const mapDispatchToProps = dispatch => (
  {
    setStoreInfo: (storeInfo) => dispatch(SetStoreInfo(storeInfo)),
      FetchStoreCategoriesStart: (storeId, sessionId) => dispatch(FetchStoreCategoriesStart(storeId, sessionId)),
    setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
    setErrors: (errors) => dispatch(SetErrors(errors))
  });

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
