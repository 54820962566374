import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    makeStyles,
    TextField,
    Card,
    MenuItem,
    CardHeader,
    Divider,
    Typography,
    Chip,
    Button,
    fade,
    colors
} from '@material-ui/core';
import Page from 'src/components/Page';
import Notifications from '../Notifications';
import Password from '../Password';
import { connect } from 'react-redux';
import axios from 'axios';
import { SetStoreInfo } from '../../../../redux/current-store/current-store-actions';
import { SetErrors, SetSnackNotice } from '../../../../redux/app/app-actions';
import * as _ from 'lodash';
import { validateData } from 'src/utils/validation';
import { Facebook, Instagram } from '@material-ui/icons';
import YouTubeIcon from '@material-ui/icons/YouTube';
import Apps from './integration-apps';
import Grow from '@material-ui/core/Grow';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    currencyContainer: {
        padding: theme.spacing(2)
    },
    currency: {
        marginLeft: theme.spacing(1)
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText
    },
    paymentMethodBox: {
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    paymentMethodLogo: {
        height: '100px',
        width: 'auto',
    },
    errorText: {
        color: theme.palette.error.main,
    },
    socialLinks: {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(1, 0),
        '& svg': {
            marginRight: theme.spacing(2)
        }
    },
    integrationOption: {
        padding: theme.spacing(2),
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'center',
        gridRowGap: theme.spacing(1),
        width: 'fit-content',
        flexWrap: 'wrap',

        '&:hover': {
            cursor: 'pointer',
            backgroundColor: fade(colors.common['black'], 0.1)
        },
        '& img': {
            width: '120px',
            height: 'auto',

        }
    }
}));

const IntregationSelection = ({
    storeInfo,
    setStoreInfo,
    setErrors,
    setSnackNotice,
    setSelectedIntegration
}) => {
    const classes = useStyles();

    return (

        <Box mb={3} >
            {Apps.map((integration, idx) => {
                return <Grow
                    in={true}
                    style={{ transformOrigin: '0 0 0' }}
                    {...(true ? { timeout: 1000 } : {})}
                >
                    <Card className={classes.integrationOption} onClick={() => setSelectedIntegration(integration.name)}>
                        <img key={idx} src={`/static/images/${integration.logo}`} alt={integration.name} />
                        <Typography variant='h6' color="textSecondary">
                            {integration.name}
                        </Typography>
                    </Card>
                </Grow>

            })}
        </Box>
    );
};

const mapStateToProps = state => ({
    storeInfo: state.currentStore.storeInfo
});

const mapDispatchToProps = dispatch => ({
    setStoreInfo: storeInfo => dispatch(SetStoreInfo(storeInfo)),
    setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
    setErrors: errors => dispatch(SetErrors(errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(IntregationSelection);
