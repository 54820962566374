import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {
    Avatar,
    Box,
    Button,
    Input,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    makeStyles,
    Container,
    CardHeader,
    Fab,
    Chip,
    useMediaQuery,
} from '@material-ui/core';
import { connect } from 'react-redux';
import imageCompression from "browser-image-compression";
import axios from 'axios';
import { DropzoneArea } from "material-ui-dropzone";
import Page from 'src/components/Page';
import { ColorPicker } from 'material-ui-color';
import { SetStoreInfo, UpdateStoreInfo } from 'src/redux/current-store/current-store-actions';
import { SetErrors, SetSnackNotice } from 'src/redux/app/app-actions';
import VisibilityIcon from '@material-ui/icons/Visibility';
import palette from 'image-palette';
import pixels from 'image-pixels';
import { Skeleton } from '@material-ui/lab';
import rgbHex from 'rgb-hex';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import contrast from 'contrast';


const ThemeDesigner = ({ className, updateStoreInfo, storeInfo, setStoreInfo, setSnackNotice, setErrors, ...rest }) => {

    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
    const [newStoreInfo, setNewStoreInfo] = useState(storeInfo);
    const [copiedIndex, setCopiedIndex] = useState(null);
    const [colorPaletteImage, setColorPaletteImage] = useState('');
    const [colorExtracts, setColorExtracts] = useState([]);
    const [isExtractingColors, setIsExtractingColors] = useState(false);


    const useStyles = makeStyles((theme) => ({
        root: {
            paddingBottom: theme.spacing(3),
            paddingTop: theme.spacing(3)
        },
        avatar: {
            height: 100,
            width: 100
        },
        colourTitles: {
            marginRight: theme.spacing(1),
            width: isSmallScreen ? '100%' : 'unset'
        },
        colourBox: {
            display: 'grid',
            gridRowGap: theme.spacing(1),
            '& .ColorPicker-MuiTextField-root': {
                maxWidth: isSmallScreen ? '145px' : 'unset'
            }
        },
        previewButton: {
            marginLeft: theme.spacing(1),
        },
        colorChip: {
            display: isSmallScreen ? 'none' : 'inherit'
        },
        visibilityIcon: {
            marginRight: theme.spacing(1),
        },
        colorCodeHex: {
            position: 'absolute',
            left: theme.spacing(1),
            top: theme.spacing(1),
        },
        colorExtractBox: {
            height: isSmallScreen ? '50px' : '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative'
        },
    }));

    const classes = useStyles();

    useEffect(() => {
        if (copiedIndex !== null) {
            const timer = setTimeout(() => setCopiedIndex(null), 2000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [copiedIndex])

    useEffect(() => {
        (async () => {
            if (colorPaletteImage) {
                setIsExtractingColors(true);
                let { ids, colors } = palette(await pixels(colorPaletteImage.base64data));
                setColorExtracts(colors);
                setIsExtractingColors(false);
            }
        })()

    }, [colorPaletteImage])

    const handleColorChange = (data, name) => {
        if (typeof data === 'string' && data.startsWith('#')) {
            setNewStoreInfo({
                ...newStoreInfo,
                colors: {
                    ...newStoreInfo.colors,
                    [name]: data
                }
            })
        }
        else {
            setNewStoreInfo({
                ...newStoreInfo,
                colors: {
                    ...newStoreInfo.colors,
                    [name]: `#${data.hex}`
                }
            })
        }

    }

    // const updateStoreInfo = () => {
    //     axios({
    //         url: 'https://kripson-store-server-8qq76.ondigitalocean.app/stores/updateStoreInfo',
    //         method: 'POST',
    //         data: {
    //             ...storeInfo,
    //             ...newStoreInfo,
    //         }

    //     }).then((response) => {
    //         if (response.data.status === 1) {
    //             console.log(storeInfo);
    //             setStoreInfo({ ...storeInfo, ...response.data.result });
    //         }
    //         else if (response.data.status === 3) {
    //             setStoreInfo({
    //                 ownerFirstName: '',
    //                 ownerLastName: '',
    //                 storeEmail: '',
    //                 storeName: '',
    //                 storeAddress: '',
    //                 colors: { primary: '#000000' }
    //             });
    //             setSnackNotice({
    //                 severity: 'warning',
    //                 message: response.data.message
    //             });
    //         }
    //         else {
    //             setSnackNotice({
    //                 notice: 'Could not update the store info',
    //                 severity: 'error'
    //             })
    //         }

    //     })
    // }

    const imageConversion = async (files) => {
        const compressedFileArray = [];
        for (let file of files) {

            const options = {
                maxSizeMB: 0.5,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            };
            try {
                const compressedFile = await imageCompression(file, options);
                compressedFileArray.push(compressedFile);

            } catch (error) {
                console.log(error);
            }
        }


        const base64dataarray = [];
        let counter = base64dataarray.length;

        compressedFileArray.forEach((file, idx) => {
            let reader = new FileReader();
            reader.onloadend = function () {
                base64dataarray[counter].base64data = reader.result;
                if (idx === files.length - 1) {
                    setColorPaletteImage(base64dataarray[0])
                }

                counter++;


            };
            reader.readAsDataURL(file);
            base64dataarray.push({ type: file.type });
        })
    };

    return (
        <Page className={classes.root} title="Theme Designer">
            <Container maxWidth="lg">
                <Box mb={3}>
                    <Typography color="textPrimary" variant="h2">
                        Theme Designer
                    </Typography>
                    <Typography color="textSecondary" gutterBottom variant="body2">
                        Design your store's theme
                    </Typography>
                </Box>
                <Card>
                    <CardHeader title="Color Palette" subheader="Configure your store color palette">

                    </CardHeader>
                    <Divider />
                    <CardContent className={classes.colourBox}>
                        <Box display="flex" alignItems="center" flexWrap="wrap">
                            <Typography color="textPrimary" variant='h6' className={classes.colourTitles}>
                                Primary color (Brand color):
                            </Typography>
                            <Chip className={classes.colorChip} label={`${storeInfo.colors.primary}`} avatar={<Avatar alt="P" src="/static/images/avatar/1.jpg" style={{ backgroundColor: storeInfo.colors.primary }} />}>
                            </Chip>

                            <ColorPicker value={`${newStoreInfo.colors.primary}` || 'transparent'} onChange={(data) => handleColorChange(data, 'primary')} />
                            <Button  size="large" variant="contained" className={classes.previewButton}> {isSmallScreen ? <VisibilityIcon /> : <><VisibilityIcon className={classes.visibilityIcon} /> Preview</>} </Button>
                        </Box>
                        <Box display="flex" alignItems="center" flexWrap="wrap">
                            <Typography color="textPrimary" variant='h6' className={classes.colourTitles}>
                                Background color:
                            </Typography>
                            <Chip className={classes.colorChip} label={`${storeInfo.colors.background || 'none'}`} avatar={<Avatar alt="B" src="/static/images/avatar/1.jpg" style={{ backgroundColor: storeInfo.colors.background || 'none' }} />}>
                            </Chip>

                            <ColorPicker value={`${newStoreInfo.colors.background}` || 'transparent'} onChange={(data) => handleColorChange(data, 'background')} />
                            <Button  size="large" variant="contained" className={classes.previewButton}> {isSmallScreen ? <VisibilityIcon /> : <><VisibilityIcon className={classes.visibilityIcon} /> Preview</>} </Button>
                        </Box>
                        <Box display="flex" alignItems="center" flexWrap="wrap">
                            <Typography color="textPrimary" variant='h6' className={classes.colourTitles}>
                                Primary text color:
                            </Typography>
                            <Chip className={classes.colorChip} label={`${storeInfo.colors.title || 'none'}`} avatar={<Avatar alt="T" src="/static/images/avatar/1.jpg" style={{ backgroundColor: storeInfo.colors.title || 'none' }} />}>
                            </Chip>

                            <ColorPicker value={`${newStoreInfo.colors.title}` || 'transparent'} onChange={(data) => handleColorChange(data, 'title')} />
                            <Button  size="large" variant="contained" className={classes.previewButton}> {isSmallScreen ? <VisibilityIcon /> : <><VisibilityIcon className={classes.visibilityIcon} /> Preview</>} </Button>
                        </Box>
                    </CardContent>
                    <Divider />

                </Card>

                <Card>
                    <CardHeader title="Extract colors" subheader="Upload a photo to extract colour palette from">

                    </CardHeader>
                    <CardActions>

                        <DropzoneArea
                            dropzoneText="Upload an image"
                            maxFileSize={20000000}
                            filesLimit={1}
                            onChange={(files) => imageConversion(files)}
                            name="colorExtractSource"
                        />
                    </CardActions>
                    {isExtractingColors ? <Skeleton variant="rect" height={100} /> : <Box display="flex" alignItems="center" flexWrap="wrap" justifyContent="center">
                        {colorExtracts.map((color, idx) => {
                            return <Box className={classes.colorExtractBox} style={{ backgroundColor: `rgba(${color[0]},${color[1]} ,${color[2]} ,${color[3]})`, width: `${100 / colorExtracts.length}%` }}>
                                {isSmallScreen ? '' : <span style={{ color: contrast(rgbHex(`rgb(${color[0]},${color[1]} ,${color[2]})`)) === 'light' ? '#000' : '#fff' }} className={classes.colorCodeHex}>{rgbHex(`rgb(${color[0]},${color[1]} ,${color[2]})`).toString().toUpperCase()}</span>
                                }

                                {copiedIndex === idx ? <span style={{ color: contrast(rgbHex(`rgb(${color[0]},${color[1]} ,${color[2]})`)) === 'light' ? '#000' : '#fff' }}> Copied!</span> :
                                    <Button  size="large" style={{ color: contrast(rgbHex(`rgb(${color[0]},${color[1]} ,${color[2]})`)) === 'light' ? '#000' : '#fff' }} endIcon={<FileCopyIcon />} onClick={() => {
                                        setCopiedIndex(idx);
                                        navigator.clipboard.writeText(rgbHex(`rgb(${color[0]},${color[1]} ,${color[2]})`).toString().toUpperCase());
                                    }}
                                        className={classes.copyButton}>
                                        {isSmallScreen ? '' : <span>Copy</span>}
                                    </Button>}
                            </Box>
                        })}
                    </Box>}

                </Card>

                <Box
                    display="flex"
                    justifyContent="flex-end"
                    p={isSmallScreen ? 0 : 2}
                    my={2}
                >
                    <Button  size="large"
                        color="primary"
                        variant="contained"
                        onClick={()=>updateStoreInfo({...storeInfo, ...newStoreInfo})}
                        style={{flexGrow: isSmallScreen ? 1 : 'unset'}}
                    >
                        Update theme
                    </Button>
                </Box>
            </Container>
        </Page>
    );
};

ThemeDesigner.propTypes = {
    className: PropTypes.string,
    setNewStoreBanner: PropTypes.func.isRequired,
};

const mapStateToProps = state =>
({
    storeInfo: state.currentStore.storeInfo
});

const mapDispatchToProps = dispatch => (
    {
        setStoreInfo: (storeInfo) => dispatch(SetStoreInfo(storeInfo)),
        setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
        setErrors: (error) => dispatch(SetErrors(error)),
        updateStoreInfo: (storeInfo) => dispatch(UpdateStoreInfo(storeInfo))
    });

export default connect(mapStateToProps, mapDispatchToProps)(ThemeDesigner);
