import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Card,
  Chip,
  Container,
  Divider,
  Grid,
  TextField,
  MenuItem,
  makeStyles,
  Hidden
} from '@material-ui/core';

import Page from 'src/components/Page';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import AwesomeSliderStyles from 'react-awesome-slider/src/styled/fold-out-animation/fold-out-animation.scss';
import '../../../customStyles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import {
  FetchStoreOrdersStart,
  SetStoreInfo
} from '../../../redux/current-store/current-store-actions';
import { SetErrors, SetSnackNotice } from '../../../redux/app/app-actions';
import { connect } from 'react-redux';
import axios from 'axios';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import * as Swal from 'sweetalert2';
// import EditOrderForm from "../order-designer-view/edit-order-form";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import CachedIcon from '@material-ui/icons/Cached';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import momentTimeZone from 'moment-timezone';
import { validateData } from 'src/utils/validation';
import OrderProductCard from 'src/components/order-product-card/order-product-card';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    padding: theme.spacing(3),
    width: '100%'
  },
  orderDetailsSection: {
    padding: '20px !important',
    display: 'grid',
    alignContent: 'start',
    gridRowGap: '20px'
  },
  orderColor: {
    height: '25px',
    width: '25px',
    borderRadius: '25px'
  },
  sizeOptionContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 50px)',
    gridColumnGap: '10px'
  },
  sizeChoice: {
    backgroundColor: 'white',
    display: 'grid',
    justifyContent: 'center'
  },
  statusSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexWrap: 'wrap'
  },
  detailsSectionRows: {},
  container: {
    maxHeight: 440
  },
  customerDetailsSection: {
    marginBottom: '16px'
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    marginLeft: theme.spacing(1)
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    marginLeft: theme.spacing(1)
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: 'white',
    marginLeft: theme.spacing(1)
  },
  buttonContainer: {
    marginTop: theme.spacing(2)
  }
}));

const EditOrderForm = ({
  storeId,
  storeInfo,
  order,
  setMode,
  productImages,
  setSnackNotice,
  setErrors,
  setStoreInfo
}) => {
  const classes = useStyles();

  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [updatedOrder, setUpdatedOrder] = React.useState({});

  const [validationErrors, setValidationErrors] = React.useState({});

  useEffect(() => {
    console.log(order);
    setUpdatedOrder({ ...order });
  }, [order]);

  useEffect(() => {
    if (Object.keys(updatedOrder).length) {
      console.log(updatedOrder);
      (async () => {
        const validationResponse = await validateData(
          'updateOrder',
          updatedOrder
        );

        const errorMap = {};
        if (validationResponse.errorMap) {
          validationResponse.errorMap.forEach(error => {
            errorMap[error.path] = error.message;
          });
        }
        setValidationErrors({ ...errorMap });
      })();
    }
  }, [updatedOrder]);

  const handleCustomerDetailChange = event => {
    setUpdatedOrder({
      ...updatedOrder,
      customer: {
        ...updatedOrder.customer,
        [event.target.name]: event.target.value
      }
    });
  };

  const handleOrderDetailChange = async event => {
    setUpdatedOrder({
      ...updatedOrder,
      [event.target.name]: event.target.value
    });
  };

  const editOrder = async () => {
    const validationResponse = await validateData('updateOrder', updatedOrder);

    if (!validationResponse.hasOwnProperty('errorMap')) {
      setSnackNotice({
        message: 'Order editing in process',
        severity: 'info'
      });

      try {
        const response = await axios({
          url:
            'https://kripson-store-server-8qq76.ondigitalocean.app/orders/updateOrder',
          method: 'POST',
          onUploadProgress: progressEvent => console.log(progressEvent),
          data: {
            sessionId: storeInfo.sessionId,
            updatedOrder,
            storeId: storeId,
            orderId: order._id
          }
        });

        if (response.data.status === 1) {
          setSnackNotice({
            message: 'Order Updated',
            severity: 'success'
          });

          setMode('detailMode');

          setUpdatedOrder({});
        } else if (response.data.status === 3) {
          setStoreInfo({
            ownerFirstName: '',
            ownerLastName: '',
            storeEmail: '',
            storeName: '',
            storeAddress: '',
            colors: { primary: '#000000'}
          });
          setSnackNotice({
            severity: 'warning',
            message: response.data.message
          });
        }

        if (response.status === 2) {
          setErrors(response.data.errors);
          setSnackNotice({
            message: 'Product update failed',
            severity: 'error'
          });
        }
      } catch (e) {
        setSnackNotice({
          message: 'Product update failed',
          severity: 'error'
        });
      }
    } else {
      setErrors(setErrors(validationResponse.errors));
    }
  };

  // const deleteOrder = async (orderId) => {
  //     setSnackNotice({
  //         message: `Deleting order`,
  //         severity: 'info'
  //     });

  //     const response = await axios({
  //         url: 'https://kripson-store-server-8qq76.ondigitalocean.app/orders/removeOrder',
  //         method: 'POST',
  //         onUploadProgress: progressEvent => console.log(progressEvent),
  //         data: {
  //             orderId,
  //             storeId
  //         },
  //     });

  //     if (response.data.status === 1) {
  //         navigate(`/app/orders`);

  //         setSnackNotice({
  //             message: `Order deleted`,
  //             severity: 'success'
  //         });
  //     }

  //     if (response.data.status === 2) {
  //         setErrors(response.data.errors);
  //     }
  // }

  // const fireDeleteConfirmation = (orderId) => {
  //     Swal.fire({
  //         title: 'Are you sure?',
  //         text: "You won't be able to revert this!",
  //         icon: 'warning',
  //         showCancelButton: true,
  //         confirmButtonColor: '#3085d6',
  //         cancelButtonColor: '#d33',
  //         confirmButtonText: 'Yes, delete it!'
  //     }).then((result) => {
  //         if (result.isConfirmed) {

  //             deleteOrder(orderId);

  //             // await Swal.fire(
  //             //   'Deleted!',
  //             //   'Your file has been deleted.',
  //             //   'success'
  //             // )
  //         }
  //     })
  // }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = [
    { id: 'productImage', label: 'Product', minWidth: 100 },
    { id: 'title', label: 'Title', minWidth: 100 },
    {
      id: 'quantity',
      label: 'Quantity',
      minWidth: 50,
      align: 'center',
      format: value => value.toLocaleString('en-IN')
    },
    {
      id: 'selectedSize',
      label: 'Size',
      minWidth: 50,
      align: 'center',
      format: value => value.toFixed(2)
    },
    {
      id: 'price',
      label: 'Price',
      minWidth: 100,
      align: 'left',
      format: value => value.toLocaleString('en-IN')
    },
    {
      id: 'removeButton',
      label: '',
      minWidth: 25,
      align: 'left',
      format: value => value.toFixed(2)
    }
  ];
  {
    /* <EditOrderForm order={order} setMode={setMode}/> */
  }

  return (
    <Page className={classes.root} title="Order Details">
      <Box mb={2} className={classes.statusSection}>

        <Box display="flex" alignItems="center">
          <Typography variant="h5">Order Status</Typography>

          <TextField
            select
            label="Select"
            value={updatedOrder.status ? updatedOrder.status : order.status}
            onChange={handleOrderDetailChange}
            helperText={'Please select the status of the order'}
            variant="outlined"
            name="status"
            fullWidth
            error={Boolean(validationErrors.status)}
            margin="normal"
          >
            <MenuItem value={'processing'}>
              <Chip
                className={classes.warning}
                icon={<CachedIcon />}
                label={'processing'}
              />
            </MenuItem>
            <MenuItem value={'delivered'}>
              <Chip
                className={classes.success}
                icon={<CheckCircleOutlineIcon />}
                label={'delivered'}
              />
            </MenuItem>
            <MenuItem value={'cancelled'}>
              <Chip
                className={classes.error}
                icon={<HighlightOffIcon />}
                label={'cancelled'}
              />
            </MenuItem>
          </TextField>
        </Box>
      </Box>

      <Paper className={classes.customerDetailsSection}>
        <Box p={2}>
          <Typography fullWidth variant="h3" mb={2}>
            Customer Details
          </Typography>
        </Box>

        <Divider />
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Typography variant="h5">Name</Typography>
              <TextField
                error={Boolean(validationErrors['customer.name'])}
                fullWidth
                label="Customer Name"
                margin="normal"
                name="name"
                onChange={handleCustomerDetailChange}
                type="text"
                value={
                  updatedOrder && Object.keys(updatedOrder).length
                    ? updatedOrder.customer.name
                    : ''
                }
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h5">Contact Details</Typography>
              <TextField
                error={Boolean(validationErrors['customer.phonenumber'])}
                fullWidth
                label="Customer phonenumber"
                margin="normal"
                name="phonenumber"
                onChange={handleCustomerDetailChange}
                type="text"
                value={
                  updatedOrder && Object.keys(updatedOrder).length
                    ? updatedOrder.customer.phonenumber
                    : ''
                }
                variant="outlined"
              />
              <TextField
                error={Boolean(validationErrors['customer.email'])}
                fullWidth
                label="Customer email"
                margin="normal"
                name="email"
                onChange={handleCustomerDetailChange}
                type="text"
                value={
                  updatedOrder && Object.keys(updatedOrder).length
                    ? updatedOrder.customer.email
                    : ''
                }
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h5">Shipping Address</Typography>
              <TextField
                error={Boolean(validationErrors['customer.address'])}
                fullWidth
                label="Customer address"
                margin="normal"
                name="address"
                onChange={handleCustomerDetailChange}
                type="text"
                value={
                  updatedOrder && Object.keys(updatedOrder).length
                    ? updatedOrder.customer.address
                    : ''
                }
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </Box>
      </Paper>
      <Box display={{ xs: 'none', md: 'block' }}>
        <Paper>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {order && order.products
                  ? order.products
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((product, idx) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={product._id}
                          >
                            {columns.map(column => {
                              const value =
                                column.id === 'productImage' ? (
                                  Object.keys(productImages).length ? (
                                    <Avatar src={productImages[product._id]} />
                                  ) : (
                                    ''
                                  )
                                ) : (
                                  product[column.id]
                                );
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === 'number'
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })
                  : ''}
                <TableRow>
                  <TableCell>
                    <Typography variant="h4">Order Total</Typography>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <Typography variant="h4">
                      {storeInfo.storeCurrencySymbol} {order ? order.total : ''}
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={order && order.products ? order.products.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <Box display={{ xs: 'block', md: 'none' }} mt={5}>
        <Typography variant="h3" style={{ marginBottom: '16px' }}>
          Ordered Products
        </Typography>
        {order && order.products
          ? order.products.map((product, idx) => (
              <OrderProductCard
                product={product}
                productImage={
                  product && productImages ? productImages[product._id] : ''
                }
              />
            ))
          : ''}
      </Box>
      <Hidden xsDown>
        <Box my={2} display="flex" justifyContent="flex-end">
          <Button  size="large"
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            onClick={editOrder}
          >
            Update
          </Button>
          <Button  size="large"
            variant="contained"
            className={classes.error}
            onClick={() => setMode('detailMode')}
          >
            Cancel
          </Button>
        </Box>
      </Hidden>

      <Hidden smUp>
        <Box my={2} display="block">
          <Button  size="large"
            fullWidth
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            onClick={editOrder}
            style={{ marginBottom: '8px' }}
          >
            Update
          </Button>
          <Button  size="large"
            fullWidth
            variant="contained"
            startIcon={<HighlightOffIcon />}
            className={classes.error}
            onClick={() => setMode('detailMode')}
            style={{ marginLeft: '0' }}
          >
            Cancel
          </Button>
        </Box>
      </Hidden>
    </Page>
  );
};

const mapStateToProps = state => ({
  storeId: state.currentStore.storeInfo._id,
  storeInfo: state.currentStore.storeInfo
});

const mapDispatchToProps = dispatch => ({
  FetchStoreOrdersStart: requiredInfo =>
    dispatch(FetchStoreOrdersStart(requiredInfo)),
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setErrors: validationErrors => dispatch(SetErrors(validationErrors)),
  setStoreInfo: store => dispatch(SetStoreInfo(store))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditOrderForm);
