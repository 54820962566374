import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio
} from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import {
  FetchStoreProductsStart,
  SetStoreInfo
} from '../../../redux/current-store/current-store-actions';
import { SetErrors, SetSnackNotice } from '../../../redux/app/app-actions';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import FilterListIcon from '@material-ui/icons/FilterList';

const useStyles = makeStyles(theme => ({
  dialogBox: {
    '& .MuiDialog-paper': {
      display: 'grid',
      padding: theme.spacing(2),
      gridRowGap: theme.spacing(1),
      backgroundColor: theme.palette.background.dark
    }
  },
  priceBox: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '10px'
  },
  buttonGroup: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '10px',
    marginTop: '5px'
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: 'white'
  }
}));

function InvoiceFilter({
  storeId,
  storeInfo,
  FetchStoreProductsStart,
  handleSearchFilterChange,
  searchFilters,
  setSnackInfo,
  setErrors,
  setStoreInfo,
  ...props
}) {
  const classes = useStyles();

  const [filters, setFilters] = useState({});

  const handleFilterChange = e => {
    if (e.target.name === 'status') {
      setFilters({
        ...filters,
        [e.target.name]: e.target.checked ? 'available now' : null
      });
    } else {
      setFilters({
        ...filters,
        [e.target.name]: e.target.value
      });
    }
  };

  const applyFilters = () => {
    handleSearchFilterChange(filters);
  };

  const clearFilters = () => {
    handleSearchFilterChange({});
  };

  useEffect(() => {
    setFilters(searchFilters);
  }, [searchFilters]);

  return (
    <>
      <FormLabel component="legend">Due Date</FormLabel>
      <Box className={classes.priceBox} mb={1}>
        <TextField
          className={classes.filterTextField}
          name="startDate"
          type="date"
          onChange={e => handleFilterChange(e)}
          placeholder={`Start Date`}
          variant="outlined"
          value={filters.startDate ? filters.startDate : null}
        />
        <TextField
          className={classes.filterTextField}
          name="endDate"
          type="date"
          onChange={e => handleFilterChange(e)}
          placeholder={`End Date`}
          variant="outlined"
          value={filters.endDate ? filters.endDate : null}
        />
      </Box>
      <FormControl component="fieldset">
        <FormLabel component="legend">Status</FormLabel>
        <RadioGroup
          aria-label="paymentStatus"
          name="paymentStatus"
          value={filters.paymentStatus ? filters.paymentStatus : null}
          onChange={handleFilterChange}
        >
          <FormControlLabel value="paid" control={<Radio />} label="Paid" />
          <FormControlLabel value="unpaid" control={<Radio />} label="Unpaid" />
          <FormControlLabel
            value="overdue"
            control={<Radio />}
            label="Overdue"
          />
        </RadioGroup>
      </FormControl>
      <Box className={classes.buttonGroup}>
        <Button  size="large"
          color="primary"
          variant="contained"
          startIcon={<DoneIcon />}
          onClick={applyFilters}
        >
          Apply
        </Button>
        <Button  size="large"
          className={classes.error}
          variant="contained"
          startIcon={<ClearIcon />}
          onClick={clearFilters}
        >
          Clear
        </Button>
      </Box>
    </>
  );
}

InvoiceFilter.propTypes = {};

const mapStateToProps = state => ({
  storeId: state.currentStore.storeInfo._id,
  storeInfo: state.currentStore.storeInfo
});

const mapDispatchToProps = dispatch => ({
  FetchStoreProductsStart: requiredInfo =>
    dispatch(FetchStoreProductsStart(requiredInfo)),
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setErrors: errors => dispatch(SetErrors(errors)),
  setStoreInfo: store => dispatch(SetStoreInfo(store))
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceFilter);
