import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Typography,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  MenuItem,
  makeStyles,
  useMediaQuery
} from '@material-ui/core';
import { connect } from 'react-redux';
import axios from 'axios';
import { SetStoreInfo, UpdateStoreInfo } from '../../../redux/current-store/current-store-actions';
import { SketchPicker } from 'react-color';
import { fonts } from '../../../fontSrc';
import { SetSnackNotice } from '../../../redux/app/app-actions';

const useStyles = makeStyles(() => ({
  root: {}
}));

const ProfileDetails = ({ className, storeInfo, updateStoreInfo, newStoreBanner, setStoreInfo, setSnackNotice, setErrors, ...rest }) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    font: ''
  });

  useEffect(() => {
    console.log(storeInfo);
    setValues({
      ...values,
      firstName: storeInfo.ownerFirstName,
      lastName: storeInfo.ownerLastName,
      phone: storeInfo.storePhone,
      email: storeInfo.storeEmail,
      address: storeInfo.storeAddress,
      font: storeInfo.font
    });
  }, [storeInfo])

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };


  const _updateStoreInfo = () => {
    const {
      firstName,
      lastName,
      email,
      phone,
      address,
      font
    } = values;


    if (firstName && lastName && email && address && font) {
      let bannerToSend = { ...storeInfo.storeBanner }
      let bannerUpdated = false;
      if (newStoreBanner) {
        bannerToSend = {
          ...newStoreBanner
        }
        bannerUpdated = true;
      }
      updateStoreInfo({
        ...storeInfo,
        ownerFirstName: firstName,
        ownerLastName: lastName,
        storeEmail: email,
        storeAddress: address,
        storePhone: phone,
        font: font,
        sessionId: storeInfo.sessionId,
        storeBanner: bannerToSend,
        bannerUpdated: bannerUpdated,
        deletedKey: newStoreBanner ? storeInfo.storeBanner.key : null
      });
    }
  }

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="The information can be edited"
          title="Profile"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Please specify owner's first name"
                label="First name"
                name="firstName"
                onChange={handleChange}
                required
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Last name"
                helperText="Please specify owner's last name"
                name="lastName"
                onChange={handleChange}
                required
                value={values.lastName}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email Address"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Phone Number"
                name="phone"
                onChange={handleChange}
                type="number"
                value={values.phone}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Address"
                name="address"
                onChange={handleChange}
                required
                value={values.address}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                select
                label="Select"
                value={values.font || 'Roboto'}
                onChange={handleChange}
                helperText="Please select the desired font"
                variant="outlined"
                name="font"
                fullWidth
              >
                {Object.keys(fonts).map((font, idx) => {
                  return (<MenuItem key={idx} value={font}>
                    {font === 'Roboto' ? `${font} (Recommended)` : font}
                  </MenuItem>)
                })}
              </TextField>
            </Grid>

          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
          my={2}
        >
          <Button size="large"
            color="primary"
            variant="contained"
            onClick={() => _updateStoreInfo()}
            style={{ flexGrow: isSmallScreen ? 1 : 'unset' }}
          >
            Save details
          </Button>
        </Box>
      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state =>
({
  storeInfo: state.currentStore.storeInfo
});

const mapDispatchToProps = dispatch => (
  {
    setStoreInfo: (storeInfo) => dispatch(SetStoreInfo(storeInfo)),
    setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
    updateStoreInfo: (storeInfo) => dispatch(UpdateStoreInfo(storeInfo))
  });

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetails);
