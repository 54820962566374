import React, { useState } from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';

import Page from 'src/components/Page';
import AddCategoryForm from "./add-category-form";
import AddProductForm from "./add-product-form";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

const ProductDesignerView = () => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Product Designer"
    >
      <Container maxWidth={false}>
        <AddProductForm/>

      </Container>
    </Page>
  );
};

export default ProductDesignerView;
