import { createStyles, makeStyles } from '@material-ui/core';

const GlobalStyles = darkMode => {
  const useStyles = makeStyles(() =>
    createStyles({
      '@global': {
        '*': {
          boxSizing: 'border-box',
          margin: 0,
          padding: 0
        },
        html: {
          '-webkit-font-smoothing': 'antialiased',
          '-moz-osx-font-smoothing': 'grayscale',
          height: '100%',
          width: '100%'
        },
        body: {
          height: '100%',
          width: '100%',
          fontFamily: "'Roboto', sans-serif"
        },
        a: {
          textDecoration: 'none'
        },
        '#root': {
          height: '100%',
          width: '100%'
        }
      }
    })
  );

  useStyles();

  return null;
};

export default GlobalStyles;
