import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import {
  FetchStoreProductsStart,
  SetStoreInfo
} from '../../redux/current-store/current-store-actions';
import { SetErrors, SetSnackNotice } from '../../redux/app/app-actions';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import FilterListIcon from '@material-ui/icons/FilterList';
import './dialog-component.scss';

const useStyles = makeStyles(theme => ({
  dialogBox: {
    '& .MuiDialog-paper': {
      display: 'grid',
      padding: theme.spacing(2),
      gridRowGap: theme.spacing(1),
      backgroundColor: theme.palette.background.dark
    },
  },
  priceBox: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '10px'
  },
  buttonGroup: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '10px',
    marginTop: '5px'
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: 'white'
  }
}));

function DialogComponent({
  storeId,
  storeInfo,
  FetchStoreProductsStart,
  handleSearchFilterChange,
  setSnackInfo,
  setErrors,
  setStoreInfo,
  ...props
}) {
  const classes = useStyles();
  const { onClose, open } = props;

  const [filters, setFilters] = useState({});

  const handleClose = () => {
    onClose();
  };

  const handleFilterChange = (e) => {
    if (e.target.name === 'status') {

      setFilters({
        ...filters,
        [e.target.name]: e.target.checked ? 'available now' : null
      })
    }
    else {
      setFilters({
        ...filters,
        [e.target.name]: e.target.value
      })
    }

  }

  const applyFilters = () => {
    console.log(filters);
    handleSearchFilterChange(filters)
  }

  const clearFilters = () => {
    handleSearchFilterChange({});
    setFilters({
      minPrice: '',
      maxPrice: '',
      status: ''
    });
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className={classes.dialogBox}
    >
      <DialogTitle id="simple-dialog-title">
        <FilterListIcon />Filters
      </DialogTitle>
      <Box className={classes.priceBox}>
        <TextField
          className={classes.filterTextField}
          name="minPrice"
          type="number"
          inputProps={{
            type: 'number',
            min: 1,
          }}
          onChange={e => handleFilterChange(e)}
          placeholder={`${storeInfo.storeCurrencySymbol} Min price`}
          variant="outlined"
          value={filters.minPrice}
        />
        <TextField
          className={classes.filterTextField}
          name="maxPrice"
          type="number"
          inputProps={{
            type: 'number',
            min: 1,
          }}
          onChange={e => handleFilterChange(e)}
          placeholder={`${storeInfo.storeCurrencySymbol} Max price`}
          variant="outlined"
          value={filters.maxPrice}
        />
      </Box>
      <FormControlLabel control={<Checkbox checked={filters.status === 'available now'} onChange={handleFilterChange} name="status" />} label="Show only available now" />
      <Box className={classes.buttonGroup}>
        <Button  size="large"
          color="primary"
          size="large"
          variant="contained"
          startIcon={<DoneIcon />}
          onClick={applyFilters}
        >
          Apply
        </Button>
        <Button  size="large"
          size="large"
          className={classes.error}
          variant="contained"
          startIcon={<ClearIcon />}
          onClick={clearFilters}

        >
          Clear
        </Button>
      </Box>
    </Dialog>
  );
}

DialogComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  storeId: state.currentStore.storeInfo._id,
  storeInfo: state.currentStore.storeInfo
});

const mapDispatchToProps = dispatch => ({
  FetchStoreProductsStart: requiredInfo =>
    dispatch(FetchStoreProductsStart(requiredInfo)),
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setErrors: errors => dispatch(SetErrors(errors)),
  setStoreInfo: store => dispatch(SetStoreInfo(store))
});

export default connect(mapStateToProps, mapDispatchToProps)(DialogComponent);
