import React, {useEffect, useState} from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    width: '50%',
    position:'absolute'

  },
  container:{
    width:'100vw',
    minHeight:'100%',
    backgroundColor:'white',
    display: 'grid',
    alignItems:'center',
    justifyItems: 'center',
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex:1000
  },
  action:{
    margin:'10px 0'
  }
});

const ProgressBar = ({completed, action}) => {
  const classes = useStyles();
  const [timer, setTimer] = useState(null);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setTimer(setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 92) {
          return oldProgress;
        }
        const diff = Math.random() * 15;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500));

    return () => {
      clearInterval(timer);
    };
  }, []);


  useEffect(() => {
    if (completed) {
      setProgress(100);
    }

    if (timer) {
      clearInterval(timer);
    }
  }, [completed]);


  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <LinearProgress variant="determinate" value={progress}/>
        <Typography variant="h4" fullWidth align={"center"} mt={3} className={classes.action}>{action}</Typography>
      </div>
    </div>)

}


export default ProgressBar;
