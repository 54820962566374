import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import { SetErrors, SetSnackNotice } from 'src/redux/app/app-actions';
import {
  FetchStoreNotificationsStart,
  FetchStoreProductsStart
} from 'src/redux/current-store/current-store-actions';
import CachedIcon from '@material-ui/icons/Cached';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';

import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box } from '@material-ui/core';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    // '&:focus': {
    //   backgroundColor: theme.palette.primary.main,
    //   '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
    //     color: theme.palette.common.white,
    //   },
    // },
  }
}))(MenuItem);

function NotificationMenu({
  storeId,
  FetchStoreNotificationsStart,
  setNotificationsNumber,
  notificationsNumber,
  storeInfo,
  notifications
}) {
  const useStyles = makeStyles(theme => ({
    warning: {
      '&:hover': {
        backgroundColor: theme.palette.warning.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.warning.contrastText
        }
      }
    },
    error: {
      '&:hover': {
        backgroundColor: theme.palette.error.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.error.contrastText,
        }
      }
    },
    success: {
      '&:hover': {
        backgroundColor: theme.palette.success.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white
        }
      }
    },
    info: {
      '&:hover': {
        backgroundColor: theme.palette.info.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white
        }
      }
    },
    new: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.primary.contrastText,
        }
      }
    }
  }));

  const classes = useStyles();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    FetchStoreNotificationsStart(storeId, storeInfo.sessionId);
  }, [storeId]);

  const getNotificationTypeIcon = type => {
    switch (type) {
      case 'warning':
        return <CachedIcon fontSize="small" />;
      case 'error':
        return <ClearIcon fontSize="small" />;
      case 'success':
        return <CheckIcon fontSize="small" />;
      case 'info':
        return <ErrorIcon fontSize="small" />;
      default:
        return;
    }
  };

  const getNotificationTypeClass = type => {
    switch (type) {
      case 'warning':
        return classes.warning;
      case 'error':
        return classes.error;
      case 'success':
        return classes.success;
      case 'info':
        return classes.info;
      default:
        return;
    }
  };

  const updateNotification = async updatedNotification => {
    const result = await axios({
      url: 'https://kripson-store-server-8qq76.ondigitalocean.app/notifications/updateNotification',
      method: 'POST',
      data: {
        storeId: storeInfo._id,
        sessionId: storeInfo.sessionId,
        updatedNotification
      }
    });
    FetchStoreNotificationsStart(storeId, storeInfo.sessionId);
  };

  const onNotificationClick = notification => {
    if (!notification.viewed) {
      updateNotification({ ...notification, viewed: true });
      setNotificationsNumber(notificationsNumber - 1);
    }

    navigate(notification.link);
  };

  return (
    <>
      <NotificationsIcon
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClick}
      />
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {notifications && notifications.length ? (
          notifications.map(notification => {
            return notification.hasOwnProperty('link') &&
              notification.link.length ? (
              <Box onClick={() => onNotificationClick(notification)}>
                <StyledMenuItem
                  className={`${getNotificationTypeClass(notification.type)} ${
                    !notification.viewed ? classes.new : ''
                  }`}
                >
                  <ListItemIcon>
                    {getNotificationTypeIcon(notification.type)}
                  </ListItemIcon>
                  <ListItemText primary={notification.message} />
                </StyledMenuItem>
              </Box>
            ) : (
              <StyledMenuItem
                className={`${getNotificationTypeClass(notification.type)} ${
                  !notification.viewed ? classes.new : ''
                }`}
              >
                <ListItemIcon>
                  {getNotificationTypeIcon(notification.type)}
                </ListItemIcon>
                <ListItemText primary={notification.message} />
              </StyledMenuItem>
            );
          })
        ) : (
          <StyledMenuItem>
            <ListItemIcon>
              <SendIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="No notifications available" />
          </StyledMenuItem>
        )}
      </StyledMenu>
    </>
  );
}

const mapStateToProps = state => ({
  storeId: state.currentStore.storeInfo._id,
  storeInfo: state.currentStore.storeInfo,
  notifications: state.currentStore.notifications
});

const mapDispatchToProps = dispatch => ({
  FetchStoreNotificationsStart: (storeId, sessionId) =>
    dispatch(FetchStoreNotificationsStart(storeId, sessionId)),
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setErrors: errors => dispatch(SetErrors(errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationMenu);
