import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar, HorizontalBar } from 'react-chartjs-2';
import {
  TextField,
  MenuItem,
  Box,
  Button,
  Card,
  Hidden,
  Grid,
  CardContent,
  Typography,
  Divider,
  useTheme,
  makeStyles,
  colors,
  useMediaQuery
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { connect } from 'react-redux';
import axios from 'axios';
import { SetStoreInfo } from 'src/redux/current-store/current-store-actions';
import { SetErrors, SetSnackNotice } from 'src/redux/app/app-actions';
import { DatePicker } from '@material-ui/pickers';

const useStyles = makeStyles(() => ({
  root: {}
}));

const monthlyLabels = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
};

const Sales = ({
  className,
  storeId,
  setStoreInfo,
  setSnackNotice,
  setErrors,
  storeInfo,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [frequency, setFrequency] = useState('daily');

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [data, setData] = useState({
    datasets: [
      {
        backgroundColor: colors.indigo[500],
        data: [10, 2, 3],
        label: 'Total Sales Amount'
      },
      {
        backgroundColor: colors.grey[200],
        data: [4, 5, 6],
        label: 'Total Sales Count'
      }
    ],
    labels: [1, 2, 4]
  });

  useEffect(() => {
    let now = new Date();
    let sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

    let day = ('0' + now.getDate()).slice(-2);
    let sevenDaysAgoDay = ('0' + sevenDaysAgo.getDate()).slice(-2);

    let month = ('0' + (now.getMonth() + 1)).slice(-2);
    let sevenDaysAgoMonth = ('0' + (sevenDaysAgo.getMonth() + 1)).slice(-2);

    let today = now.getFullYear() + '-' + month + '-' + day;
    sevenDaysAgo =
      sevenDaysAgo.getFullYear() +
      '-' +
      sevenDaysAgoMonth +
      '-' +
      sevenDaysAgoDay;

    setStartDate(sevenDaysAgo);
    setEndDate(today);
  }, []);

  useEffect(() => {
    console.log(startDate);
    if (frequency && startDate && endDate && storeId) {
      console.log('test');
      (async () => {
        const response = await axios({
          url: `https://kripson-store-server-8qq76.ondigitalocean.app/stats/${
            frequency === 'daily'
              ? 'getDailySales'
              : frequency === 'monthly'
              ? 'getMonthlySales'
              : 'getYearlySales'
          }`,
          method: 'POST',
          data: {
            storeId: storeInfo._id,
            startDate,
            endDate,
            sessionId: storeInfo.sessionId
          }
        });
        if (response.data.status === 1) {
          setData({
            datasets: [
              {
                backgroundColor: colors.indigo[500],
                data: response.data.result.map(
                  record => record.totalSaleAmount
                ),
                label: 'Total Sales Amount in ' + storeInfo.storeCurrencySymbol,
                indexAxis: 'y'
              },
              {
                backgroundColor: colors.grey[200],
                data: response.data.result.map(record => record.count),
                label: 'Total Sales Count',
                indexAxis: 'y'
              }
            ],

            labels:
              frequency === 'monthly'
                ? response.data.result.map(record => monthlyLabels[record._id])
                : response.data.result.map(record => record._id)
          });
        } else if (response.data.status === 3) {
          setStoreInfo({
            ownerFirstName: '',
            ownerLastName: '',
            storeEmail: '',
            storeName: '',
            storeAddress: '',
            colors: { primary: '#000000'}
          });
          setSnackNotice({
            severity: 'warning',
            message: response.data.message
          });
        } else {
          setErrors(response.data.errors);
        }
      })();
    }
  }, [frequency, startDate, endDate, storeId]);

  const options = {
    // animation: true,
    cornerRadius: 0,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 12,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          barThickness: 12,
          maxBarThickness: 12,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          stacked: true,
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Box p={2}>
        <Typography variant="h5" color="textSecondary">
          Sales
        </Typography>
      </Box>
      <Divider />
      <Hidden smDown>
        <Box display="flex" justifyContent="space-between" px={2}>
          <Box>
            <TextField
              select
              label="Select"
              variant="outlined"
              name="storeCategory"
              margin="normal"
              style={{ width: '150px' }}
              value={frequency}
              onChange={e => setFrequency(e.target.value)}
            >
              <MenuItem value={'daily'} selected>
                Daily
              </MenuItem>
              <MenuItem value={'monthly'}>Monthly</MenuItem>
              <MenuItem value={'yearly'}>Yearly</MenuItem>
            </TextField>
          </Box>

          <Box>
            <DatePicker
              margin="normal"
              id="date-picker-dialog"
              label="Start Date"
              format="MM/dd/yyyy"
              inputVariant="outlined" 
              value={startDate}
              onChange={date => {
                setStartDate(date);
              }}
              style={{ marginRight: '15px' }}
              name="startDate"
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
            <DatePicker
              margin="normal"
              id="date-picker-dialog"
              label="End Date"
              format="MM/dd/yyyy"
              inputVariant="outlined"
              value={endDate}
              onChange={date => {
                setEndDate(date);
              }}
              name="startDate"
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
          </Box>
        </Box>
      </Hidden>

      <Hidden mdUp>
        <Grid container style={{ padding: '15px' }}>
          <Grid item xs={12}>
            <TextField
              select
              label="Select"
              variant="outlined"
              name="storeCategory"
              margin="normal"
              value={frequency}
              fullWidth
              onChange={e => setFrequency(e.target.value)}
            >
              <MenuItem value={'daily'} selected>
                Daily
              </MenuItem>
              <MenuItem value={'monthly'}>Monthly</MenuItem>
              <MenuItem value={'yearly'}>Yearly</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              helperText="Start Date"
              margin="normal"
              name="reason"
              type="date"
              variant="outlined"
              value={startDate}
              fullWidth
              onChange={e => {
                setStartDate(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              helperText="End Date"
              margin="normal"
              name="reason"
              type="date"
              variant="outlined"
              value={endDate}
              fullWidth
              onChange={e => {
                setEndDate(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </Hidden>

      <CardContent>
        <Box height={400} position="relative">
          {isSmallScreen ? (
            <HorizontalBar data={data} options={options} />
          ) : (
            <Bar data={data} options={options} />
          )}
        </Box>
      </CardContent>
      <Divider />
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button  size="large"
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
        >
          Overview
        </Button>
      </Box>
    </Card>
  );
};

Sales.propTypes = {
  className: PropTypes.string
};
const mapStateToProps = state => ({
  storeId: state.currentStore.storeInfo._id,
  storeInfo: state.currentStore.storeInfo
});

const mapDispatchToProps = dispatch => ({
  setStoreInfo: store => dispatch(SetStoreInfo(store)),
  setErrors: error => dispatch(SetErrors(error)),
  setSnackNotice: notice => dispatch(SetSnackNotice(notice))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sales);
