import React, { useEffect, useState } from "react";
import { Box, Container, Grid, makeStyles } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import Page from "src/components/Page";
import Toolbar from "./Toolbar";
import ProductCard from "./ProductCard";
import { FetchStoreProductsStart, SetStoreInfo } from "../../../../redux/current-store/current-store-actions";
import { SetErrors, SetPreLoader } from "../../../../redux/app/app-actions";
import { connect } from "react-redux";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  productCard: {
    height: "100%",
  },
  skeletons: {
    marginLeft: theme.spacing(3),
  },
}));

const PosProductList = ({ setPreLoader, currentCustomer, SetStoreInfo, setSnackNotice, setErrors, storeId, categories, FetchStoreProductsStart }) => {
  const classes = useStyles();
  const location = useLocation();
  const [keyword, setKeyword] = useState("");
  const [fetchingProducts, setFetchingProducts] = useState(true);
  const [searchFilters, setSearchFilters] = useState({});
  const [products, setProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  useEffect(() => {
    if (totalProducts && page >= Math.ceil(totalProducts / rowsPerPage)) {
      console.log(Math.ceil(totalProducts / rowsPerPage));
      setPage(Math.ceil(totalProducts / rowsPerPage) - 1);
    }
  }, [totalProducts]);

  let { category } = useParams();
  const navigate = useNavigate();

  const handleSearchFilterChange = (filter) => {
    setSearchFilters({
      ...filter,
    });
  };

  const handleChangePage = (event, newPage) => {
    console.log(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchProducts = () => {
    if (storeId) {
      const filter = {};
      if (category) {
        filter.parentCategory = category;
      }

      if (keyword) {
        filter.$or = [{ title: { $regex: keyword, $options: "ix" } }, { brand: { $regex: keyword, $options: "ix" } }];
      }

      filter.minPrice = searchFilters.minPrice || "0";
      filter.maxPrice = searchFilters.maxPrice || "999999999999999";

      if (searchFilters.status) {
        filter.status = searchFilters.status;
      }
      setFetchingProducts(true);
      axios({
        url: "https://kripson-store-server-8qq76.ondigitalocean.app/products/getProducts",
        method: "POST",
        data: {
          storeId: storeId,
          filter: filter,
          limit: rowsPerPage,
          skip: page * rowsPerPage,
        },
      }).then((response) => {
        if (response.data.status === 1) {
          setTotalProducts(response.data.totals);
          if (response.data.result.length === 0) {
            setPage(0);
          }
          setProducts([...response.data.result]);
        } else if (response.data.status === 3) {
          SetStoreInfo({});
          setSnackNotice({
            severity: "warning",
            message: response.data.message,
          });
        } else {
          setErrors(response.data.errors);
        }
        setFetchingProducts(false);
      });

      
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [categories, location.pathname, storeId, keyword, searchFilters, page, rowsPerPage]);

  return (
    <Page className={classes.root} title="Products">
      <Container maxWidth={false}>
        <Toolbar setKeyword={setKeyword} handleSearchFilterChange={handleSearchFilterChange} />
        <Box mt={3}>
          <Grid container spacing={3}>
            {!fetchingProducts &&
              products.map((product) => (
                <Grid item key={product.id} lg={4} md={6} xs={12}>
                  <Link
                    to={
                      location
                        ? location.pathname.includes(`stores`)
                          ? `/stores/${storeId}/products/${category}/${product._id}`
                          : `/pos/products/${category}/${product._id}`
                        : `/pos/products/${category}/${product._id}`
                    }
                  >
                    <ProductCard className={classes.productCard} product={product} />
                  </Link>
                </Grid>
              ))}
            {fetchingProducts &&
              [1, 2, 3, 4].map((num) => (
                <Grid item key={num} lg={4} md={6} xs={12}>
                  <Skeleton component="div" height={350} style={{ transform: "unset" }} />
                </Grid>
              ))}
          </Grid>
        </Box>
        <Box mt={3} display="flex" justifyContent="center">
          <Pagination
            color="primary"
            count={Math.ceil(totalProducts / rowsPerPage) || 1}
            size="small"
            onChange={(e, epage) => {
              handleChangePage(e, epage - 1);
            }}
            variant="outlined"
            shape="rounded"
          />
        </Box>
      </Container>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  storeId: state.currentStore.storeInfo._id,
  categories: state.currentStore.categories,
});

const mapDispatchToProps = (dispatch) => ({
  FetchStoreProductsStart: (requiredInfo) => dispatch(FetchStoreProductsStart(requiredInfo)),
  setStoreInfo: (storeInfo) => dispatch(SetStoreInfo(storeInfo)),
  setErrors: (errors) => dispatch(SetErrors(errors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PosProductList);
