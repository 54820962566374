import React from 'react';
import './Logo.scss';
const Logo = (props) => {
  return (
    <img
      alt="Logo"
      src="/static/logo.png"
      {...props}
      className={'Logo'}
    />
  );
};

export default Logo;
