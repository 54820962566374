import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardContent, Divider, Typography, Button, Box, makeStyles, Chip } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { getFormattedDate } from 'src/utils/date-time';
import SendIcon from '@material-ui/icons/Send';


const RefundRequestCard = ({ refundRequest }) => {

    const useStyles = makeStyles((theme) => ({
        footerBox: {
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            padding: theme.spacing(2)
        },
        moneyChip: {
            backgroundColor: '#b3ffb3'
        }
    }))

    const classes = useStyles();


    return <Card>
        <CardHeader 
            title={'Refund request # :  ' + refundRequest.refundRequestNumber}
            subheader={getFormattedDate(refundRequest.createdDate)}
        >

        </CardHeader>
        <Divider />
        <Box className={classes.footerBox}>
            <Chip label={refundRequest.refundTotal} className={classes.moneyChip}> </Chip>
            <RouterLink to={`/app/refundRequests/${refundRequest._id}`}><Button  size="large" variant="contained" color="primary" endIcon={<SendIcon />}>
                Details
            </Button></RouterLink>
        </Box>

    </Card>
}

export default RefundRequestCard;