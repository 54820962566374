import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    makeStyles,
    TextField,
    Card,
    MenuItem,
    CardHeader,
    Divider,
    Typography,
    Chip,
    Button
} from '@material-ui/core';
import Page from 'src/components/Page';
import Notifications from './Notifications';
import Password from './Password';
import { connect } from 'react-redux';
import axios from 'axios';
import { SetStoreInfo } from '../../../redux/current-store/current-store-actions';
import { SetErrors, SetSnackNotice } from '../../../redux/app/app-actions';
import * as _ from 'lodash';
import FlagIcon from '../../../components/flag-component/flag-component';
import { flagCurrencyMap } from '../../../flagCurrencyMap';
import PaymentMethodSetupForm from 'src/components/payment-method-setup-form/payment-method-setup-form';
import countryPaymentMethodMap from './country-payment-method-map';
import { validateData } from 'src/utils/validation';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    currencyContainer: {
        padding: theme.spacing(2)
    },
    currency: {
        marginLeft: theme.spacing(1)
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText
    },
    paymentMethodBox: {
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    paymentMethodLogo: {
        height: '100px',
        width: 'auto',
    },
    errorText: {
        color: theme.palette.error.main,
    }
}));





const PaymentMethodSettings = ({ storeInfo,
    setStoreInfo,
    setErrors,
    setSnackNotice }) => {

    const [paymentMethods, setPaymentMethods] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        getStorePaymentMethods();
    }, [])

    const getStorePaymentMethods = () => {
        if (storeInfo) {
            setSnackNotice({
                message: 'Retrieving store payment methods',
                severity: 'info'
            });
            axios({
                url:
                    'https://kripson-store-server-8qq76.ondigitalocean.app/stores/getPaymentMethods',
                method: 'POST',
                data: {
                    sessionId: storeInfo.sessionId,
                    storeId: storeInfo._id,
                }
            })
                .then(response => {
                    if (response.data.status === 1) {
                        setSnackNotice({
                            message: response.data.message,
                            severity: 'success'
                        });
                        console.log('test');
                        setPaymentMethods(response.data.result);
                    } else if (response.data.status === 3) {
                        setStoreInfo({
                            ownerFirstName: '',
                            ownerLastName: '',
                            storeEmail: '',
                            storeName: '',
                            storeAddress: '',
                            colors: {primary: '#000000'}
                          });
                        setSnackNotice({
                            severity: 'warning',
                            message: response.data.message
                        });
                    } else {
                        setErrors(response.data.errors);
                    }
                })
                .catch(error => {
                    setSnackNotice({
                        message: 'Payment methods could not be retrieved.',
                        severity: 'error'
                    });
                });
        }
    };


    const deleteStorePaymentMethod = (id) => {
        if (storeInfo) {
            setSnackNotice({
                message: 'Deleting payment method',
                severity: 'info'
            });
            axios({
                url:
                    'https://kripson-store-server-8qq76.ondigitalocean.app/stores/removePaymentMethod',
                method: 'POST',
                data: {
                    sessionId: storeInfo.sessionId,
                    storeId: storeInfo._id,
                    paymentMethodId: id
                }
            })
                .then(response => {
                    if (response.data.status === 1) {
                        setSnackNotice({
                            message: response.data.message,
                            severity: 'success'
                        });
                        console.log('test');
                        getStorePaymentMethods();
                    } else if (response.data.status === 3) {
                        setStoreInfo({
                            ownerFirstName: '',
                            ownerLastName: '',
                            storeEmail: '',
                            storeName: '',
                            storeAddress: '',
                            colors: {primary: '#000000'}
                          });
                        setSnackNotice({
                            severity: 'warning',
                            message: response.data.message
                        });
                    } else {
                        setErrors(response.data.errors);
                    }
                })
                .catch(error => {
                    setSnackNotice({
                        message: 'Payment method could not be deleted.',
                        severity: 'error'
                    });
                });
        }
    };

    return <Page title="Payment Methods" className={classes.root}>
        <Container maxWidth="lg">
            <Box mb={3}>
                <Typography color="textPrimary" variant="h2">
                    Payment Methods
                </Typography>
                <Typography color="textSecondary" gutterBottom variant="body2">
                    Update your payment methods
                </Typography>
            </Box>
            <Box my={1}>
                <Card >
                    <CardHeader
                        subheader="Add payment methods"
                        title="Payment Method"
                    >
                    </CardHeader>
                    <Divider />
                    <Box>
                        <PaymentMethodSetupForm callBack={getStorePaymentMethods} />
                    </Box>
                </Card>
            </Box>


            <Box my={1}>
                <Card >
                    <CardHeader
                        subheader="View your payment methods"
                        title="Payment Method"
                    >

                    </CardHeader>
                    <Divider />
                    <Box>
                        {paymentMethods && paymentMethods.map(paymentMethod => <Box className={classes.paymentMethodBox}>
                            <img className={classes.paymentMethodLogo} src={`/static/images/${countryPaymentMethodMap[paymentMethod.name].logo}`} alt={paymentMethod.name} />
                            <span>{paymentMethod.name}</span>
                            {paymentMethod.isPrimary ? <Chip color="primary" label={'Primary'}></Chip> : ''}
                            <Button  size="large" className={classes.error} variant="contained" onClick={() => deleteStorePaymentMethod(paymentMethod._id)}>Delete</Button>
                        </Box>
                        )}
                    </Box>
                </Card>
            </Box>
        </Container>
    </Page>
}


const mapStateToProps = state => ({
    storeInfo: state.currentStore.storeInfo
});

const mapDispatchToProps = dispatch => ({
    setStoreInfo: storeInfo => dispatch(SetStoreInfo(storeInfo)),
    setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
    setErrors: errors => dispatch(SetErrors(errors))
});


export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodSettings);



