import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Hidden,
  Grid,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Link } from 'react-router-dom';
import { DatePicker } from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  toolBar: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  }
}));

const Toolbar = ({
  className,
  setKeyword,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>

      <Box mt={3}>
        <Card>
          <Hidden smDown>
            <CardContent className={classes.toolBar}>
              <Box maxWidth={500}>
                <TextField
                  fullWidth
                  onChange={e => setKeyword(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  placeholder="Search Order"
                  variant="outlined"
                />
              </Box>
              <Box>
                <DatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Start Date"
                  format="MM/dd/yyyy"
                  inputVariant="outlined"
                  value={startDate}
                  clearable={true}
                  onChange={date => {
                    setStartDate(date);
                  }}
                  style={{ marginRight: '15px' }}
                  name="startDate"
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                />
                <DatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="End Date"
                  clearable={true}
                  format="MM/dd/yyyy"
                  inputVariant="outlined"
                  value={endDate}
                  onChange={date => {
                    setEndDate(date);
                  }}
                  name="startDate"
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                />
              </Box>
            </CardContent>
          </Hidden>

          <Hidden mdUp>
            <Grid container style={{ padding: '15px' }}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  onChange={e => setKeyword(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  placeholder="Search Order"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: '15px' }}>
                <TextField
                  helperText="Start Date"
                  name="reason"
                  type="date"
                  variant="outlined"
                  value={startDate}
                  fullWidth
                  onChange={e => {
                    setStartDate(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  helperText="End Date"
                  name="reason"
                  type="date"
                  variant="outlined"
                  fullWidth
                  value={endDate}
                  onChange={e => {
                    setEndDate(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </Hidden>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
