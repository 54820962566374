import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Container,
  Link,
  makeStyles,
  TextField,
  Typography,
  Card,
  Hidden,
  Switch,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Avatar,
  Fab,
  FormHelperText
} from '@material-ui/core';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  FetchStoreCategoriesStart,
  SetStoreInfo
} from '../../../redux/current-store/current-store-actions';
import { SetErrors, SetSnackNotice } from '../../../redux/app/app-actions';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import { SketchPicker } from 'react-color';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { DropzoneArea } from 'material-ui-dropzone';
import * as FA from 'react-fontawesome';
import imageCompression from 'browser-image-compression';
import ProgressBar from '../../../components/progress-bar/progress-bar';
import Page from '../../../components/Page';
import * as _ from 'lodash';
import { NavLink } from 'react-router-dom';
import ChipInput from 'material-ui-chip-input';
import generateUniqueToken from '../../../utils/generate-unique-token';
import SelectProductsDialog from '../select-products-dialog';
import CheckoutItemComponent from 'src/components/checkout-item/checkout-item.component';
import { DataGrid } from '@material-ui/data-grid';
import CustomNoRowsOverlay from 'src/components/custom-no-rows/custom-no-rows';
import { validateData } from 'src/utils/validation';
import {
  DatePicker,
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
  TimePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  sizeGrid: {
    margin: '1em 0'
  },

  formSectionHeader: {
    margin: '16px 0'
  },
  extraMargin: {
    margin: '40px 0 0 0'
  },
  card: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  smallCard: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  individualSize: {
    display: 'grid',
    gridColumnGap: '10px',
    alignItems: 'center',
    gridTemplateColumns: 'auto 1fr auto'
  },
  formContainer: {
    position: 'relative'
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: 'white'
  }
}));

const AddDiscountForm = ({
  storeId,
  categories,
  setSnackNotice,
  setStoreInfo,
  storeInfo,
  notice,
  setErrors
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const createDiscount = async values => {
    if (Object.keys(customErrors).every(errorKey => !customErrors)) {
      setSnackNotice({
        message: 'Creating new discount offer',
        severity: 'info'
      });
      try {
        const response = await axios({
          url: 'https://kripson-store-server-8qq76.ondigitalocean.app/discounts/createDiscount',
          method: 'POST',
          onUploadProgress: progressEvent => console.log(progressEvent),
          data: {
            sessionId: storeInfo.sessionId,
            newDiscount: {
              ...newDiscount,
              startDate: newDiscount.startDate.getTime(),
              endDate: newDiscount.endDate.getTime(),
              eligibleProducts: newDiscount.eligibleProducts.map(
                prd => prd._id
              ),
              eligibleCategories: newDiscount.eligibleCategories.map(
                cat => cat._id
              ),
              storeId: storeId
            }
          }
        });
        if (response.data.status === 1) {
          setSnackNotice({
            message: 'Discount offer created',
            severity: 'success'
          });
        } else if (response.data.status === 3) {
          setStoreInfo({
            ownerFirstName: '',
            ownerLastName: '',
            storeEmail: '',
            storeName: '',
            storeAddress: '',
            colors: { primary: '#000000'}
          });
          setSnackNotice({
            severity: 'warning',
            message: response.data.message
          });
        } else {
          setErrors(response.data.errors);
          setSnackNotice({
            message: 'Discount creation failed',
            severity: 'error'
          });
        }
      } catch (e) {
        setSnackNotice({
          message: 'Discount creation failed',
          severity: 'error'
        });
      }
    } else {
      console.log(newDiscount);
    }
  };

  const [selectProductsDialogOpen, setSelectProductsDialogOpen] = useState(
    false
  );
  const [selectCategoriesDialogOpen, setSelectCategoriesDialogOpen] = useState(
    false
  );

  const [customErrors, setCustomErrors] = useState({
    images: '',
    stockQuantity: {}
  });

  const [isMaxNumberOfUse, setIsMaxNumberOfUse] = useState(false);
  const [isMaxNumberOfUsePerOrder, setIsMaxNumberOfUsePerOrder] = useState(
    false
  );
  const [
    isMaxNumberOfUsePerCustomer,
    setIsMaxNumberOfUsePerCustomer
  ] = useState(false);
  const [isProductLimitPerUse, setIsProductLimitPerUse] = useState(false);

  const [newDiscount, setNewDiscount] = useState({
    title: '',
    banner: '',
    code: '',
    type: '',
    value: '',
    appliesTo: '',
    canUseInConjunction: false,
    eligibleCategories: [],
    eligibleProducts: [],
    maxNumberOfUse: 1,
    maxNumberOfUsePerOrder: 1,
    maxNumberOfUsePerCustomer: 1,
    productLimitPerUse: 1,
    orderRequirements: [],
    customerRequirements: [],
    startDate: new Date(),
    endDate: new Date(),
    expired: false
  });

  const validateNewDiscount = async () => {
    const errors = await validateData('newDiscount', newDiscount);

    const errorMap = {};
    if (errors.errorMap) {
      errors.errorMap.forEach(error => {
        errorMap[error.path] = error.message;
      });
    }
    setCustomErrors({ ...errorMap });
  };

  const columns = [
    {
      field: 'image',
      headerName: 'img',
      width: 90,
      renderCell: params => {
        return <Avatar src={params.value.props.src} />;
      }
    },
    { field: 'title', headerName: 'title', width: 200 },
    {
      field: '',
      headerName: '',
      width: 50,
      renderCell: params => {
        return (
          <DeleteIcon
            onClick={() =>
              setNewDiscount({
                ...newDiscount,
                eligibleProducts: newDiscount.eligibleProducts.filter(
                  (prd, idx) => idx !== params.id - 1
                )
              })
            }
          />
        );
      }
    }
  ];

  const imageConversion = async files => {
    const compressedFileArray = [];
    for (let file of files) {
      const options = {
        maxSizeMB: 0.4,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      };
      try {
        const compressedFile = await imageCompression(file, options);
        compressedFileArray.push(compressedFile);
      } catch (error) {
        console.log(error);
      }
    }

    const base64dataarray = [];
    let counter = base64dataarray.length;

    compressedFileArray.forEach((file, idx) => {
      let reader = new FileReader();
      reader.onloadend = function() {
        base64dataarray[counter].base64data = reader.result;
        if (idx === files.length - 1) {
          setNewDiscount({
            ...newDiscount,
            banner: base64dataarray[0]
          });
        }

        counter++;
      };
      reader.readAsDataURL(file);
      base64dataarray.push({ type: file.type });
    });
  };

  useEffect(() => {
    validateNewDiscount();
  }, [newDiscount]);

  const handleChange = e => {
    if (e.target.name === 'appliesTo') {
      if (e.target.value === 'products') {
        setNewDiscount({
          ...newDiscount,
          [e.target.name]: e.target.value,
          eligibleCategories: []
        });
      } else if (e.target.value === 'categories') {
        setNewDiscount({
          ...newDiscount,
          [e.target.name]: e.target.value,
          eligibleProducts: []
        });
      } else {
        setNewDiscount({
          ...newDiscount,
          [e.target.name]: e.target.value,
          eligibleCategories: [],
          eligibleProducts: []
        });
      }
    } else if (e.target.name === 'canUseInConjunction') {
      setNewDiscount({
        ...newDiscount,
        [e.target.name]: e.target.checked
      });
    } else {
      setNewDiscount({
        ...newDiscount,
        [e.target.name]: e.target.value
      });
    }
  };

  const generateDiscountCode = async () => {
    const newCode = await generateUniqueToken(
      storeInfo.sessionId,
      'discounts',
      'code',
      storeId,
      8,
      'distinguishable'
    );
    setNewDiscount({
      ...newDiscount,
      code: newCode
    });
  };

  const [discountForm, setDiscountForm] = useState('');

  useEffect(() => {
    if (categories.length > 0) {
      setDiscountForm(
        <form onSubmit={e => e.preventDefault()} className={classes.root}>
          <Box mb={3}>
            <Typography color="textPrimary" variant="h2">
              Create Discount
            </Typography>
            <Typography color="textSecondary" gutterBottom variant="body2">
              Create a discount offer
            </Typography>
          </Box>
          <SelectProductsDialog
            open={selectProductsDialogOpen}
            onClose={() => setSelectProductsDialogOpen(false)}
            addEligibleProducts={products => {
              setNewDiscount({
                ...newDiscount,
                eligibleProducts: newDiscount.eligibleProducts.concat(products)
              });
            }}
            alreadySelectedProductIds={newDiscount.eligibleProducts.map(
              prd => prd._id
            )}
          />
          <Card className={isSmallScreen ? classes.smallCard : classes.card}>
            <Typography color="textPrimary" gutterBottom variant="h5">
              Discount offer title
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  placeholder="eg : Festival offer"
                  value={newDiscount.title}
                  onChange={handleChange}
                  helperText="Discount offer title"
                  variant="outlined"
                  name="title"
                  fullWidth
                  error={Boolean(customErrors.title)}
                  margin="normal"
                />
              </Grid>
            </Grid>
          </Card>
          <Card className={isSmallScreen ? classes.smallCard : classes.card}>
            <Typography color="textPrimary" gutterBottom variant="h5">
              Discount code
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  placeholder="eg : FESTIVE30"
                  value={newDiscount.code}
                  onChange={handleChange}
                  helperText="Code to use at checkout"
                  variant="outlined"
                  name="code"
                  fullWidth
                  error={Boolean(customErrors.code)}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <Button  size="large"
                  variant="contained"
                  margin="normal"
                  color="primary"
                  onClick={generateDiscountCode}
                >
                  Generate Code
                </Button>
              </Grid>
            </Grid>
          </Card>

          <Card className={isSmallScreen ? classes.smallCard : classes.card}>
            <Typography color="textPrimary" gutterBottom variant="h5">
              Start and End Dates
            </Typography>

            <Grid container justify="start" spacing={1}>
              <Grid item xs={12} md={6}>
                <DatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Discount start date"
                  format="MM/dd/yyyy"
                  inputVariant="outlined"
                  fullWidth
                  value={newDiscount.startDate}
                  onChange={date =>
                    setNewDiscount({
                      ...newDiscount,
                      startDate: date
                    })
                  }
                  name="startDate"
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  style={{ marginRight: '8px' }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TimePicker
                  margin="normal"
                  id="time-picker"
                  label="Discount start time"
                  fullWidth
                  value={newDiscount.startDate}
                  inputVariant="outlined"
                  onChange={date =>
                    setNewDiscount({
                      ...newDiscount,
                      startDate: date
                    })
                  }
                  name="startDate"
                  KeyboardButtonProps={{
                    'aria-label': 'change time'
                  }}
                />
              </Grid>
            </Grid>

            <Grid container justify="start" spacing={1}>
              <Grid item xs={12} md={6}>
                <DatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Discount end date"
                  format="MM/dd/yyyy"
                  value={newDiscount.endDate}
                  fullWidth
                  inputVariant="outlined"
                  onChange={date =>
                    setNewDiscount({
                      ...newDiscount,
                      endDate: date
                    })
                  }
                  name="endDate"
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  style={{ marginRight: '8px' }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TimePicker
                  margin="normal"
                  id="time-picker"
                  label="Discount start time"
                  value={newDiscount.endDate}
                  fullWidth
                  inputVariant="outlined"
                  onChange={date =>
                    setNewDiscount({
                      ...newDiscount,
                      endDate: date
                    })
                  }
                  name="endDate"
                  KeyboardButtonProps={{
                    'aria-label': 'change time'
                  }}
                />
              </Grid>
            </Grid>
          </Card>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card
                className={isSmallScreen ? classes.smallCard : classes.card}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <FormControl component="fieldset" error={customErrors.type}>
                      <FormLabel component="legend">
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h5"
                        >
                          Types
                        </Typography>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="body2"
                        >
                          Please, select the type of the discount
                        </Typography>
                      </FormLabel>
                      <RadioGroup
                        aria-label="type"
                        name="type"
                        value={newDiscount.type}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="percentage"
                          control={<Radio />}
                          label="%"
                        />
                        <FormControlLabel
                          value="amount"
                          control={<Radio />}
                          label="Amount"
                        />
                      </RadioGroup>
                      <FormHelperText>{customErrors.type}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                className={isSmallScreen ? classes.smallCard : classes.card}
              >
                <Typography color="textPrimary" gutterBottom variant="h5">
                  Value
                </Typography>
                <Typography color="textSecondary" gutterBottom variant="body2">
                  Please, select the value of discount
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(customErrors.value)}
                      fullWidth
                      helperText={'Discount value'}
                      label={
                        newDiscount.type === 'percentage'
                          ? '%'
                          : newDiscount.type === 'amount'
                          ? storeInfo.storeCurrencySymbol
                          : ''
                      }
                      margin="normal"
                      name="value"
                      onChange={handleChange}
                      type="text"
                      value={newDiscount.value}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>

          <Card className={isSmallScreen ? classes.smallCard : classes.card}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControl
                  component="fieldset"
                  error={customErrors.appliesTo}
                >
                  <FormLabel component="legend">
                    <Typography color="textPrimary" gutterBottom variant="h5">
                      Applies to
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    aria-label="type"
                    name="appliesTo"
                    value={newDiscount.appliesTo}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="order"
                      control={<Radio color="primary" />}
                      label="Entire order"
                    />
                    <FormControlLabel
                      value="categories"
                      control={<Radio color="primary" />}
                      label="Specific categories"
                    />
                    <FormControlLabel
                      value="products"
                      control={<Radio color="primary" />}
                      label="Specific products"
                    />
                  </RadioGroup>
                  <FormHelperText>{customErrors.appliesTo}</FormHelperText>
                </FormControl>
              </Grid>
              {['products', 'categories'].includes(newDiscount.appliesTo) ? (
                <Grid item xs={12}>
                  <Button  size="large"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (newDiscount.appliesTo === 'products') {
                        setSelectProductsDialogOpen(true);
                      } else {
                        setSelectCategoriesDialogOpen(true);
                      }
                    }}
                  >
                    {newDiscount.eligibleCategories.length ||
                    newDiscount.eligibleProducts.length
                      ? 'Add more'
                      : 'Select'}{' '}
                    eligible {newDiscount.appliesTo} for discount
                  </Button>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          </Card>
          {newDiscount.eligibleCategories.length ||
          newDiscount.eligibleProducts.length ? (
            <Card className={isSmallScreen ? classes.smallCard : classes.card}>
              <Typography color="textPrimary" gutterBottom variant="h5">
                Selected {newDiscount.appliesTo}
              </Typography>
              <DataGrid
                autoHeight
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay
                }}
                rows={newDiscount.eligibleProducts.map((product, idx) => {
                  return {
                    title: product.title,
                    image: <Avatar src={product.images[0].url} />,
                    id: idx + 1,
                    color: product.color
                  };
                })}
                columns={columns}
                pageSize={10}
              />
            </Card>
          ) : (
            ''
          )}

          <Box mt={2}>
            <Card className={isSmallScreen ? classes.smallCard : classes.card}>
              <Typography variant="h5" color="textPrimary" gutterBottom>
                Apply Limits
              </Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Enforce limits on this discount offer
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={newDiscount.canUseInConjunction}
                    onChange={handleChange}
                    name="canUseInConjunction"
                    color="primary"
                  />
                }
                label="Can use in combination with other discount codes?"
              />
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <TextField
                    error={Boolean(customErrors.maxNumberOfUse)}
                    fullWidth
                    helperText={
                      'Set max number of use throughout the store for this discount'
                    }
                    label={'Max number of use'}
                    margin="normal"
                    name="maxNumberOfUse"
                    onChange={e => {
                      if (
                        e.target.value === '' ||
                        Number(e.target.value) !== 0
                      ) {
                        console.log(e.target.value);
                        handleChange(e);
                      } else {
                        console.log(e.target.value);
                        setNewDiscount({
                          ...newDiscount,
                          maxNumberOfUse: 1
                        });
                      }
                    }}
                    inputProps={{
                      type: 'number',
                      min: 1,
                    }}
                    type="number"
                    value={newDiscount.maxNumberOfUse}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    error={Boolean(customErrors.maxNumberOfUsePerOrder)}
                    fullWidth
                    helperText={'Set max number of use per order'}
                    label={'Max number of use'}
                    margin="normal"
                    name="maxNumberOfUsePerOrder"
                    onChange={e => {
                      if (
                        e.target.value === '' ||
                        Number(e.target.value) !== 0
                      ) {
                        handleChange(e);
                      } else {
                        setNewDiscount({
                          ...newDiscount,
                          maxNumberOfUsePerOrder: 1
                        });
                      }
                    }}
                    inputProps={{
                      type: 'number',
                      min: 1,
                    }}
                    type="number"
                    value={newDiscount.maxNumberOfUsePerOrder}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    error={Boolean(customErrors.maxNumberOfUsePerCustomer)}
                    fullWidth
                    helperText={'Set max number of use per customer'}
                    label={'Max number of use'}
                    margin="normal"
                    name="maxNumberOfUsePerCustomer"
                    onChange={e => {
                      if (
                        e.target.value === '' ||
                        Number(e.target.value) !== 0
                      ) {
                        handleChange(e);
                      } else {
                        setNewDiscount({
                          ...newDiscount,
                          maxNumberOfUsePerCustomer: 1
                        });
                      }
                    }}
                    inputProps={{
                      type: 'number',
                      min: 1,
                    }}
                    type="number"
                    value={newDiscount.maxNumberOfUsePerCustomer}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    error={Boolean(customErrors.productLimitPerUse)}
                    fullWidth
                    helperText={
                      'Set max number of products to apply this discount on (per use)'
                    }
                    label={'product limit per use'}
                    margin="normal"
                    name="productLimitPerUse"
                    onChange={e => {
                      if (
                        e.target.value === '' ||
                        Number(e.target.value) !== 0
                      ) {
                        handleChange(e);
                      } else {
                        setNewDiscount({
                          ...newDiscount,
                          productLimitPerUse: 1
                        });
                      }
                    }}
                    inputProps={{
                      type: 'number',
                      min: 1,
                    }}
                    type="number"
                    value={newDiscount.productLimitPerUse}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Card>
          </Box>
          <Box mt={2}>
            <DropzoneArea
              dropzoneText="Upload a banner for the offer (optional)"
              filesLimit={1}
              onChange={files => imageConversion(files)}
            />
          </Box>

          <Hidden xsDown>
            <Box my={5} display="flex" justifyContent="space-between">
              <Button  size="large"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={createDiscount}
              >
                Create Discount
              </Button>
              <Button  size="large"
                className={classes.error}
                style={{ marginLeft: '15px' }}
                variant="contained"
                fullWidth
                component={RouterLink}
                size="large"
                to="/app/discounts"
              >
                Cancel
              </Button>
            </Box>
          </Hidden>

          <Hidden smUp>
            <Box my={5} display="block">
              <Button  size="large"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={createDiscount}
                style={{ marginBottom: '8px' }}
              >
                Create Discount
              </Button>
              <Button  size="large"
                className={classes.error}
                variant="contained"
                fullWidth
                component={RouterLink}
                size="large"
                to="/app/discounts"
              >
                Cancel
              </Button>
            </Box>
          </Hidden>
        </form>
      );
    }
  }, [
    newDiscount,
    customErrors,
    categories,
    selectProductsDialogOpen,
    selectCategoriesDialogOpen
  ]);

  return (
    <div className={classes.formContainer}>
      {notice.message === 'Creating new discount' ? (
        <ProgressBar completed={false} action={'Creating your discount'} />
      ) : (
        ''
      )}
      {discountForm}
    </div>
  );
};

const mapStateToProps = state => ({
  notice: state.app.notice,
  storeId: state.currentStore.storeInfo._id,
  storeInfo: state.currentStore.storeInfo,
  categories: state.currentStore.categories
});

const mapDispatchToProps = dispatch => ({
  FetchStoreCategoriesStart: (storeId, sessionId) =>
    dispatch(FetchStoreCategoriesStart(storeId, sessionId)),
  setStoreInfo: storeInfo => dispatch(SetStoreInfo(storeInfo)),
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setErrors: errors => dispatch(SetErrors(errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDiscountForm);
