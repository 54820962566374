import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  FetchStoreOrdersStart,
  SetStoreInfo
} from '../../redux/current-store/current-store-actions';
import { SetErrors, SetSnackNotice } from '../../redux/app/app-actions';
import axios from 'axios';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Chip,
  Container,
  useMediaQuery
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

import CachedIcon from '@material-ui/icons/Cached';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { Link as RouterLink } from 'react-router-dom';
import Page from 'src/components/Page';
import { Pagination } from '@material-ui/lab';
import RefundRequestCard from 'src/components/refund-request-card/refund-request-card';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: 'white'
  },
  cardGrid: {
    display: 'grid',
    gridRowGap: theme.spacing(1)
  },
  refundCardContainer: {
    display: 'grid',
    gridRowGap: theme.spacing(1)
  },
  paginator: {
    justifySelf: 'center'
  }
}));

const RefundRequestListView = ({
  storeId,
  setStoreInfo,
  setSnackNotice,
  setErrors,
  storeInfo
}) => {
  const [refundRequests, setRefundRequests] = useState([]);
  const [totalRefundRequests, setTotalRefundRequests] = useState(0);
  const isSmallScreen = useMediaQuery("(max-width:1050px)");
  const [limit, setLimit] = useState(1);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const classes = useStyles();


  useEffect(() => {
    if (storeId) {
      (async () => {
        const temp = await axios({
          url: 'https://kripson-store-server-8qq76.ondigitalocean.app/refundRequests/getRefundRequests',
          method: 'POST',
          data: {
            sessionId: storeInfo.sessionId,
            storeId: storeId,
            page: page || 0,
            limit: limit,
            skip: page * limit,
          }
        });
        if (temp.data.status === 1) {
          setTotalRefundRequests(temp.data.totals);
          if (temp.data.result.length === 0) {
            setPage(0);
          }
          setRefundRequests(temp.data.result);
        } else if (temp.data.status === 3) {
          setStoreInfo({
            ownerFirstName: '',
            ownerLastName: '',
            storeEmail: '',
            storeName: '',
            storeAddress: '',
            colors: { primary: '#000000'}
          });
          setSnackNotice({
            severity: 'warning',
            message: temp.data.message
          });
        } else {
          setErrors(temp.data.errors);
        }
      })();
    }
  }, [storeId, limit, page]);

  useEffect(() => {
    if (totalRefundRequests && page >= Math.ceil(totalRefundRequests / limit)) {
      setPage(Math.ceil(totalRefundRequests / limit) - 1);
    }
  }, [totalRefundRequests]);

  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const getRefundRequestStatusIcon = status => {
    switch (status) {
      case 'pending':
        return <CachedIcon />;
      case 'declined':
        return <ClearIcon />;
      case 'approved':
        return <CheckIcon />;
      default:
        return;
    }
  };

  const getRefundRequestStatusClass = status => {
    switch (status) {
      case 'pending':
        return classes.warning;
      case 'declined':
        return classes.error;
      case 'approved':
        return classes.success;
      default:
        return;
    }
  };

  return (
    <Page className={classes.root}>
      <Container maxWidth={false}>
        <Box mb={3}>
          <Typography color="textPrimary" variant="h2">
            Refund Requets
          </Typography>
          <Typography color="textSecondary" gutterBottom variant="body2">
            List of refund requests
          </Typography>
        </Box>
        {isSmallScreen
          ?
          //if small screen show cards
          <Box className={classes.refundCardContainer}>
            <Box className={classes.cardGrid}>
              {refundRequests ? refundRequests.map((refundRequest, idx) => <RefundRequestCard refundRequest={refundRequest} key={idx} />) : ''}
            </Box>
            <Pagination
              color="primary"
              count={Math.ceil(totalRefundRequests / limit) || 1}
              className={classes.paginator}
              size="small"
              onChange={(e, epage) => {
                handlePageChange(e, epage - 1);
              }}
              variant="outlined"
              shape="rounded"
            />
          </Box>
          :
          <Card>
            <PerfectScrollbar>
              <Box minWidth={1050}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Order Id</TableCell>
                      <TableCell>Refund Request Number</TableCell>
                      <TableCell>Refund Total</TableCell>
                      <TableCell>Requested Date</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {refundRequests.map(request => (
                      <TableRow
                        hover
                        key={request._id}
                        onClick={() =>
                          navigate(`/app/refundRequests/${request._id}`)
                        }
                      >
                        <TableCell>
                          <Box alignItems="center" display="flex">
                            <Avatar
                              className={classes.avatar}
                              src={request.avatarUrl}
                            ></Avatar>
                            <Typography color="textPrimary" variant="body1">
                              {request.orderId}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>{request.refundRequestNumber}</TableCell>
                        <TableCell>
                          {storeInfo.storeCurrencySymbol} {request.refundTotal}
                        </TableCell>
                        <TableCell>
                          {new Date(request.createdDate).toLocaleDateString()}
                        </TableCell>
                        <TableCell>
                          <Chip
                            className={getRefundRequestStatusClass(
                              request.status
                            )}
                            icon={getRefundRequestStatusIcon(request.status)}
                            label={request.status}
                          />
                        </TableCell>
                        <TableCell>
                          <RouterLink to="/">
                            <Button  size="large" variant="contained" color="primary">
                              Details
                            </Button>
                          </RouterLink>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
            <TablePagination
              component="div"
              count={totalRefundRequests}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[1, 5, 10, 25]}
            />
          </Card>
        }
      </Container>
    </Page>
  );
};

const mapStateToProps = state => ({
  storeId: state.currentStore.storeInfo._id,
  storeInfo: state.currentStore.storeInfo
});

const mapDispatchToProps = dispatch => ({
  FetchStoreOrdersStart: requiredInfo =>
    dispatch(FetchStoreOrdersStart(requiredInfo)),
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setErrors: validationErrors => dispatch(SetErrors(validationErrors)),
  setStoreInfo: store => dispatch(SetStoreInfo(store))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RefundRequestListView);
