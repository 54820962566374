const countryPaymentMethodMap = {
    STRIPE: {
        logo: "stripe_logo.svg",
        name: "stripe",
        availableCountries: ['AUD'],
        controls: [
            {
                name: 'publishableKey',
                type: 'text',
                helperText: 'Please, provide your stripe publishable key',
            },
            {
                name: 'secretKey',
                type: 'text',
                helperText: 'Please, provide your stripe secret key',
            }
        ]
    },
    ESEWA: {
        logo: "esewa_logo.png",
        name: "esewa",
        availableCountries: ['AUD','NPR'],
        controls: [
            {
                name: 'merchartId',
                type: 'text',
                helperText: 'Please, provide your merchant Id provided by esewa to you',
            }
        ]
    }

};


export default countryPaymentMethodMap;