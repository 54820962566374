import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  CardHeader,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button
} from '@material-ui/core';
import * as FA from 'react-fontawesome';
import { SetErrors, SetSnackNotice } from 'src/redux/app/app-actions';
import {
  FetchStoreNotificationsStart,
  FetchStoreordersStart
} from 'src/redux/current-store/current-store-actions';
import CachedIcon from '@material-ui/icons/Cached';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import momentTimeZone from 'moment-timezone';
import './order-product-card.scss';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SendIcon from '@material-ui/icons/Send';
import Decimal from 'decimal.js-light';

function OrderProductCard({
  storeId,
  storeInfo,
  product,
  productImage,
  ...rest
}) {
  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      marginBottom: theme.spacing(2)
    },
    warning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText
    },
    error: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText
    },
    success: {
      backgroundColor: theme.palette.success.main,
      color: 'white'
    }
  }));

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [discountApplied, setDiscountApplied] = React.useState(
    new Decimal('0')
  );

  useEffect(() => {
    let temp = new Decimal('0');
    console.log(product.discountData);
    if (product.discountData && Object.keys(product.discountData).length) {
      for (const key in product.discountData) {
        temp = temp.plus(product.discountData[key]['discountAmountApplied']);
      }
    }

    setDiscountApplied(temp);
  }, [product]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getOrderStatusIcon = status => {
    switch (status) {
      case 'processing':
        return <CachedIcon />;
      case 'cancelled':
      case 'refunded':
        return <ClearIcon />;
      case 'delivered':
        return <CheckIcon />;
      default:
        return;
    }
  };

  const getOrderStatusClass = status => {
    switch (status) {
      case 'processing':
        return classes.warning;
      case 'cancelled':
      case 'refunded':
        return classes.error;
      case 'delivered':
        return classes.success;
      default:
        return;
    }
  };

  return (
    <Card className={`${classes.root} orderProductCard`} {...rest}>
      <div
        className="productImageSection"
        style={{ backgroundImage: `url(${productImage})` }}
      ></div>
      <div className="productDetailSection">
        <Typography variant="h5">{product.title}</Typography>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="body2">
              <strong>Qty :</strong> {product.quantity}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              <strong>Size :</strong> {product.selectedSize}
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="h5">
          {storeInfo.storeCurrencySymbol + ` `}
          {discountApplied.toString() !== '0' ? (
            <>
              {storeInfo.storeCurrencySymbol}{' '}
              <del>
                {new Decimal(product.price).times(product.quantity).toString()}
              </del>
              <ins style={{ color: 'red' }}>
                {new Decimal(product.price)
                  .times(product.quantity)
                  .sub(discountApplied)
                  .toString()}
              </ins>
            </>
          ) : (
            new Decimal(product.price)
              .times(product.quantity)
              .sub(discountApplied)
              .toString()
          )}
        </Typography>
      </div>
    </Card>
  );
}

const mapStateToProps = state => ({
  storeId: state.currentStore.storeInfo._id,
  storeInfo: state.currentStore.storeInfo,
  notifications: state.currentStore.notifications
});

const mapDispatchToProps = dispatch => ({
  FetchStoreNotificationsStart: (storeId, sessionId) =>
    dispatch(FetchStoreNotificationsStart(storeId, sessionId)),
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setErrors: errors => dispatch(SetErrors(errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderProductCard);
