import React from 'react';
import { makeStyles, CardHeader, Card, CardContent, Avatar, Divider, Typography, Box } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const CustomerCard = ({ customer }) => {

    const useStyles = makeStyles(theme => ({
        detailsBox: {
            display: 'grid',
            gridRowGap: theme.spacing(1)

        },
        avatar: {
            width: '80px',
            height: '80px',
            marginRight: theme.spacing(2)
        },
        icons: {
            marginRight: theme.spacing(1)
        }
    }));

    const classes = useStyles();

    return <Card>

        <CardContent className={classes.cardContent}>
            <Box display={'flex'} alignItems='center'>
                <Box>
                    <Box display={'flex'} alignItems='center' justifyContent='center'>
                        <Avatar src={customer.profilePicture && customer.profilePicture.url ? customer.profilePicture.url : ''} className={classes.avatar} />
                    </Box>
                </Box>
                <Box className={classes.detailsBox}>
                    <Box display={'flex'} alignItems='center'>
                        <AccountCircleIcon className={classes.icons} /><Typography variant='h6'>{customer.name}</Typography>
                    </Box>
                    <Box display={'flex'} alignItems='center'>
                        <HomeIcon className={classes.icons} /><Typography variant='h6'>{customer.address}</Typography>
                    </Box>
                    <Box display={'flex'} alignItems='center'>
                        <AlternateEmailIcon className={classes.icons} /><Typography variant='h6'>{customer.email}</Typography>
                    </Box>
                    <Box display={'flex'} alignItems='center'>
                        <PhoneIphoneIcon className={classes.icons} /><Typography variant='h6'>{customer.phone}</Typography>
                    </Box>
                </Box>

            </Box>



        </CardContent>
    </Card>
}

export default CustomerCard;