import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, makeStyles, Avatar } from '@material-ui/core';
import { Pagination, Skeleton } from '@material-ui/lab';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import ProductCard from './ProductCard';
import {
  FetchStoreProductsStart,
  SetStoreInfo
} from '../../../redux/current-store/current-store-actions';
import { SetErrors, SetSnackNotice } from '../../../redux/app/app-actions';
import { connect } from 'react-redux';
import { Link, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  },
  container: {
    backgroundColor: 'white'
  }
}));

const ProductList = ({
  setSnackNotice,
  setStoreInfo,
  setErrors,
  storeInfo,
  storeId,
  categories,
  FetchStoreProductsStart
}) => {
  const classes = useStyles();
  const location = useLocation();
  const [products, setProducts] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [searchFilters, setSearchFilters] = useState({});
  const [mode, setMode] = useState('card');
  const [fetchingProducts, setFetchingProducts] = useState(true);

  const [totalProducts, setTotalProducts] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    if (totalProducts && page >= Math.ceil(totalProducts / rowsPerPage)) {
      console.log(Math.ceil(totalProducts / rowsPerPage));
      setPage(Math.ceil(totalProducts / rowsPerPage) - 1);
    }
  }, [totalProducts]);

  let { category } = useParams();
  const navigate = useNavigate();

  const handleSearchFilterChange = filter => {
    setSearchFilters({
      ...filter
    });
  };

  const columns = [
    { id: 'images', label: 'Product', minWidth: 20 },
    { id: 'title', label: 'Title', minWidth: 100 },
    {
      id: 'parentCategory',
      label: 'Category',
      minWidth: 50,
      align: 'center',
      format: value => value.toLocaleString('en-IN')
    },
    {
      id: 'brand',
      label: 'Brand',
      minWidth: 50,
      align: 'center',
      format: value => value.toFixed(2)
    },
    {
      id: 'price',
      label: 'Price',
      minWidth: 100,
      align: 'left',
      format: value => value.toLocaleString('en-IN')
    }
  ];

  const handleChangePage = (event, newPage) => {
    console.log(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const filter = {};
    if (category) {
      filter.parentCategory = category;
    }

    if (keyword) {
      filter.$or = [
        { title: { $regex: keyword, $options: 'ix' } },
        { brand: { $regex: keyword, $options: 'ix' } }
      ];
    }

    filter.minPrice = searchFilters.minPrice || '0';
    filter.maxPrice = searchFilters.maxPrice || '999999999999999';

    if (searchFilters.status) {
      filter.status = searchFilters.status;
    }

    setFetchingProducts(true);

    axios({
      url: 'https://kripson-store-server-8qq76.ondigitalocean.app/products/getProducts',
      method: 'POST',
      onUploadProgress: progressEvent => console.log(progressEvent),
      data: {
        sessionId: storeInfo.sessionId,
        storeId: storeId,
        filter: filter,
        limit: rowsPerPage,
        skip: page * rowsPerPage
      }
    }).then(response => {
      if (response.data.status === 1) {
        setTotalProducts(response.data.totals);
        if (response.data.result.length === 0) {
          setPage(0);
        }
        setProducts([...response.data.result]);
      } else if (response.data.status === 3) {
        setStoreInfo({
          ownerFirstName: '',
          ownerLastName: '',
          storeEmail: '',
          storeName: '',
          storeAddress: '',
          colors: { primary: '#000000' }
        });
        setSnackNotice({
          severity: 'warning',
          message: response.data.message
        });
      } else {
        setErrors(response.data.errors);
      }
      setFetchingProducts(false);
    });
  }, [
    categories,
    location.pathname,
    keyword,
    searchFilters,
    page,
    rowsPerPage
  ]);

  return (
    <Page className={classes.root} title="Products">
      <Container maxWidth={false}>
        <Toolbar
          setKeyword={setKeyword}
          setMode={setMode}
          handleSearchFilterChange={handleSearchFilterChange}
        />
        {mode === 'card' ? (
          <Box mt={3}>
            <Grid container spacing={3}>
              {!fetchingProducts &&
                products.map(product => (
                  <Grid item key={product.id} lg={4} md={6} xs={12}>
                    <Link
                      to={
                        location
                          ? location.pathname.includes(`stores`)
                            ? `/stores/${storeId}/products/${category}/${product._id}`
                            : `/app/products/${category}/${product._id}`
                          : `/app/products/${category}/${product._id}`
                      }
                    >
                      <ProductCard
                        className={classes.productCard}
                        product={product}
                      />
                    </Link>
                  </Grid>
                ))}
              {fetchingProducts &&
                [1, 2, 3, 4].map(num => (
                  <Grid item key={num} lg={4} md={6} xs={12}>
                    <Skeleton
                      component="div"
                      height={350}
                      style={{ transform: 'unset' }}
                    />
                  </Grid>
                ))}
            </Grid>

            <Box mt={3} display="flex" justifyContent="center">
              <Pagination
                color="primary"
                count={Math.ceil(totalProducts / rowsPerPage) || 1}
                size="small"
                onChange={(e, epage) => {
                  handleChangePage(e, epage - 1);
                }}
                variant="outlined"
                shape="rounded"
              />
            </Box>
          </Box>
        ) : (
          <Box mt={3}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map(column => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products && products.length
                    ? products.map((product, idx) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={product._id}
                            onClick={() =>
                              navigate(
                                location
                                  ? location.pathname.includes(`stores`)
                                    ? `/stores/${storeId}/products/${category}/${product._id}`
                                    : `/app/products/${category}/${product._id}`
                                  : `/app/products/${category}/${product._id}`
                              )
                            }
                          >
                            {columns.map(column => {
                              const value =
                                column.id === 'images' ? (
                                  product.images && product.images.length ? (
                                    <Avatar src={product.images[0].url} />
                                  ) : (
                                    ''
                                  )
                                ) : column.id === 'parentCategory' ? (
                                  categories.find(
                                    category =>
                                      category._id === product[column.id]
                                  )?.categoryName
                                ) : (
                                  product[column.id]
                                );

                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === 'number'
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })
                    : ''}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[1, 5, 10, 15, 20, 25, 50, 100]}
                component="div"
                count={totalProducts}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Box>
        )}
      </Container>
    </Page>
  );
};

const mapStateToProps = state => ({
  storeInfo: state.currentStore.storeInfo,
  storeId: state.currentStore.storeInfo._id,
  categories: state.currentStore.categories
});

const mapDispatchToProps = dispatch => ({
  FetchStoreProductsStart: requiredInfo =>
    dispatch(FetchStoreProductsStart(requiredInfo)),
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setStoreInfo: store => dispatch(SetStoreInfo(store)),
  setErrors: errors => dispatch(SetErrors(errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
