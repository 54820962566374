import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles,
  useMediaQuery
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import {
  FetchStoreMembersStart,
} from "../../../redux/current-store/current-store-actions";
import { SetSnackNotice } from "../../../redux/app/app-actions";
import { connect } from "react-redux";
import { Link, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import CustomerCard from 'src/components/customer-card/customer-card';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CustomerListView = ({ FetchStoreMembersStart, setSnackNotice, storeInfo, storeId, members }) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery('(max-width:1000px)');


  useEffect(() => {
    FetchStoreMembersStart(storeId, storeInfo.sessionId);
  }, [storeId])

  return (
    <Page
      className={classes.root}
      title="Customers"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          {isSmallScreen ? members ? members.map(cust => <CustomerCard customer={cust} />) : '' :   <Results customers={members} />}
        
        </Box>
      </Container>
    </Page>
  );
};

const mapStateToProps = state =>
({
  storeId: state.currentStore.storeInfo._id,
  members: state.currentStore.members,
  storeInfo: state.currentStore.storeInfo
});

const mapDispatchToProps = dispatch => (
  {
    FetchStoreMembersStart: (requiredInfo, sessionId) => dispatch(FetchStoreMembersStart(requiredInfo, sessionId)),
    setSnackNotice: (notice) => dispatch(SetSnackNotice(notice))
  });

export default connect(mapStateToProps, mapDispatchToProps)(CustomerListView);
