import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Card,
  Chip,
  Container,
  Divider,
  Grid,
  Hidden,
  makeStyles
} from '@material-ui/core';

import Page from 'src/components/Page';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import AwesomeSliderStyles from 'react-awesome-slider/src/styled/fold-out-animation/fold-out-animation.scss';
import '../../../customStyles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import {
  FetchStoreOrdersStart,
  SetStoreInfo
} from '../../../redux/current-store/current-store-actions';
import { SetErrors, SetSnackNotice } from '../../../redux/app/app-actions';
import { connect } from 'react-redux';
import axios from 'axios';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import * as Swal from 'sweetalert2';
import EditOrderForm from './edit-order-form';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import CachedIcon from '@material-ui/icons/Cached';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import momentTimeZone from 'moment-timezone';
import RefundForm from './refund-form';
import { AddCircleOutline } from '@material-ui/icons';
import OrderProductCard from 'src/components/order-product-card/order-product-card';
import Decimal from 'decimal.js-light';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    padding: theme.spacing(3),
    width: '100%'
  },
  orderDetailsSection: {
    padding: '20px !important',
    display: 'grid',
    alignContent: 'start',
    gridRowGap: '20px'
  },
  orderColor: {
    height: '25px',
    width: '25px',
    borderRadius: '25px'
  },
  sizeOptionContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 50px)',
    gridColumnGap: '10px'
  },
  sizeChoice: {
    backgroundColor: 'white',
    display: 'grid',
    justifyContent: 'center'
  },
  statusSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  detailsSectionRows: {},
  container: {
    maxHeight: 440
  },
  customerDetailsSection: {
    marginBottom: '16px'
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    marginRight: theme.spacing(1)
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    marginRight: theme.spacing(1)
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: 'white',
    marginRight: theme.spacing(1)
  },
  buttonContainer: {
    marginTop: theme.spacing(2)
  }
}));

const OrderDetailsView = ({
  storeId,
  storeInfo,
  setStoreInfo,
  setSnackNotice,
  setErrors
}) => {
  const classes = useStyles();

  const { orderId } = useParams();
  const navigate = useNavigate();

  const [order, setOrder] = useState({});
  const [mode, setMode] = useState('detailMode');
  const [productImages, setProductImages] = useState({});

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const retrieveOrder = (orderId, storeId) => {
    axios({
      url: 'https://kripson-store-server-8qq76.ondigitalocean.app/orders/getOrderById',
      method: 'POST',
      data: {
        sessionId: storeInfo.sessionId,
        storeId: storeId,
        orderId: orderId
      }
    }).then(response => {
      if (response.data.status === 1 && response.data.result.length) {
        setOrder(response.data.result[0]);
      } else if (response.data.status === 3) {
        setStoreInfo({
          ownerFirstName: '',
          ownerLastName: '',
          storeEmail: '',
          storeName: '',
          storeAddress: '',
          colors: {primary: '#000000'}
        });
        setSnackNotice({
          severity: 'warning',
          message: response.data.message
        });
      } else {
        setErrors(response.data.errors);
      }
    });
  };

  const retrieveOrderProductImages = (productIds, storeId) => {
    axios({
      url: 'https://kripson-store-server-8qq76.ondigitalocean.app/products/getProductImagesById',
      method: 'POST',
      data: {
        sessionId: storeInfo.sessionId,
        storeId: storeId,
        productIds: productIds
      }
    }).then(response => {
      if (response.data.status === 1) {
        const productImagesMap = {};
        response.data.result.map(product => {
          productImagesMap[product._id] = product.images[0].url;
          return;
        });
        setProductImages(productImagesMap);
      } else if (response.data.status === 3) {
        setStoreInfo({
          ownerFirstName: '',
          ownerLastName: '',
          storeEmail: '',
          storeName: '',
          storeAddress: '',
          colors: {primary: '#000000'}
        });
        setSnackNotice({
          severity: 'warning',
          message: response.data.message
        });
      } else {
        setErrors(response.data.errors);
      }
    });
  };

  useEffect(() => {
    if (orderId && storeId) {
      retrieveOrder(orderId, storeId);
    }
  }, [orderId, storeId]);

  useEffect(() => {
    if (order && order.products && order.products.length) {
      retrieveOrderProductImages(
        order.products.map(product => product._id),
        storeId
      );
    }
  }, [order]);

  useEffect(() => {
    if (mode === 'detailMode' && orderId && storeId) {
      retrieveOrder(orderId, storeId);
    }
  }, [mode]);

  const deleteOrder = async orderId => {
    setSnackNotice({
      message: `Deleting order`,
      severity: 'info'
    });

    const response = await axios({
      url: 'https://kripson-store-server-8qq76.ondigitalocean.app/orders/removeOrder',
      method: 'POST',
      onUploadProgress: progressEvent => console.log(progressEvent),
      data: {
        sessionId: storeInfo.sessionId,
        orderId,
        storeId
      }
    });

    if (response.data.status === 1) {
      navigate(`/app/orders`);

      setSnackNotice({
        message: `Order deleted`,
        severity: 'success'
      });
    } else if (response.data.status === 3) {
      setStoreInfo({
        ownerFirstName: '',
        ownerLastName: '',
        storeEmail: '',
        storeName: '',
        storeAddress: '',
        colors: {primary: '#000000'}
      });
      setSnackNotice({
        severity: 'warning',
        message: response.data.message
      });
    } else {
      setErrors(response.data.errors);
    }
  };

  const fireDeleteConfirmation = orderId => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.isConfirmed) {
        deleteOrder(orderId);

        // await Swal.fire(
        //   'Deleted!',
        //   'Your file has been deleted.',
        //   'success'
        // )
      }
    });
  };

  const getOrderStatusIcon = status => {
    switch (status) {
      case 'processing':
        return <CachedIcon />;
      case 'cancelled':
      case 'refunded':
        return <HighlightOffIcon />;
      case 'delivered':
        return <CheckCircleOutlineIcon />;
      default:
        return;
    }
  };

  const getOrderStatusClass = status => {
    switch (status) {
      case 'processing':
        return classes.warning;
      case 'cancelled':
      case 'refunded':
        return classes.error;
      case 'delivered':
        return classes.success;
      default:
        return;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = [
    { id: 'productImage', label: 'Product', minWidth: 100 },
    { id: 'title', label: 'Title', minWidth: 100 },
    {
      id: 'quantity',
      label: 'Quantity',
      minWidth: 50,
      align: 'center',
      format: value => value.toLocaleString('en-IN')
    },
    {
      id: 'selectedSize',
      label: 'Size',
      minWidth: 50,
      align: 'center',
      format: value => value.toFixed(2)
    },
    {
      id: 'price',
      label: 'Price',
      minWidth: 100,
      align: 'left',
      format: value => value.toLocaleString('en-IN')
    },
    {
      id: 'removeButton',
      label: '',
      minWidth: 25,
      align: 'left',
      format: value => value.toFixed(2)
    }
  ];

  return mode === 'editMode' ? (
    order ? (
      <EditOrderForm
        order={order}
        setMode={setMode}
        productImages={productImages}
      />
    ) : (
      ''
    )
  ) : mode === 'refundMode' ? (
    order ? (
      <RefundForm
        order={order}
        setMode={setMode}
        productImages={productImages}
      />
    ) : (
      ''
    )
  ) : (
    <Page className={classes.root} title="Order Details">
      <Box mb={2} className={classes.statusSection}>
        <Box
          display="flex"
          alignItems="center"
          style={{ flexWrap: 'wrap', marginRight: '5px', marginBottom: '5px' }}
        >
          <Typography variant="h5">Created Date : </Typography>

          <Typography variant="h5">
            {momentTimeZone(order.createdDate)
              .tz('Asia/Kathmandu')
              .format('DD/MM/YYYY')}
          </Typography>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          style={{ marginBottom: '5px', flexWrap: 'wrap' }}
        >
          <Box display="flex" alignItems="center" style={{ flexWrap: 'wrap' }}>
            <Chip
              className={getOrderStatusClass(order.status)}
              icon={getOrderStatusIcon(order.status)}
              label={order.status}
              style={{ marginBottom: '2px' }}
            />
            {order && order.tags
              ? order.tags.map(tag => (
                  <Chip
                    style={{ marginLeft: '0' }}
                    className={classes.error}
                    label={tag}
                    style={{ marginBottom: '2px' }}
                  ></Chip>
                ))
              : ''}
          </Box>
        </Box>
      </Box>

      <Paper className={classes.customerDetailsSection}>
        <Box p={2}>
          <Typography fullWidth variant="h3" mb={2}>
            Customer Details
          </Typography>
        </Box>

        <Divider />
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Typography variant="h5">Name</Typography>
              <Typography variant="body2">
                {order && Object.keys(order).length ? order.customer.name : ''}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h5">Contact Details</Typography>
              <Typography variant="body2">
                {order && Object.keys(order).length
                  ? order.customer.phonenumber
                  : ''}
              </Typography>
              <Typography variant="body2">
                {order && Object.keys(order).length ? order.customer.email : ''}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h5">Shipping Address</Typography>
              <Typography variant="body2">
                {order && Object.keys(order).length
                  ? order.customer.address
                  : ''}
              </Typography>
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </Box>
      </Paper>
      {order && order.tags && order.tags.includes('fully refunded') ? (
        <>
          <Hidden xsDown>
            <Box my={2} display="flex" justifyContent="flex-end">
              <Button  size="large"
                variant="contained"
                color="primary"
                onClick={() => setMode('refundMode')}
              >
                See refund history
              </Button>
            </Box>
          </Hidden>

          <Hidden smUp>
            <Box my={2} display="block">
              <Button  size="large"
                variant="contained"
                color="primary"
                onClick={() => setMode('refundMode')}
                fullWidth
              >
                See refund history
              </Button>
            </Box>
          </Hidden>
        </>
      ) : (
        <>
          <Hidden xsDown>
            <Box my={2} display="flex" justifyContent="flex-end">
              <Button  size="large"
                startIcon={<AddCircleOutline />}
                variant="contained"
                color="primary"
                onClick={() => setMode('refundMode')}
              >
                Create Refund
              </Button>
            </Box>
          </Hidden>

          <Hidden smUp>
            <Box my={2} display="block">
              <Button  size="large"
                startIcon={<AddCircleOutline />}
                variant="contained"
                color="primary"
                onClick={() => setMode('refundMode')}
                fullWidth
              >
                Create Refund
              </Button>
            </Box>
          </Hidden>
        </>
      )}
      <Box display={{ xs: 'none', md: 'block' }}>
        <Paper>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {order && order.products
                  ? order.products
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((product, idx) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={product._id + product.selectedSize}
                          >
                            {columns.map(column => {
                              let value = '';
                              const discountData = product.discountData;
                              let discountApplied = new Decimal('0');
                              if (discountData && Object.keys(discountData)) {
                                for (const key in discountData) {
                                  discountApplied = discountApplied.plus(
                                    discountData[key]['discountAmountApplied']
                                  );
                                }
                              }

                              switch (column.id) {
                                case 'productImage':
                                  value = Object.keys(productImages).length ? (
                                    <Avatar src={productImages[product._id]} />
                                  ) : (
                                    ''
                                  );
                                  break;
                                case 'price':
                                  value =
                                    discountApplied.toString() !== '0' ? (
                                      <>
                                        {storeInfo.storeCurrencySymbol} <del>
                                          {new Decimal(product.price)
                                            .times(product.quantity)
                                            .toString()}
                                        </del>
                                        <ins style={{color:'red'}}>
                                          {new Decimal(product.price)
                                            .times(product.quantity)
                                            .sub(discountApplied)
                                            .toString()}
                                        </ins>
                                      </>
                                    ) : (
                                      new Decimal(product.price)
                                        .times(product.quantity)
                                        .sub(discountApplied)
                                        .toString()
                                    );
                                  break;
                                default:
                                  value = product[column.id];
                              }

                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === 'number'
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })
                  : ''}
                <TableRow>
                  <TableCell>
                    <Typography variant="h4">Order Total</Typography>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <Typography variant="h4">
                      {storeInfo.storeCurrencySymbol} {order ? order.total : ''}
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={order && order.products ? order.products.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <Box display={{ xs: 'block', md: 'none' }} mt={5}>
        <Typography variant="h3" style={{ marginBottom: '16px' }}>
          Ordered Products
        </Typography>
        {order && order.products
          ? order.products.map((product, idx) => (
              <OrderProductCard
                product={product}
                productImage={
                  product && productImages ? productImages[product._id] : ''
                }
              />
            ))
          : ''}
      </Box>
      <Hidden xsDown>
        <Box my={2} display="flex" justifyContent="flex-end">
          <Button  size="large"
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            onClick={() => setMode('editMode')}
            style={{ marginRight: '8px' }}
          >
            Edit
          </Button>
          <Button  size="large"
            className={classes.error}
            variant="contained"
            startIcon={<DeleteIcon />}
            onClick={() => fireDeleteConfirmation(orderId)}
          >
            Delete
          </Button>
        </Box>
      </Hidden>

      <Hidden smUp>
        <Box my={2} display="block">
          <Button  size="large"
            fullWidth
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            onClick={() => setMode('editMode')}
            style={{ marginBottom: '8px' }}
          >
            Edit
          </Button>
          <Button  size="large"
            fullWidth
            className={classes.error}
            style={{ marginLeft: '0' }}
            variant="contained"
            startIcon={<DeleteIcon />}
            onClick={() => fireDeleteConfirmation(orderId)}
          >
            Delete
          </Button>
        </Box>
      </Hidden>
    </Page>
  );
};

const mapStateToProps = state => ({
  storeId: state.currentStore.storeInfo._id,
  storeInfo: state.currentStore.storeInfo
});

const mapDispatchToProps = dispatch => ({
  FetchStoreOrdersStart: requiredInfo =>
    dispatch(FetchStoreOrdersStart(requiredInfo)),
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setErrors: errors => dispatch(SetErrors(errors)),
  setStoreInfo: storeInfo => dispatch(SetStoreInfo(storeInfo))
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailsView);
