import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  Typography,
  Slider,
  SvgIcon,
  makeStyles,
  MenuItem,
  Grid,
  Hidden
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Search as SearchIcon } from 'react-feather';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  FetchStoreProductsStart,
  SetStoreInfo
} from 'src/redux/current-store/current-store-actions';
import { SetErrors, SetSnackNotice } from 'src/redux/app/app-actions';
import FilterListIcon from '@material-ui/icons/FilterList';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import DialogComponent from 'src/components/dialog-component/dialog-component';
import { NavLink as RouterLink } from 'react-router-dom';
import DialogTemplateComponent from 'src/components/dialog-template-component/dialog-template-component';
import InvoiceFilter from './InvoiceFilter';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  bigProductSearchBox: {
    marginRight: theme.spacing(1)
  },
  smallProductSearchBox: {
    marginBottom: theme.spacing(1)
  },
  filterTextField: {
    marginRight: theme.spacing(1),
    '& input': {
      padding: '5px',
      backgroundColor: 'white'
    }
  },
  topButtonBox: {
    flexWrap: 'wrap'
  }
}));

const Toolbar = ({
  className,
  setKeyword,
  storeInfo,
  setMode,
  searchFilters,
  handleSearchFilterChange,
  ...rest
}) => {
  const classes = useStyles();
  const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const [dialogContent, setDialogContent] = useState({});
  const [filterDialogOpen, setFilterDialogOpen] = React.useState(false);

  useEffect(() => {
    console.log(filterDialogOpen);
    setDialogContent(
     
    );
  }, [filterDialogOpen]);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Hidden xsDown>
        <Box
          display="flex"
          justifyContent="space-between"
          className={classes.topButtonBox}
        >
          <Button  size="large"
            color="primary"
            variant="contained"
            startIcon={<FilterListIcon />}
            onClick={() => {
              setFilterDialogOpen(true);
            }}
          >
            Apply filters
          </Button>
          <Link to="/app/invoices/designer">
            <Button  size="large"
              color="primary"
              variant="contained"
              startIcon={<EditIcon />}
            >
              Invoice Designer
            </Button>
          </Link>
        </Box>
      </Hidden>

      <Hidden smUp>
        <Box
          display="flex"
          justifyContent="stretch"
          className={classes.topButtonBox}
        >
          <Button  size="large"
            color="primary"
            variant="contained"
            startIcon={<FilterListIcon />}
            onClick={() => { 
              setFilterDialogOpen(true);
            }}
            style={{ marginBottom: '10px' }}
            fullWidth
          >
            Apply filters
          </Button>

          <Button  size="large"
            color="primary"
            variant="contained"
            fullWidth
            startIcon={<AddCircleOutlineIcon />}
            component={RouterLink}
            to="/app/discounts/designer"
          >
            Invoice Designer
          </Button>
        </Box>
      </Hidden>

      <DialogTemplateComponent
        dialogContent={ <InvoiceFilter handleSearchFilterChange={handleSearchFilterChange}  searchFilters={searchFilters}/>}
        open={filterDialogOpen}
        onClose={() => setFilterDialogOpen(false)}
       
      />
      <Box></Box>
      <Box mt={2}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12} sm={8}>
                <Box
                  maxWidth={597}
                  className={
                    matches
                      ? classes.bigProductSearchBox
                      : classes.smallProductSearchBox
                  }
                >
                  <TextField
                    fullWidth
                    onChange={e => setKeyword(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Search Invoice"
                    variant="outlined"
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={4} alignItems="flex-end">
                <TextField
                  select
                  label="Select View Mode"
                  onChange={e => setMode(e.target.value)}
                  variant="outlined"
                  fullWidth
                >
                  <MenuItem value="card">Card</MenuItem>
                  <MenuItem value="table">Table</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => ({
  storeId: state.currentStore.storeInfo._id,
  storeInfo: state.currentStore.storeInfo
});

const mapDispatchToProps = dispatch => ({
  FetchStoreProductsStart: requiredInfo =>
    dispatch(FetchStoreProductsStart(requiredInfo)),
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setErrors: errors => dispatch(SetErrors(errors)),
  setStoreInfo: store => dispatch(SetStoreInfo(store))
});

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
