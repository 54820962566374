import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
// import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Logo from 'src/components/Logo/Logo';
import NotificationMenu from 'src/components/notification-menu/notification-menu';
import { SetStoreInfo } from 'src/redux/current-store/current-store-actions';
import { SetErrors, SetSnackNotice } from 'src/redux/app/app-actions';
import { connect } from 'react-redux';
import axios from 'axios';
import UIfx from 'uifx';
import notificationAudio from '../../assets/audio/notification.wav';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  }
}));

const TopBar = ({
  className,
  setStoreInfo,
  setErrors,
  storeInfo,
  setSnackNotice,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const [notificationsNumber, setNotificationsNumber] = useState(0);
  const notificationAudioSound = new UIfx(notificationAudio, {
    volume: 0.4, // number between 0.0 ~ 1.0
    throttleMs: 100
  });

  useEffect(() => {
    getUnviewedNotificationsNumber();
  }, []);

  const logout = async () => {
    const response = await axios({
      url: 'https://kripson-store-server-8qq76.ondigitalocean.app/customers/logout',
      method: 'POST',
      data: {
        sessionId: storeInfo.sessionId
      }
    });

    if (response.data.status === 1) {
      setStoreInfo({
        ownerFirstName: '',
        ownerLastName: '',
        storeEmail: '',
        storeName: '',
        storeAddress: '',
        colors: {primary: '#000000'}
      });
      setSnackNotice({
        severity: 'success',
        message: 'Logged out successfully.'
      });
    } else {
      setErrors(response.data.errors);
    }
  };

  const getUnviewedNotificationsNumber = async () => {
    const response = await axios({
      url: 'https://kripson-store-server-8qq76.ondigitalocean.app/notifications/getUnviewedNotificationsNumber',
      method: 'POST',
      data: {
        sessionId: storeInfo.sessionId,
        storeId: storeInfo._id
      }
    });

    if (response.data.status === 1) {
      if (response.data.result) {

        notificationAudioSound.play();
        setSnackNotice({
          severity: 'info',
          message: 'You have got new notifications.'
        })
      }
      setNotificationsNumber(response.data.result);
    } else if(response.data.status === 3) {
      setSnackNotice({
        severity: 'warning',
        message: response.data.message
      });
    }
    else
    {
      setErrors(response.data.errors);
    }
  };

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden mdDown>
          <IconButton color="inherit">
            <Badge badgeContent={notificationsNumber} color="error">
              <NotificationMenu
                setNotificationsNumber={setNotificationsNumber}
                notificationsNumber={notificationsNumber}
              />
            </Badge>
          </IconButton>
          <IconButton color="inherit" onClick={logout}>
            <ExitToAppIcon  />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit">
            <Badge badgeContent={notificationsNumber} color="error">
              <NotificationMenu
                setNotificationsNumber={setNotificationsNumber}
                notificationsNumber={notificationsNumber}
              />
            </Badge>
          </IconButton>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
  setStoreInfo: storeInfo => dispatch(SetStoreInfo(storeInfo)),
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setErrors: errors => dispatch(SetErrors(errors))
});

const mapStateToProps = state => ({
  storeInfo: state.currentStore.storeInfo
});

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
