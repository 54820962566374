import axios from 'axios';
import cryptoRandomString from 'crypto-random-string';
import * as _ from 'lodash';

export default async function generateUniqueToken(sessionId, collection, attribute, storeId, tokenLength, tokenType){
    while(true){
        const newToken = cryptoRandomString({length: tokenLength, type: tokenType});

        const result = await axios({
            url:`https://kripson-store-server-8qq76.ondigitalocean.app/${collection}/get${_.capitalize(collection)}`,
            method:'POST',
            data:{
                sessionId,
                storeId,
                filter:{
                    [attribute]: newToken
                }
            }
        })
        console.log(result);

        const isUnique = !result.data.result.length;

        if(isUnique) {
            return newToken;
        }
    }
}