import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {
  Avatar,
  Box,
  Button,
  Input,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {connect} from 'react-redux';
import imageCompression from "browser-image-compression";
import axios from 'axios';
import {DropzoneArea} from "material-ui-dropzone";

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith',
  timezone: 'GTM-7'
};

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  }
}));

const Profile = ({ className, storeInfo, setNewStoreBanner, ...rest }) => {
  const classes = useStyles();


  const imageConversion = async (files) => {
    const compressedFileArray = [];
    for (let file of files) {

      const options = {
        maxSizeMB: 0.4,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      };
      try {
        const compressedFile = await imageCompression(file, options);
        compressedFileArray.push(compressedFile);

      } catch (error) {
        console.log(error);
      }
    }


    const base64dataarray = [];
    let counter = base64dataarray.length;

    compressedFileArray.forEach((file, idx) => {
      let reader = new FileReader();
      reader.onloadend = function () {
        base64dataarray[counter].base64data = reader.result;
        if (idx === files.length - 1) {
          setNewStoreBanner(base64dataarray[0])
        }

        counter++;


      };
      reader.readAsDataURL(file);
      base64dataarray.push({type: file.type});
    })
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Avatar
            className={classes.avatar}
            src={storeInfo && storeInfo.storeBanner? storeInfo.storeBanner.url:''}
          />
           <Typography
            color="textPrimary"
            variant="h3"
          >
            {`${storeInfo.storeName}`}
          </Typography>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="body1"
          >
            {storeInfo.ownerFirstName + ' ' + storeInfo.ownerLastName}
          </Typography>
         
        </Box>
      </CardContent>
      <Divider />
      <CardActions>

        <DropzoneArea
                  dropzoneText="Upload a new store banner"
                  filesLimit={1}
                  onChange={(files) => imageConversion(files)}
                  name = "storeBanner"
        />
      </CardActions>
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
  setNewStoreBanner:PropTypes.func.isRequired,
};

const mapStateToProps = state =>
({
    storeInfo: state.currentStore.storeInfo
});

const mapDispatchToProps = dispatch => (
    {

    });

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
