import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useState, useEffect } from 'react';
import { useRoutes, useNavigate, useLocation } from 'react-router-dom';
import {
  fade,
  colors,
  ThemeProvider,
  Card,
  Box,
  Typography,
  makeStyles,
  createMuiTheme
} from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import { connect } from 'react-redux';
import contrast from 'contrast';
import Snackbar from '@material-ui/core/Snackbar';
import * as Swal from 'sweetalert2';
import { SetErrors, SetSnackNotice } from './redux/app/app-actions';
import Alert from '@material-ui/lab/Alert';
import { fonts } from './fontSrc';
import FontLoader from './components/font-loader/font-loader';
import { green, yellow } from '@material-ui/core/colors';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Link } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import Brightness4Icon from '@material-ui/icons/Brightness4';

const useStyles = makeStyles(theme => ({
  verificationAlert: {
    position: 'fixed',
    right: '10px',
    bottom: '10px',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    '& p': {
      marginLeft: '10px'
    },
    '& a': {
      textDecoration: 'underline',
      marginLeft: '10px'
    }
  },
  speedDial: {
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2)
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2)
    }
  }
}));



const App = ({

  notice,
  errors,
  setErrors,
  storeInfo,
  setSnackNotice
}) => {
  const classes = useStyles();
  const routing = useRoutes(routes);
  const [currentTheme, setCurrentTheme] = useState(theme);
  const [currentFont, setCurrentFont] = useState('Roboto');
  const [fontLink, setFontLink] = useState('');
  const [speedDialOpen, setSpeedDialOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [darkMode, setDarkMode] = useState(false);
  const actions = [{ icon: <Brightness4Icon />, name: `Switch to ${darkMode ? 'Light' : 'Dark'} Mode` }];

  useEffect(() => {
    if (storeInfo && storeInfo.font) {
      setCurrentFont(storeInfo.font);
    }

    if (
      storeInfo.sessionId &&
      location.pathname !== '/app/account/verify' &&
      !storeInfo.isVerified
    ) {
      navigate('/app/account/verify');
      setSnackNotice({
        message: 'Please, verify your account first',
        severity: 'warning'
      });
    }
  }, [storeInfo, location]);

  useEffect(() => {
    if (currentTheme) {
      if (darkMode) {
        setCurrentTheme(
          createMuiTheme({
            ...currentTheme,
            palette: {
              type: 'dark',
              ...currentTheme.palette,
              background: {
                ...currentTheme.background,
                dark: '#0f0f0f',
                default: '#0f0f0f',
                paper: '#1f1f1f'
              },
              text: {
                ...currentTheme.palette.text,
                primary: colors.blueGrey[100],
                secondary: colors.blueGrey[300]
              }
            },
            overrides: {
              ...currentTheme.palette.overrides,
              MuiDataGrid: {
                root: {
                  borderColor: fade('#fff', 0.75)
                },
                columnsContainer: {
                  borderBottom: `1px solid ${fade('#fff', 0.75)} !important`
                }
              },
              MuiTextField: {
                root: {
                  '& .MuiInputBase-root': {
                    backgroundColor: '#ffffff12 !important'
                  },
                  '& *': {
                    '& .MuiInputBase-root': {
                      backgroundColor: '#ffffff12 !important'
                    }
                  }
                }
              }
            }
          })
        );
      }
      else {
        setCurrentTheme(
          createMuiTheme({
            ...currentTheme,
            palette: {
              type: 'light',
              ...currentTheme.palette,
              background: {
                ...currentTheme.background,
              },
              text: {
                ...currentTheme.text,
              }
            },
            overrides: {
              ...currentTheme.palette.overrides,
            }
          })
        );
      }
    }

  }, [darkMode]);

  useEffect(() => {
    setFontLink(<FontLoader font={fonts[currentFont]} />);
  }, [currentFont]);

  const [snackBar, setSnackBar] = useState('');

  useEffect(() => {
    setSnackBar(
      <Snackbar
        open={notice.message && notice.message.length ? true : false}
        key={
          notice.message && notice.message.length
            ? notice.message
            : 'snackBarKey'
        }
        autoHideDuration={5000}
        onClose={() => setSnackNotice({ message: '', severity: 'success' })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity={notice.severity}>
          {notice.message && notice.message.length ? notice.message : ''}
        </Alert>
      </Snackbar>
    );
  }, [notice]);

  useEffect(() => {
    if (errors && errors.length) {
      Swal.fire({
        title: '<strong>Oops, something went wrong</strong>',
        icon: 'error',
        heightAuto: false,
        text: '',
        html: `<div>${errors && errors.length
          ? errors.reduce((acc, error) => acc + `<p>${error}</p>`, '')
          : ''
          }</div>`,
        showCloseButton: true
      }).then(() => {
        setErrors([]);
      });
    }
  }, [errors]);

  useEffect(() => {
    if (storeInfo && storeInfo.colors && storeInfo.colors.primary) {
      setCurrentTheme({
        ...currentTheme,
        typography: {
          ...currentTheme.typography,
          fontFamily: fonts[storeInfo.font || 'Roboto'].fontFamily,
          h3: {
            ...currentTheme.typography.h3,
            fontFamily: fonts[storeInfo.font || 'Roboto'].fontFamily
          },
          h4: {
            ...currentTheme.typography.h4,
            fontFamily: fonts[storeInfo.font || 'Roboto'].fontFamily
          },
          h5: {
            ...currentTheme.typography.h5,
            fontFamily: fonts[storeInfo.font || 'Roboto'].fontFamily
          },
          h6: {
            ...currentTheme.typography.h6,
            fontFamily: fonts[storeInfo.font || 'Roboto'].fontFamily
          },
          h7: {
            ...currentTheme.typography.h7,
            fontFamily: fonts[storeInfo.font || 'Roboto'].fontFamily
          },
          h8: {
            ...currentTheme.typography.h8,
            fontFamily: fonts[storeInfo.font || 'Roboto'].fontFamily
          },
          // 'body1':{
          //   ...currentTheme.typography.body1,
          //   fontFamily: fonts[storeInfo.font || 'Roboto'].fontFamily,
          // },
          // 'body2':{
          //   ...currentTheme.typography.body2,
          //   fontFamily: fonts[storeInfo.font || 'Roboto'].fontFamily,
          // },
          button: {
            ...currentTheme.typography.button,
            fontFamily: fonts[storeInfo.font || 'Roboto'].fontFamily
          }
        },
        palette: {
          ...currentTheme.palette,
          primary: {
            ...currentTheme.palette.primary,
            main: storeInfo.colors.primary,
            contrastText: contrast(storeInfo.colors.primary) === 'light' ? '#000' : '#fff'
          },
          background: {
            ...currentTheme.palette.background,
            default: storeInfo.colors.background || colors.common.white,
          },
          text: {
            ...currentTheme.palette.text,
            primary: storeInfo.colors.title || '#000000'
          }
        },
        overrides: {
          Mui: {
            selected: {
              label: {
                backgroundColor: 'red'
              }
            }
          },
          MuiButton: {
            containedPrimary: {
              '&:hover': {
                backgroundColor: fade(
                  storeInfo.colors.primary,
                  currentTheme.palette.action.hoverOpacity * 20
                )
              }
            }
          },
          MuiTreeItem: {
            root: {
              '&:focus': {
                backgroundColor: 'transparent'
              },
              '&$selected > $content $label:hover, &$selected:focus > $content $label , &$selected > $content $label': {
                // backgroundColor: (0, _styles.fade)(theme.palette.primary.main, theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity),
                backgroundColor: 'transparent',
                color: storeInfo.colors.primary,
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                  backgroundColor: 'transparent'
                }
              }
            },
            iconContainer: {
              display: 'none'
            }
          },
          MuiDropzonePreviewList: {
            root: {
              display: 'grid',
              gridTemplateColumns: `repeat(auto-fit, minmax(250px, 1fr))`
            },
            imageContainer: {
              maxWidth: 'unset'
            },
            removeButton: {
              top: '20px',
              right: '40px'
            }
          }
        }
      });
    }
  }, [storeInfo]);

  useEffect(() => {
    console.log(currentTheme);
  }, [currentTheme]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={currentTheme}>
        <GlobalStyles />
        {/* <SpeedDial
          ariaLabel="SpeedDial example"
          className={classes.speedDial}
          icon={<SpeedDialIcon />}
          onClose={() => setSpeedDialOpen(false)}
          onOpen={() => setSpeedDialOpen(true)}
          open={speedDialOpen}
          direction={'up'}
        >
          {actions.map(action => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={() => setDarkMode(!darkMode)}
            />
          ))}
        </SpeedDial> */}
        {fontLink}
        {snackBar}
        {routing}
        {storeInfo &&
          storeInfo._id &&
          !storeInfo.isVerified &&
          storeInfo.sessionId ? (
          <Box>
            <Card className={classes.verificationAlert}>
              {storeInfo && storeInfo.isVerified ? (
                <>
                  <CheckCircleIcon style={{ color: green[500] }} />
                  <Typography variant="body2">Verified Account</Typography>
                </>
              ) : (
                <>
                  <ErrorIcon style={{ color: yellow[700] }} />
                  <Typography variant="body2">
                    Your account is not verified{' '}
                    <Link to="/app/account/verify">Verify Now</Link>
                  </Typography>
                </>
              )}
            </Card>
          </Box>
        ) : (
          ''
        )}
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

const mapStateToProps = state => ({
  notice: state.app.notice,
  storeInfo: state.currentStore.storeInfo,
  errors: state.app.errors
});

const mapDispatchToProps = dispatch => ({
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setErrors: errors => dispatch(SetErrors(errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
