import React, { useEffect, useState } from 'react';
import './checkout.styles.scss';
import CheckoutItem from '../../../components/checkout-item/checkout-item.component.jsx';
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery
} from '@material-ui/core';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import CheckoutDetailForm from '../../../components/checkout-detail-form/checkout-detail-form';
import axios from 'axios';
import { SetSnackNotice, SetErrors } from '../../../redux/app/app-actions';
import { ClearStoreCart, SetStoreCart } from '../../../redux/cart/cart.actions';
import { validateData } from '../../../utils/validation';
import { Typography } from '@material-ui/core';
import Decimal from 'decimal.js-light';
import { SetStoreInfo } from 'src/redux/current-store/current-store-actions';
import { makeStyles } from '@material-ui/styles';
import CartItemList from './cart-item-list';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import PaymentIcon from '@material-ui/icons/Payment';
import PaymentForm from 'src/components/payment-form/payment-form';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    display: 'grid',
    rowGap: theme.spacing(3),
    alignContent: 'start',
    marginTop: theme.spacing(3)
  },
  card: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper
  },
  orderSummary: {
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '350px'
  },
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  }
}));

function getSteps() {
  return ['Confirm cart items', 'Shipping Details', 'Confirm and Pay'];
}

function getStepIcon(step) {
  switch (step) {
    case 0:
      return <ShoppingCartIcon />;
    case 1:
      return <ContactPhoneIcon />;
    case 2:
      return <PaymentIcon />;
    default:
      return 'Unknown step';
  }
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return 'Add or remove cart items';
    case 1:
      return 'Provide your contact and shipping details';
    case 2:
      return 'Select payment method, pay and confirm order';
    default:
      return 'Unknown step';
  }
}

function Checkout({
  setSnackNotice,
  setErrors,
  setStoreCart,
  storeInfo,
  SetStoreInfo,
  stores,
  storeId,
  ClearStoreCart
}) {
  const classes = useStyles();
  const [cartItems, setCartItems] = useState([]);
  const [total, setTotal] = useState('0');
  const [totalCost, setTotalCost] = useState('0');
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const [order, setOrder] = useState({
    status: 'processing',
    products: [],
    storeId: '',
    customer: {}
  });

  const [activeStep, setActiveStep] = React.useState(0);
  const [stepContent, setStepContent] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

  const isStepOptional = step => {
    return false;
  };

  const isStepSkipped = step => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const createOrder = async () => {
    console.log(order);
    const validationResponse = await validateData('newOrder', order);

    if (!validationResponse.validity) {
      setErrors(validationResponse.errors);

      return;
    }

    setSnackNotice({
      message: 'Creating new order',
      severity: 'info'
    });

    try {
      const response = await axios({
        url:
          'https://kripson-store-server-8qq76.ondigitalocean.app/orders/createOrder',
        method: 'POST',
        data: {
          sessionId: storeInfo.sessionId,
          newOrder: {
            ...order,
            total: total,
            totalCost: totalCost,
            storeId: storeInfo._id
          }
        }
      });

      if (response.data.status === 1) {
        setTimeout(() => {
          setSnackNotice({
            message: 'Order confirmed',
            severity: 'success'
          });
          ClearStoreCart(storeId);
        }, 2000);
      } else if (response.data.status === 3) {
        SetStoreInfo({});
        ClearStoreCart(storeId);
        setSnackNotice({
          severity: 'warning',
          message: response.data.message
        });
      } else {
        setErrors(response.data.errors);
        setSnackNotice({
          message: 'Order confirmation failed',
          severity: 'error'
        });
        ClearStoreCart(storeId);
      }
    } catch (e) {
      setSnackNotice({
        message: 'Order confirmation failed',
        severity: 'error'
      });
      ClearStoreCart(storeId);
      console.log(e);
    }
  };

  useEffect(() => {
    if (storeId && stores && stores.hasOwnProperty(storeId)) {
      setCartItems([...stores[storeId].cartItems]);
    }

    setOrder({
      ...order,
      storeId: storeInfo.id || storeId
    });
  }, [storeId, stores]);

  useEffect(() => {
    if (cartItems.length) {
      setOrder({
        ...order,
        products: cartItems.map(item => {
          const productToReturn = {
            _id: item['item']._id,
            title: item['item'].title,
            price: item['item'].price,
            costPrice: item['item'].costPrice,
            quantity: item.quantity,
            selectedSize: item['item'].selectedSize,
            isRefundable: item["item"].isRefundable, 
          };

          if (item.hasOwnProperty('discountData')) {
            productToReturn['discountData'] = item.discountData;
          }

          return productToReturn;
        })
      });

      let thisTotalCost = new Decimal(0);
      let thisTotalCostPrice = new Decimal(0);
      for (const item of cartItems) {
        let totalDiscountApplied = new Decimal(0);
        if (item.discountData) {
          console.log(item.discountData);
          for (const dis of Object.keys(item.discountData)) {
            totalDiscountApplied = totalDiscountApplied.plus(
              new Decimal(item.discountData[dis].discountAmountApplied)
            );
          }
        }
        thisTotalCost = thisTotalCost
          .add(new Decimal(item['item'].price).mul(item.quantity))
          .minus(totalDiscountApplied);
        thisTotalCostPrice = thisTotalCostPrice.add(
          new Decimal(item['item'].costPrice).mul(item.quantity)
        );
      }

      console.log(thisTotalCost);
      setTotal(thisTotalCost.toString());
      setTotalCost(thisTotalCostPrice.toString());
    } else {
      setTotal('0');
    }
  }, [cartItems]);

  useEffect(() => {
    setOrder({ ...order, total: total });

    setOrder({ ...order, totalCost: totalCost });
  }, [total, totalCost]);

  useEffect(() => {
    switch (activeStep) {
      case 0:
        setStepContent(<CartItemList cartItems={cartItems} total={total} />);
        break;
      case 1:
        setStepContent(
          <CheckoutDetailForm
            setCustomer={details =>
              setOrder({ ...order, customer: { ...details } })
            }
          />
        );
        break;
      case 2:
        setStepContent(
          <PaymentForm
            total={total}
            setPayment={details =>
              setOrder({
                ...order,
                paymentStatus: details.paymentStatus,
                dueDate: details.paymentDueDate ? details.paymentDueDate : null,
                paymentMethod: details.paymentMethod
                  ? details.paymentMethod
                  : null
              })
            }
          />
        );
      default:
    }
  }, [activeStep, cartItems, total]);

  return (
    <Container className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              {index < activeStep ? (
                <StepLabel {...labelProps}>
                  {isSmallScreen ? '' : label}
                </StepLabel>
              ) : (
                <StepLabel
                  StepIconComponent={() => getStepIcon(index)}
                  {...labelProps}
                >
                  {isSmallScreen ? '' : label}
                </StepLabel>
              )}
            </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            {/* <Button  size="large" onClick={handleReset} className={classes.button}>
              Reset
            </Button> */}
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>
              {getStepContent(activeStep)}
            </Typography>
            {stepContent}
            <div className={classes.buttonGroup}>
              <Button  size="large"
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                Back
              </Button>
              {isStepOptional(activeStep) && (
                <Button  size="large"
                  variant="contained"
                  color="primary"
                  onClick={handleSkip}
                  className={classes.button}
                >
                  Skip
                </Button>
              )}

              {activeStep === steps.length - 1 ? (
                <Button  size="large"
                  variant="contained"
                  color="primary"
                  onClick={createOrder}
                  className={classes.button}
                >
                  Confirm and Pay
                </Button>
              ) : (
                <Button  size="large"
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                >
                  Next
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </Container>
  );
}

const mapStateToProps = state => ({
  storeId: state.currentStore.storeInfo._id,
  stores: state.cart.stores,
  storeInfo: state.currentStore.storeInfo
});

const mapDispatchToProps = dispatch => ({
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setStoreCart: (cartItems, storeId) =>
    dispatch(SetStoreCart(cartItems, storeId)),
  setErrors: errors => dispatch(SetErrors(errors)),
  SetStoreInfo: customerInfo => dispatch(SetStoreInfo(customerInfo)),
  ClearStoreCart: storeId => dispatch(ClearStoreCart(storeId))
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
