import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import {
  FetchStoreProductsStart,
  SetStoreInfo
} from '../../redux/current-store/current-store-actions';
import { SetErrors, SetSnackNotice } from '../../redux/app/app-actions';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import FilterListIcon from '@material-ui/icons/FilterList';
import './dialog-template-component.scss';

const useStyles = makeStyles(theme => ({
  dialogBox: {
    '& .MuiDialog-paper': {
      display: 'grid',
      padding: theme.spacing(2),
      gridRowGap: theme.spacing(1),
      backgroundColor: theme.palette.background.dark
    },
  },
}));

function DialogTemplateComponent({
  dialogTitle,
  dialogContent,
  ...props
}) {
  const classes = useStyles();
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className={classes.dialogBox}
    >
      <DialogTitle id="simple-dialog-title">
          {dialogTitle? dialogTitle: <><FilterListIcon/> Filters </>}
      </DialogTitle>
      {dialogContent}
    </Dialog>
  );
}

DialogTemplateComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  storeId: state.currentStore.storeInfo._id,
  storeInfo: state.currentStore.storeInfo
});

const mapDispatchToProps = dispatch => ({
  FetchStoreProductsStart: requiredInfo =>
    dispatch(FetchStoreProductsStart(requiredInfo)),
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setErrors: errors => dispatch(SetErrors(errors)),
  setStoreInfo: store => dispatch(SetStoreInfo(store))
});

export default connect(mapStateToProps, mapDispatchToProps)(DialogTemplateComponent);
