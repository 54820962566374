import React, { useState } from 'react';
import { Box, Container, Grid, makeStyles, Typography } from '@material-ui/core';
import Page from 'src/components/Page';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Account = () => {
  const classes = useStyles();
  const [newStoreBanner, setNewStoreBanner] = useState(undefined);

  return (
    <Page className={classes.root} title="Account">
      <Container maxWidth="lg">
        <Box mb={3}>
          <Typography color="textPrimary" variant="h2">
            Account
          </Typography>
          <Typography color="textSecondary" gutterBottom variant="body2">
            General profile info
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xs={12}>
            <Profile setNewStoreBanner={setNewStoreBanner} />
          </Grid>
          <Grid item lg={8} md={6} xs={12}>
            <ProfileDetails newStoreBanner={newStoreBanner} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Account;
