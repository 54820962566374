import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    makeStyles,
    TextField,
    Card,
    MenuItem,
    CardHeader,
    Divider,
    Typography,
    Chip,
    Button
} from '@material-ui/core';
import Page from 'src/components/Page';
import Notifications from './Notifications';
import Password from './Password';
import { connect } from 'react-redux';
import axios from 'axios';
import { SetStoreInfo } from '../../../redux/current-store/current-store-actions';
import { SetErrors, SetSnackNotice } from '../../../redux/app/app-actions';
import * as _ from 'lodash';
import { validateData } from 'src/utils/validation';
import { Facebook, Instagram } from '@material-ui/icons';
import YouTubeIcon from '@material-ui/icons/YouTube';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    currencyContainer: {
        padding: theme.spacing(2)
    },
    currency: {
        marginLeft: theme.spacing(1)
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText
    },
    paymentMethodBox: {
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    paymentMethodLogo: {
        height: '100px',
        width: 'auto',
    },
    errorText: {
        color: theme.palette.error.main,
    },
    socialLinks:{
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(1 , 0),
        '& svg':{
            marginRight: theme.spacing(2)
        }
    }
}));

const SocialsLinks = ({
    storeInfo,
    setStoreInfo,
    setErrors,
    setSnackNotice
}) => {
    const classes = useStyles();
    const [newSocials, setNewSocials] = useState({});

    useEffect(() => {
        setNewSocials(storeInfo.socials || {});
    }, [storeInfo]);


    const updateStoreInfo = async () => {
        if (storeInfo) {

            const validationResponse = await validateData('updatedStore', {
                ...storeInfo,
                socials: newSocials,
            });

            if (!validationResponse.validity) {
                setErrors(validationResponse.errors);
                return;
            }

            setSnackNotice({
                message: 'Updating store information',
                severity: 'warning'
            });

            axios({
                url:
                    'https://kripson-store-server-8qq76.ondigitalocean.app/stores/updateStoreInfo',
                method: 'POST',
                data: {
                    ...storeInfo,
                    socials: newSocials,
                }
            })
                .then(response => {
                    if (response.data.status === 1) {
                        setSnackNotice({
                            message: 'Store information updated successfully',
                            severity: 'success'
                        });
                        setStoreInfo({...storeInfo, ...response.data.result});
                    } else if (response.data.status === 3) {
                        setStoreInfo({
                            ownerFirstName: '',
                            ownerLastName: '',
                            storeEmail: '',
                            storeName: '',
                            storeAddress: '',
                            colors: { primary:'#000000'}
                        });
                        setSnackNotice({
                            severity: 'warning',
                            message: response.data.message
                        });
                    } else {
                        setErrors(response.data.errors);
                    }
                })
                .catch(error => {
                    setSnackNotice({
                        message: 'Store information could not be updated',
                        severity: 'error'
                    });
                });
        }
    };

    return (
        <Page className={classes.root} title="Settings">
            <Container maxWidth="lg">
                <Box mb={3}>
                    <Typography color="textPrimary" variant="h2">
                        Social Links
                    </Typography>
                    <Typography color="textSecondary" gutterBottom variant="body2">
                        Edit your other media links
                    </Typography>
                </Box>
                <Box mb={3}>
                    <Card>
                        <CardHeader
                            subheader="Edit your other media links"
                            title="Social Links"
                        >
                        </CardHeader>
                        <Divider />
                        <Box className={classes.currencyContainer}>

                            <Box className={classes.socialLinks}>
                                <Facebook fontSize="large"/>
                                <TextField
                                    onChange={(e) => setNewSocials({
                                        ...newSocials,
                                        facebook: e.target.value
                                    })}
                                    fullWidth
                                    value={newSocials.facebook || ''}
                                    label="Facebook"
                                    variant="outlined"

                                />
                            </Box>
                            <Box className={classes.socialLinks}>
                                <Instagram fontSize="large"/>
                                <TextField
                                    onChange={(e) => setNewSocials({
                                        ...newSocials,
                                        instagram: e.target.value
                                    })}
                                    fullWidth
                                    value={newSocials.instagram || ''}
                                    label="Instagram"
                                    variant="outlined"

                                />
                            </Box>
                            <Box className={classes.socialLinks}>
                                <YouTubeIcon fontSize="large"/>
                                <TextField
                                    onChange={(e) => setNewSocials({
                                        ...newSocials,
                                        youtube: e.target.value
                                    })}
                                    fullWidth
                                    value={newSocials.youtube || ''}
                                    label="Youtube"
                                    variant="outlined"

                                />
                            </Box>

                        </Box>
                    </Card>

                    <Box display="flex" alignItems="center" justifyContent="flex-end" my={1}>
                        <Button  size="large" variant="contained" onClick={updateStoreInfo} color="primary">Update Links</Button>
                    </Box>
                </Box>
            </Container>
        </Page>
    );
};

const mapStateToProps = state => ({
    storeInfo: state.currentStore.storeInfo
});

const mapDispatchToProps = dispatch => ({
    setStoreInfo: storeInfo => dispatch(SetStoreInfo(storeInfo)),
    setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
    setErrors: errors => dispatch(SetErrors(errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(SocialsLinks);
