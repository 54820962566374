import React, { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
  makeStyles,
  InputAdornment
} from '@material-ui/core';
import Page from 'src/components/Page';
import axios from 'axios';
import { SetSnackNotice, SetErrors } from "../../redux/app/app-actions";
import { FetchStoreCategoriesStart, SetStoreInfo } from "../../redux/current-store/current-store-actions";
import { connect } from "react-redux";
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import LockIcon from '@material-ui/icons/Lock';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    display: 'grid',
    alignContent: 'center',
  }
}));

const LoginView = ({ setSnackNotice, setStoreInfo, storeInfo, FetchStoreCategoriesStart, setErrors }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    if (storeInfo.sessionId) {
      navigate('/app/dashboard');
    }
  }, [storeInfo])


  const login = async (values) => {
    setSnackNotice({
      message: 'Logging in',
      severity: 'info'
    });
    const response = await axios({
      url: 'https://kripson-store-server-8qq76.ondigitalocean.app/stores/login',
      method: 'POST',
      data: { ...values },
    });

    if (response.data.status === 1) {
      setStoreInfo(response.data.result);
      FetchStoreCategoriesStart(response.data.result._id, response.data.result.sessionId);
      setSnackNotice({
        message: `Logged in Successfully`,
        severity: 'success'
      });
      navigate('/app/dashboard');
    }
    else {
      setErrors(response.data.errors)
    }
  }
  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              storeEmail: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              storeEmail: Yup.string().email('Must be a valid email').max(255).required('storeEmail is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={(values, { setSubmitting }) => {
              login(values);
              setSubmitting(false);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Sign in
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Sign in on the internal platform
                  </Typography>
                </Box>

                <TextField
                  error={Boolean(touched.storeEmail && errors.storeEmail)}
                  fullWidth
                  helperText={touched.storeEmail && errors.storeEmail}
                  label="Email Address"
                  margin="normal"
                  name="storeEmail"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AlternateEmailIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="email"
                  value={values.storeEmail}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                  }}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />

                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  <Link
                    component={RouterLink}
                    to="/forgotPassword"
                    variant="h6"
                  >
                    Forgot Password?
                  </Link>

                </Typography>
                <Box my={2}>
                  <Button  size="large"
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Don&apos;t have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/register"
                    variant="h6"
                  >
                    Sign up
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

const mapStateToProps = state =>
({
  storeInfo: state.currentStore.storeInfo
});

const mapDispatchToProps = dispatch => (
  {
    setStoreInfo: (storeInfo) => dispatch(SetStoreInfo(storeInfo)),
    FetchStoreCategoriesStart: (storeId, sessionId) => dispatch(FetchStoreCategoriesStart(storeId, sessionId)),
    setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
    setErrors: (errors) => dispatch(SetErrors(errors))
  });

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
