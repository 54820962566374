import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Container,
  Link,
  makeStyles,
  TextField,
  Typography,
  IconButton
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  FetchStoreCategoriesStart,
  SetStoreInfo
} from '../../../redux/current-store/current-store-actions';
import { SetSnackNotice, SetErrors } from '../../../redux/app/app-actions';
import { connect } from 'react-redux';
import AllCategoryList from './all-category-list';
import * as _ from 'lodash';
import { DropzoneArea } from 'material-ui-dropzone';
import imageCompression from 'browser-image-compression';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '20px'
  }
}));

const AddCategoryForm = ({
  storeId,
  setStoreInfo,
  storeInfo,
  setSnackNotice,
  FetchStoreCategoriesStart,
  setErrors
}) => {
  const classes = useStyles();
  const [newImage, setNewImage] = useState({});


  const imageConversion = async (files) => {
    const compressedFileArray = [];
    for (let file of files) {

      const options = {
        maxSizeMB: 0.4,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      };
      try {
        const compressedFile = await imageCompression(file, options);
        compressedFileArray.push(compressedFile);

      } catch (error) {
        console.log(error);
      }
    }


    const base64dataarray = [];
    let counter = base64dataarray.length;

    compressedFileArray.forEach((file, idx) => {
      let reader = new FileReader();
      reader.onloadend = function () {
        base64dataarray[counter].base64data = reader.result;
        if (idx === files.length - 1) {
          setNewImage(base64dataarray[0])
        }

        counter++;


      };
      reader.readAsDataURL(file);
      base64dataarray.push({ type: file.type });
    })
  };

  const createCategory = async values => {

    if (values.hasOwnProperty('categoryName')) {
      values.categoryName = _.capitalize(values['categoryName']);
    }
    setSnackNotice({
      message: 'Creating new category',
      severity: 'info'
    });
    const response = await axios({
      url: 'https://kripson-store-server-8qq76.ondigitalocean.app/stores/createCategory',
      method: 'POST',
      data: {
        sessionId: storeInfo.sessionId,
        ...values,
        categoryImage: newImage,
        storeId: storeId
      }
    });

    if (response.data.status === 1) {
      FetchStoreCategoriesStart(storeId, storeInfo.sessionId);
      setSnackNotice({
        message: `${values.categoryName} category created`,
        severity: 'success'
      });
    }
    else if (response.data.status === 3) {
      setStoreInfo({
        ownerFirstName: '',
        ownerLastName: '',
        storeEmail: '',
        storeName: '',
        storeAddress: '',
        colors: { primary: '#000000' }
      });
      setSnackNotice({
        severity: 'warning',
        message: response.data.message
      });
    }
    else {
      setErrors(response.data.errors);
      setSnackNotice({
        message: `${values.categoryName} category could not be created`,
        severity: 'error'
      });
    }
  };

  return (
    <div className={classes.root}>
      <Formik
        initialValues={{
          categoryName: '',
        }}
        validationSchema={Yup.object().shape({
          // categoryName: Yup.string().max(10).required('Category name is required')
        })}
        onSubmit={(values, { setSubmitting }) => {
          createCategory(values);
          setSubmitting(false);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Box>
              <Box mb={3}>
                <Typography color="textPrimary" variant="h2">
                  Add category
                </Typography>
                <Typography color="textSecondary" gutterBottom variant="body2">
                  Create new category to organize products under it.

                </Typography>
              </Box>

              <TextField
                error={Boolean(touched.categoryName && errors.categoryName)}
                fullWidth
                helperText={touched.categoryName && errors.categoryName}
                label="Category Name"
                margin="normal"
                name="categoryName"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.categoryName}
                variant="outlined"
              />

              <DropzoneArea
                dropzoneText="Add a category image"
                filesLimit={1}
                onChange={(files) => imageConversion(files)}
                name="categoryImage"
              />

              <Box my={2}>
                <Button size="large"
                  color="primary"
                  disabled={isSubmitting || !values.categoryName}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Add category
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
      <Box mt={4} mb={2}>
        <Typography color="textPrimary" variant="h2">
          All categories
        </Typography>
      </Box>

      <AllCategoryList/>
    </div>
  );
};

const mapStateToProps = state => ({
  storeId: state.currentStore.storeInfo._id,
  storeInfo: state.currentStore.storeInfo,
});

const mapDispatchToProps = dispatch => ({
  FetchStoreCategoriesStart: (storeId, sessionId) =>
    dispatch(FetchStoreCategoriesStart(storeId, sessionId)),
  setStoreInfo: storeInfo => dispatch(SetStoreInfo(storeInfo)),
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setErrors: errors => dispatch(SetErrors(errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCategoryForm);
