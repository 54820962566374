import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  CardHeader,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  TextField,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import * as FA from 'react-fontawesome';
import { SetErrors, SetSnackNotice } from 'src/redux/app/app-actions';
import {
  FetchStoreNotificationsStart,
  FetchStoreordersStart
} from 'src/redux/current-store/current-store-actions';
import CachedIcon from '@material-ui/icons/Cached';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import momentTimeZone from 'moment-timezone';
// import './order-product-card.scss';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SendIcon from '@material-ui/icons/Send';
import Decimal from 'decimal.js-light';
import PaymentMethodSelectionSection from './payment-method-selection-section';
import { DatePicker } from '@material-ui/pickers';

function PaymentForm({ storeId, storeInfo, setPayment, total, ...rest }) {
  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      marginBottom: theme.spacing(2)
    },
    warning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText
    },
    error: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText
    },
    success: {
      backgroundColor: theme.palette.success.main,
      color: 'white'
    },
    paymentForm: {
      padding: theme.spacing(2)
    }
  }));

  const classes = useStyles();
  const [paymentStatus, setPaymentStatus] = useState('paid');
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentDueDate, setPaymentDueDate] = useState(new Date());


  useEffect(() => {
      
    const details = {
        paymentStatus,
        paymentMethod: paymentStatus === 'paid' && paymentMethod ? paymentMethod : null,
        paymentDueDate: paymentStatus === 'unpaid' && paymentDueDate ? paymentDueDate : null
    };
    console.log(details);
    setPayment(details);

  }, [paymentStatus, paymentMethod, paymentDueDate])

  const getOrderStatusIcon = status => {
    switch (status) {
      case 'processing':
        return <CachedIcon />;
      case 'cancelled':
      case 'refunded':
        return <ClearIcon />;
      case 'delivered':
        return <CheckIcon />;
      default:
        return;
    }
  };

  const getOrderStatusClass = status => {
    switch (status) {
      case 'processing':
        return classes.warning;
      case 'cancelled':
      case 'refunded':
        return classes.error;
      case 'delivered':
        return classes.success;
      default:
        return;
    }
  };

  return (
    <Card className={`${classes.root} ${classes.paymentForm}`} {...rest}>
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={paymentStatus === 'paid' ? false : true}
              onChange={e =>
                setPaymentStatus(e.target.checked ? 'unpaid' : 'paid')
              }
              name="isRefundable"
              color="primary"
            />
          }
          label="Make this a pre-order"
        />
        {paymentStatus === 'paid' ? (
          <PaymentMethodSelectionSection
            setPaymentMethod={setPaymentMethod}
            paymentMethod={paymentMethod}
          />
        ) : (
          <DatePicker
            margin="normal"
            id="date-picker-dialog"
            label="Payment due date"
            format="MM/dd/yyyy"
            inputVariant="outlined"
            fullWidth
            value={paymentDueDate ? paymentDueDate: ''}
            onChange={date => setPaymentDueDate(new Date(date).getTime())}
            name="startDate"
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        )}
        <Typography variant="h5">
          Total: {storeInfo.storeCurrencySymbol} {total}
        </Typography>
      </div>
    </Card>
  );
}

const mapStateToProps = state => ({
  storeId: state.currentStore.storeInfo._id,
  storeInfo: state.currentStore.storeInfo,
  notifications: state.currentStore.notifications
});

const mapDispatchToProps = dispatch => ({
  FetchStoreNotificationsStart: (storeId, sessionId) =>
    dispatch(FetchStoreNotificationsStart(storeId, sessionId)),
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setErrors: errors => dispatch(SetErrors(errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);
