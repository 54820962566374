import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { AppBar, Badge, Box, Hidden, IconButton, Toolbar, makeStyles } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import InputIcon from "@material-ui/icons/Input";
import Logo from "src/components/Logo/Logo";
import { Decimal } from "decimal.js-light";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    width: 60,
    height: 60,
  },
  link: {
    color: "inherit",
  },
}));

const TopBar = ({ className, onMobileNavOpen, storeInfo, stores, ...rest }) => {
  const classes = useStyles();
  const [notifications] = useState([]);
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    if (storeInfo._id && stores && stores.hasOwnProperty(storeInfo._id)) {
      setCartItems([...stores[storeInfo._id].cartItems]);
    }
  }, [storeInfo, stores]);

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden mdDown>
          {storeInfo && storeInfo.includeOnlinePayment ? (
            <RouterLink className={classes.link} to={`/pos/checkout`}>
              <IconButton color="inherit">
                <Badge badgeContent={cartItems.reduce((acc, currrentItem) => acc + currrentItem.quantity, 0)} color="error">
                  <ShoppingCartOutlinedIcon />
                </Badge>
              </IconButton>
            </RouterLink>
          ) : (
            ""
          )}
          <IconButton color="inherit">
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          {storeInfo && storeInfo.includeOnlinePayment ? (
            <RouterLink className={classes.link} to={`/pos/checkout`}>
              <IconButton color="inherit">
              <Badge badgeContent={cartItems.reduce((acc, currrentItem) => acc + currrentItem.quantity, 0)} color="error">
                  <ShoppingCartOutlinedIcon />
                </Badge>
              </IconButton>
            </RouterLink>
          ) : (
            ""
          )}
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

const mapStateToProps = (state) => ({
  stores: state.cart.stores,
  storeInfo: state.currentStore.storeInfo,
});

const mapDispatchToProps = (dispatch) => ({
  // FetchStoreProductsStart: (requiredInfo) => dispatch(FetchStoreProductsStart(requiredInfo)),
  // setPreLoader: (notice) => dispatch(SetPreLoader(notice)),
  // AddItem: (item, storeId) => dispatch(AddItem(item, storeId))
});

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
