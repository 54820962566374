import React from 'react';
import {
  Button,
  Grid,
  makeStyles,
  useMediaQuery,
  Card,
  Box,
  Container,
  Divider,
  CardContent,
  Typography
} from '@material-ui/core';
import { connect } from 'react-redux';
import { SetStoreInfo } from '../../redux/current-store/current-store-actions';
import { SetErrors, SetSnackNotice } from '../../redux/app/app-actions';
import * as _ from 'lodash';
import { Bar, HorizontalBar, Line } from 'react-chartjs-2';
import Page from 'src/components/Page';

const StatsSection = ({
  storeInfo,
  setStoreInfo,
  setErrors,
  setSnackNotice,
  showBackButton,
  statLabel,
  data,
  options,
  setShowStats,
  padding,
  children
}) => {

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      maxWidth: '100vw',
      minHeight: '100vh',
      padding: padding ? theme.spacing(padding) : 0,
      display: 'grid',
      alignContent: 'start',
      gridRowGap: theme.spacing(2)
    },
    currencyContainer: {
      padding: theme.spacing(2)
    },
    currency: {
      marginLeft: theme.spacing(1)
    },
    bigChart: {
      padding: theme.spacing(2)
    }
  }));

  const classes = useStyles();

  return (
    <Page title={'Stats'}>

      <Box className={classes.root}>
        {showBackButton ? <Grid container spacing={1}>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <Button  size="large"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => setShowStats(false)}
            >
              Back
            </Button>
          </Grid>
        </Grid> : ''}
        {children}
        <Grid container spacing={1}>
          {!isSmallScreen ?
            <Grid item xs={12} lg={6}>
              <Card>
                <Box p={2}>
                  <Typography variant="h5" color="textSecondary">
                    {statLabel?? 'Sales'}
                  </Typography>

                </Box>
                <Divider />
                <Box position="relative" style={{ maxHeight: '600px', minHeight: '400px' }} className={classes.bigChart}>
                  <Line data={data} options={options} />
                </Box>
              </Card>
            </Grid> : ''}
          <Grid item xs={12} lg={6}>
            <Card>
              <Box p={2}>
                <Typography variant="h5" color="textSecondary">
                {statLabel?? 'Sales'}
                </Typography>
              </Box>
              <Divider />

              <Box position="relative" style={{ maxHeight: '600px', minHeight: '400px' }} className={classes.bigChart}>
                {isSmallScreen ? (
                  <HorizontalBar data={data} options={options} />
                ) : (
                  <Bar data={data} options={options} />
                )}
              </Box>


            </Card>
          </Grid>
        </Grid>
      </Box>
    </Page>

  );
};

const mapStateToProps = state => ({
  storeInfo: state.currentStore.storeInfo
});

const mapDispatchToProps = dispatch => ({
  setStoreInfo: storeInfo => dispatch(SetStoreInfo(storeInfo)),
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setErrors: errors => dispatch(SetErrors(errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(StatsSection);
