import React, { useEffect, useState } from "react";
import { Box, Card, Container, Grid, makeStyles, TextField, useTheme } from "@material-ui/core";

import Page from "src/components/Page";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import "../../../../customStyles.scss";
import { Link, useParams } from "react-router-dom";
import { FetchStoreProductsStart, SetStoreInfo } from "../../../../redux/current-store/current-store-actions";
import { connect } from "react-redux";
import axios from "axios";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import PhoneIcon from "@material-ui/icons/Phone";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { AddItem } from "src/redux/cart/cart.actions";
import Swal from "sweetalert2";
import ErrorIcon from "@material-ui/icons/Error";
import { yellow } from "@material-ui/core/colors";
import Decimal from "decimal.js-light";
import { SetErrors } from "../../../../redux/app/app-actions";
import ProductReviewSection from "src/components/product-review-section/product-review-section";
import PaymentIcon from '@material-ui/icons/Payment';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    "& a": {
      color: theme.palette.primary.contrastText,
    },
  },
  productDetailsSection: {
    padding: "20px !important",
    display: "grid",
    alignContent: "start",
    gridRowGap: "20px",
    marginBottom: "20px",
  },
  similarProductsSection: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    padding: "20px !important",
    gridColumnGap: "10px",
    gridRowGap: "10px",
    marginTop: "20px",
  },
  productColor: {
    height: "25px",
    width: "25px",
    borderRadius: "25px",
  },
  sizeOptionContainer: {
    display: 'flex',
  },
  sizeChoice: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    justifyContent: "center",
    alignContent: "center",
    boxShadow: "0 0 5px 0 rgba(0,0,0,0.15)",
    borderRadius: "5px",
    minHeight: "25px",
    padding: theme.spacing(1),
    marginRight: theme.spacing(1),
    "&:hover": {
      cursor: "pointer",
    },
  },
  warningMessage: {
    display: "flex",
    "& h3": {
      marginLeft: "10px",
    },
  },
  sizeChoiceSelected: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  detailsSectionRows: {},
  productFeature:{
    display: "flex",
    alignItems: "center",
    '& *':{
      marginRight: theme.spacing(1)
    }
  }
}));

const PosProductDetailsView = ({ storeInfo, AddItem, storesCart, categories, currentCustomer, SetStoreInfo, setSnackNotice, setErrors }) => {
  const classes = useStyles();
  const { productId } = useParams();
  let { category } = useParams();
  const [stockSize, setStock] = useState({});

  const [product, setProduct] = useState({});
  const [fetchingProducts, setFetchingProducts] = useState(true);
  const [products, setProducts] = useState([]);

  const [selectedSize, setSelectedSize] = useState(undefined);
  const [quantityToBeAdded, setQuantityToBeAdded] = useState(undefined);

  const [numberInCart, setNumberInCart] = useState("0");
  const theme = useTheme();

  useEffect(() => {
    if (storesCart && storeInfo._id && product && storesCart[storeInfo._id]) {
      const productInCart = storesCart[storeInfo._id].cartItems.find((item) => item.item._id === product._id && item.item.selectedSize === selectedSize);
      console.log(productInCart);
      if (productInCart) {
        setNumberInCart(productInCart.quantity);
      } else {
        setNumberInCart(0);
      }
    }
  }, [storesCart, storeInfo, product, selectedSize]);



  useEffect(() => {
    product.selectedSize = selectedSize;
  }, [selectedSize]);

  const addToCart = () => {
    if (selectedSize) {
      if (quantityToBeAdded > (product.stockQuantity[selectedSize] - numberInCart)) {
        Swal.fire({
          text: "The selected quantity should not exceed the available quantity.",
          icon: "error",
          showCloseButton: true,
        });
        return;
      }
      AddItem(product, storeInfo._id, quantityToBeAdded);


    } else {
      Swal.fire({
        text: "Please select a size",
        icon: "error",
        showCloseButton: true,
      });
    }
  };



  useEffect(() => {
    if (productId && storeInfo._id) {
      axios({
        url: "https://kripson-store-server-8qq76.ondigitalocean.app/products/getProductById",
        method: "POST",
        data: {
          sessionId: storeInfo.sessionId,
          storeId: storeInfo._id,
          productId: productId,
        },
      }).then((response) => {
        if (response.data.status === 1) {
          setProduct(response.data.result[0]);
        }
        else if (response.data.status === 3) {
          SetStoreInfo({});
          setSnackNotice({
            severity: 'warning',
            message: response.data.message
          });
        }
        else {
          setErrors(response.data.errors);
        }

      });
    }
  }, [productId, storeInfo._id]);


  return (
    <Page className={classes.root} title="Product Details">
      <Grid container spacing={1}>
        <Grid item xs={12} md={7} className={classes.productDetailsSection}>
          <AwesomeSlider
            animation={"foldOutAnimation"}
            className={"slider"}
            media={
              product.images
                ? product.images.map((image, index) => {
                  return {
                    source: image.url,
                  };
                })
                : []
            }
          />
        </Grid>

        <Grid item xs={12} md={5} className={classes.productDetailsSection}>
          <Typography align="left" color="textPrimary" gutterBottom variant="h3">
            {product.title}
          </Typography>

          <Typography align="left" color="textSecondary" gutterBottom variant="body2">
            {product.description}
          </Typography>

          <Typography align="left" color="textPrimary" gutterBottom variant="h3">
            {storeInfo.storeCurrencySymbol} {product.price ? product.price.toLocaleString("en-IN") : ""}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            Primary color:
          </Typography>
          <span className={classes.productColor} style={{ backgroundColor: product.color ? product.color : "" }}></span>


          <Box>
            <Typography align="left" color="textPrimary" variant="h5" gutterBottom>Product Features</Typography>
            <Box className={classes.productFeature}>
              {product && product.isRefundable ? <CheckCircleIcon style={{ fill: theme.palette.success.main}}/> : <CancelIcon style={{ fill: theme.palette.error.main}}/>}
              <PaymentIcon />
              <span>{product && product.isRefundable ? 'Refundable' : 'Non-refundable'}</span>
            </Box>

            <Box className={classes.productFeature}>
              {product && product.isDeliverable ? <CheckCircleIcon style={{ fill: theme.palette.success.main}}/> : <CancelIcon style={{ fill: theme.palette.error.main}}/>}
              <LocalShippingIcon />
              {product && product.isDeliverable ? 'Deliverable' : 'Non-deliverable'}
            </Box>
          </Box>

          {product.status === "available now" ? (
            <>
              {" "}
              <Typography variant="h5" color="textPrimary">{storeInfo && storeInfo.includeOnlinePayment ? "Choose Sizes:" : "Available Sizes:"}</Typography>
              <div className={classes.sizeOptionContainer}>
                {product.sizes
                  ? product.sizes.map((size, index) => (
                    <Card
                      align="left"
                      color="textPrimary"
                      gutterBottom
                      variant="body2"
                      className={`${classes.sizeChoice} ${selectedSize === size ? classes.sizeChoiceSelected : ""}`}
                      onClick={() => setSelectedSize(size)}
                    >
                      {size}
                    </Card>
                  ))
                  : ""}
              </div>

              <TextField onChange={(e) => setQuantityToBeAdded(Number(e.target.value))} inputProps={{
                type: "number",
                min: 1,
                max: product && product.stockQuantity && selectedSize ? product.stockQuantity[selectedSize] : 0
              }} label="Quantity" variant="outlined" required />


              {product && product.stockQuantity && selectedSize ? <Typography variant="h6" color="textPrimary" >{new Decimal(product.stockQuantity[selectedSize]).minus(new Decimal(numberInCart)).toString()} Remaining </Typography> : ""}
              {(currentCustomer && currentCustomer._id && currentCustomer.isVerified) || !(currentCustomer && currentCustomer._id) ? (
                storeInfo && storeInfo.includeOnlinePayment ? (
                  <Button  size="large"
                    disabled={product && product.stockQuantity && selectedSize && quantityToBeAdded ? (product.stockQuantity[selectedSize] - numberInCart === 0) : true}
                    variant="contained"
                    color="primary"
                    startIcon={<AddShoppingCartIcon />}
                    onClick={addToCart}
                  >
                    Add to Cart
                  </Button>
                ) : (
                  <a href={`tel://${storeInfo && storeInfo.storePhone ? storeInfo.storePhone : ''}`}>
                    <Button  size="large"
                      variant="contained"
                      color="primary"
                      startIcon={<PhoneIcon />}
                    >
                      Contact store
                    </Button>
                  </a>
                )
              ) : (
                <Box className={classes.warningMessage}>
                  <ErrorIcon style={{ color: yellow[700] }} />
                  <Typography variant="h3"> Please verify your account to start adding items to cart</Typography>
                </Box>
              )}
            </>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
      <ProductReviewSection productId={productId} />


    </Page >
  );
};

const mapStateToProps = (state) => ({
  storeInfo: state.currentStore.storeInfo,
  categories: state.currentStore.categories,
  storesCart: state.cart.stores,
});

const mapDispatchToProps = (dispatch) => ({
  FetchStoreProductsStart: (requiredInfo) => dispatch(FetchStoreProductsStart(requiredInfo)),
  setStoreInfo: (storeInfo) => dispatch(SetStoreInfo(storeInfo)),
  setErrors: (errors) => dispatch(SetErrors(errors)),
  AddItem: (item, storeId, quantity) => dispatch(AddItem(item, storeId, quantity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PosProductDetailsView);
