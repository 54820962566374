import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Card } from "@material-ui/core";
import "./checkout-detail-form.scss";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme)=>({
    detailsForm:{
        padding:`${theme.spacing(4)}px ${theme.spacing(2)}px`,
        marginBottom: theme.spacing(2)
    }
}))
const CheckoutDetailForm = ({ setCustomer }) => {
  
  const classes = useStyles();
  const [values, setValues] = useState({
    name: "",
    address: "",
    email: "",
    phonenumber: "",
  });



  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.id]: e.target.value,
    });
  };


  useEffect(() => {
    setCustomer({...values});
  },[values])

  return (
    <Card className={`checkout-detail-form ${classes.detailsForm}`}>
      <Typography variant="h3">Please fill in your details and confirm the order</Typography>
      <TextField id="name" label="name" onChange={handleChange} variant="outlined" className="form-control" value={values.name} required />
      <TextField id="address" label="address" onChange={handleChange} variant="outlined" className="form-control" value={values.address} required />
      <TextField id="email" label="email address" onChange={handleChange} variant="outlined" className="form-control" value={values.email} required />
      <TextField id="phonenumber" label="phone number" onChange={handleChange} variant="outlined" className="form-control" value={values.phone} required />
      <div className="button-group"></div>
    </Card>
  );
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutDetailForm);
