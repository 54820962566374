import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import {
  FetchStoreCategoriesStart,
  SetStoreInfo
} from '../../redux/current-store/current-store-actions';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FetchStoresStart } from 'src/redux/app/app-actions';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const StoreLayout = ({
  FetchStoreCategoriesStart,
  FetchStoresStart,
  setStoreInfo,
  stores,
  storeInfo,
  existingStoreId,
  categories
}) => {
  const classes = useStyles();
  const { category, productId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  useEffect(() => {
    if (!storeInfo.sessionId) {
      navigate('/login');
    }
  }, [storeInfo]);

  useEffect(() => {
    if (existingStoreId) {
      FetchStoreCategoriesStart(existingStoreId);
    }
  }, [existingStoreId]);

  useEffect(() => {
    let allCategoryId = '';
    console.log(location);
    if (categories.length && location && !productId) {
      if (location.pathname.split('/').length <= 4) {
        navigate(`/pos/products/`);
      }
    }
  }, [categories]);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        storeId={existingStoreId}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  FetchStoreCategoriesStart: storeId =>
    dispatch(FetchStoreCategoriesStart(storeId)),
  FetchStoresStart: filter => dispatch(FetchStoresStart(filter)),
  setStoreInfo: info => dispatch(SetStoreInfo(info))
});
const mapStateToProps = state => ({
  existingStoreId: state.currentStore.storeInfo._id,
  categories: state.currentStore.categories,
  storeInfo: state.currentStore.storeInfo
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreLayout);
