import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  SvgIcon,
  Avatar,
  Fab,
  Hidden,
  useMediaQuery
} from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import {
  FetchStoreProductsStart,
  SetStoreInfo
} from '../../redux/current-store/current-store-actions';
import { SetErrors, SetSnackNotice } from '../../redux/app/app-actions';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import FilterListIcon from '@material-ui/icons/FilterList';
// import './dialog-component.scss';
import axios from 'axios';
import { Search as SearchIcon } from 'react-feather';
import { DataGrid } from '@material-ui/data-grid';
import CustomNoRowsOverlay from 'src/components/custom-no-rows/custom-no-rows';

const columns = [
  {
    field: 'image',
    headerName: 'image',
    width: 100,
    renderCell: params => {
      return <Avatar src={params.value} />;
    }
  },
  { field: 'title', headerName: 'title', width: 200 },
  {
    field: 'color',
    headerName: 'color',
    width: 90,
    renderCell: params => {
      return <Fab style={{ backgroundColor: params.value }} size="small"></Fab>;
    }
  }
];

const useStyles = makeStyles(theme => ({
  dialogBox: {
    '& .MuiDialog-paper': {
      display: 'grid',
      padding: theme.spacing(2),
      gridRowGap: theme.spacing(1),
      backgroundColor: theme.palette.background.dark,
      width: '900px',
      maxWidth: '99vw',
      margin:theme.spacing(1)
    }
  },
  priceBox: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '10px'
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: '5px'
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: 'white'
  }
}));

function SelectProductDialog({
  storeId,
  storeInfo,
  FetchStoreProductsStart,
  setSnackNotice,
  setErrors,
  setStoreInfo,
  ...props
}) {
  const classes = useStyles();
  const { onClose, open, addEligibleProducts, alreadySelectedProductIds} = props;
  const [keyword, setKeyword] = useState('');
  const [filters, setFilters] = useState({});
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [fetchingProducts, setFetchingProducts] = useState(true);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    setSelectedProducts(selectionModel.map(i => products[i - 1]));
  }, [selectionModel]);

  const handleFilterChange = e => {
    if (e.target.name === 'status') {
      setFilters({
        ...filters,
        [e.target.name]: e.target.checked ? 'available now' : null
      });
    } else {
      setFilters({
        ...filters,
        [e.target.name]: e.target.value
      });
    }
  };

  const clearFilters = () => {
    setFilters({
      minPrice: '',
      maxPrice: '',
      status: ''
    });
  };

  useEffect(() => {
    fetchProductsToSelectFrom();
  }, [filters, keyword]);

  const fetchProductsToSelectFrom = () => {
    const filter = {};

    if (keyword) {
      filter.$or = [
        { title: { $regex: keyword, $options: 'ix' } },
        { brand: { $regex: keyword, $options: 'ix' } }
      ];
    }

    filter.minPrice = filters.minPrice || '0';
    filter.maxPrice = filters.maxPrice || '999999999999999';

    if (filters.status) {
      filter.status = filters.status;
    }

    setFetchingProducts(true);

    axios({
      url:
        'https://kripson-store-server-8qq76.ondigitalocean.app/products/getProducts',
      method: 'POST',
      onUploadProgress: progressEvent => console.log(progressEvent),
      data: {
        sessionId: storeInfo.sessionId,
        storeId: storeId,
        filter: filter
      }
    }).then(response => {
      if (response.data.status === 1) {
        setProducts([...response.data.result]);
        setSelectionModel([]);
      } else if (response.data.status === 3) {
        setStoreInfo({
          ownerFirstName: '',
          ownerLastName: '',
          storeEmail: '',
          storeName: '',
          storeAddress: '',
          colors: {primary: '#000000'}
        });
        setSnackNotice({
          severity: 'warning',
          message: response.data.message
        });
      } else {
        setErrors(response.data.errors);
      }
      setFetchingProducts(false);
    });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className={classes.dialogBox}
      maxWidth={'md'}
    >
      <DialogTitle id="simple-dialog-title">
        <FilterListIcon />
        Select Products
      </DialogTitle>
      <TextField
        fullWidth
        onChange={e => setKeyword(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SvgIcon fontSize="small" color="action">
                <SearchIcon />
              </SvgIcon>
            </InputAdornment>
          )
        }}
        placeholder="Search product"
        variant="outlined"
      />
      <Box className={classes.priceBox}>
        <TextField
          className={classes.filterTextField}
          name="minPrice"
          type="number"
          inputProps={{
            type: 'number',
            min: 1,
          }}
          onChange={e => handleFilterChange(e)}
          placeholder={`${storeInfo.storeCurrencySymbol} Min price`}
          variant="outlined"
          value={filters.minPrice}
        />
        <TextField
          className={classes.filterTextField}
          name="maxPrice"
          type="number"
          inputProps={{
            type: 'number',
            min: 1,
          }}
          onChange={e => handleFilterChange(e)}
          placeholder={`${storeInfo.storeCurrencySymbol} Max price`}
          variant="outlined"
          value={filters.maxPrice}
        />
      </Box>

      <Box className={classes.buttonGroup}>
        <FormControlLabel
          control={
            <Checkbox
              checked={filters.status === 'available now'}
              onChange={handleFilterChange}
              name="status"
            />
          }
          label="Show only available now"
        />
        <Button  size="large"
          startIcon={<ClearIcon />}
          onClick={clearFilters}
          style={{ justifySelf: 'end', textDecoration: 'underline' }}
        >
          Clear filters
        </Button>
      </Box>
      <DataGrid
        autoHeight
        components={{
          NoRowsOverlay: CustomNoRowsOverlay
        }}
        onSelectionModelChange={newSelection => {
          setSelectionModel(newSelection.selectionModel);
        }}
        selectionModel={selectionModel}
        rows={products.map((product, idx) => {
          return {
            title: product.title,
            image: product.images[0].url,
            id: idx + 1,
            color: product.color
          };
        })}
        columns={columns}
        loading={fetchingProducts}
        pageSize={5}
        checkboxSelection
      />
      <Hidden xsDown>
        <Box my={5} display="flex" justifyContent="space-between">
          <Button  size="large"
            color="primary"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={()=>addEligibleProducts(selectedProducts.filter(prd=>!(alreadySelectedProductIds.includes(prd._id))))}
          >
            Save
          </Button>
          <Button  size="large"
            className={classes.error}
            style={{ marginLeft: '15px' }}
            variant="contained"
            fullWidth
            size="large"
            onClick={onClose}
          >
            Cancel
          </Button>
        </Box>
      </Hidden>

      <Hidden smUp>
        <Box my={5} display="block">
          <Button  size="large"
            color="primary"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={()=>addEligibleProducts(selectedProducts.filter(prd=>!(alreadySelectedProductIds.includes(prd._id))))}
            style={{ marginBottom: '8px' }}
          >
            Save
          </Button>
          <Button  size="large"
            className={classes.error}
            variant="contained"
            fullWidth
            size="large"
            onClick={onClose}
          >
            Cancel
          </Button>
        </Box>
      </Hidden>
    </Dialog>
  );
}

SelectProductDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  addEligibleProducts: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  storeId: state.currentStore.storeInfo._id,
  storeInfo: state.currentStore.storeInfo
});

const mapDispatchToProps = dispatch => ({
  FetchStoreProductsStart: requiredInfo =>
    dispatch(FetchStoreProductsStart(requiredInfo)),
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setErrors: errors => dispatch(SetErrors(errors)),
  setStoreInfo: store => dispatch(SetStoreInfo(store))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectProductDialog);
