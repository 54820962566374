import {takeLatest,call,put} from 'redux-saga/effects';
import axios from 'axios';
import {
  FetchStoreCategoriesError,
  FetchStoreCategoriesSuccess, FetchStoreProductsError,
  FetchStoreProductsSuccess,
  FetchStoreOrdersSuccess,
  FetchStoreOrdersError,
  FetchStoreNotificationsSuccess,
  FetchStoreNotificationsError,
  FetchStoreMembersSuccess,
  FetchStoreMembersError,
  SetStoreInfo
} from "./current-store-actions";
import { SetSnackNotice } from '../app/app-actions';


const sessionExpired = false;





export function* fetchStoreCategoriesStart()
{
    yield takeLatest('FETCH_STORE_CATEGORIES_START', fetchStoreCategoriesStartAsync);
}


export function* updateStoreInfoStart()
{
    yield takeLatest('UPDATE_STORE_INFO', updateStoreInfoStartAsync);
}

export function* fetchStoreProductsStart()
{
  yield takeLatest('FETCH_STORE_PRODUCTS_START', fetchStoreProductsStartAsync);
}

export function* fetchStoreOrdersStart()
{
  yield takeLatest('FETCH_STORE_ORDERS_START', fetchStoreOrdersStartAsync);
}

export function* fetchStoreNotificationsStart()
{
  yield takeLatest('FETCH_STORE_NOTIFICATIONS_START', fetchStoreNotificationsStartAsync);
}

export function* fetchStoreMembersStart()
{
  yield takeLatest('FETCH_STORE_MEMBERS_START', fetchStoreMembersStartAsync);
}


export function* fetchStoreCategoriesStartAsync(action)
{

    try
    {
        const categories = yield fetchStoreCategories(action.payload);

        yield put(FetchStoreCategoriesSuccess(categories));
    }
    catch(e)
    {
        if(e.code === 'CUSTOM_3')
        {
          yield put(SetStoreInfo({
        ownerFirstName: '',
        ownerLastName: '',
        storeEmail: '',
        storeName: '',
        storeAddress: '',
        colors: {primary: '#000000'}
      }));
          yield put(SetSnackNotice({
            severity: 'warning',
            message: e.message
          }));
        }
        yield put(FetchStoreCategoriesError(e.message));
    }
}

export function* fetchStoreProductsStartAsync(action)
{

  try
  {
    const products = yield fetchStoreProducts(action.payload);

    yield put(FetchStoreProductsSuccess(products));
  }
  catch(e)
  {
    if(e.code === 'CUSTOM_3')
    {
      yield put(SetStoreInfo({
        ownerFirstName: '',
        ownerLastName: '',
        storeEmail: '',
        storeName: '',
        storeAddress: '',
        colors: {primary: '#000000'}
      }));
      yield put(SetSnackNotice({
        severity: 'warning',
        message: e.message
      })); 
    }
    yield put(FetchStoreProductsError(e.message));
  }
}

export function* fetchStoreOrdersStartAsync(action)
{

  try
  {
    const orders = yield fetchStoreOrders(action.payload);


    yield put(FetchStoreOrdersSuccess(orders));
  }
  catch(e)
  {
    if(e.code === 'CUSTOM_3')
    {
      yield put(SetStoreInfo({
        ownerFirstName: '',
        ownerLastName: '',
        storeEmail: '',
        storeName: '',
        storeAddress: '',
        colors: {primary: '#000000'}
      }));

      yield put(SetSnackNotice({
        severity: 'warning',
        message: e.message
      }));
    }
    yield put(FetchStoreOrdersError(e.message));
  }
}

export function* fetchStoreNotificationsStartAsync(action)
{

  try
  {
    const notifications = yield fetchStoreNotifications(action.payload);

    yield put(FetchStoreNotificationsSuccess(notifications));
  }
  catch(e)
  {
    if(e.code === 'CUSTOM_3')
    {
      yield put(SetStoreInfo({
        ownerFirstName: '',
        ownerLastName: '',
        storeEmail: '',
        storeName: '',
        storeAddress: '',
        colors: {primary: '#000000'}
      }));
      yield put(SetSnackNotice({
        severity: 'warning',
        message: e.message
      }));
    }
    yield put(FetchStoreNotificationsError(e.message));
  }
}

export function* fetchStoreMembersStartAsync(action)
{

  try
  {
    const members = yield fetchStoreMembers(action.payload);

    yield put(FetchStoreMembersSuccess(members));
  }
  catch(e)
  {
    if(e.code === 'CUSTOM_3')
    {
      yield put(SetStoreInfo({
        ownerFirstName: '',
        ownerLastName: '',
        storeEmail: '',
        storeName: '',
        storeAddress: '',
        colors: {primary: '#000000'}
      }));
      yield put(SetSnackNotice({
        severity: 'warning',
        message: e.message
      }));
    }
    yield put(FetchStoreMembersError(e.message));
  }
}


export function* updateStoreInfoStartAsync(action)
{

  try
  {
    yield put(SetSnackNotice({
      severity: 'info',
      message: 'Updating store info.'
    }));

    const data = yield updateStoreInfo(action.payload);

    yield put(SetStoreInfo({sessionId: action.payload.sessionId, ...data}));
    yield put(SetSnackNotice({
      severity: 'success',
      message: 'Store info updated successfully.'
    }));
  }
  catch(e)
  {
    if(e.code === 'CUSTOM_3')
    {
      yield put(SetStoreInfo({
        ownerFirstName: '',
        ownerLastName: '',
        storeEmail: '',
        storeName: '',
        storeAddress: '',
        colors: {primary: '#000000'}
      }));
      yield put(SetSnackNotice({
        severity: 'warning',
        message: e.message
      }));
    }
    else
    {
      yield put(SetSnackNotice({
        severity: 'error',
        message: 'Something went wrong, please try again.'
      }));
    }
  }
}

const updateStoreInfo = async (data)=>
{

    const response = await axios({
        url:'https://kripson-store-server-8qq76.ondigitalocean.app/stores/updateStoreInfo',
        method:'POST',
        data: data
    });
    if(response.data.status === 1)
    {
  
      return(response.data.result);
    }
    else if(response.data.status === 3)
    {
      const err = new Error(response.data.message);
      err.code = 'CUSTOM_3';
      throw err;
    }
    else
    {
      throw response.data.errors;
    }

};

const fetchStoreCategories = async (data)=>
{

    const response = await axios({
        url:'https://kripson-store-server-8qq76.ondigitalocean.app/stores/getCategories',
        method:'POST',
        data:{storeId:data.storeId, sessionId:data.sessionId}
    });
    if(response.data.status === 1)
    {
  
      return(response.data.result);
    }
    else if(response.data.status === 3)
    {
      const err = new Error(response.data.message);
      err.code = 'CUSTOM_3';
      throw err;
    }
    else
    {
      return ([]);
    }

};


const fetchStoreProducts = async (data)=>
{

  const response = await axios({
    url:'https://kripson-store-server-8qq76.ondigitalocean.app/products/getProducts',
    method:'POST',
    data: {...data.requiredInfo, sessionId:data.sessionId}
  });

  if(response.data.status === 1)
  {

    return(response.data.result);
  }
  else if(response.data.status === 3)
  {
    const err = new Error(response.data.message);
    err.code = 'CUSTOM_3';
    throw err;
  }
  else
  {
    return ([]);
  }

};

const fetchStoreOrders = async (data)=>
{
 

  const response = await axios({
    url:'https://kripson-store-server-8qq76.ondigitalocean.app/orders/getOrders',
    method:'POST',
    data: {...data.body, sessionId:data.sessionId},
  });


  if(response.data.status === 1)
  {

    return(response.data.result);
  }
  else if(response.data.status === 3)
  {
    const err = new Error(response.data.message);
    err.code = 'CUSTOM_3';
    throw err;
  }
  else
  {
    return ([]);
  }

};



const fetchStoreNotifications = async (data)=>
{

  const response = await axios({
    url:'https://kripson-store-server-8qq76.ondigitalocean.app/notifications/getNotifications',
    method:'POST',
    data: {storeId:data.requiredInfo, sessionId:data.sessionId, limit: 15}
  });

  if(response.data.status === 1)
  {

    return(response.data.result);
  }
  else if(response.data.status === 3)
  {
    const err = new Error(response.data.message);
    err.code = 'CUSTOM_3';
    throw err;
  }
  else
  {
    return ([]);
  }

};



const fetchStoreMembers= async (data)=>
{
  console.log(data);
  const response = await axios({
    url:'https://kripson-store-server-8qq76.ondigitalocean.app/members/getMembers',
    method:'POST',
    data: {storeId:data.requiredInfo, sessionId:data.sessionId}
  });



  if(response.data.status === 1)
  {

    return(response.data.result);
  }
  else if(response.data.status === 3)
  {
    const err = new Error(response.data.message);
    err.code = 'CUSTOM_3';
    throw err;
  }
  else
  {
    return ([]);
  }

};




