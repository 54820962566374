import React, { useEffect, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Chip,
  ListItem,
  makeStyles,
  Typography
} from '@material-ui/core';
import StyledTreeView from '../../../components/styled-tree-view/StyledTreeView';
import { connect } from 'react-redux';
import axios from 'axios';
import './NavItem.scss';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  chip: {
    height: '24px',
    width: '24px',
    position: 'relative',
    '& *': {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: 'white'
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'space-between',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  productButton: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '4px 0',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const NavItem = ({ className, href, title, storeInfo, ...rest }) => {
  const classes = useStyles();
  const [discountOffers, setDiscountOffers] = useState([]);

  const getDiscounts = () => {
    if (title === 'Discount Offers') {
      axios({
        url:
          'https://kripson-store-server-8qq76.ondigitalocean.app/discounts/getDiscounts',
        method: 'POST',
        data: {
          storeId: storeInfo._id,
          filter: { endDate: { $gte: new Date().getTime() } }
        }
      })
        .then(data => {
          console.log(data);
          if (data.data.status === 1) {
            setDiscountOffers(data.data.result);
          }
        })
        .catch(err => {});
    }
  };

  useEffect(() => {
    getDiscounts();
  }, [storeInfo]);

  return (
    <ListItem
      className={clsx(classes.item, className)}
      disableGutters
      {...rest}
    >
      <Button  size="large"
        activeClassName={classes.active}
        className={
          title === 'Products' ? classes.productButton : classes.button
        }
        component={RouterLink}
        to={href}
      >
        {title}
        {discountOffers && discountOffers.length ? (
          <Chip
            className={`${classes.error} ${classes.chip} animated-chip`}
            label={discountOffers.length}
          ></Chip>
        ) : (
          ''
        )}
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  title: PropTypes.string
};

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => ({
  storeInfo: state.currentStore.storeInfo
});

export default connect(mapStateToProps, mapDispatchToProps)(NavItem);
