import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Hidden, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import data from './data';
import { FetchStoreOrdersStart } from '../../../redux/current-store/current-store-actions';
import { connect } from 'react-redux';
import OrderCard from 'src/components/order-card/order-card';
import { Link, useParams, useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const OrderListView = ({}) => {
  const classes = useStyles();
  const [keyword, setKeyword] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  return (
    <Page className={classes.root} title="Orders">
      <Container maxWidth={false}>
        <Toolbar
          setKeyword={setKeyword}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          startDate={startDate}
          endDate={endDate}
        />
        <Box mt={3}>
          <Results
            keyword={keyword}
            startDate={startDate}
            endDate={endDate}
          />
        </Box>
      </Container>
    </Page>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OrderListView);
