import { Box, Button, Card, CardHeader, Divider, makeStyles, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';


const useStyles = makeStyles(theme => ({
    form: {

    },
    formBody: {
        display: 'grid',
        gridRowGap: theme.spacing(1),
        padding: theme.spacing(2)
    }
}))


const TemplateFormGenerator = ({ controls, submit, submitLabel, existingObject, formHeader}) => {

    const [returnObject, setReturnObject] = useState({});
    const classes = useStyles();

    useEffect(() => {
        if (existingObject) {
            setReturnObject(existingObject)
        }
    }, [existingObject])

    const handleChange = (e) => {
        setReturnObject({
            ...returnObject,
            [e.target.name]: e.target.value
        });
    }

    return <Card className={classes.form}>
        <CardHeader title={formHeader || ''} />
        <Divider />
        <Box className={classes.formBody}>
            {controls ? controls.map((control) => {
                return <TextField
                    variant="outlined"
                    name={control.name}
                    type={control.type}
                    value={returnObject[control.name] || ''}
                    label={control.label}
                    onChange={handleChange}
                />
            }) : ''}
            <Button  size="large" color="primary" variant="contained" onClick={() => submit(returnObject)}>{submitLabel || 'Submit'}</Button>
        </Box>


    </Card>
}


export default TemplateFormGenerator;