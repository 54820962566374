import React, { useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    Card,
    Chip,
    Container,
    Divider,
    Grid,
    makeStyles,
    useMediaQuery
} from '@material-ui/core';

import Page from 'src/components/Page';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import AwesomeSliderStyles from 'react-awesome-slider/src/styled/fold-out-animation/fold-out-animation.scss';
import '../../customStyles.scss';
import { useNavigate, useParams } from "react-router-dom";
import { FetchStoreOrdersStart, SetStoreInfo } from "../../redux/current-store/current-store-actions";
import { SetErrors, SetSnackNotice } from "../../redux/app/app-actions";
import { connect } from "react-redux";
import axios from "axios";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import * as Swal from "sweetalert2";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import CachedIcon from '@material-ui/icons/Cached';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import momentTimeZone from 'moment-timezone';
import { AddCircleOutline } from '@material-ui/icons';
import OrderProductCard from 'src/components/order-product-card/order-product-card';



const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        padding: theme.spacing(3),
        width: '100%',
    },
    orderDetailsSection: {
        padding: '20px !important',
        display: 'grid',
        alignContent: 'start',
        gridRowGap: '20px'
    },
    orderColor: {
        height: '25px',
        width: '25px',
        borderRadius: '25px'
    },
    sizeOptionContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, 50px)',
        gridColumnGap: '10px'
    },
    sizeChoice: {
        backgroundColor: 'white',
        display: 'grid',
        justifyContent: 'center'
    },
    statusSection: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    detailsSectionRows: {
    },
    container: {
        maxHeight: 440,
    },
    customerDetailsSection: {
        marginBottom: '16px',
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
        marginLeft: theme.spacing(1),
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        marginLeft: theme.spacing(1),
    },
    success: {
        backgroundColor: theme.palette.success.main,
        color: 'white',
        marginLeft: theme.spacing(1)
    },
    buttonContainer: {
        marginTop: theme.spacing(2)
    }

}));

const RefundDetailsView = ({ storeId, storeInfo, setStoreInfo, setSnackNotice, setErrors }) => {
    const classes = useStyles();

    const { refundRequestId } = useParams();
    const navigate = useNavigate();

    const [refundRequest, setRefundRequest] = useState({});
    const [order, setOrder] = useState({});
    const isSmallScreen = useMediaQuery("(max-width:1050px)");
    const [productImages, setProductImages] = useState({});

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);


    const retrieveRefundRequest = (requestId, storeId) => {
        axios({
            url: 'https://kripson-store-server-8qq76.ondigitalocean.app/refundRequests/getRefundRequestById',
            method: 'POST',
            data: {
                sessionId: storeInfo.sessionId,
                storeId: storeId,
                refundRequestId: refundRequestId
            }
        }).then((response) => {
            if (response.data.status === 1) {

                setRefundRequest(response.data.result[0])
            }
            else if (response.data.status === 3) {
                setStoreInfo({
                    ownerFirstName: '',
                    ownerLastName: '',
                    storeEmail: '',
                    storeName: '',
                    storeAddress: '',
                    colors: {primary: '#000000'}
                });
                setSnackNotice({
                    severity: 'warning',
                    message: response.data.message
                });
            }
            else {
                setErrors(response.data.errors);
            }

        });
    }

    const retrieveOrder = (orderId, storeId) => {
        axios({
            url: 'https://kripson-store-server-8qq76.ondigitalocean.app/orders/getOrderById',
            method: 'POST',
            data: {
                sessionId: storeInfo.sessionId,
                storeId: storeId,
                orderId: orderId
            }
        }).then((response) => {
            if (response.data.status === 1) {

                setOrder(response.data.result[0])
            }
            else if (response.data.status === 3) {
                setStoreInfo({
                    ownerFirstName: '',
                    ownerLastName: '',
                    storeEmail: '',
                    storeName: '',
                    storeAddress: '',
                    colors: {primary: '#000000'}
                });
                setSnackNotice({
                    severity: 'warning',
                    message: response.data.message
                });
            }
            else {
                setErrors(response.data.errors);
            }

        });
    }

    const retrieveOrderProductImages = (productIds, storeId) => {
        axios({
            url: 'https://kripson-store-server-8qq76.ondigitalocean.app/products/getProductImagesById',
            method: 'POST',
            data: {
                sessionId: storeInfo.sessionId,
                storeId: storeId,
                productIds: productIds
            }
        }).then((response) => {
            if (response.data.status === 1) {

                const productImagesMap = {};
                response.data.result.map(product => {
                    productImagesMap[product._id] = product.images[0].url;
                    return;
                });
                setProductImages(productImagesMap);
            }
            else if (response.data.status === 3) {
                setStoreInfo({
                    ownerFirstName: '',
                    ownerLastName: '',
                    storeEmail: '',
                    storeName: '',
                    storeAddress: '',
                    colors: {primary: '#000000'}
                });
                setSnackNotice({
                    severity: 'warning',
                    message: response.data.message
                });
            }
            else {
                setErrors(response.data.errors);
            }

        });
    }

    useEffect(() => {

        if (refundRequestId && storeId) {
            retrieveRefundRequest(refundRequestId, storeId);
        }
    }, [refundRequestId, storeId]);


    useEffect(() => {
        if (refundRequest && Object.keys(refundRequest).length) {
            retrieveOrder(refundRequest.orderId, refundRequest.storeId);
        }
    }, [refundRequest]);


    useEffect(() => {
        if (order && order.products && order.products.length) {
            retrieveOrderProductImages(order.products.map(product => product._id), storeId)
        }
    }, [order]);

    const approveRequest = async (order, refundRequest) => {
        setSnackNotice({
            message: `Approving refund request`,
            severity: 'info'
        });

        const response = await axios({
            url: 'https://kripson-store-server-8qq76.ondigitalocean.app/refundRequests/approveRefundRequest',
            method: 'POST',
            onUploadProgress: progressEvent => console.log(progressEvent),
            data: {
                sessionId: storeInfo.sessionId,
                orderId: order._id,
                storeId: order.storeId,
                refundRequestId
            },
        });
        if (response.data.status === 1) {

            setSnackNotice({
                severity: 'success',
                message: response.data.message
            });
        }
        else if (response.data.status === 3) {
            setStoreInfo({
                ownerFirstName: '',
                ownerLastName: '',
                storeEmail: '',
                storeName: '',
                storeAddress: '',
                colors: { primary:'#000000'}
            });
            setSnackNotice({
                severity: 'warning',
                message: response.data.message
            });
        }
        else {
            setErrors(response.data.errors);
        }

        retrieveRefundRequest(refundRequestId, storeId);
    }

    const declineRequest = async (reason) => {
        setSnackNotice({
            message: `Declining refund request`,
            severity: 'info'
        });

        const response = await axios({
            url: 'https://kripson-store-server-8qq76.ondigitalocean.app/refundRequests/declineRefundRequest',
            method: 'POST',
            onUploadProgress: progressEvent => console.log(progressEvent),
            data: {
                sessionId: storeInfo.sessionId,
                storeId: order.storeId,
                orderId: refundRequest.orderId,
                refundRequestId,
                reasonForDecline: reason
            },
        });
        if (response.data.status === 1) {

            setSnackNotice({
                message: `Refund request declined`,
                severity: 'warning'
            });
        }
        else if (response.data.status === 3) {
            setStoreInfo({
                ownerFirstName: '',
                ownerLastName: '',
                storeEmail: '',
                storeName: '',
                storeAddress: '',
                colors: {primary: '#000000'}
              });
            setSnackNotice({
                severity: 'warning',
                message: response.data.message
            });
        }
        else {
            setErrors(response.data.errors);
        }

        retrieveRefundRequest(refundRequestId, storeId);
    }


    const fireDeclineConfirmation = () => {

        Swal.fire({
            title: 'Please, provide a reason for declining.',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, decline it!',
            showLoaderOnConfirm: true,
            preConfirm: (reason) => {
                if (reason && reason.length) {
                    return reason;
                }
                else {
                    Swal.showValidationMessage(
                        `Please, provide a reason for decline before submission`
                    )
                }



            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                declineRequest(result.value);
            }
        })
    }

    const getRefundRequestStatusIcon = status => {
        switch (status) {
            case 'pending':
                return <CachedIcon />;
            case 'declined':
                return <ClearIcon />;
            case 'approved':
                return <CheckIcon />;
            default:
                return;
        }
    };

    const getRefundRequestStatusClass = status => {
        switch (status) {
            case 'pending':
                return classes.warning;
            case 'declined':
                return classes.error;
            case 'approved':
                return classes.success;
            default:
                return;
        }
    };



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };



    const columns = [
        { id: 'productImage', label: 'Product', minWidth: 20 },
        { id: 'title', label: 'Title', minWidth: 100 },
        {
            id: 'quantityToRefund',
            label: 'Quantity to refund',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toLocaleString('en-IN'),
        },
        {
            id: 'selectedSize',
            label: 'Size',
            minWidth: 50,
            align: 'center',
            format: (value) => value.toFixed(2),
        },
        {
            id: 'amount',
            label: 'Amount',
            minWidth: 100,
            align: 'left',
            format: (value) => value.toLocaleString('en-IN'),
        },
        {
            id: 'reason',
            label: 'Reason for refund',
            minWidth: 125,
            align: 'left'
        },
    ];

    return (<Page
        className={classes.root}
        title="Refund request details"
    >
        <Box mb={2} className={classes.statusSection}>
            <Box display="flex" alignItems="center" className={classes.statusSection}>
                <Typography variant="h5">
                    Created Date:
                </Typography>

                <Typography variant="h5">
                    {momentTimeZone(refundRequest.createdDate).tz("Asia/Kathmandu").format('DD/MM/YYYY')}
                </Typography>
            </Box>

            <Box display="flex" alignItems="center">
                <Typography variant="h5">
                    Refund Request Status
                </Typography>

                <Chip className={getRefundRequestStatusClass(refundRequest.status)} icon={getRefundRequestStatusIcon(refundRequest.status)} label={refundRequest.status} />
            </Box>


        </Box>

        <Paper className={classes.customerDetailsSection}>
            <Box p={2}>
                <Typography fullWidth variant="h3" mb={2}>
                    Refund Request Details
                </Typography>
            </Box>


            <Divider />
            <Box p={2}>
                <Grid container spacing={2} >
                    <Grid item xs={12} md={4}>
                        <Typography variant="h5">
                            Order Id
                        </Typography>
                        <Typography variant="body2">
                            {refundRequest && Object.keys(refundRequest).length ? refundRequest.orderId : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h5">
                            Refund Request Number
                        </Typography>
                        <Typography variant="body2">
                            {refundRequest && Object.keys(refundRequest).length ? refundRequest.refundRequestNumber : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h5">
                            Refund Total
                        </Typography>
                        <Typography variant="body2">
                            {storeInfo.storeCurrencySymbol} {refundRequest && Object.keys(refundRequest).length ? refundRequest.refundTotal : ''}
                        </Typography>

                    </Grid>
                    <Grid item xs={12} md={4}>

                    </Grid>
                </Grid>
            </Box>
        </Paper>
        <Box mb={2} display="flex" justifyContent="flex-end">
            {/* {order && order.tags && order.tags.includes('fully refunded') ? <><Button  size="large" variant="contained" color= "primary" onClick={() => setMode('refundMode')}>
                See refund history
            </Button></>:
            <Button  size="large" startIcon = {<AddCircleOutline/>} variant="contained" color= "primary" onClick={() => setMode('refundMode')}>
                Create Refund
            </Button>} */}
        </Box>
        {isSmallScreen ?
            refundRequest
                && refundRequest.products
                && order && order.products
                ?
                Object.keys(refundRequest.products).map(productId => ({ ...refundRequest.products[productId], ...order.products.find(product => product._id === productId) })).map(product => (<OrderProductCard product={product} productImage={productImages && productImages[product._id]} />))
                : '' :
            <Paper>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {refundRequest && refundRequest.products && order && order.products ? Object.keys(refundRequest.products).map(productId => ({ ...refundRequest.products[productId], ...order.products.find(product => product._id === productId) })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((product, idx) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={product._id}>
                                        {columns.map((column) => {
                                            const value = column.id === 'productImage' ? Object.keys(productImages).length ? <Avatar src={productImages[product._id]} /> : '' : product[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            }) : ''}
                            <TableRow>
                                <TableCell>
                                    <Typography variant='h4'>
                                        Refund Total
                                    </Typography>
                                </TableCell>
                                <TableCell>

                                </TableCell>
                                <TableCell>

                                </TableCell>
                                <TableCell>


                                </TableCell>
                                <TableCell>
                                    <Typography variant='h4'>
                                        {storeInfo.storeCurrencySymbol} {refundRequest && Object.keys(refundRequest).length ? refundRequest.refundTotal : ''}
                                    </Typography>
                                </TableCell>
                                <TableCell>

                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={refundRequest && refundRequest.products ? refundRequest.products.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        }
        {refundRequest && refundRequest.status === 'pending' ? <Grid container justify="flex-end" className={classes.buttonContainer}>
            <Grid item>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Button  size="large" fullWidth onClick={() => {
                            approveRequest(order, refundRequest);
                        }} variant="contained" color="primary" startIcon={<EditIcon />}>
                            Approve
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button  size="large" fullWidth className={classes.error} variant="contained" startIcon={<DeleteIcon />} onClick={() => fireDeclineConfirmation()}>
                            Decline
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

        </Grid> : ''}

    </Page>
    );
};

const mapStateToProps = state =>
({
    storeId: state.currentStore.storeInfo._id,
    storeInfo: state.currentStore.storeInfo,
});

const mapDispatchToProps = dispatch => (
    {
        FetchStoreOrdersStart: (requiredInfo) => dispatch(FetchStoreOrdersStart(requiredInfo)),
        setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
        setErrors: (errors) => dispatch(SetErrors(errors)),
        setStoreInfo: store => dispatch(SetStoreInfo(store)),
    });

export default connect(mapStateToProps, mapDispatchToProps)(RefundDetailsView);
