import React, { useState } from 'react';
import {
    Container,
    Card,
    Button,
    Box,
    Typography,
    makeStyles,
    TextField
} from '@material-ui/core';
import Page from 'src/components/Page';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';
import { connect } from 'react-redux';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {SetSnackNotice, SetErrors} from '../../../redux/app/app-actions';
import {SetStoreInfo} from '../../../redux/current-store/current-store-actions';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    verifyAccountContainer: {
        display: 'grid',
        padding: theme.spacing(2)
    },
    verifyButtonBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

    },
    resendLink: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText
    },
    success: {
        backgroundColor: theme.palette.success.main,
        color: 'white'
    }
}));

const VerifyView = ({ storeInfo, setSnackNotice, setStoreInfo, setErrors }) => {
    const classes = useStyles();
    const [verificationCode, setVerificationCode] = useState(null);
    const navigate = useNavigate();

    const verifyAccount = () => {
        axios({
            url: `https://kripson-store-server-8qq76.ondigitalocean.app/stores/verifyAccount`,
            method:
            'POST',
            data: {
                sessionId: storeInfo.sessionId,
                verificationCode:verificationCode,
                storeId: storeInfo._id,
            }
        }).then((response)=>{
            if(response.data.status === 1)
            {
        
                setSnackNotice({
                    message:'Account Verified',
                    severity: 'success'
                });

              

                setStoreInfo({
                    ...storeInfo,
                    isVerified: true,
                })

                navigate('/app/account');
            }
            else if (response.data.status === 3) {
                setStoreInfo({});
                setSnackNotice({
                  severity: 'warning',
                  message: response.data.message
                });
            }
            else {
                setSnackNotice({
                    message:response.data.message,
                    severity: 'error'
                })
                setErrors(response.data.errors)
            }
        }).catch((error)=>{
            setSnackNotice({
                message:'Something went wrong, please try again',
                severity: 'error'
            })
        })
    }

    const sendVerification = () => {
        axios({
            url: `https://kripson-store-server-8qq76.ondigitalocean.app/stores/sendVerification`,
            method: 'POST',
            data: {
                sessionId: storeInfo.sessionId,
                purpose: 'accountVerification',
                storeEmail: storeInfo.storeEmail,
                storeId: storeInfo._id,
            }
        }).then((response)=>{
            if(response.data.status === 1)
            {
                setSnackNotice({
                    message:'Verification code sent',
                    severity: 'success'
                });
            }
            else if (response.data.status === 3) {
                setStoreInfo({});
                setSnackNotice({
                  severity: 'warning',
                  message: response.data.message
                });
            }
            else {
                setSnackNotice({
                    message:response.data.message,
                    severity: 'error'
                })
                setErrors(response.data.errors)
            }
        })
    }

    return (
        <Page
            className={classes.root}
            title="Verify Account"
        >
            <Container maxWidth="lg">
                <Box mb={2}>
                    <Typography variant="h2">
                        Verify Your Account
                    </Typography>
                </Box>
                <Card className={classes.verifyAccountContainer}>
                    <TextField
                        type="text"
                        variant="outlined"
                        placeholder="verification code"
                        helperText="Please enter the verification code sent to you via email"
                        onChange={(e) => setVerificationCode(e.target.value)}
                    />

                    <Box mt={2} className={classes.verifyButtonBox}>
                        <Box onClick={sendVerification}>
                            <Typography variant="body3" className={classes.resendLink} >Resend the verification code</Typography>

                        </Box>
                        <Button  size="large" onClick={verifyAccount} className={classes.success} variant="contained" disabled={!(verificationCode && verificationCode.length)}>
                            Verify Account
                        </Button>
                    </Box>

                </Card>
            </Container>
        </Page>
    );
};

const mapDispatchToProps = dispatch => (
    {
        setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
        setErrors: (errors) => dispatch(SetErrors(errors)),
        setStoreInfo: (storeInfo) => dispatch(SetStoreInfo(storeInfo))
    });
const mapStateToProps = state =>
({

    storeInfo: state.currentStore.storeInfo,
});


export default connect(mapStateToProps, mapDispatchToProps)(VerifyView);
