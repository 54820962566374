import * as yup from 'yup';


const schemaMap = {
    newStore: {

        ownerFirstName: yup.string().required(),
        ownerLastName: yup.string().required(),
        password: yup.string().required(),
        policy: yup.boolean().required(),
        storeAddress: yup.string().required(),
        storeName: yup.string().required(),
        colors: yup.object().required().shape({
            primary: yup.string().required()
        }),
        storeEmail: yup.string().email(),
        storeCategory: yup.string().required(),
        storeBanner: yup.object().required().shape({
            type: yup.string().required(),
            base64data: yup.string().required()
        }),
        createdOn: yup.date().default(function () {
            return new Date();
        })
    },
    updateStore: {

        ownerFirstName: yup.string().required(),
        ownerLastName: yup.string().required(),
        policy: yup.boolean().required(),
        storeAddress: yup.string().required(),
        storeName: yup.string().required(),
        colors: yup.object().required().shape({
            primary: yup.string().required()
        }),
        storeEmail: yup.string().email(),
        storeCategory: yup.string().required(),
        storeBanner: yup.object().required().shape({
            key: yup.string().required(),
            url: yup.string().required()
        }),
        createdOn: yup.date().default(function () {
            return new Date();
        }),
        refundDuration: yup.number('Refund duration should be a number').min(0, 'Refund duration days should be 0 or greater.' )
    },
    newStoreCategory: {
        categoryName: yup.string().min(1).max(10).required(),
        storeId: yup.string().min(20).required(),
    },
    removeStoreCategory: {
        categoryId: yup.string().min(20).required(),
        storeId: yup.string().min(20).required(),
    },
    getStoreCategories: {
        storeId: yup.string().min(20).required(),
    },
    getStoreProducts: {
        storeId: yup.string().min(20).required(),
    },
    getStoreProductById: {
        storeId: yup.string().min(20).required(),
        productId: yup.string().min(20).required()
    },
    forgotPassword: {
        email: yup.string().email().required('Please supply a destination store email for the verification code.'),
    },
    resetPassword: {
        verificationCode: yup.string().required(),
        password: yup.string().required(),
        storeEmail: yup.string().email().required(),
    },
    getStoreOrders: {
        storeId: yup.string().min(20).required(),
    },
    getStoreOrderById: {
        storeId: yup.string().min(20).required(),
        orderId: yup.string().min(20).required()
    },
    removeProduct: {
        storeId: yup.string().min(20).required(),
        productId: yup.string().min(20).required()
    },
    newProduct: {
        price: yup.number().min(1).required('Price is required'),
        sizes: yup.array().min(1).required('size is required'),
        color: yup.string().required('Color is required'),
        stockQuantity: yup.object().required('stockQuantity is required'),
        status: yup.string().required('Status is required'),
        costPrice: yup.number().min(1).required('Cost price is required'),
        title: yup.string().required('Title is required'),
        description: yup.string().required('Description is required'),
        images: yup.array().min(1).required('At least one product image is required'),
        isRefundable: yup.boolean().required('Please mark whether this product is refundable or not'),
        isDeliverable: yup.boolean().required('Please mark whether this product is deliverable or not'),
    },
    updateProduct: {
        price: yup.number().min(1).required('Price is required'),
        sizes: yup.array().min(1).required('size is required'),
        color: yup.string().required('Color is required'),
        stockQuantity: yup.object().required('stockQuantity is required'),
        status: yup.string().required('Status is required'),
        costPrice: yup.number().min(1).required('Cost price is required'),
        title: yup.string().required('Title is required'),
        description: yup.string().required('Description is required'),
        images: yup.array().min(1).required('At least one product image is required')
    },
    removeOrder: {
        storeId: yup.string().min(20).required(),
        orderId: yup.string().min(20).required()
    },
    newOrder: {
        products: yup.array().min(1).required('At least one product should be ordered'),
        status: yup.string().matches(/processing/).required('Status is required'),
        paymentStatus: yup.string().required('Payment Status is required'),
        dueDate: yup.number().when('paymentStatus', { is: 'unpaid', then: yup.number().required('Due Date is required') }),
        customer: yup.object().required().shape({
            name: yup.string().required('name is required'),
            address: yup.string().required('address is required'),
            phonenumber: yup.string().required('phonenumber is required'),
            email: yup.string().required('email is required'),
        }),
    },
    updateOrder: {
        products: yup.array().min(1).required('At least one product should be ordered'),
        storeId: yup.string().min(20).required('Store Id is required'),
        total: yup.number().min(1).required('Total is required'),
        status: yup.string().required('Status is required'),
        customer: yup.object().required().shape({
            name: yup.string().min(1).required('name is required'),
            address: yup.string().min(1).required('address is required'),
            phonenumber: yup.string().min(1).required('phonenumber is required'),
            email: yup.string().min(1).required('email is required'),
        }),
    },
    newDiscount: {
        title: yup.string().required('Title is required'),
        code: yup.string().required('Discount code is required'),
        type: yup.string().required('Discount type is required'),
        value: yup.string().required('Discount value is required'),
        appliesTo: yup.string().required('Please, select how to apply the discount'),
        canUseInConjunction: yup.boolean().required('Please state if this code can be used with other discount codes'),
        // eligibleCategories: [],
        // eligibleProducts: [],
        // maxNumberofUse: 1,
        // maxNumberofUsePerOrder: 1,
        // maxNumberofUsePerCustomer: 1,
        // productLimitPerUse: 1,
        // orderRequirements: [],
        // customerRequirements: [],
        startDate: yup.date().required('Start date is required'),
        endDate: yup.date().required('End date is required'),
    }
}



export const validateData = async (schemaName, data) => {
    let schema = yup.object().shape(schemaMap[schemaName]);

    try {
        const validObj = await schema.validate(data, { abortEarly: false });
        return { validity: true, validObj: validObj, errors: [] };
    }
    catch (e) {
        console.log(e);
        return { validity: false, errorMap: e.inner, errors: e.errors };
    }



}