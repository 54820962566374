import React, { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Avatar, Box, Button, Divider, Drawer, Hidden, List, Typography, makeStyles } from "@material-ui/core";
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon,
} from "react-feather";
import NavItem from "./NavItem";
import StyledTreeView from "../../../components/styled-tree-view/StyledTreeView";
import { FetchStoreCategoriesStart, SetStoreInfo } from "../../../redux/current-store/current-store-actions";
import { connect } from "react-redux";
import axios from "axios";
import { SetErrors } from "src/redux/app/app-actions";

const user = {
  avatar: "/static/images/avatars/avatar_6.png",
  jobTitle: "Senior Developer",
  name: "Katarina Smith",
};

const items = [
  {
    href: "/app/dashboard",
    icon: BarChartIcon,
    title: "Dashboard",
  },
  {
    href: "/app/customers",
    icon: UsersIcon,
    title: "Customers",
  },
  {
    href: "/app/products",
    icon: ShoppingBagIcon,
    title: "Products",
  },
  {
    href: "/app/account",
    icon: UserIcon,
    title: "Account",
  },
  {
    href: "/app/settings",
    icon: SettingsIcon,
    title: "Settings",
  },
  {
    href: "/login",
    icon: LockIcon,
    title: "Login",
  },
  {
    href: "/register",
    icon: UserPlusIcon,
    title: "Register",
  },
  {
    href: "/404",
    icon: AlertCircleIcon,
    title: "Error",
  },
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
}));

const NavBar = ({ onMobileClose, storeInfo, SetStoreInfo, setSnackNotice, setErrors, openMobile, categories, storeId }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);





  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar className={classes.avatar} component={RouterLink} src={storeInfo && storeInfo.storeBanner ? storeInfo.storeBanner.url : ""} to="/app/account" />
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {storeInfo ? storeInfo.storeName : ""}
        </Typography>

      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {categories
            ? categories.map((category) => {
                return <NavItem href={"/pos/products/" + category._id} key={category.categoryName} title={category.categoryName} />;
              })
            : ""}
        </List>
      </Box>
      <Box display="flex" flexGrow={1} p={2} alignItems={'flex-end'}>
        <List style={{width: '100%'}}>
          <NavItem href={"/pos/discountOffers"} key={storeId} title={"Discount Offers"} />
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer anchor="left" classes={{ paper: classes.mobileDrawer }} onClose={onMobileClose} open={openMobile} variant="temporary">
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
      <style jsx>
        {`
                .slider{
                  --loader-bar-color: ${storeInfo.colors.primary} !important;
                },
              `}
      </style>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

const mapDispatchToProps = (dispatch) => ({
  setStoreInfo: (storeInfo) => dispatch(SetStoreInfo(storeInfo)),
  SetStoreInfo: (customerInfo) => dispatch(SetStoreInfo(customerInfo)),
  setErrors: (errors) => dispatch(SetErrors(errors)),
});

const mapStateToProps = (state) => ({

  storeInfo: state.currentStore.storeInfo,
  categories: state.currentStore.categories,
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
