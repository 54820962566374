import {all,call} from 'redux-saga/effects';
import {fetchStoreCategoriesStart, fetchStoreNotificationsStart, fetchStoreOrdersStart, fetchStoreMembersStart, updateStoreInfoStart} from "./current-store/current-store-saga";
import {fetchStoresStart} from "./app/app-saga";
import { applyDiscountCodesStart } from './cart/cart-saga';




export default function* rootSaga()
{
    yield all([
        call(fetchStoreCategoriesStart),
      call(fetchStoresStart),
      call(fetchStoreOrdersStart),
      call(fetchStoreNotificationsStart),
      call(fetchStoreMembersStart),
      call(applyDiscountCodesStart),
      call(updateStoreInfoStart)
    ])
};
