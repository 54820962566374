import { Box, Button, makeStyles, MenuItem, TextField, FormControlLabel, Switch } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { flagCurrencyMap } from 'src/flagCurrencyMap';
import countryPaymentMethodMap from 'src/views/settings/SettingsView/country-payment-method-map';
import FlagIcon from '../flag-component/flag-component';
import { connect } from 'react-redux';
import { SetErrors, SetSnackNotice } from 'src/redux/app/app-actions';
import { SetStoreInfo } from 'src/redux/current-store/current-store-actions';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    currencyContainer: {
        padding: theme.spacing(2)
    },
    paymentMethodLogo: {
        height: '44px',
        width: 'auto',
    },
    currency: {
        marginLeft: theme.spacing(1)
    },
    selectMenuItem: {
        display: 'flex',
        alignItems: 'center',
    }
}));

const PaymentMethodSetupForm = ({ setSnackNotice, storeInfo, setErrors, setStoreInfo, callBack }) => {


    const [availableMethods, setAvailableMethods] = useState([]);
    const [selectedMethod, setSelectedMethod] = useState('');
    const [paymentMethod, setPaymentMethod] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const classes = useStyles();


    useEffect(() => {
        setAvailableMethods(Object.keys(countryPaymentMethodMap));
    }, [countryPaymentMethodMap])

    useEffect(() => {

        if (selectedMethod) {

            validatePaymentMethod();
        }

    }, [paymentMethod, selectedMethod])


    const validatePaymentMethod = () => {
        countryPaymentMethodMap[selectedMethod].controls.forEach(control => {
            if (!Boolean(paymentMethod[control.name])) {
                setValidationErrors({ ...validationErrors, [control.name]: false });
            }
            else {
                delete validationErrors[control.name];
            }
        })
    }

    const handleFormControlChange = (e) => {

        setPaymentMethod({
            ...paymentMethod,
            [e.target.name]: e.target.value
        });
    }

    const addPaymentMethod = async () => {

        try {
            const addResult = await axios({
                url: 'https://kripson-store-server-8qq76.ondigitalocean.app/stores/addPaymentMethod',
                method: 'POST',
                data: {
                    sessionId: storeInfo.sessionId,
                    storeId: storeInfo._id,
                    paymentMethod: {
                        isPrimary: false,
                        ...paymentMethod,
                        name: selectedMethod,
                        availableCountries: countryPaymentMethodMap[selectedMethod].availableCountries
                    }
                }

            });

            console.log(addResult);

            if (addResult.data.status === 1) {
                setSnackNotice({
                    severity: 'success',
                    notice: addResult.data.message
                });

                callBack();
            }

            if (addResult.data.status === 2) {
                setSnackNotice({
                    severity: 'error',
                    notice: addResult.data.message
                });

                setErrors(addResult.data.errors)
            }

            if (addResult.data.status === 3) {
                setStoreInfo({});
            }

        }
        catch (err) {

        }
    }

    return <Box p={2}>


        <TextField
            select
            value={selectedMethod}
            onChange={e => setSelectedMethod(e.target.value)}
            helperText={'Please select the currency for store transactions'}
            variant="outlined"
            name="selectedMethod"
            fullWidth
            error={!selectedMethod}
            margin="normal"
        >
            {availableMethods.map(method => (
                <MenuItem value={method} >
                    <Box className={classes.selectMenuItem}>
                        <img className={classes.paymentMethodLogo} src={`/static/images/${countryPaymentMethodMap[method].logo}`} alt={method} />
                        <span className={classes.currency}>
                            {method}
                        </span>
                    </Box>

                </MenuItem>
            ))}
        </TextField>

        { selectedMethod && countryPaymentMethodMap[selectedMethod].availableCountries.includes(storeInfo.storeCurrency) ? <Box mb={1}>
            <FormControlLabel
                control={
                    <Switch
                        checked={paymentMethod.isPrimary}
                        onChange={(e) => {
                            setPaymentMethod({
                                ...paymentMethod,
                                isPrimary: e.target.checked
                            });
                        }}
                        name="isPrimary"
                        color="primary"
                    />
                }
                label="Make primary payment method?"
            />
        </Box> : ''}




        {selectedMethod && countryPaymentMethodMap[selectedMethod].controls ?
            countryPaymentMethodMap[selectedMethod].controls.map(control => (<TextField
                value={paymentMethod[control.name]}
                onChange={handleFormControlChange}
                helperText={control.helperText || ''}
                variant="outlined"
                name={control.name}
                fullWidth
                error={!paymentMethod[control.name]}
                margin="normal"
            />))
            : ''}

        <Button  size="large" variant="contained" color="primary"  size="large" disabled={!(selectedMethod) || validationErrors && Object.keys(validationErrors).length > 0} onClick={addPaymentMethod}>Add Payment Method</Button>


    </Box>;
}

const mapStateToProps = (state) => ({
    storeInfo: state.currentStore.storeInfo,
});

const mapDispatchToProps = (dispatch) => ({
    setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
    setErrors: (errors) => dispatch(SetErrors(errors)),
    setStoreInfo: store => dispatch(SetStoreInfo(store))
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodSetupForm);