import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  IconButton
} from '@material-ui/core';
import { connect } from "react-redux";
import DeleteIcon from '@material-ui/icons/Delete';
import EditButton from '@material-ui/icons/Edit';
import axios from "axios";
import { SetErrors, SetSnackNotice } from "../../../redux/app/app-actions";
import { FetchStoreCategoriesSuccess, SetStoreInfo } from "../../../redux/current-store/current-store-actions";
import EditCategoryForm from './edit-category-form';


const useStyles = makeStyles((theme) => ({
  root: {},
  iconButton: {
    margin: '0 auto'
  }

}));

const AllCategoryList = ({ className, storeInfo, storeCategories, storeId, setSnackNotice, setStoreInfo, setErrors, FetchStoreCategoriesSuccess, ...rest }) => {
  const classes = useStyles();
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [mode, setMode] = useState('list');
  const [editingCategory, setEditingCategory] = useState({})
  const [categories, setCategories] = useState([]);



  const deleteCategory = async (categoryId, categoryName) => {
    setSnackNotice({
      message: `Deleting ${categoryName} category`,
      severity: 'info'
    });

    const response = await axios({
      url: 'https://kripson-store-server-8qq76.ondigitalocean.app/stores/removeCategory',
      method: 'POST',
      onUploadProgress: progressEvent => console.log(progressEvent),
      data: {
        sessionId: storeInfo.sessionId,
        categoryId,
        storeId
      },
    });

    if (response.data.status === 1) {

      FetchStoreCategoriesSuccess([...categories.filter(category => category._id !== categoryId)]);

      setSnackNotice({
        message: `${categoryName} category deleted`,
        severity: 'success'
      });
    }
    else if (response.data.status === 3) {
      setStoreInfo({
        ownerFirstName: '',
        ownerLastName: '',
        storeEmail: '',
        storeName: '',
        storeAddress: '',
        colors: { primary: '#000000' }
      });
      setSnackNotice({
        severity: 'warning',
        message: response.data.message
      });
    }
    else {
      setErrors(response.data.errors);
      setSnackNotice({
        message: 'Product creation failed',
        severity: 'error'
      });
    }
  }


  useEffect(() => {

    if (storeCategories && storeCategories.length > 0) {
      setCategories([...storeCategories]);
    }

    console.log(storeCategories);
  }, [storeCategories]);


  const handleSelectAll = (event) => {
    let newSelectedCategoryIds;

    if (event.target.checked) {
      newSelectedCategoryIds = categories.map((category) => category.id);
    } else {
      newSelectedCategoryIds = [];
    }

    setSelectedCategoryIds(newSelectedCategoryIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCategoryIds.indexOf(id);
    let newSelectedCategoryIds = [];

    if (selectedIndex === -1) {
      newSelectedCategoryIds = newSelectedCategoryIds.concat(selectedCategoryIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCategoryIds = newSelectedCategoryIds.concat(selectedCategoryIds.slice(1));
    } else if (selectedIndex === selectedCategoryIds.length - 1) {
      newSelectedCategoryIds = newSelectedCategoryIds.concat(selectedCategoryIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCategoryIds = newSelectedCategoryIds.concat(
        selectedCategoryIds.slice(0, selectedIndex),
        selectedCategoryIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCategoryIds(newSelectedCategoryIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    mode === 'edit' && editingCategory && editingCategory._id ? <EditCategoryForm category={editingCategory} setMode={setMode}/> :
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <PerfectScrollbar>
          <Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCategoryIds.length === categories.length}
                      color="primary"
                      indeterminate={
                        selectedCategoryIds.length > 0
                        && selectedCategoryIds.length < categories.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>
                    Category Name
                  </TableCell>
                  <TableCell>
                    Remaining products
                  </TableCell>
                  <TableCell>

                  </TableCell>
                  <TableCell>

                  </TableCell>
                  <TableCell>

                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categories.filter(category => category.categoryName !== 'All').slice(0, limit).map((category) => (
                  <TableRow
                    hover
                    key={category._id}
                    selected={selectedCategoryIds.indexOf(category._id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedCategoryIds.indexOf(category._id) !== -1}
                        onChange={(event) => handleSelectOne(event, category._id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        alignItems="center"
                        display="flex"
                      >
                        <Typography
                          color="textPrimary"
                          variant="body1"
                        >
                          {category.categoryName}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {/*{category.email}*/}
                    </TableCell>
                    <TableCell>
                      {/*{`${category.address.city}, ${category.address.state}, ${category.address.country}`}*/}
                    </TableCell>
                    <TableCell align={'center'}>
                      <IconButton aria-label="delete" className={classes.iconButton} onClick={() => { setMode('edit'); setEditingCategory(category) }}>
                        <EditButton />
                      </IconButton>
                    </TableCell>
                    <TableCell align={'center'}>
                      <IconButton aria-label="delete" className={classes.iconButton}>
                        <DeleteIcon onClick={() => deleteCategory(category._id, category.categoryName)} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={categories.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
  );
};

AllCategoryList.propTypes = {
  className: PropTypes.string,
  categories: PropTypes.array.isRequired
};

const mapStateToProps = state =>
({
  storeCategories: state.currentStore.categories,
  storeId: state.currentStore.storeInfo._id,
  storeInfo: state.currentStore.storeInfo,
});

const mapDispatchToProps = dispatch => (
  {
    setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
    setErrors: (errors) => dispatch(SetErrors(errors)),
    setStoreInfo: store => dispatch(SetStoreInfo(store)),
    FetchStoreCategoriesSuccess: (categories) => dispatch(FetchStoreCategoriesSuccess(categories))
  });

export default connect(mapStateToProps, mapDispatchToProps)(AllCategoryList);
