import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Grid,
  Card,
  Hidden,
  Chip
} from '@material-ui/core';
import Page from 'src/components/Page';
import { SwatchesPicker } from 'react-color';
import { SetErrors, SetSnackNotice } from '../../redux/app/app-actions';
import { connect } from 'react-redux';
import axios from 'axios';
import MenuItem from '@material-ui/core/MenuItem';
import { categories } from './storeCategories';
import { flagCurrencyMap } from '../../flagCurrencyMap';
import { DropzoneArea } from 'material-ui-dropzone';
import imageCompression from 'browser-image-compression';
import { SetStoreInfo } from 'src/redux/current-store/current-store-actions';
import FlagIcon from '../../components/flag-component/flag-component';
import { validatePassword } from '../../utils/password-validation';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import './register-view.scss';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    maxWidth: '100%',
    overFlow: 'hidden',
    display:'grid',
    alignContent:'center',
  },
  currency: {
    marginLeft: theme.spacing(1)
  },
  navigationButtons: {
    minWidth: '100px'
  },
  errorRule: {
    backgroundColor: theme.palette.error.main
  },
  errorRuleIcon: {
    color: 'black'
  },
  errorRuleLabel: {
    color: 'black'
  },
  successRuleIcon: {
    color: 'white'
  },
  successRuleLabel: {
    color: 'white'
  },
  successRule: {
    backgroundColor: theme.palette.success.main
  },
  policyCheck: {
    paddingLeft: 0,
    marginLeft: '-3px',
    marginBottom: '5px'
  },
  passwordRuleBox: {
    padding: theme.spacing(2),
    position: 'fixed',
    bottom: '15px',
    backgroundColor: 'white',
    zIndex: 1000
  },
  attachedPasswordRuleBox: {
    display: 'flex',
    flexWrap: 'wrap'
    // '& span':{
    //   marginTop:'2px',
    //   fontSize: '11px',
    //   color: '#333333',
    //   textAlign: 'justify'
    // }
  },
  passwordRule: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    columnGap: '10px',
    alignItems: 'start',
    margin: '4px 0'
  }
}));

const RegisterView = ({ setSnackNotice, setStoreInfo, setErrors }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [registerationStep, setRegisterationStep] = useState(1);

  const [newStoreInfo, setNewStoreInfo] = useState({
    colors:{
      primary: '#000000'
    },
    ownerFirstName: '',
    ownerLastName: '',
    storeEmail: '',
    storePhone: '',
    password: '',
    policy: false,
    storeName: '',
    storeAddress: '',
    storeCategory: '',
    includeDelivery: false,
    includeOnlinePayment: false,
    storeBanner: '',
    storeCurrency: 'NPR'
  });
  const [passwordErrors, setPasswordErrors] = useState({
    min: true,
    uppercase: true,
    lowercase: true,
    digits: true,
    symbols: true,
    spaces: true
  });
  const errorKeys = [
    'min',
    'uppercase',
    'lowercase',
    'digits',
    'symbols',
    'spaces'
  ];
  const errorKeyMessageMap = {
    min: `Eight charactors long`,
    uppercase: `One uppercase character`,
    lowercase: `One lowercase character`,
    digits: `One number`,
    symbols: `One special character (!$%#@ etc)`,
    spaces: `No spaces`
  };

  useEffect(() => {
    console.log(passwordErrors);
  }, [passwordErrors]);

  const validateNewPassword = password => {
    const validationErros = validatePassword(password);

    if (validationErros.length) {
      const newPasswordErros = {};
      Object.keys(passwordErrors).forEach(key => {
        if (validationErros.includes(key)) {
          newPasswordErros[key] = true;
        } else {
          newPasswordErros[key] = false;
        }
      });

      setPasswordErrors(newPasswordErros);
    } else {
      setPasswordErrors({
        min: false,
        uppercase: false,
        lowercase: false,
        digits: false,
        symbols: false,
        spaces: false
      });
    }
  };

  const imageConversion = async files => {
    const compressedFileArray = [];
    for (let file of files) {
      const options = {
        maxSizeMB: 0.4,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      };
      try {
        const compressedFile = await imageCompression(file, options);
        compressedFileArray.push(compressedFile);
      } catch (error) {
        console.log(error);
      }
    }

    const base64dataarray = [];
    let counter = base64dataarray.length;

    compressedFileArray.forEach((file, idx) => {
      let reader = new FileReader();
      reader.onloadend = function() {
        base64dataarray[counter].base64data = reader.result;
        if (idx === files.length - 1) {
          setNewStoreInfo({
            ...newStoreInfo,
            storeBanner: base64dataarray[0]
          });
        }

        counter++;
      };
      reader.readAsDataURL(file);
      base64dataarray.push({ type: file.type });
    });
  };

  useEffect(() => {
    if (
      newStoreInfo.colors.primary &&
      newStoreInfo.ownerFirstName &&
      newStoreInfo.ownerLastName &&
      newStoreInfo.storeEmail &&
      newStoreInfo.password &&
      newStoreInfo.policy &&
      newStoreInfo.storeAddress &&
      newStoreInfo.storeName &&
      newStoreInfo.storeCategory &&
      newStoreInfo.storeBanner &&
      newStoreInfo.storeCurrency &&
      newStoreInfo.storePhone
    ) {
      registerNewStore();
    }
  }, [newStoreInfo]);

  const registerNewStore = async () => {
    setSnackNotice({
      message: 'Registering your new store',
      severity: 'info'
    });

    delete newStoreInfo.customCategory;

    const response = await axios({
      url:
        'https://kripson-store-server-8qq76.ondigitalocean.app/stores/createStore',
      method: 'POST',
      data: newStoreInfo
    });

    if (response.data.status === 1) {
      navigate('/login');
    } else {
      setErrors(response.data.errors);
    }
  };

  return (
    <Page className={classes.root} title="Register">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          {registerationStep === 1 ? (
            <Formik
              initialValues={{
                storeEmail: newStoreInfo.storeEmail,
                ownerFirstName: newStoreInfo.ownerFirstName,
                ownerLastName: newStoreInfo.ownerLastName,
                password: newStoreInfo.password,
                policy: newStoreInfo.policy,
                storePhone: newStoreInfo.storePhone
              }}
              validationSchema={Yup.object().shape({
                storeEmail: Yup.string()
                  .email('Must be a valid email')
                  .max(255)
                  .required('Email is required'),
                storePhone: Yup.string()
                  .matches(
                    /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/,
                    'Phone number is not valid'
                  )
                  .required('Phone number is required'),
                ownerFirstName: Yup.string()
                  .max(255)
                  .required('First name is required'),
                ownerLastName: Yup.string()
                  .max(255)
                  .required('Last name is required'),
                password: Yup.string()
                  .max(255)
                  .test(
                    'validatePassword',
                    'Password must conform to the mentioned rules',
                    function(value) {
                      const result = validatePassword(value);
                      if (result.length) {
                        return false;
                      } else {
                        return true;
                      }
                    }
                  )
                  .required('password is required'),
                policy: Yup.boolean().oneOf(
                  [true],
                  'This field must be checked'
                )
              })}
              onSubmit={values => {
                setNewStoreInfo({
                  ...newStoreInfo,
                  ...values
                });
                setRegisterationStep(
                  registerationStep === 3 ? 3 : registerationStep + 1
                );
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form>
                  <Box mb={3}>
                    <Typography color="textPrimary" variant="h2">
                      Register your store
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      Use your email to create a new store account
                    </Typography>
                  </Box>
                  <TextField
                    error={Boolean(
                      touched.ownerFirstName && errors.ownerFirstName
                    )}
                    fullWidth
                    helperText={touched.ownerFirstName && errors.ownerFirstName}
                    label="Owner first name"
                    margin="normal"
                    name="ownerFirstName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.ownerFirstName}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(
                      touched.ownerLastName && errors.ownerLastName
                    )}
                    fullWidth
                    helperText={touched.ownerLastName && errors.ownerLastName}
                    label="Owner last name"
                    margin="normal"
                    name="ownerLastName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.ownerLastName}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.storeEmail && errors.storeEmail)}
                    fullWidth
                    helperText={touched.storeEmail && errors.storeEmail}
                    label="Email Address"
                    margin="normal"
                    name="storeEmail"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.storeEmail}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.storePhone && errors.storePhone)}
                    fullWidth
                    helperText={touched.storePhone && errors.storePhone}
                    label="Phone number"
                    margin="normal"
                    name="storePhone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.storePhone}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Password"
                    margin="normal"
                    name="password"
                    onChange={e => {
                      handleChange(e);
                      validateNewPassword(e.target.value);
                    }}
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />
                  <div className={`${classes.attachedPasswordRuleBox}`}>
                    {passwordErrors
                      ? Object.keys(passwordErrors).map(errorKey => {
                          if (passwordErrors[errorKey]) {
                            return (
                              <div className={classes.passwordRule}>
                                <Chip
                                  className={classes.errorRule}
                                  classes={{
                                    icon: classes.errorRuleIcon,
                                    label: classes.errorRuleLabel
                                  }}
                                  color="default"
                                  icon={<SentimentVeryDissatisfiedIcon />}
                                  label={errorKeyMessageMap[errorKey]}
                                />
                              </div>
                            );
                          } else {
                            return (
                              <div className={classes.passwordRule}>
                                <Chip
                                  className={classes.successRule}
                                  classes={{
                                    icon: classes.successRuleIcon,
                                    label: classes.successRuleLabel
                                  }}
                                  icon={<InsertEmoticonIcon />}
                                  label={errorKeyMessageMap[errorKey]}
                                />
                              </div>
                            );
                          }
                        })
                      : ''}
                  </div>
                  <Box alignItems="center" display="flex">
                    <Checkbox
                      checked={values.policy}
                      name="policy"
                      onChange={handleChange}
                      className={classes.policyCheck}
                    />
                    <Typography color="textSecondary" variant="body1">
                      I have read the{' '}
                      <Link
                        color="primary"
                        component={RouterLink}
                        to="#"
                        underline="always"
                        variant="h6"
                      >
                        Terms and Conditions
                      </Link>
                    </Typography>
                  </Box>
                  {Boolean(touched.policy && errors.policy) && (
                    <FormHelperText error>{errors.policy}</FormHelperText>
                  )}
                  <Typography color="textSecondary" variant="body1">
                    Have an account?{' '}
                    <Link component={RouterLink} to="/login" variant="h6">
                      Sign in
                    </Link>
                  </Typography>
                  <Grid justify="flex-end" direction="row" container mt={3}>
                    <Grid
                      container
                      justify="flex-end"
                      sm={6}
                      xs={12}
                      spacing={3}
                    >
                      <Grid item>
                        {registerationStep !== 1 ? (
                          <Button  size="large"
                            className={classes.navigationButtons}
                            variant="contained"
                            color="secondary"
                            onClick={() =>
                              setRegisterationStep(
                                registerationStep === 1
                                  ? 1
                                  : registerationStep - 1
                              )
                            }
                          >
                            Back
                          </Button>
                        ) : (
                          ''
                        )}
                      </Grid>

                      {registerationStep !== 3 ? (
                        <Grid item>
                          <Button  size="large"
                            className={classes.navigationButtons}
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                          >
                            Next
                          </Button>{' '}
                        </Grid>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          ) : (
            ''
          )}

          {registerationStep === 2 ? (
            <Formik
              initialValues={{
                storeName: newStoreInfo.storeName,
                storeAddress: newStoreInfo.storeAddress,
                storeCurrency: newStoreInfo.storeCurrency
              }}
              validationSchema={Yup.object().shape({
                storeName: Yup.string()
                  .max(255)
                  .required('Store name is required'),
                storeAddress: Yup.string()
                  .max(255)
                  .required('Business Address is required'),
                storeCurrency: Yup.string()
                  .max(5)
                  .required('Store Currency is required')
              })}
              onSubmit={async (values, { setSubmitting }) => {
                setNewStoreInfo({
                  ...newStoreInfo,
                  ...values
                });
                setSubmitting(false);
                setRegisterationStep(
                  registerationStep === 3 ? 3 : registerationStep + 1
                );
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form>
                  <Box mb={3}>
                    <Typography color="textPrimary" variant="h2">
                      Please, provide your business details
                    </Typography>
                  </Box>
                  <TextField
                    error={Boolean(touched.storeName && errors.storeName)}
                    fullWidth
                    helperText={touched.storeName && errors.storeName}
                    label="Store name"
                    margin="normal"
                    name="storeName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.storeName}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.storeAddress && errors.storeAddress)}
                    fullWidth
                    helperText={touched.storeAddress && errors.storeAddress}
                    label="Store address"
                    margin="normal"
                    name="storeAddress"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.storeAddress}
                    variant="outlined"
                  />
                  <TextField
                    select
                    label="Select"
                    value={values.storeCurrency}
                    onChange={handleChange}
                    helperText={
                      'Please select the currency for store transactions'
                    }
                    variant="outlined"
                    onBlur={handleBlur}
                    name="storeCurrency"
                    fullWidth
                    error={Boolean(
                      touched.storeCurrency && errors.storeCurrency
                    )}
                    margin="normal"
                  >
                    {Object.keys(flagCurrencyMap).map(flag => (
                      <MenuItem value={flag}>
                        {' '}
                        <FlagIcon code={flagCurrencyMap[flag]} size={'lg'} />
                        <span className={classes.currency}>
                          {flag}
                        </span>
                      </MenuItem>
                    ))}
                  </TextField>
                  <Box my={3}>
                    <Typography color="textPrimary" variant="body1">
                      Pick your brand color for the store
                    </Typography>
                  </Box>
                  <SwatchesPicker
                    label="Brand Color"
                    name="brandColor"
                    color={newStoreInfo.colors.primary}
                    onChangeComplete={color => {
                      setNewStoreInfo({
                        ...newStoreInfo,
                        colors: {
                          primary: color.hex
                        }
                      });
                    }}
                    width={window.width / 2}
                  />

                  <Typography color="textSecondary" variant="body1">
                    Have an account?{' '}
                    <Link component={RouterLink} to="/login" variant="h6">
                      Sign in
                    </Link>
                  </Typography>
                  <Grid justify="flex-end" direction="row" container mt={3}>
                    <Grid
                      container
                      justify="flex-end"
                      sm={6}
                      xs={12}
                      spacing={3}
                    >
                      <Grid item>
                        {registerationStep !== 1 ? (
                          <Button  size="large"
                            className={classes.navigationButtons}
                            variant="contained"
                            color="secondary"
                            onClick={() =>
                              setRegisterationStep(
                                registerationStep === 1
                                  ? 1
                                  : registerationStep - 1
                              )
                            }
                          >
                            Back
                          </Button>
                        ) : (
                          ''
                        )}
                      </Grid>

                      {registerationStep !== 3 ? (
                        <Grid item>
                          <Button  size="large"
                            className={classes.navigationButtons}
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                          >
                            Next
                          </Button>{' '}
                        </Grid>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          ) : (
            ''
          )}

          {registerationStep === 3 ? (
            <Formik
              initialValues={{
                storeCategory: newStoreInfo.storeCategory,
                includeDelivery: newStoreInfo.includeDelivery,
                includeOnlinePayment: newStoreInfo.includeOnlinePayment,
                customCategory: ''
              }}
              validationSchema={Yup.object().shape({
                storeCategory: Yup.string()
                  .max(25)
                  .required('Store Category is required'),
                includeDelivery: Yup.boolean().required(),
                includeOnlinePayment: Yup.boolean().required(),
                customCategory: Yup.string().when('storeCategory', {
                  is: 'Other',
                  then: Yup.string().required()
                })
              })}
              onSubmit={async (values, { setSubmitting }) => {
                // console.log(values);
                // if(values.storeCategory === 'Other')
                // {
                //   delete values.storeCategory;
                //   if(newStoreInfo.storeCategory.length > 0)
                //   {
                //     setNewStoreInfo({
                //       ...newStoreInfo,
                //       ...values
                //     });
                //   }
                // }
                // else{

                if (values.customCategory && values.customCategory.length) {
                  setNewStoreInfo({
                    ...newStoreInfo,
                    ...values,
                    storeCategory: values.customCategory
                  });
                } else {
                  setNewStoreInfo({
                    ...newStoreInfo,
                    ...values
                  });
                }
                // }
                setSubmitting(false);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form>
                  <Box mb={3}>
                    <Typography color="textPrimary" variant="h2">
                      Please, provide further details of your business
                    </Typography>
                  </Box>
                  <TextField
                    select
                    label="Select"
                    value={values.storeCategory}
                    onChange={handleChange}
                    helperText={'Please select the category of the store'}
                    variant="outlined"
                    onBlur={handleBlur}
                    name="storeCategory"
                    fullWidth
                    error={Boolean(
                      touched.storeCategory && errors.storeCategory
                    )}
                    margin="normal"
                  >
                    {categories.map(category => (
                      <MenuItem value={category}>{category}</MenuItem>
                    ))}
                  </TextField>

                  {!categories
                    .filter(cat => cat.toLowerCase() !== 'other')
                    .includes(values.storeCategory) ? (
                    values.storeCategory && values.storeCategory.length ? (
                      <TextField
                        label="Store Category"
                        value={values.customCategory}
                        onChange={handleChange}
                        helperText={'Please input the category of the store'}
                        variant="outlined"
                        onBlur={handleBlur}
                        name="customCategory"
                        fullWidth
                        error={Boolean(
                          touched.customCategory && errors.customCategory
                        )}
                        margin="normal"
                      ></TextField>
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  )}

                  <Box>
                    <Grid container alignItems="center">
                      <Grid item>
                        <Checkbox
                          id="includeDelivery"
                          fullWidth
                          checked={values.includeDelivery}
                          name="includeDelivery"
                          onChange={handleChange}
                          error={Boolean(
                            touched.includeDelivery && errors.includeDelivery
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" color="textSecondary">
                          Offer Delivery services?
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box>
                    <Grid container alignItems="center">
                      <Grid item>
                        <Checkbox
                          id="includeOnlinePayment"
                          fullWidth
                          checked={values.includeOnlinePayment}
                          name="includeOnlinePayment"
                          onChange={handleChange}
                          error={Boolean(
                            touched.includeOnlinePayment &&
                              errors.includeOnlinePayment
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" color="textSecondary">
                          Include online payment?
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  {/*{values.storeCategory === 'Other'?*/}
                  {/*  <TextField*/}
                  {/*  label="Store Category"*/}
                  {/*  value={newStoreInfo.storeCategory}*/}
                  {/*  onChange={(e)=>{setNewStoreInfo({*/}

                  {/*    ...newStoreInfo,*/}
                  {/*    storeCategory: e.target.value*/}
                  {/*  });*/}
                  {/*  console.log(e.target)}}*/}
                  {/*  helperText={"Please provide the category of the store"}*/}
                  {/*  variant="outlined"*/}
                  {/*  onBlur={handleBlur}*/}
                  {/*  name="storeCategory"*/}
                  {/*  fullWidth*/}
                  {/*  error={newStoreInfo.hasOwnProperty('storeCategory') && newStoreInfo.storeCategory.length}*/}
                  {/*  margin="normal"*/}
                  {/*/>:''}*/}
                  
                  <Typography color="textSecondary" variant="body1">
                     Please, upload your store banner:
                  </Typography>
                 
                  <DropzoneArea
                    filesLimit={1}
                    onChange={files => imageConversion(files)}
                    name="storeBanner"
                  />

                  <Box my={2}>
                    <Button  size="large"
                      onClick={handleSubmit}
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Sign up now
                    </Button>
                  </Box>
                  <Typography color="textSecondary" variant="body1">
                    Have an account?{' '}
                    <Link component={RouterLink} to="/login" variant="h6">
                      Sign in
                    </Link>
                  </Typography>
                  <Grid justify="flex-end" direction="row" container mt={3}>
                    <Grid
                      container
                      justify="flex-end"
                      sm={6}
                      xs={12}
                      spacing={3}
                    >
                      <Grid item>
                        {registerationStep !== 1 ? (
                          <Button  size="large"
                            className={classes.navigationButtons}
                            variant="contained"
                            color="secondary"
                            onClick={() =>
                              setRegisterationStep(
                                registerationStep === 1
                                  ? 1
                                  : registerationStep - 1
                              )
                            }
                          >
                            Back
                          </Button>
                        ) : (
                          ''
                        )}
                      </Grid>

                      {registerationStep !== 3 ? (
                        <Grid item>
                          <Button  size="large"
                            type="submit"
                            className={classes.navigationButtons}
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              setRegisterationStep(
                                registerationStep === 3
                                  ? 3
                                  : registerationStep + 1
                              )
                            }
                          >
                            Next
                          </Button>{' '}
                        </Grid>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          ) : (
            ''
          )}
        </Container>
      </Box>
    </Page>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setStoreInfo: storeInfo => dispatch(SetStoreInfo(storeInfo)),
  setErrors: errors => dispatch(SetErrors(errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterView);
