import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Chip,
  Grid,
  Hidden
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import CachedIcon from '@material-ui/icons/Cached';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import OrderCard from 'src/components/order-card/order-card';
import {
  FetchStoreOrdersStart,
  FetchStoreOrdersSuccess,
  SetStoreInfo
} from 'src/redux/current-store/current-store-actions';
import axios from 'axios';
import { Pagination } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: 'white'
  }
}));

const Results = ({
  className,
  storeInfo,
  setStoreInfo,
  fetchStoreOrdersSuccess,
  keyword,
  startDate,
  endDate,
  orders,
  ...rest
}) => {
  const classes = useStyles();
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    if (totalOrders && page >= Math.ceil(totalOrders / limit)) {
      console.log(Math.ceil(totalOrders / limit));
      setPage(Math.ceil(totalOrders / limit) - 1);
    }
  }, [totalOrders]);

  useEffect(() => {
    (async () => {
      const filter = {};
      if (keyword && keyword.length) {
        filter['$or'] = [
          { 'customer.name': { $regex: keyword, $options: 'i' } },
          { orderNumber: { $regex: keyword, $options: 'i' } },
          { 'customer.email': { $regex: keyword, $options: 'i' } },
          { 'customer.address': { $regex: keyword, $options: 'i' } }
        ];
      }

      if (startDate && endDate) {
        filter['createdDate'] = {
          $gte: new Date(startDate).getTime(),
          $lte: new Date(endDate).getTime()
        };
      }
      if (storeInfo._id) {
        const response = await axios({
          url: 'https://kripson-store-server-8qq76.ondigitalocean.app/orders/getOrders',
          method: 'POST',
          data: {
            ...(Object.keys(filter).length > 0
              ? {
                  storeId: storeInfo._id,
                  filter,
                  page: page || 0,
                  limit: limit,
                  skip: page * limit
                }
              : {
                  storeId: storeInfo._id,
                  page: page || 0,
                  limit: limit,
                  skip: page * limit
                }),
            sessionId: storeInfo.sessionId
          }
        });

        if (response.data.status === 1) {
          setTotalOrders(response.data.totals);
          if (response.data.result.length === 0) {
            setPage(0);
          }
          fetchStoreOrdersSuccess(response.data.result);
        } else if (response.data.status === 3) {
          setStoreInfo({});
        } else {
          return [];
        }
      }
    })();
  }, [storeInfo, keyword, startDate, endDate, page, limit]);

  const handleSelectAll = event => {
    let newSelectedOrderIds;

    if (event.target.checked) {
      newSelectedOrderIds = orders.map(order => order._id);
    } else {
      newSelectedOrderIds = [];
    }

    setSelectedOrderIds(newSelectedOrderIds);
  };

  const handleSelectOne = (event, _id) => {
    const selectedIndex = selectedOrderIds.indexOf(_id);
    let newSelectedOrderIds = [];

    if (selectedIndex === -1) {
      newSelectedOrderIds = newSelectedOrderIds.concat(selectedOrderIds, _id);
    } else if (selectedIndex === 0) {
      newSelectedOrderIds = newSelectedOrderIds.concat(
        selectedOrderIds.slice(1)
      );
    } else if (selectedIndex === selectedOrderIds.length - 1) {
      newSelectedOrderIds = newSelectedOrderIds.concat(
        selectedOrderIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedOrderIds = newSelectedOrderIds.concat(
        selectedOrderIds.slice(0, selectedIndex),
        selectedOrderIds.slice(selectedIndex + 1)
      );
    }

    setSelectedOrderIds(newSelectedOrderIds);
  };

  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const getOrderStatusIcon = status => {
    switch (status) {
      case 'processing':
        return <CachedIcon />;
      case 'cancelled':
      case 'refunded':
        return <HighlightOffIcon />;
      case 'delivered':
        return <CheckCircleOutlineIcon />;
      default:
        return;
    }
  };

  const getOrderStatusClass = status => {
    switch (status) {
      case 'processing':
        return classes.warning;
      case 'cancelled':
      case 'refunded':
        return classes.error;
      case 'delivered':
        return classes.success;
      default:
        return;
    }
  };

  return (
    <>
      <Hidden mdDown>
        <Card className={clsx(classes.root, className)} {...rest}>
          <PerfectScrollbar>
            <Box minWidth={1000}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={orders && selectedOrderIds.length === orders.length}
                        color="primary"
                        indeterminate={
                          selectedOrderIds.length > 0 &&
                          selectedOrderIds.length < orders.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>Customer Name</TableCell>
                    <TableCell>Customer Email</TableCell>
                    <TableCell>Customer Address</TableCell>
                    <TableCell>Customer Phone</TableCell>
                    <TableCell>Order Total</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders && orders.map(order => (
                    <TableRow
                      hover
                      key={order._id}
                      selected={selectedOrderIds.indexOf(order._id) !== -1}
                      onClick={() => navigate(`/app/orders/${order._id}`)}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedOrderIds.indexOf(order._id) !== -1}
                          onChange={event => handleSelectOne(event, order._id)}
                          value="true"
                        />
                      </TableCell>
                      <TableCell>
                        <Box alignItems="center" display="flex">
                          <Avatar
                            className={classes.avatar}
                            src={order.avatarUrl}
                          >
                            {getInitials(order.customer.name)}
                          </Avatar>
                          <Typography color="textPrimary" variant="body1">
                            {order.customer.name}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>{order.customer.email}</TableCell>
                      <TableCell>{order.customer.address}</TableCell>
                      <TableCell>{order.customer.phonenumber}</TableCell>
                      <TableCell>
                        {storeInfo.storeCurrencySymbol} {order.total}
                      </TableCell>
                      <TableCell>
                        <Chip
                          className={getOrderStatusClass(order.status)}
                          icon={getOrderStatusIcon(order.status)}
                          label={order.status}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={totalOrders}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[1, 5, 10, 25]}
          />
        </Card>
      </Hidden>
      <Hidden lgUp>
        <Grid container spacing={3}>
          {orders && orders.map(order => (
            <Grid item key={order.id} lg={4} md={6} xs={12}>
              <OrderCard order={order} />
            </Grid>
          ))}
        </Grid>
        <Box display="flex" justifyContent="center" my={2}>
          <Pagination
            color="primary"
            count={Math.ceil(totalOrders / limit) || 1}
            size="small"
            onChange={(e, epage) => {
              handlePageChange(e, epage - 1);
            }}
            variant="outlined"
            shape="rounded"
          />
        </Box>
      </Hidden>
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => ({
  storeInfo: state.currentStore.storeInfo,
  orders: state.currentStore.orders
});

const mapDispatchToProps = dispatch => ({
  fetchStoreOrdersSuccess: orders => dispatch(FetchStoreOrdersSuccess(orders)),
  setStoreInfo: storeInfo => dispatch(SetStoreInfo(storeInfo))
});

export default connect(mapStateToProps, mapDispatchToProps)(Results);
