import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  CardHeader,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Fab
} from '@material-ui/core';

import { SetErrors, SetSnackNotice } from 'src/redux/app/app-actions';
import { FetchStoreNotificationsStart } from 'src/redux/current-store/current-store-actions';
import CachedIcon from '@material-ui/icons/Cached';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import momentTimeZone from 'moment-timezone';
import './invoice-card.scss';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SendIcon from '@material-ui/icons/Send';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import VisibilityIcon from '@material-ui/icons/Visibility';

function InvoiceCard({
  storeId,
  storeInfo,
  invoice,
  viewInvoice,
  downloadInvoice,
  resendInvoice,
  ...rest
}) {
  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%'
    },
    warning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText
    },
    error: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText
    },
    success: {
      backgroundColor: theme.palette.success.main,
      color: 'white'
    },
    info: {
      backgroundColor: theme.palette.info.main,
      color: 'white'
    }
  }));

  const classes = useStyles();

  const getInvoiceStatusIcon = status => {
    switch (status) {
      case 'processing':
        return <CachedIcon />;
      case 'cancelled':
      case 'refunded':
        return <ClearIcon />;
      case 'delivered':
        return <CheckIcon />;
      default:
        return;
    }
  };

  const getInvoiceStatusClass = status => {
    switch (status) {
      case 'paid':
        return classes.success;
      case 'overdue':
      case 'refunded':
        return classes.error;
      case 'unpaid':
        return classes.warning;
      default:
        return;
    }
  };

  return (
    <Card className={classes.root} {...rest}>
      <div className="card-header">
        <Typography color="textPrimary" variant="h5">
          {` Invoice #: ` + invoice.invoiceNumber}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {momentTimeZone(invoice.createdDate)
            .tz('Asia/Kathmandu')
            .format('DD/MM/YYYY')}
        </Typography>
      </div>
      <Divider />
      <CardContent style={{ width: '100%' }}>
        <Box
          mt={2}
          className="details-button-container"
          style={{ width: '100%' }}
        >
          <Typography variant="body2">
            <strong>Order #: {invoice.orderNumber}</strong>
          </Typography>
          <Chip
            label={invoice.paymentStatus}
            className={getInvoiceStatusClass(invoice.paymentStatus)}
          ></Chip>
        </Box>
      </CardContent>
      <Box flexGrow={1} />
      <Divider />
      <Box p={1}>
        <Grid container justify="space-between" alignItems="center"  style={{margin: 0}}>
          <Grid className={classes.statsItem} item>
            <Fab
              className={classes.info}
              onClick={() => viewInvoice(invoice._id)}
              aria-label="view"
              size="small"
              style={{marginRight: '10px'}}
            >
              <VisibilityIcon />
            </Fab>
            <Fab
              className={classes.success}
              onClick={() => downloadInvoice(invoice._id)}
              aria-label="view"
              size="small"
            >
              <CloudDownloadIcon />
            </Fab>
          </Grid>
          <Grid className={classes.statsItem} item>
            <Button  size="large"
              variant="contained"
              size="large"
              color="primary"
              onClick={() => resendInvoice(invoice._id)}
              endIcon={<SendIcon />}
            >
              Resend
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}

const mapStateToProps = state => ({
  storeId: state.currentStore.storeInfo._id,
  storeInfo: state.currentStore.storeInfo,
  notifications: state.currentStore.notifications
});

const mapDispatchToProps = dispatch => ({
  FetchStoreNotificationsStart: (storeId, sessionId) =>
    dispatch(FetchStoreNotificationsStart(storeId, sessionId)),
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setErrors: errors => dispatch(SetErrors(errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceCard);
