import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  colors
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import axios from 'axios';
import { connect } from 'react-redux';
import { SetErrors, SetSnackNotice } from 'src/redux/app/app-actions';
import { SetStoreInfo } from 'src/redux/current-store/current-store-actions';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.indigo[600],
    height: 56,
    width: 56
  }
}));

const TotalProfit = ({
  className,
  storeId,
  setStoreInfo,
  setSnackNotice,
  setErrors,
  storeInfo,
  ...rest
}) => {
  const classes = useStyles();

  const [totalProfit, setTotalProfit] = useState(0);

  useEffect(() => {
    if (storeInfo.sessionId) {
      (async () => {
        const response = await axios({
          url: 'https://kripson-store-server-8qq76.ondigitalocean.app/stats/getTotalProfit',
          method: 'POST',
          data: { storeId, sessionId: storeInfo.sessionId }
        });
        if (response.data.status === 1) {
          setTotalProfit(response.data.result);
        } else if (response.data.status === 3) {
          setStoreInfo({
            ownerFirstName: '',
            ownerLastName: '',
            storeEmail: '',
            storeName: '',
            storeAddress: '',
            colors: { primary: '#000000'}
          });
          setSnackNotice({
            severity: 'warning',
            message: response.data.message
          });
        } else {
          setErrors(response.data.errors);
        }
      })();
    }
  }, [storeId, storeInfo]);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              TOTAL PROFIT
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {storeInfo.storeCurrencySymbol} {Math.round(totalProfit, 2)}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <AttachMoneyIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

TotalProfit.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => ({
  storeId: state.currentStore.storeInfo._id,
  storeInfo: state.currentStore.storeInfo
});

const mapDispatchToProps = dispatch => ({
  setStoreInfo: store => dispatch(SetStoreInfo(store)),
  setErrors: error => dispatch(SetErrors(error)),
  setSnackNotice: notice => dispatch(SetSnackNotice(notice))
});

export default connect(mapStateToProps, mapDispatchToProps)(TotalProfit);
