import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery
} from '@material-ui/core';
import * as _ from 'lodash';

const useStyles = makeStyles(({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const Notifications = ({ className, notificationMedium, updateStoreInfo, setNewNotificationMedium, ...rest }) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const handleChange = (e) => {
    if (e.target.checked) {
      let _temp = [...notificationMedium];
      if (!_temp.includes(e.target.value)) {
        _temp = _temp.concat(e.target.value);
        setNewNotificationMedium(_temp);
      }
    }
    else {
      setNewNotificationMedium(_.remove(notificationMedium, function (n) {
        return n !== e.target.value;
      }));
    }
  }

  return (
    <form
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="Manage the notifications"
          title="Notifications"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={6}
            wrap="wrap"
          >
            <Grid
              className={classes.item}
              item
              md={4}
              sm={6}
              xs={12}
            >
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h6"
              >
                Notifications
              </Typography>
              <FormControlLabel
                control={(
                  <Checkbox onClick={handleChange} value="email" checked={notificationMedium ? notificationMedium.includes('email') : false} />
                )}
                label="Email"
              />
              <FormControlLabel
                control={(
                  <Checkbox onClick={handleChange} value="pushnotification" checked={notificationMedium ? notificationMedium.includes('pushnotification') : false} />
                )}
                label="Push Notifications"
              />
              <FormControlLabel
                control={<Checkbox onClick={handleChange} value="text" checked={notificationMedium ? notificationMedium.includes('text') : false} />}
                label="Text Messages"
              />
            </Grid>
            {/* <Grid
              className={classes.item}
              item
              md={4}
              sm={6}
              xs={12}
            >
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h6"
              >
                Messages
              </Typography>
              <FormControlLabel
                control={(
                  <Checkbox />
                )}
                label="Email"
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Push Notifications"
              />
              <FormControlLabel
                control={(
                  <Checkbox defaultChecked />
                )}
                label="Phone calls"
              />
            </Grid> */}
          </Grid>
        </CardContent>
        <Divider />

        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button size="large"
            color="primary"
            variant="contained"
            onClick={updateStoreInfo}
            style={{ flexGrow: isSmallScreen ? 1 : 'unset' }}
          >
            Save
          </Button>
        </Box>
      </Card>
    </form>
  );
};

Notifications.propTypes = {
  className: PropTypes.string
};

export default Notifications;
