import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  CardHeader,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button
} from '@material-ui/core';

import { SetErrors, SetSnackNotice } from 'src/redux/app/app-actions';
import { FetchStoreNotificationsStart } from 'src/redux/current-store/current-store-actions';
import CachedIcon from '@material-ui/icons/Cached';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import momentTimeZone from 'moment-timezone';
import './order-card.scss';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SendIcon from '@material-ui/icons/Send';

function OrderCard({ storeId, storeInfo, order, ...rest }) {
  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%'
    },
    warning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText
    },
    error: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText
    },
    success: {
      backgroundColor: theme.palette.success.main,
      color: 'white'
    }
  }));

  const classes = useStyles();

  const getOrderStatusIcon = status => {
    switch (status) {
      case 'processing':
        return <CachedIcon />;
      case 'cancelled':
      case 'refunded':
        return <ClearIcon />;
      case 'delivered':
        return <CheckIcon />;
      default:
        return;
    }
  };

  const getOrderStatusClass = status => {
    switch (status) {
      case 'processing':
        return classes.warning;
      case 'cancelled':
      case 'refunded':
        return classes.error;
      case 'delivered':
        return classes.success;
      default:
        return;
    }
  };

  return (
    <Card className={classes.root} {...rest}>
      <div className="card-header">
        <Typography color="textPrimary" variant="h4">
          {` Order #: ` + order.orderNumber}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {momentTimeZone(order.createdDate)
            .tz('Asia/Kathmandu')
            .format('DD/MM/YYYY')}
        </Typography>
      </div>
      <Divider />
      <CardContent style={{ width: '100%' }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Avatar style={{marginRight:'10px'}}/>
            <Typography className={classes.heading} variant="h6" style={{display:'grid', alignItems: 'center'}}>
              Customer Details
            </Typography>
          </AccordionSummary>
          <Box p={2} style={{ display: 'grid', rowGap: '5px' }}>
            <Typography variant="body2">
              <strong>{`Name: `}</strong> {order.customer.name}
            </Typography>
            <Typography variant="body2">
              <strong>{`Address: `}</strong> {order.customer.address}
            </Typography>
            <Typography variant="body2">
              <strong>{`Email: `}</strong> {order.customer.email}
            </Typography>
            <Typography variant="body2">
              <strong>{`Phone: `}</strong> {order.customer.phonenumber}
            </Typography>
          </Box>
        </Accordion>
        {/* <Box
          mt={2}
          className="details-button-container"
          style={{ width: '100%' }}
        ></Box> */}
      </CardContent>
      <Box flexGrow={1} />
      <Box px={2} pb={2} pt={0}>
        <Grid container justify="space-between" alignItems="center" spacing={2}>
          <Grid className={classes.statsItem} item>
            <Chip label={` ${storeInfo.storeCurrencySymbol} ` + order.total} style={{ backgroundColor:"#b3ffb3", fontWeight: 500}}>
            </Chip>
          </Grid>
          <Grid className={classes.statsItem} item>
            {/* <Chip
              className={getOrderStatusClass(order.status)}
              icon={getOrderStatusIcon(order.status)}
              label={order.status}
            /> */}
            <Button  size="large"
              variant="contained"
              color="primary"
              size="large"
              component={NavLink}
              to={`/app/orders/${order._id}`}
              endIcon={<SendIcon />}
            >
              See Details
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}

const mapStateToProps = state => ({
  storeId: state.currentStore.storeInfo._id,
  storeInfo: state.currentStore.storeInfo,
  notifications: state.currentStore.notifications
});

const mapDispatchToProps = dispatch => ({
  FetchStoreNotificationsStart: (storeId, sessionId) =>
    dispatch(FetchStoreNotificationsStart(storeId, sessionId)),
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setErrors: errors => dispatch(SetErrors(errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderCard);
