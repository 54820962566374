import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    makeStyles,
    TextField,
    Card,
    Hidden,
    MenuItem,
    fade,
    useMediaQuery,
    useTheme,
    Divider,
    Avatar,
    Typography,
    colors,
    Grid,
    Button
} from '@material-ui/core';
import Page from 'src/components/Page';
import { connect } from 'react-redux';
import axios from 'axios';
import { SetStoreInfo } from '../../redux/current-store/current-store-actions';
import { SetErrors, SetSnackNotice } from '../../redux/app/app-actions';
import * as _ from 'lodash';
import { DatePicker } from '@material-ui/pickers';
import SelectProductsDialog from '../discount/select-products-dialog';
import CustomNoRowsOverlay from 'src/components/custom-no-rows/custom-no-rows';
import { DataGrid } from '@material-ui/data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import TuneIcon from '@material-ui/icons/Tune';
import StatsSection from '../stats/stats-section';

const monthlyLabels = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December'
};

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100vh',
        position: 'relative',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    rootContainer: {
        display: 'grid',
        minHeight: '100%',

        gridRowGap: theme.spacing(3)
    },
    currencyContainer: {
        padding: theme.spacing(2)
    },
    currency: {
        marginLeft: theme.spacing(1)
    }
}));

const BillingView = ({ storeInfo, setStoreInfo, setErrors, setSnackNotice }) => {
    const classes = useStyles();
    const [frequency, setFrequency] = useState('daily');
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const theme = useTheme();

    const [startDate, setStartDate] = useState(new Date(Date.now() - (30 * 86400000)));
    const [endDate, setEndDate] = useState(new Date(Date.now()));

    const [data, setData] = useState({
        datasets: [
            {
                backgroundColor: colors.indigo[500],
                fill: false,
                data: [10, 2, 3],
                label: 'Total Usage in MilliSeconds'
            }
        ],
        labels: [1, 2, 4]
    });

    const options = {
        // animation: true,
        layout: { padding: 0 },
        legend: { display: false },
        maintainAspectRatio: true,
        responsive: true,
        scales: {
            xAxes: [
                {
                    barThickness: 15,
                    maxBarThickness: 15,
                    barPercentage: 0.5,
                    categoryPercentage: 0.5,
                    ticks: {
                        fontColor: theme.palette.text.secondary
                    },
                    gridLines: {
                        display: false,
                        drawBorder: false
                    }
                }
            ],
            yAxes: [
                {
                    barThickness: 12,
                    maxBarThickness: 12,
                    barPercentage: 0.5,
                    categoryPercentage: 0.5,
                    stacked: true,
                    ticks: {
                        fontColor: theme.palette.text.secondary,
                        beginAtZero: true,
                        min: 0
                    },
                    gridLines: {
                        borderDash: [2],
                        borderDashOffset: [2],
                        color: theme.palette.divider,
                        drawBorder: false,
                        zeroLineBorderDash: [2],
                        zeroLineBorderDashOffset: [2],
                        zeroLineColor: theme.palette.divider
                    }
                }
            ]
        },
        elements: {
            line: {
                tension: 0.5
            }
        },
        tooltips: {
            backgroundColor: theme.palette.background.default,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 1,
            enabled: true,
            footerFontColor: theme.palette.text.secondary,
            intersect: false,
            mode: 'index',
            titleFontColor: theme.palette.text.primary
        }
    };

    useEffect(() => {
        console.log(startDate);
        if (frequency && startDate && endDate && storeInfo && storeInfo._id) {
            console.log('test');
            (async () => {
                const response = await axios({
                    url: `https://kripson-store-server-8qq76.ondigitalocean.app/stats/getDailyUsage`,
                    method: 'POST',
                    data: {
                        storeId: storeInfo._id,
                        startDate,
                        endDate,
                        sessionId: storeInfo.sessionId
                    }
                });
                if (response.data.status === 1) {
                    setData({
                        datasets: [
                            {
                                backgroundColor: fade(
                                    theme.palette.primary.main,
                                    0.75
                                ),
                                data: response.data.result.map(
                                    record => record.totalMilliSeconds
                                ),
                                fill: 'origin',
                                label: 'Total Usage in MilliSeconds',
                                indexAxis: 'y'
                            }
                        ],
                        labels: response.data.result.map(record => record._id)
                    });
                } else if (response.data.status === 3) {
                    setStoreInfo({
                        ownerFirstName: '',
                        ownerLastName: '',
                        storeEmail: '',
                        storeName: '',
                        storeAddress: '',
                        colors: { primary:'#000000'}
                    });
                    setSnackNotice({
                        severity: 'warning',
                        message: response.data.message
                    });
                } else {
                    setErrors(response.data.errors);
                }
            })();
        }
    }, [frequency, startDate, endDate, storeInfo]);



    return <Page className={classes.root} title="Billing">
        <Container maxWidth={false} className={classes.rootContainer}>
            <Box mb={3}>
                <Typography color="textPrimary" variant="h2">
                    Billing
                </Typography>
                <Typography color="textSecondary" gutterBottom variant="body2">
                    See your daily usage
                </Typography>
            </Box>
            <Card>
                <Box p={2}>
                    <Typography variant="h5" color="textSecondary">
                        Constraints
                    </Typography>
                </Box>
                <Divider />
                <Hidden smDown>
                    <Box display="flex" justifyContent="space-between" px={2} pb={1}>
                        <Box>
                            <TextField
                                select
                                label="Select"
                                variant="outlined"
                                name="storeCategory"
                                margin="normal"
                                style={{ width: '150px' }}
                                value={frequency}
                                onChange={e => setFrequency(e.target.value)}
                            >
                                <MenuItem value={'daily'} selected>
                                    Daily
                                </MenuItem>
                                <MenuItem value={'monthly'}>Monthly</MenuItem>
                                <MenuItem value={'yearly'}>Yearly</MenuItem>
                            </TextField>
                        </Box>

                        <Box>
                            <DatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label="Start Date"
                                format="MM/dd/yyyy"
                                inputVariant="outlined"
                                value={startDate}
                                onChange={date => {
                                    setStartDate(date);
                                }}
                                style={{ marginRight: '15px' }}
                                name="startDate"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date'
                                }}
                            />
                            <DatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label="End Date"
                                format="MM/dd/yyyy"
                                inputVariant="outlined"
                                value={endDate}
                                onChange={date => {
                                    setEndDate(date);
                                }}
                                name="startDate"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date'
                                }}
                            />
                        </Box>
                    </Box>
                </Hidden>

                <Hidden mdUp>
                    <Grid container style={{ padding: '15px' }}>
                        <Grid item xs={12}>
                            <TextField
                                select
                                label="Select"
                                variant="outlined"
                                name="storeCategory"
                                margin="normal"
                                value={frequency}
                                fullWidth
                                onChange={e => setFrequency(e.target.value)}
                            >
                                <MenuItem value={'daily'} selected>
                                    Daily
                                </MenuItem>
                                <MenuItem value={'monthly'}>Monthly</MenuItem>
                                <MenuItem value={'yearly'}>Yearly</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                helperText="Start Date"
                                margin="normal"
                                name="reason"
                                type="date"
                                variant="outlined"
                                value={startDate}
                                fullWidth
                                onChange={e => {
                                    setStartDate(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                helperText="End Date"
                                margin="normal"
                                name="reason"
                                type="date"
                                variant="outlined"
                                value={endDate}
                                fullWidth
                                onChange={e => {
                                    setEndDate(e.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                </Hidden>
            </Card>
            <StatsSection data={data} options={options} showBackButton={false} statLabel={'Server usage'}/>
        </Container>
    </Page>
};

const mapStateToProps = state => ({
    storeInfo: state.currentStore.storeInfo
});

const mapDispatchToProps = dispatch => ({
    setStoreInfo: storeInfo => dispatch(SetStoreInfo(storeInfo)),
    setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
    setErrors: errors => dispatch(SetErrors(errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingView);
